import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { Client } from "../../common/Client";
import { userByIdApiUrl, userPhotosUrl } from "../../constants/ApiConstants";
import { ToastErrorConfig } from "../../constants/SiteConstants";
import { LoginHelper } from "../../util/LoginHelper";
import { getUserList } from "../user/ActionCreator";
import { UserType } from "../user/Type";
import { updateProfileData, updateProfileError, updateProfileImage, updateProfileLoading } from "./Action";
import { ProfileFormValueType } from "./Type";

const transformData = (data: any): ProfileFormValueType => {
    return {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.username,
        password: data.password,
        id: data.id,
        imageUrl: "",
        image: null,
        role: data.role
    }
};

const transformPhoto = (value: ProfileFormValueType) => {
    const formData = new FormData();
    value.image && formData.append("photo", value.image as Blob);
    return formData;
}


const tranformProfileData = (data: UserType, profileImage: File | null | string): ProfileFormValueType => {
    return {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email,
        id: data.id,
        role: data.role,
        password: "",
        imageUrl: profileImage as string,
        image: null
    };
};

export const getProfileData = (userId: string, forceRefresh = false): any => (dispatch: Dispatch) => {
    dispatch(updateProfileLoading(true));
    return Client.getInstance()
        .getData(userByIdApiUrl(userId), {}, forceRefresh)
        .then((res) => dispatch(updateProfileData(transformData(res.data.data))))
        .catch(() => dispatch(updateProfileError("Something went wrong")))
        .finally(() => dispatch(updateProfileLoading(false)));
};

export const getProfileImage = (userDetails: UserType): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .getImage(userPhotosUrl(userDetails.id.toString()))
        .then((res) => {
            dispatch(updateProfileData(tranformProfileData(userDetails as UserType, res as string)));
        }).catch(() => {
            dispatch(updateProfileData(tranformProfileData(userDetails as UserType, null)));
            dispatch(updateProfileError("Something went wrong"));
        });

export const removeProfileImageAction = (userId: string): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .deleteData(userPhotosUrl(userId))
        .then(() => {
            dispatch(updateProfileImage())
            return Promise.resolve();
        })
        .catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            dispatch(updateProfileError("Something went wrong"));
        });

export const updateProfileImageAction = (value: ProfileFormValueType, setUploadPercent: (percent: number) => void): any => (dispatch: Dispatch) => {
    const options = {
        onUploadProgress: (progressEvent: any) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setUploadPercent(percent);
        }
    };
    return Client.getInstance()
        .updateData(userPhotosUrl(value.id.toString()), transformPhoto(value), options)
        .then(() => {
            dispatch(getProfileImage(value as any));
            return Promise.resolve();
        }).catch(() => dispatch(updateProfileError("Something went wrong")));
}

const reverseTransformData = (value: ProfileFormValueType) => {
    const data: Record<string, string> = {
        email: value.email,
        firstName: value.firstName,
        lastName: value.lastName,
        role: value.role
    };
    if (value.password !== "") {
        data["password"] = value.password;
    }
    return data;
}

export const updateProfileDataAction = (value: ProfileFormValueType): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .updateData(userByIdApiUrl(value.id.toString()), reverseTransformData(value))
        .then(() => {
            dispatch(getUserList(true));
            return Promise.resolve();
        })
        .catch((err) => {
            dispatch(updateProfileError("Something went wrong"));
            toast.error(err || "Something went wrong", ToastErrorConfig);
            return Promise.reject();
        })
        .finally(() => dispatch(updateProfileLoading(false)));

