import { Grid } from '@mui/material';
import { Formik } from 'formik';
import { colors } from '../../constants/Colors';
import { LogoContainer, StyledLogo } from '../commoncomponents/CommonStyle';
import { CustomTextfieldWrapper } from '../commoncomponents/CustomTextfieldWrapper';
import { PrimaryButton } from '../commoncomponents/designs/Button';
import { Label, PlaceholderText } from '../commoncomponents/designs/Text';
import FormikCheckbox from '../commoncomponents/fields/FormikCheckbox';
import ImageDrop from '../commoncomponents/fields/ImageUpload';
import ImageUploadModal from '../commoncomponents/ImageUploadModal';
import { useSignupFormHook } from './Hooks';
import { SignupInitialValue, SignUpValidationSchema } from './Util';

const SignupForm = () => {

    const { createAccountDetail, isImageUploading, uploadPercent } = useSignupFormHook();
    return (
        <Formik
            initialValues={SignupInitialValue}
            validationSchema={SignUpValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
                createAccountDetail(values, setSubmitting)
            }}
        >
            {
                ({ handleSubmit, isSubmitting, values, setFieldValue }) => (
                    <Grid container spacing={"20px"}>
                        <Grid item xs={6} >
                            <CustomTextfieldWrapper
                                name='firstName'
                                id='firstName'
                                fullWidth
                                label={"First name"}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <CustomTextfieldWrapper
                                name='lastName'
                                id='lastName'
                                fullWidth
                                label={"Last name"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextfieldWrapper
                                name='email'
                                id='email'
                                fullWidth
                                label={"Email"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextfieldWrapper
                                name='confirmEmail'
                                id='confirmEmail'
                                fullWidth
                                label={"Confirm Email"}
                            />
                        </Grid>
                        <Grid item xs={12} mt={"10px"}>
                            <PlaceholderText mb={"5px"} color={colors.primary.dark}>Profile photo</PlaceholderText>
                            {
                                values.profilePictureUrl ?
                                    <LogoContainer>
                                        <Grid item>
                                            <StyledLogo src={values.profilePictureUrl} />
                                        </Grid>
                                        <Grid item>
                                            <Label
                                                onClick={() => {
                                                    setFieldValue("profilePicture", null);
                                                    setFieldValue("profilePictureUrl", "");
                                                }}
                                                style={{ cursor: "pointer" }}>Remove</Label>
                                        </Grid>
                                    </LogoContainer>
                                    :
                                    <ImageDrop
                                        name='profilePicture'
                                        setImageUrl={(url) => setFieldValue("profilePictureUrl", url)}
                                        imageSize={1}
                                        logoInfo={"Upload a file or drag and drop"}
                                        logoSubMessage={"PNG, JPG, GIF up to 1MB"}
                                    />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextfieldWrapper
                                name='password'
                                id='password'
                                fullWidth
                                type={"password"}
                                label={"Password"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <CustomTextfieldWrapper
                                name='confirmPassword'
                                id='confirmPassword'
                                type={"password"}
                                fullWidth
                                label={"Confirm password"}
                            />
                        </Grid>
                        <Grid item xs={12} mt={"10px"}>
                            <FormikCheckbox
                                name='receiveEmail'
                                id='receiveEmail'
                                label='Receive system notifications via email'
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PrimaryButton
                                isLoading={isSubmitting}
                                textcolor={colors.secondary.light}
                                backgroundcolor={colors.primary.dark}
                                fullWidth
                                onClick={() => handleSubmit()} label='Create Account' />
                        </Grid>
                        {
                            values.profilePicture && isImageUploading && <ImageUploadModal
                                progress={uploadPercent}
                                file={values.profilePicture as File}
                                handleClose={() => setFieldValue("profilePicture", null)}
                            />
                        }
                    </Grid>
                )
            }
        </Formik>
    )
}

export default SignupForm