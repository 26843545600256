import { Grid } from '@mui/material';
import { FC } from 'react'
import { colors } from '../../constants/Colors';
import { SiteType } from '../../store/sites/Type';
import { PrimaryButton } from '../commoncomponents/designs/Button';
import { Paragraph2 } from '../commoncomponents/designs/Text';
import { PaperContainer, PropertyCardImage } from './Style';
import NoImage from "../../images/dummyimages/noimgproperty.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../util/Type';

type OptionActionsProps = {
    propertyInfo: SiteType;
}
const OptionActions: FC<OptionActionsProps> = ({
    propertyInfo
}) => {
    const { name, invoiceReference, invoiceImageUrl } = propertyInfo;
    const navigate = useNavigate();
    const { id } = useParams<{
        id: string
    }>();
    const role = useSelector(useGetUserRole);

    return (
        <PaperContainer elevation={0}>
            <PropertyCardImage src={invoiceImageUrl || NoImage} />
            <Grid mt={"15px"}>
                <Paragraph2 textAlign={"center"} color={colors.primary.light}>{name}</Paragraph2>
                <Paragraph2 textAlign={"center"} color={colors.primary.light}>{invoiceReference}</Paragraph2>
            </Grid>
            <Grid mt={"19px"}>
                <Paragraph2 textAlign={"center"} textTransform={"uppercase"} color={colors.primary.light}>Optional Actions</Paragraph2>
            </Grid>
            {
                doesLoggedinUserHavePermission(role, USER_ROLES.PROPERTY, PERMISSIONS.CREATE) &&
                <Grid mt={"15px"} spacing={"20px"} container >
                    <Grid item xs={12}>
                        <PrimaryButton variant='contained'
                            fullWidth
                            backgroundcolor={colors.secondary.light}
                            buttonType='secondary'
                            label='Add property'
                            onClick={() => navigate(`/site/${id}/manageProperties/add`)}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <PrimaryButton variant='contained'
                            fullWidth
                            backgroundcolor={colors.secondary.light}
                            buttonType='secondary'
                            onClick={() => navigate(`/site/${id}/manageProperties/addmultiple`)}
                            label='Add multiple properties'
                        />
                    </Grid>
                </Grid>
            }

        </PaperContainer>
    )
}

export default OptionActions