import { Grid } from '@mui/material';
import { FC } from 'react';
import Logo from "../../../images/logo.jpg";
import { StyledLogo } from './Style';

type LeftSectionProps = {
    children?: React.ReactNode;
}
const LeftSection: FC<LeftSectionProps> = ({ children }) => {
    return (
        <Grid>
            <StyledLogo src={Logo} alt="logo" />
            {children}
        </Grid>
    );
}

export default LeftSection