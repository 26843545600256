import { Grid } from '@mui/material'
import CurrentCustomer from './currentcustomer'
import FutureCustomer from './futurecustomer'
import { useCustomerHook } from './Hooks';
import PastCustomers from './pastcustomer'

const CustomerView = () => {
    const { filterCustomerByDate, customerList, allCustomers } = useCustomerHook();
    return (
        <Grid>
            <Grid mt={"35px"}>
                <CurrentCustomer allCustomers={allCustomers} key={JSON.stringify(customerList)} customerList={filterCustomerByDate("current")} />
            </Grid>
            <Grid mt={"35px"}>
                <FutureCustomer key={JSON.stringify(customerList)} customerList={filterCustomerByDate("future")} />
            </Grid>
            <Grid mt={"35px"}>
                <PastCustomers key={JSON.stringify(customerList)} customerList={filterCustomerByDate("past")} />
            </Grid>
        </Grid>
    );
};

export default CustomerView;
