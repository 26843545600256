import { ManagePropertyDetailType } from "../../../../store/sites/Type";

export const PropertySettingInitialValue = (data?: ManagePropertyDetailType): ManagePropertyDetailType => ({
    address: data?.address || "",
    name: data?.name || "",
    type: data?.type || "",
    image: data?.image || null,
    imageUrl: data?.imageUrl || "",
    xeroCategory1: data?.xeroCategory1 || "",
    xeroCategory2: data?.xeroCategory2 || "",
    xeroOption1: data?.xeroOption1 || "",
    xeroOption2: data?.xeroOption2 || "",
    services: data?.services || []
});
