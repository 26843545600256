import { Grid, IconButton } from '@mui/material';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import { AiOutlineCloud } from 'react-icons/ai';
import { BiHide, BiShow } from 'react-icons/bi';
import { colors } from '../../constants/Colors';
import { CustomTextfieldWrapper } from '../commoncomponents/CustomTextfieldWrapper';
import { PrimaryButton, TextButton } from '../commoncomponents/designs/Button';
import { Label } from '../commoncomponents/designs/Text';
import ImageDrop from '../commoncomponents/fields/ImageUpload';
import { LogoContainer, LogoImage } from './Style';
import ImageUploadModal from '../commoncomponents/ImageUploadModal';
import { useProfileFormHook } from './Hooks';
import { ProfileDetailFormValueType } from './Type';

const ProfileForm = () => {
    const [showPassword, setShowPassword] = useState({
        confirmPassword: false,
        password: false,
    });
    const { values, handleSubmit, isSubmitting, setFieldValue, setValues } = useFormikContext();
    const data = values as ProfileDetailFormValueType;
    const { percent, removeProfileImage } = useProfileFormHook(data);

    const renderPassword = () => {
        return (
            <Grid container border={`1px ${colors.secondary.light} solid`} borderRadius={"8px"} bgcolor={colors.secondary.light} p={"10px"}>
                <Grid item xs={12} mt={"20px"}>
                    <Label>Password</Label>
                    <CustomTextfieldWrapper
                        name={"password"}
                        id={'password'}
                        type={showPassword.password ? "text" : "password"}
                        variant={"standard"}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setShowPassword((prev) => ({
                                    ...prev,
                                    password: !prev.password,
                                }))}>
                                    {
                                        showPassword ? <BiHide /> : <BiShow />
                                    }
                                </IconButton>
                            )
                        }}
                    />
                </Grid>
                <Grid item xs={12} mt={"20px"}>
                    <Label>Confirm Password</Label>
                    <CustomTextfieldWrapper
                        name={"confirmPassword"}
                        id={'confirmPassword'}
                        type={showPassword.confirmPassword ? "text" : "password"}
                        variant={"standard"}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <IconButton onClick={() => setShowPassword((prev) => ({
                                    ...prev,
                                    confirmPassword: !prev.confirmPassword
                                }))}>
                                    {
                                        showPassword ? <BiHide /> : <BiShow />
                                    }
                                </IconButton>
                            )
                        }}
                    />
                </Grid>
            </Grid>
        )
    }
    return (
        <Grid>
            {
                data.imageUrl ?
                    <Grid container justifyContent={"center"}>
                        <LogoContainer>
                            <LogoImage src={data.imageUrl} />
                            <Label
                                onClick={() => removeProfileImage(data, setValues)}
                                style={{ cursor: "pointer" }}>Remove</Label>
                        </LogoContainer>
                    </Grid>
                    :
                    <ImageDrop
                        name='image'
                        setImageUrl={(url) => setFieldValue("imageUrl", url)}
                        imageSize={1}
                        logoInfo={"Upload a file or drag and drop"}
                        logoSubMessage={"PNG, JPG, GIF up to 1MB"}
                    />
            }
            {
                data.image && <ImageUploadModal
                    progress={percent}
                    file={data.image as File}
                    handleClose={() => setFieldValue("image", null)}
                />
            }
            <Grid item mt={"26px"}>
                <Label>First name</Label>
                <CustomTextfieldWrapper
                    name={"firstName"}
                    id={'firstName'}
                    variant={"standard"}
                    fullWidth
                />
            </Grid>
            <Grid item mt={"20px"}>
                <Label>Last name</Label>
                <CustomTextfieldWrapper
                    name={"lastName"}
                    id={'lastName'}
                    variant={"standard"}
                    fullWidth
                />
            </Grid>
            <Grid item mt={"20px"}>
                <Label>Username(Email)</Label>
                <CustomTextfieldWrapper
                    name={"email"}
                    id={'email'}
                    variant={"standard"}
                    fullWidth
                />
            </Grid>
            <Grid item mt={"20px"} display={"flex"} justifyContent={"flex-end"}>
                <TextButton
                    additionalStyles={{
                        fontWeight: 'bold',
                        textDecoration: "underline"
                    }} label={!data.changePassword ? 'Change password' : 'Old password'} onClick={() => setFieldValue("changePassword", !data.changePassword)} />
            </Grid>
            {
                data.changePassword && renderPassword()
            }
            <Grid item xs={12} mt={"26px"} md={12}>
                <PrimaryButton isLoading={isSubmitting} onClick={() => handleSubmit()} label='Update' backgroundcolor={colors.primary.dark} textcolor={colors.secondary.neutral} fullWidth icon={<AiOutlineCloud />} />
            </Grid>
        </Grid>
    );
};

export default ProfileForm;
