import { Grid, useMediaQuery, useTheme } from '@mui/material';
import DataGridWrapper from '../commoncomponents/datagrid/DataGrid';
import SideNavbar from '../commoncomponents/sidenavbar';
import LeftSection from '../commoncomponents/sitesection/LeftSection';
import RightSection from '../commoncomponents/sitesection/RightSection';
import OptionalActions from './OptionActions';
import { useManagePropertyHook } from './Hooks';
import { manageSiteOptions, SiteManageProperty } from './Util';
import ManagerPropertyHeader from './mobile/ManagePropertyHeader';
import { PrimaryButton } from '../commoncomponents/designs/Button';
import { colors } from '../../constants/Colors';
import OptionalActionPopup from './mobile/OptionalActionPopup';
import MobileNavbarMenu from '../commoncomponents/sidenavbar/MobileNavbar';
import BreadcrumbWrapper from '../commoncomponents/designs/BreadcrumbWrapper';
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../util/Type';

const ManageProperty = () => {
    const {
        propertiesList,
        stepper,
        managePropertyListColumns,
        id,
        propertyInfo,
        anchorEl,
        setAnchorEl,
        handleAnchorClick,
        isLoading
    } = useManagePropertyHook();

    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const ipadView = useMediaQuery(theme.breakpoints.down(1200));
    const role = useSelector(useGetUserRole);

    if (mobileView) {
        return (
            <Grid>
                {
                    propertyInfo && <ManagerPropertyHeader propertyInfo={propertyInfo} />
                }
                <MobileNavbarMenu
                    menuOptions={manageSiteOptions(id || "", role)}
                    selectedMenuId={1}
                />
                <Grid style={{
                    pointerEvents:
                        !doesLoggedinUserHavePermission(role, USER_ROLES.PROPERTY, PERMISSIONS.VIEW)
                            ? "none" : "all"
                }}>
                    <DataGridWrapper
                        rows={propertiesList as any}
                        loading={isLoading}
                        columns={managePropertyListColumns(mobileView)}
                    />
                </Grid>
            </Grid>
        );
    }

    if (ipadView) {
        return (
            <Grid container spacing={"40px"}>
                <Grid item xs={3}>
                    <LeftSection>
                        <SideNavbar
                            selectedMenuId={2}
                            sidebarOptions={SiteManageProperty(id || "", role)}
                        />
                    </LeftSection>
                </Grid>
                <Grid item xs={9}>
                    <BreadcrumbWrapper
                        tabType={stepper}
                    />
                    <Grid mt={"47px"}
                        style={{
                            pointerEvents:
                                !doesLoggedinUserHavePermission(role, USER_ROLES.PROPERTY, PERMISSIONS.VIEW)
                                    ? "none" : "all"
                        }}>
                        <Grid container justifyContent={"flex-end"}>
                            <Grid item>
                                <PrimaryButton
                                    buttonview='sm'
                                    onClick={handleAnchorClick}
                                    textcolor={colors.secondary.neutral}
                                    backgroundcolor={colors.primary.dark}
                                    label='OPTIONAL ACTIONS' />
                            </Grid>
                        </Grid>
                        <OptionalActionPopup
                            anchorEl={anchorEl}
                            handleClose={() => setAnchorEl(null)}
                        />
                        <DataGridWrapper
                            rows={propertiesList as any}
                            loading={isLoading}
                            columns={managePropertyListColumns()}
                        />

                    </Grid>
                </Grid>
            </Grid>
        )
    }
    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar
                        selectedMenuId={2}
                        sidebarOptions={SiteManageProperty(id || "", role)}
                    />
                </LeftSection>
            </Grid>
            <Grid item xs={7}>
                <BreadcrumbWrapper
                    tabType={stepper}
                />
                <Grid mt={"47px"}
                    style={{
                        pointerEvents:
                            !doesLoggedinUserHavePermission(role, USER_ROLES.PROPERTY, PERMISSIONS.VIEW)
                                ? "none" : "all"
                    }}>
                    <DataGridWrapper
                        rows={propertiesList as any}
                        columns={managePropertyListColumns()}
                    />
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <RightSection>
                    {
                        propertyInfo && <OptionalActions propertyInfo={propertyInfo} />
                    }
                </RightSection>
            </Grid>
        </Grid>
    )
}

export default ManageProperty;