import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { Client } from "../../common/Client";
import { UpdateUserType } from "../../components/user/updateuser/type";
import { updateArchiveApiUrl, userApiUrl, userByIdApiUrl, userPhotosUrl } from "../../constants/ApiConstants";
import { ToastErrorConfig } from "../../constants/SiteConstants";
import { LoginHelper } from "../../util/LoginHelper";
import { getProfileImage } from "../profile/ActionCreator";
import { ProfileFormValueType } from "../profile/Type";
import { updateListIsError, updateListIsLoading, updateUserList } from "./Action";
import { AddUserType, UserType } from "./Type";

const transformData = (data: any): UserType[] => {
    if (!data) {
        return [];
    }

    return data.map((item: any) => ({
        id: item.id,
        createdAt: item.created,
        modifiedAt: item.modified,
        email: item.email,
        role: item?.role || "none",
        archived: item.archived,
        firstName: item.firstName,
        lastName: item.lastName,
        emailNotifications: item.emailNotifications
    }));
};

export const getUserList = (forceRefresh = false): any => (dispatch: Dispatch) => {
    dispatch(updateListIsLoading(true));
    return Client.getInstance()
        .getData(userApiUrl(""), {}, forceRefresh)
        .then((res) => {
            const userList = transformData(res.data.data.results);
            const userEmail = LoginHelper.getUser();
            const userDetails = userList.find((item: UserType) => item.email === userEmail.email);
            dispatch(updateUserList(userList));
            dispatch(getProfileImage(userDetails as UserType));
        })
        .catch(() => dispatch(updateListIsError("Something went wrong")))
        .finally(() => dispatch(updateListIsLoading(false)));
};

const revereseAddUserTransformData = (data: AddUserType): any => {
    return {
        email: data.email,
        emailNotifications: data.receiveEmail,
        firstName: data.firstName,
        lastName: data.lastName,
        password: data.password,
        role: data.role
    };
};

export const addNewUser = (value: AddUserType): any => (dispatch: Dispatch) => {
    dispatch(updateListIsLoading(true));
    return Client.getInstance()
        .createData(userApiUrl(""), revereseAddUserTransformData(value))
        .then((res) => {
            dispatch(getUserList(true));
            return Promise.resolve(res.data.data.id);
        })
        .catch((err) => {
            dispatch(updateListIsError("Something went wrong"));
            toast.error(err || "Something went wrong", ToastErrorConfig);
            return Promise.reject();
        })
        .finally(() => dispatch(updateListIsLoading(false)));
};

export const updateArchivedUser = (userId: string, isArchiveBoolean: boolean): any => (dispatch: Dispatch) => {
    dispatch(updateListIsLoading(true));
    return Client.getInstance().createData(updateArchiveApiUrl(userId, isArchiveBoolean), {}).then(() => {
        dispatch(getUserList(true));
        return Promise.resolve();
    })
        .catch(() => {
            dispatch(updateListIsError("Something went wrong"));
            return Promise.reject();
        })
        .finally(() => dispatch(updateListIsLoading(false)));
};

const transformPhoto = (value: ProfileFormValueType) => {
    const formData = new FormData();
    value.image && formData.append("photo", value.image as Blob);
    return formData;
}

export const uploadUserImageAction = (value: ProfileFormValueType, setUploadPercent: (percent: number) => void): any => (dispatch: Dispatch) => {
    const options = {
        onUploadProgress: (progressEvent: any) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setUploadPercent(percent);
        }
    };
    return Client.getInstance()
        .updateData(userPhotosUrl(value.id.toString()), transformPhoto(value), options)
        .then(() => Promise.resolve())
        .catch((err) => {
            dispatch(updateListIsError("Something went wrong"));
            toast.error(err || "Something went wrong", ToastErrorConfig);
        });
}

const userReverseTransformData = (value: UserType) => ({
    email: value.email,
    firstName: value.firstName,
    lastName: value.lastName,
    role: value.role
});

export const updateUserDetail = (value: UserType): any => (dispatch: Dispatch) => Client.getInstance()
    .updateData(userByIdApiUrl(value.id.toString()), userReverseTransformData(value))
    .then(() => {
        dispatch(getUserList(true));
        return Promise.resolve();
    })
    .catch((err) => {
        toast.error(err || "Something went wrong", ToastErrorConfig);
        return Promise.reject();
    })

export const getUserImage = (userId: string) =>
    Client.getInstance()
        .getImage(userPhotosUrl(userId))
        .then((res) => {
            return Promise.resolve(res);
        }).catch(() => {
            return Promise.reject();
        });


const transformUpdatePhoto = (value: UpdateUserType) => {
    const formData = new FormData();
    value.image && formData.append("photo", value.image as Blob);
    return formData;
}

export const updateUserProfileImageAction = (
    userId: string,
    value: UpdateUserType,
    setUploadPercent: (percent: number) => void) => {
    const options = {
        onUploadProgress: (progressEvent: any) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setUploadPercent(percent);
        }
    };
    return Client.getInstance()
        .updateData(userPhotosUrl(userId), transformUpdatePhoto(value), options)
        .then(() => {
            return Promise.resolve();
        }).catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
        });
}
