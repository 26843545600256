import {
  API_BASE_URL as BASE_URL,
  MAP_KEY,
  XERO_CONNECT_API_URL,
  XERO_REQUEST_URL,
} from "./SiteConstants";

export const loginApiUrl = `${BASE_URL}/whoami`;
export const createAccountApiUrl = `${BASE_URL}/signup/dummy`;

//User api-
export const userApiUrl = (addtionalInfo?: string) =>
  `${BASE_URL}/users${addtionalInfo}`;
export const createNewUserUrl = `${BASE_URL}/create`;
export const confirmNewlyCreatedUrl = (code: string) =>
  `${BASE_URL}/users/confirm?code=${code}`;
export const userByIdApiUrl = (userId: string) => `${BASE_URL}/users/${userId}`; //get, update
export const deleteUserUrl = (userId: string) => `${BASE_URL}/users/${userId}`;
export const userPhotosUrl = (userId: string) =>
  `${BASE_URL}/users/${userId}/photo`; //get, update, delete
export const updateArchiveApiUrl = (userId: string, isArchived: boolean) =>
  `${BASE_URL}/users/${userId}/archived?archived=${isArchived}`;

//Site Xero api-
export const deleteSiteXeroApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/xero/auth`;
export const connectSiteXeroAPiUrl = (
  siteId: string,
  code: string,
  state: string
) =>
  `${BASE_URL}/sites/${siteId}/xero/auth-connect?state=${state}&code=${code}&request_uri=${XERO_REQUEST_URL}`;
export const getSiteXeroAuthApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/xero/auth-params`;

export const getXeroDetailAuthInfo = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/xero/auth-info`;

export const getSiteXeroCustomersApiUrl = (siteId: string, text: string) =>
  `${BASE_URL}/sites/${siteId}/xero/customers?query=${text}`;
export const getSiteXeroAccountCodesApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/xero/account-codes`;
export const getSiteXeroInvoiceOptionApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/xero/invoice-options`;
export const loginXeroAuth = (
  responseType: string,
  clientId: string,
  scope: string,
  state: string
) =>
  `${XERO_CONNECT_API_URL}?response_type=${responseType}&client_id=${clientId}&redirect_uri=${XERO_REQUEST_URL}&scope=${scope}&state=${state}`;
//Site service api-
export const siteServicesApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/services`; //get, add
export const updateSiteServiceApiUrl = (siteId: string, serviceId: string) =>
  `${BASE_URL}/sites/${siteId}/services/${serviceId}`; //update, delete
export const updateSiteTimeOfScheduleUrl = (siteId: string, name: string) =>
  `${BASE_URL}/sites/${siteId}/services/${name}/tou`;
export const downloadTimeOfUseApiUrl = `${BASE_URL}/dummy`;

//site-property-customer api -
export const sitePropertyCustomersApiUrl = (
  siteId: string,
  propertyId: string
) => `${BASE_URL}/sites/${siteId}/properties/${propertyId}/customers`; //get, add
export const updateSitePropertyCustomerApiUrl = (
  siteId: string,
  propertyId: string,
  customerId: string
) =>
  `${BASE_URL}/sites/${siteId}/properties/${propertyId}/customers/${customerId}`;
export const deleteSitePropertyCustomerApiUrl = (
  siteId: string,
  propertyId: string,
  customerId: string
) =>
  `${BASE_URL}/sites/${siteId}/properties/${propertyId}/customers/${customerId}`; //update, delete. get
export const createSitePropertyServiceUsageApiUrl = (
  siteId: string,
  propertyId: string,
  customerId: string
) =>
  `${BASE_URL}/sites/${siteId}/properties/${propertyId}/customers/${customerId}/service-usages`;

//Site property api-
export const getSiteSearchPropertiesApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/properties`;
export const createNewPropertyApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/properties`;
export const getpropertyDetailApiUrl = (siteId: string, propertyId: string) =>
  `${BASE_URL}/sites/${siteId}/properties/${propertyId}`; //get, update
export const propertyPhotosApiUrl = (siteId: string, propertyId: string) =>
  `${BASE_URL}/sites/${siteId}/properties/${propertyId}/photo`; //get, update, delete
export const getPropertyServiceUsagesApiUrl = (
  siteId: string,
  propertyId: string
) => `${BASE_URL}/sites/${siteId}/properties/${propertyId}/service-usages`;
export const propertyServiceUsageApiUrl = (
  siteId: string,
  propertyId: string,
  serviceId: string
) =>
  `${BASE_URL}/sites/${siteId}/properties/${propertyId}/services/${serviceId}`; //update, delete
export const deletepropertyDetailApiUrl = (
  siteId: string,
  propertyId: string
) => `${BASE_URL}/sites/${siteId}/properties/${propertyId}`;
export const uploadMulitpleProperty = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/properties/import`;
//Site api-
export const getActiveBillingPeriodApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/active-billing-period`;
export const getSiteListApiUrl = (query: string) => `${BASE_URL}/sites${query}`;
export const searchSiteApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}`;
export const createNewSiteApiUrl = `${BASE_URL}/sites`;
export const getSiteDetailApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}`;
export const updateSiteApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}`;
export const deleteSiteApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}`;
export const updateSiteStatusApiUrl = (siteId: string, isActive: boolean) =>
  `${BASE_URL}/sites/${siteId}/active?active=${isActive}`;
export const sitePhotoUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/photo`; // update, delete, get
export const serviceCategoriesApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/service-categories`; //get, post
export const updateServiceCategoriesApiUrl = (siteId: string, catId: string) =>
  `${BASE_URL}/sites/${siteId}/service-categories/${catId}`; //update delete

//Org api -
export const searchOrgApiUrl = () => `${BASE_URL}/orgs`; //search, create new organisation
export const orgDetailApiUrl = (organisationId: string) =>
  `${BASE_URL}/orgs/${organisationId}`; //get, update, delete
export const updateAssignUserToOrgUrl = (organisationId: string) =>
  `${BASE_URL}/orgs/${organisationId}/members`;
export const orgPhotoApiUrl = (organisationId: string) =>
  `${BASE_URL}/orgs/${organisationId}/photo`; //update, delete, get
export const orgServiceTariffApiUrl = (organisationId: string) =>
  `${BASE_URL}/orgs/${organisationId}/service-tariff-kinds`; //get, post
export const orgServiceUnitsApiUrl = (organisationId: string) =>
  `${BASE_URL}/orgs/${organisationId}/service-units`; //get, post
export const orgServiceUnitsDetailApiUrl = (
  organisationId: string,
  unitId: string
) => `${BASE_URL}/orgs/${organisationId}/service-units/${unitId}`; //update, delete

//lookup api -

export const lookupBiilingCycleApiUrl = `${BASE_URL}/lookup/billing-cycles`; //get
export const lookupCurrenciesApiUrl = `${BASE_URL}/lookup/currencies`; //get
export const lookupLanguagesApiUrl = `${BASE_URL}/lookup/languages`; //get
export const lookupRolesApiUrl = `${BASE_URL}/lookup/roles`; //get
export const lookupTimeZoneApiUrl = `${BASE_URL}/lookup/time-zones`; //get
export const lookupSiteServiceBillingApiUrl = `${BASE_URL}/lookup/service-billing-types`; //get
export const lookupInvoiceReferenceParamsApiUrl = `${BASE_URL}/lookup/invoice-reference-params`; //get
export const lookupServiceChronoUnitsApiUrl = `${BASE_URL}/lookup/service-chrono-units`;
export const lookupXeroInvoiceStatusApiUrl = `${BASE_URL}/lookup/invoice-statuses`;
export const lookupAvailaibleInvoiceItemApiUrl = `${BASE_URL}/lookup/invoice-item-description-params`;

//helpanfeedback
export const sendHelpANdFeedbackMessageApiUrl = `${BASE_URL}/help`; //post

//download template
export const downloadPropertyTemplateCsv = `${BASE_URL}/templates/property-import-csv`;
export const downloadServiceUsageTemplateCsv = `${BASE_URL}/templates/site-service-usage-import-csv`;
export const uploadServiceUsageTemplateCsv = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/properties/import-service-usages`;

export const googleMapAPiKey = () =>
  `https://maps.googleapis.com/maps/api/js?key=${MAP_KEY}&libraries=places`;

//forgot password
export const forgotpasswordApiUrl = (email: string) =>
  `${BASE_URL}/users/forgot-password?email=${email}`; //post
export const resetpasswordApiUrl = `${BASE_URL}/users/reset-password`; //post

//publish invoices
export const publishInvoicesApiUrl = (siteId: string) =>
  `${BASE_URL}/sites/${siteId}/publish-invoices`;
