import { object, string } from "yup"
import { Client } from "../../common/Client"
import { forgotpasswordApiUrl } from "../../constants/ApiConstants"

export const forgotPassword = (email: string) => Client.getInstance()
    .createData(forgotpasswordApiUrl(email), {})
    .then(() => Promise.resolve());

export const forgotPasswordInitialValue = {
    email: '',
}

export const forgotPasswordValidationSchema = object().shape({
    email: string().email("Please enter valid email").required('Email is required'),
});
