import { useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { portfolioData, portfolioType, propertyListType } from "../../constants/DummyData";
import { managePropertyTabOption } from "./Util";
import { GridCellParams } from "@mui/x-data-grid";
import { StyledColumnText, StyledHeader } from "../commoncomponents/datagrid/DataGridStyle";
import { BiBuilding } from "react-icons/bi";
import { BsFillHouseDoorFill } from "react-icons/bs";
import { MdApartment } from "react-icons/md";
import { TextButton } from "../commoncomponents/designs/Button";
import { useDispatch, useSelector } from "react-redux";
import { useGetSiteDetail, useGetSitePropertyList, useSiteLoading } from "../../store/sites/Selector";
import { getSiteIdDetail, getSitePropertyList } from "../../store/sites/ActionCreator";

const renderPropertyType: Record<string, any> = {
    Apartment: <MdApartment style={{ fontSize: 30 }} />,
    House: <BsFillHouseDoorFill style={{ fontSize: 30 }} />,
    Chalet: <BiBuilding style={{ fontSize: 30 }} />
};

export const useManagePropertyHook = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const dispatch = useDispatch();

    const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const propertyInfo = useSelector(useGetSiteDetail);
    const propertiesList = useSelector(useGetSitePropertyList);
    const isLoading = useSelector(useSiteLoading);

    useEffect(() => {
        if (id) {
            dispatch(getSiteIdDetail(id));
            dispatch(getSitePropertyList(id));
        }
    }, []);

    const stepper = managePropertyTabOption(id || "");

    const managePropertyListColumns = (isMobileView?: boolean) => useMemo(() => ([
        {
            field: "type",
            headerName: "Type",
            width: 90,
            renderHeader: () => <StyledHeader label="Type" />,
            renderCell: (params: GridCellParams) => renderPropertyType[params.row.type]
        },
        {
            field: "name",
            headerName: "Property Name",
            flex: 1,
            renderHeader: () => <StyledHeader label="Property Name" />,
            renderCell: (params: GridCellParams) => <StyledColumnText isMobileView={isMobileView} label={params.row.name} />
        },
        {
            field: "id",
            width: isMobileView ? 100 : 150,
            headerName: "",
            renderHeader: () => <StyledHeader label="" />,
            renderCell: (params: GridCellParams) => <TextButton style={{ textTransform: "uppercase" }} buttonview="sm" variant="text" onClick={() => navigate(`/site/${id}/manageProperties/${params.row.id}/edit`)} label="Manage" icon={<BiBuilding />} />
        }
    ]), []);

    return {
        propertiesList,
        id,
        stepper,
        managePropertyListColumns,
        propertyInfo,
        handleAnchorClick,
        anchorEl,
        setAnchorEl,
        isLoading
    }
}
