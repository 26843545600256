import { mixed, object, string } from "yup";
import { dummySiteServiceCsv } from "../../../../../constants/DummyData";
import { ServiceListType } from "../../../../../store/siteservice/Type";
import { tabOptionType } from "../../../../commoncomponents/designs/Type";
import { ServiceSettingType } from "./Type";
import { number } from "yup";

export const ServiceSettingInitialValue = (serviceInfo?: ServiceListType): ServiceSettingType => ({
    name: serviceInfo?.name || "",
    selectedTariffTab: "simple",
    description: serviceInfo?.description || "",
    serviceCategory: serviceInfo?.category?.id.toString() || "none",
    nodeId: serviceInfo?.dataEnum?.nodeId.toString() || '',
    sourceId: serviceInfo?.dataEnum?.sourceId || '',
    metric: serviceInfo?.dataEnum?.metric || '',
    enabled: serviceInfo?.enabled || false,
    xeroAccountingCode: serviceInfo?.xeroDetails.accountCode || "none",
    tou: {
        file: null
    },
    tariffId: serviceInfo?.tariffKind.id.toString() || "",
    simpleTarif: {
        billingType: serviceInfo?.billingType || "Metered",
        standingCharge: serviceInfo?.chronoUnits || "none",
        standingChargeUnit: serviceInfo?.unitRate.toString() || "",
        standingUnitRate: serviceInfo?.units.id.toString() || "none",
        standingUnits: serviceInfo?.unitRate.toString() || "",
    },
    invoiceTransactionDescription: serviceInfo?.invoiceTransactionDescription || ""
});

export const tabOptions: tabOptionType[] = [
    {
        label: 'Simple Tarif',
        value: 'simple',
        isAvailaible: true
    },
    {
        label: 'Time of use',
        value: 'time',
        isAvailaible: true
    },
];

export const ServiceValidationSchema = (isMetered = false) => object().shape({
    name: string().required("Name is required"),
    description: string().required("Description is required"),
    serviceCategory: string().notOneOf(["none"], "Service category is required").required("Service category is required"),
    nodeId: isMetered ? number().typeError("Node Id should be a number") : string().nullable(),
    tariffId: string().notOneOf(["none"], "Tariff Id is required").required("Tariff id is required"),
    simpleTarif: mixed().when("selectedTariffTab", {
        is: (val: string) => val === "simple",
        then: object().shape({
            billingType: string().notOneOf(["none"], "Billing type is required").required("Billing type is required"),
            standingCharge: string().when("billingType", {
                is: (val: string) => !["Metered", "Manual"].includes(val),
                then: string().notOneOf(["none"], "Standing charge is required").required("Standing charge is required"),
                otherwise: string().nullable()
            }),
            standingUnitRate: string().when("billingType", {
                is: (val: string) => val !== "Recurring",
                then: string().notOneOf(["none"], "Unit rate is required").required("Unit rate is required"),
                otherwise: string().nullable(),
            })
        }),
        otherwise: object().nullable()
    })
});

export const CategoryInitialValue = {
    name: ""
}

export const CategoryValidationSchema = object().shape({
    name: string().required("Please enter category name")
});

export const UnitInitialValue = {
    name: ""
}

export const UnitValidationSchema = object().shape({
    name: string().required("Please enter category name")
});

const downloadBlob = (content: string, filename: string, contentType: string) => {
    const blob = new Blob([content], { type: contentType });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement('a');
    pom.href = url;
    pom.setAttribute('download', filename);
    pom.click();
}

export const downloadSiteServiceCsv = (fileName?: string) => downloadBlob(dummySiteServiceCsv, `${fileName || "demo"}.csv`, 'text/csv;charset=utf-8;');
