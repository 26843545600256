import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { Client } from "../../common/Client";
import { OptionType } from "../../components/commoncomponents/fields/Type";
import { getSiteListApiUrl, orgDetailApiUrl, orgPhotoApiUrl, searchOrgApiUrl, updateAssignUserToOrgUrl, updateSiteApiUrl, userApiUrl } from "../../constants/ApiConstants";
import { ToastErrorConfig } from "../../constants/SiteConstants";
import { transformSiteData } from "../sites/ActionCreator";
import { SiteType } from "../sites/Type";
import { updateOrgList, updateOrgListIsError, updateOrgListIsLoading, updateOrgSelectedUserList, updateOrgSelectedSiteList, updateOrgUserList, updateSelectedOrgDetail, updateOrgSiteList, updateOrgImage } from "./Action";
import { OrgDetailType, OrgSiteListType, OrgSwitchListType, OrgSwitchType, OrgType } from "./Type";

const transformData = (data: any): OrgType[] => {
    if (!data) {
        return [];
    }

    return data.map((item: any) => ({
        id: item.id,
        createdAt: item.created,
        modifiedAt: item.modified,
        name: item.name,
        role: ""
    }));
};

export const getOrgList = (forceRefresh = false): any => (dispatch: Dispatch) => {
    dispatch(updateOrgListIsLoading(true));
    return Client.getInstance()
        .getData(searchOrgApiUrl(), {}, forceRefresh)
        .then((res) => dispatch(updateOrgList(transformData(res.data.data.results))))
        .catch(() => dispatch(updateOrgListIsError("Something went wrong")))
        .finally(() => dispatch(updateOrgListIsLoading(false)));
};

export const deleteOrgListItem = (orgId: string): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .deleteData(orgDetailApiUrl(orgId))
        .then(() => dispatch(getOrgList(true)))
        .catch((err) => {
            dispatch(updateOrgListIsError("Something went wrong"));
            toast.error(err || "Something went wrong", ToastErrorConfig);
        })
        .finally(() => dispatch(updateOrgListIsLoading(false)));
};

export const addOrgItem = (name: string): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .createData(searchOrgApiUrl(), {
            name: name
        })
        .then((res) => {
            dispatch(getOrgList(true));
            dispatch(updateSelectedOrgDetail(transformOrgDetailData(res.data.data)));
            return Promise.resolve(res.data.data.id);
        })
        .catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            dispatch(updateOrgListIsError("Something went wrong"));
        })
        .finally(() => dispatch(updateOrgListIsLoading(false)));
};

export const updateOrgItem = (name: string, id: string): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .updateData(orgDetailApiUrl(id), {
            name: name
        })
        .then((res) => {
            dispatch(getOrgList(true));
            return Promise.resolve(res.data.data.id);
        })
        .catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            return Promise.reject(err)
        })
};

const transformOrgDetailData = (data: any): OrgType => {
    return {
        id: data.id,
        createdAt: data.created,
        modifiedAt: data.modified,
        name: data.name,
        userList: {
            allList: [],
            selectedList: []
        },
        siteList: {
            allList: [],
            selectedList: []
        }
    }
}

export const getOrgDetail = (id: string): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .getData(orgDetailApiUrl(id), {}, true)
        .then((res) => {
            dispatch(updateSelectedOrgDetail(transformOrgDetailData(res.data.data)));
            return Promise.resolve();
        })
        .catch(() => Promise.reject("Something went wrong"))
};

export const getOrgDropdownList = (orgList: OrgType[]): OptionType[] => {
    if (!orgList) {
        return [];
    }
    const transformData = [{
        value: "none",
        label: "Select organisation"
    }]
    const transformOrgList = orgList.map((item: OrgType) => ({
        value: item.id.toString(),
        label: item.name
    }));
    return [...transformData, ...transformOrgList];
};

export const updateSelectedOrgUserList = (orgId: string, userList: OrgSwitchListType): any => (dispatch: Dispatch) => {
    return Client.getInstance().updateData(updateAssignUserToOrgUrl(orgId), userList.selectedList.map((item) => item.id)).then(() => {
        dispatch(updateOrgUserList(userList))
        return Promise.resolve();
    }).catch((err) => {
        dispatch(updateOrgListIsError("Something went wrong"));
        toast.error(err || "Something went wrong", ToastErrorConfig);
        return Promise.reject(err);
    });
};


const transformOrgSelectedUserListData = (data: any): OrgSwitchType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        id: item.id,
        name: `${item.firstName} ${item.lastName}`,
    }));
}

export const getSelectedOrgUserList = (orgId: string, forceRefresh = false): any => (dispatch: Dispatch) => {
    dispatch(updateOrgListIsLoading(true));
    return Client.getInstance()
        .getData(userApiUrl(`?archived=false&max=100&offset=0&organisationIds=${orgId}`), {}, forceRefresh)
        .then((res) => dispatch(updateOrgSelectedUserList(transformOrgSelectedUserListData(res.data.data.results))))
        .catch(() => dispatch(updateOrgListIsError("Something went wrong")))
        .finally(() => dispatch(updateOrgListIsLoading(false)));
};

export const getSelectedOrgSiteList = (orgId: string, forceRefresh = false): any => (dispatch: Dispatch) => {
    dispatch(updateOrgListIsLoading(true));
    return Client.getInstance()
        .getData(getSiteListApiUrl(`?max=100&offset=0&organisationIds=${orgId}`), {}, forceRefresh)
        .then((res) => dispatch(updateOrgSelectedSiteList(transformSiteData(res.data.data.results))))
        .catch(() => dispatch(updateOrgListIsError("Something went wrong")))
        .finally(() => dispatch(updateOrgListIsLoading(false)));
};

export const updateSelectedOrgSiteList = (orgId: string, userList: OrgSiteListType, selectedSite: SiteType): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .updateData(
            updateSiteApiUrl(selectedSite.id.toString()),
            {
                ...selectedSite,
                organisationId: orgId
            }).then(() => {
                dispatch(updateOrgSiteList(userList));
                return Promise.resolve();
            }).catch((err) => {
                toast.error(err, ToastErrorConfig);
                return Promise.reject(err);
            });

const transformPhoto = (value: OrgDetailType) => {
    const formData = new FormData();
    value.image && formData.append("photo", value.image as Blob);
    return formData;
}

export const updateOrganisationImageAction = (value: OrgDetailType, setUploadPercent: (percent: number) => void): any => (dispatch: Dispatch) => {
    const options = {
        onUploadProgress: (progressEvent: any) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setUploadPercent(percent);
        }
    };
    return Client.getInstance()
        .updateData(orgPhotoApiUrl(value.id.toString()), transformPhoto(value), options)
        .then(() => {
            dispatch(getOrgImage(value.id, true));
            return Promise.resolve();
        }).catch((err) => {
            toast.error(err, ToastErrorConfig);
            return Promise.reject(err);
        });
}

export const getOrgImage = (id: string, forceRefresh = false): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .getImage(orgPhotoApiUrl(id), {}, forceRefresh)
        .then((res) => {
            dispatch(updateOrgImage(res));
        }).catch(() => {
            dispatch(updateOrgListIsError("Something went wrong"));
        });

export const removeOrgImageAction = (id: string): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .deleteData(orgPhotoApiUrl(id))
        .then(() => {
            dispatch(updateOrgImage(""))
            return Promise.resolve();
        })
        .catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            dispatch(updateOrgListIsError("Something went wrong"));
        });
