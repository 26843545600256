import Styled from "@emotion/styled";
import { LinearProgress, Typography } from "@mui/material";
import { colors } from "../../../constants/Colors";
import { fontFamily } from "../../../constants/FontFamily";


type TextProps = {
    color?: string;
    fontWeight?: number | string;
    fontfamily?: 'primary' | 'secondary';
    textsize?: string;
}

export const DisplayText = Styled(Typography) <TextProps>`
    font-family: ${(props) => props.fontFamily === "primary" ? fontFamily.primary : fontFamily.secondary};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 600};
    font-size: 67.34px;
    line-height: 82px;
    letter-spacing: -0.01em;
    color: ${(props) => props.color || colors.primary.neutral800};
`;

export const Heading1 = Styled(Typography) <TextProps>`
    font-family: ${(props) => props.fontFamily === "primary" ? fontFamily.primary : fontFamily.secondary};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 600};
    font-size: 50.52px;
    line-height: 62px;
    color: ${(props) => props.color || colors.primary.neutral800};
`;

export const Heading2 = Styled(Typography) <TextProps>`
    font-family: ${(props) => props.fontFamily === "primary" ? fontFamily.primary : fontFamily.secondary};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 600};
    font-size: 37.9px;
    line-height: 46px;
    color: ${colors.primary.neutral800};
`;

export const Heading3 = Styled(Typography) <TextProps>`
    font-family: ${(props) => props.fontFamily === "primary" ? fontFamily.primary : fontFamily.secondary};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 600};
    font-size: 21.33px;
    line-height: 26px;
    color: ${(props) => props.color || colors.primary.neutral800};
`;

export const Paragraph1 = Styled(Typography) <TextProps>`
    font-family: ${(props) => props.fontFamily === "primary" ? fontFamily.primary : fontFamily.secondary};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 500};
    font-size: ${(props) => props.textsize || "18px"};
    line-height: 28px;
    font-feature-settings: 'salt' on;
    color: ${(props) => props.color || colors.primary.neutral900};
`;

export const Paragraph2 = Styled(Typography) <TextProps>`
    font-family: ${(props) => props.fontFamily === "primary" ? fontFamily.primary : fontFamily.secondary};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 500};
    font-size: 18px;
    line-height: 28px;
    font-feature-settings: 'salt' on;
    color: ${(props) => props.color || colors.primary.neutral900};
`;

export const Label = Styled(Typography) <TextProps>`
    font-family: ${fontFamily.secondary};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 500};
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    font-feature-settings: 'salt' on;
    color: ${(props) => props.color || "${colors.primary.light}"};
`;

export const Label2 = Styled(Typography) <TextProps>`
    font-family: ${(props) => props.fontFamily === "primary" ? fontFamily.primary : fontFamily.secondary};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 500};
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: ${(props) => props.color || "#262A36"};
`;

export const Paragraph2Mobile = Styled(Paragraph2)`
    font-size: 12px;
    line-height: 15px;
    color: ${(props) => props.color || colors.secondary.neutral};
`;

export const Paragraph3 = Styled(Typography) <TextProps>`
    font-family: ${(props) => props.fontFamily === "primary" ? fontFamily.primary : fontFamily.secondary};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 500};
    font-size: 18px;
    line-height: 28px;
    font-feature-settings: 'salt' on;
    color: ${(props) => props.color || colors.primary.neutral900};
`;

export const Small = Styled(Typography) <TextProps>`
    font-family: ${(props) => props.fontFamily === "primary" ? fontFamily.primary : fontFamily.secondary};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 500};
    font-size: 18px;
    line-height: 28px;
    font-feature-settings: 'salt' on;
    color: ${(props) => props.color || colors.primary.neutral900};
`;

export const Small2Mobile = Styled(Small)`
    font-size: 6.76px;
    line-height: 8px;
    color: ${(props) => props.color || colors.primary.dark};
`;

export const PlaceholderText = Styled(Typography) <TextProps>`
    font-family: ${(props) => props.fontFamily === "primary" ? fontFamily.primary : fontFamily.secondary};
    font-style: normal;
    font-weight: ${(props) => props.fontWeight || 500};
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: ${colors.primary.light};
`;

export const StyledLinearProgress = Styled(LinearProgress)`
    &.MuiLinearProgress-root {
        height: 8px;
        border-radius: 60px;
        background-color: #F0F3F7;
    }
    & .MuiLinearProgress-bar {
        background-color: black ;
    }
`;

export const StyledLink = Styled.a`
    color: ${(props) => props.color || colors.primary.neutral800};
    text-decoration: none;
    cursor: pointer;
    &:hover {
        text-decoration: underline;
    }
`;

type AddTextProps = {
    iserror?: boolean;
}
export const AddText = Styled(Label) <AddTextProps>`
    cursor: pointer;
    display: flex;
    justify-content: flex-end;
`;