import { DataGrid, GridColDef, GridRowProps } from "@mui/x-data-grid";
import { FC } from "react";
import { StyledGrid } from "./Style";

type DataGridWrapperProps = {
    rows: GridRowProps[];
    columns: GridColDef[];
    loading?: boolean;
    rowHeight?: number;
};
const DataGridWrapper: FC<DataGridWrapperProps> = ({ rows, columns, loading, rowHeight }) => (
    <StyledGrid pb={"50px"}>
        <DataGrid
            rows={rows}
            columns={columns}
            loading={loading}
            hideFooter
            autoHeight
            hideFooterPagination
            disableExtendRowFullWidth
            disableSelectionOnClick
            disableColumnMenu
            headerHeight={42}
            rowHeight={rowHeight || 52}
        />
    </StyledGrid>
);

export default DataGridWrapper;
