import { Dialog, Grid } from '@mui/material'
import { FC } from 'react'
import { colors } from '../../../constants/Colors';
import { PrimaryButton, TextButton } from '../designs/Button';
import { Heading3, Paragraph1 } from '../designs/Text';

type DeleteModalProps = {
    title: string;
    message: string;
    onClose: () => void;
    onSubmit: () => void;
}
const DeleteModal: FC<DeleteModalProps> = ({
    message,
    title,
    onClose,
    onSubmit
}) => {
    return (
        <Dialog onClose={onClose} open>
            <Grid container p={"30px"}>
                <Grid item xs={12}>
                    <Heading3>{title}</Heading3>
                </Grid>
                <Grid mt={"40px"} item xs={12}>
                    <Paragraph1>{message}</Paragraph1>
                </Grid>
                <Grid item mt={"40px"} xs={12}>
                    <Grid container justifyContent={"center"} alignItems={"center"}>
                        <Grid item xs={4}>
                            <TextButton
                                fullWidth
                                onClick={onClose} label={"Cancel"} />
                        </Grid>
                        <Grid item xs={4}>
                            <PrimaryButton
                                fullWidth
                                textcolor={colors.secondary.light} backgroundcolor={colors.primary.dark}
                                onClick={onSubmit} label={"Proceed"} />
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Dialog>
    );
};

export default DeleteModal