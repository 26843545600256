import { Grid, Menu } from '@mui/material';
import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserRole } from '../../../../store/auth/Selector';
import { deleteSiteProperty } from '../../../../store/sites/ActionCreator';
import { doesLoggedinUserHavePermission } from '../../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../../util/Type';
import { PrimaryButton } from '../../../commoncomponents/designs/Button';
import DeleteModal from '../../../commoncomponents/modal/DeleteModal';

type OptionActionPopupProps = {
    anchorEl: HTMLElement | null;
    handleClose: () => void;
}

const ManagePropertyOptionActionPopup: FC<OptionActionPopupProps> = ({
    anchorEl,
    handleClose
}) => {

    const dispatch = useDispatch();
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const navigate = useNavigate();
    const { id, homeid } = useParams<{
        id: string,
        homeid: string
    }>();
    const role = useSelector(useGetUserRole);

    const handleDeleteProperty = () => {
        homeid && id && dispatch(deleteSiteProperty(id, homeid))
            .then(() => {
                navigate(`/site/${id}/manageproperties`);
            })
    }

    return (
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            PaperProps={{
                style: {
                    width: 190
                }
            }}
            onClose={handleClose}
        >
            <Grid p={"10px"} spacing={"10px"} container>
                {
                    doesLoggedinUserHavePermission(role, USER_ROLES.PROPERTY, PERMISSIONS.CREATE) &&
                    <>
                        <Grid item xs={12}>
                            <PrimaryButton
                                onClick={() => navigate(`/site/${id}/manageProperties/add`)}
                                buttonview='sm' fullWidth label={"Add properties"}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PrimaryButton
                                onClick={() => navigate(`/site/${id}/manageProperties/addmultiple`)}
                                buttonview='sm' fullWidth label={"Add Multiple Properties"}
                            />
                        </Grid>
                    </>
                }
                {
                    doesLoggedinUserHavePermission(role, USER_ROLES.PROPERTY, PERMISSIONS.DELETE) &&
                    <Grid item xs={12}>
                        <PrimaryButton
                            onClick={() => setOpenStatusModal(true)}
                            buttonview='sm' fullWidth label={"Delete Property"} />
                    </Grid>
                }
                {
                    openStatusModal &&
                    <DeleteModal
                        message={`Are you sure you want to remove this property.`}
                        title={`Property Delete`}
                        onClose={() => setOpenStatusModal(false)}
                        onSubmit={handleDeleteProperty}
                    />
                }
            </Grid>
        </Menu>
    )
}

export default ManagePropertyOptionActionPopup