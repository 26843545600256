export enum PERMISSIONS {
    CREATE = 'create',
    VIEW = 'view',
    EDIT = 'edit',
    DELETE = 'delete'
}

export enum USER_ROLES {
    SITE = "site",
    SITE_SETTINGS = "siteSettings",
    MANAGE_USER = "manageUser",
    MANAGE_SITE_PERMISSION = "manageSitePermission",
    ADMIN_USER = "adminUser",
    SERVICE_FOR_SITE = "serviceForSite",
    SERVICE_TARIFFS = "serviceTariffs",
    PROPERTY = "property",
    SITE_PROPERTY_SERVICES = "sitePropertyServices",
    PROPERTY_SERVICE = "propertyService",
    NEW_RESIDENT = "newResident",
    RESIDENT_MOVE_IN_OUT_DATE = "residentMoveInOutDate",
    LINK_XERO_TO_SITE = "linkXeroToSite",
    ADD_SOLAR_NETWORK_TOKEN_TO_SITE = "addSolarNetworkTokenToSite",
    CONFIGURE_SOLAR_NETWORK = "configureSolarNetwork",
    SHARED_SERVICE = "sharedService",
    HISTORIC_METERED_USAGE = "historicMeteredUsage",
    MANUAL_ADD_USAGE = "manuallyAddUsage",
    ORGANISATION_MANAGEMENT = "organisationManagement",
    PROPERTY_SETTINGS = "propertySettings",
}