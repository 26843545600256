import { InputAdornment } from "@mui/material";
import { GridCellParams } from "@mui/x-data-grid";
import { useState } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { permissionDisabledField, permissionLevelOption } from "../../constants/SiteConstants";
import { updateUserDetail } from "../../store/user/ActionCreator";
import { UserType } from "../../store/user/Type";
import { doesLoggedinUserHavePermission, formatDateString } from "../../util/SiteUtil";
import { StyledColumnText, StyledHeader } from "../commoncomponents/datagrid/DataGridStyle";
import { SelectWrapper } from "../commoncomponents/fields/FormikSelect";
import { Grid } from "@mui/material";
import UserInfo from "./UserInfo";
import { useGetUserRole } from "../../store/auth/Selector";
import { PERMISSIONS, USER_ROLES } from "../../util/Type";

export const useUserListHooks = () => {

    const [openAddNewUserModal, setOpenAddNewUserModal] = useState(false);
    const dispatch = useDispatch();
    const role = useSelector(useGetUserRole);

    const toggleModal = () => {
        setOpenAddNewUserModal((prev) => !prev);
    };

    const handleRoleChange = (row: UserType, option: string) => {
        dispatch(updateUserDetail({
            ...row,
            role: option
        }));
    }

    const userListColumns = () => [
        {
            field: "firstName",
            flex: 1,
            minWidth: 350,
            renderHeader: () => <StyledHeader label="FULL NAME" />,
            renderCell: (params: GridCellParams) =>
                <Grid container alignItems={"center"}>
                    <Grid item>
                        <UserInfo userDetail={params.row} />
                    </Grid>
                    <Grid item>
                        <StyledColumnText label={`${params.row.firstName} ${params.row.lastName}`} />
                    </Grid>

                </Grid>

        },
        {
            field: "role",
            flex: 1,
            minWidth: 200,
            renderHeader: () => <StyledHeader label="PERMISSION LEVEL" />,
            renderCell: (params: GridCellParams) =>
                <SelectWrapper
                    disabled={permissionDisabledField(role, params.row.role) || !doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.EDIT)}
                    value={params.row.role}
                    name={`${params.row.role}-permission`}
                    id={`${params.row.role}-permission`}
                    options={permissionLevelOption}
                    size={"small"}
                    onChange={(event) => handleRoleChange(params.row, event.target.value as string)}
                    startAdornment={
                        <InputAdornment position="start">
                            <AiOutlineUser style={{ marginRight: 10 }} />
                        </InputAdornment>
                    }
                />
        },
        {
            field: "createdAt",
            flex: 1,
            minWidth: 200,
            renderHeader: () => <StyledHeader label="DATE JOINED" />,
            renderCell: (params: GridCellParams) =>
                <StyledColumnText
                    label={`${formatDateString(params.row.createdAt)}`}
                />
        },
    ];

    return {
        userListColumns,
        toggleModal,
        openAddNewUserModal
    }
}

