import { FC, useEffect, useMemo, useState } from "react";
import Box from "@mui/material/Box";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { AiOutlineUser } from "react-icons/ai";
import { useField } from "formik";
import { getCustomerDetail } from "./Util";
import { useParams } from "react-router-dom";
import { TextFieldContainer } from "../../../commoncomponents/CommonStyle";
import { debounce } from "lodash";
import { CustomerSearchType } from "../../../../store/sites/Type";

type CustomerSearchProps = {
  name: string;
  label?: string;
  descriptionText?: string;
} & TextFieldProps;

const CustomerSearch: FC<CustomerSearchProps> = ({
  label,
  name,
  descriptionText,
  ...rest
}) => {
  const [_, { value }, { setValue }] = useField(name);
  const [customer, setCustomer] = useState<CustomerSearchType | null>(null);
  const [inputValue, setInputValue] = useState(value?.identifier || "");
  const [options, setOptions] = useState<readonly CustomerSearchType[]>([]);
  const { id } = useParams();

  const fetch = useMemo(
    () =>
      debounce(
        (
          request: { input: string },
          callback: (results?: readonly CustomerSearchType[]) => void
        ) => {
          id &&
            getCustomerDetail(id, request.input).then((res) => {
              callback(res);
            });
        },
        300
      ),
    []
  );

  useEffect(() => {
    let active = true;

    fetch({ input: inputValue }, (results?: readonly CustomerSearchType[]) => {
      if (active) {
        let newOptions: readonly CustomerSearchType[] = [];
        if (customer) {
          newOptions = [customer];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        setOptions(newOptions);
        setCustomer(newOptions.filter((item) => item.name === inputValue)[0]);
      }
    });

    return () => {
      active = false;
    };
  }, [customer, inputValue, fetch]);

  return (
    <TextFieldContainer>
      <Autocomplete
        id="customer-search-autocomplete"
        getOptionLabel={(option) =>
          typeof option === "string" ? option : option.name
        }
        filterOptions={(x) => x}
        options={options}
        autoComplete
        includeInputInList
        filterSelectedOptions
        value={customer || null}
        onChange={(_, newValue: CustomerSearchType | null) => {
          setOptions(newValue ? [newValue, ...options] : options);
          setCustomer(newValue);
          setValue(newValue);
        }}
        onInputChange={(_, newInputValue) => {
          setInputValue(newInputValue);
        }}
        renderInput={(params) => (
          <TextField variant="standard" {...params} fullWidth {...rest} />
        )}
        renderOption={(props, option) => {
          return (
            <li {...props}>
              <Grid container alignItems="center">
                <Grid item>
                  <Box
                    component={AiOutlineUser}
                    sx={{ color: "text.secondary", mr: 2 }}
                  />
                </Grid>
                <Grid item xs>
                  <Typography variant="body2" color="text.secondary">
                    {option.name}
                  </Typography>
                </Grid>
              </Grid>
            </li>
          );
        }}
      />
    </TextFieldContainer>
  );
};

export default CustomerSearch;
