import Styled from "@emotion/styled";
import { Grid } from "@mui/material";
import { colors } from "../../../constants/Colors";

type StyleMenuProps = {
  isselected?: boolean;
}
export const StyledMenu = Styled.div<StyleMenuProps>`
  display  : flex ;
  gap: 17.5px;
  margin: 5px 0px;
  border-radius: 4px;
  padding: 15px;
  cursor: pointer;
  align-items: center;
  background: ${(props) => props.isselected ? colors.secondary.light : colors.secondary.neutral};
  p {
    font-weight: ${(props) => props.isselected && 700} !important;
  }
  &:hover {
    background: ${colors.secondary.light};
  }
`;

type MobilePropertyButtonProps = {
  isSelected?: boolean;
}
export const MobilePropertyButton = Styled(Grid) <MobilePropertyButtonProps>`
    padding: 14px !important;
    text-align: center;
    ${(props) => props.isSelected && `
    background: #F0F3F7;
    border-radius: 2px;
    `}
    svg {
            font-size: 12px;
        }
`;