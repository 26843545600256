import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { Label2 } from '../designs/Text';
import { StyledMenu } from './Style';
import { SideBarOptionsType } from './Util';
import { FC } from "react";

type SideNavbarMenuProps = {
    sidebarOptions: SideBarOptionsType[];
    selectedMenuId: number;
}
const SideNavbar: FC<SideNavbarMenuProps> = ({
    sidebarOptions,
    selectedMenuId
}) => {
    const navigate = useNavigate();

    return (
        <Grid container>
            {
                sidebarOptions.filter((item) => item.isAvailaible).map((item) =>
                    <Grid key={item.id} item xs={12}>
                        <StyledMenu isselected={item.id === selectedMenuId} onClick={() => navigate(item.url)}>
                            {item.icons}
                            <Label2 textTransform={"capitalize"}>{item.label}</Label2>
                        </StyledMenu>
                    </Grid>
                )
            }
        </Grid>
    )
};

export default SideNavbar;