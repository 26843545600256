import { FC, useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import TextField, { TextFieldProps } from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import { GoLocation } from "react-icons/go";
import { TextFieldContainer } from '../CommonStyle';
import { useField } from 'formik';
import { Heading2, Heading3, Paragraph1 } from '../designs/Text';
import { BiPencil } from 'react-icons/bi';

const autocompleteService = { current: null };

interface MainTextMatchedSubstrings {
    offset: number;
    length: number;
}
interface StructuredFormatting {
    main_text: string;
    secondary_text: string;
    main_text_matched_substrings: readonly MainTextMatchedSubstrings[];
}
interface PlaceType {
    description: string;
    structured_formatting: StructuredFormatting;
}

type FormikGoogleMapAutoCompleteProps = {
    id: string;
    name: string;
    label?: string;
    descriptionText?: string;
    initialText?: string;
    isEditable?: boolean;
} & TextFieldProps;

const FormikGoogleMapAutoComplete: FC<FormikGoogleMapAutoCompleteProps> = ({
    label,
    name,
    id,
    descriptionText,
    initialText,
    isEditable,
    ...rest
}) => {
    const [_, { value }, { setValue }] = useField(name);
    const [place, setPlace] = useState<PlaceType | null>(null);
    const [inputValue, setInputValue] = useState(value);
    const [options, setOptions] = useState<readonly PlaceType[]>([]);
    const [editText, setEditText] = useState(isEditable);

    const fetch = useMemo(
        () =>
            throttle(
                (
                    request: { input: string },
                    callback: (results?: readonly PlaceType[]) => void,
                ) => {
                    (autocompleteService.current as any).getPlacePredictions(
                        request,
                        callback,
                    );
                },
                200,
            ),
        [],
    );

    useEffect(() => {
        let active = true;
        if (!autocompleteService.current && (window as any).google) {
            autocompleteService.current = new (
                window as any
            ).google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions(place ? [place] : []);
            return undefined;
        }

        fetch({ input: inputValue }, (results?: readonly PlaceType[]) => {
            if (active) {
                let newOptions: readonly PlaceType[] = [];
                if (place) {
                    newOptions = [place];
                }
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                setOptions(newOptions);
                setPlace(newOptions.filter((item) => item.description === inputValue)[0]);
            }
        });

        return () => {
            active = false;
        };
    }, [place, inputValue, fetch]);

    return (
        <TextFieldContainer>
            {
                editText ? <Paragraph1 style={{ cursor: 'pointer', display: 'flex', alignItems: 'center', gap: 5, fontWeight: 600 }} onClick={() => setEditText(false)} textsize='16px'>{initialText} <BiPencil /></Paragraph1> : <Autocomplete
                    id="google-map-autocomplete"
                    getOptionLabel={(option) =>
                        typeof option === 'string' ? option : option.description
                    }
                    filterOptions={(x) => x}
                    options={options}
                    autoComplete
                    includeInputInList
                    filterSelectedOptions
                    value={place || null}
                    onChange={(_, newValue: PlaceType | null) => {
                        setOptions(newValue ? [newValue, ...options] : options);
                        setPlace(newValue);
                        setValue(newValue?.description);
                    }}
                    onInputChange={(_, newInputValue) => {
                        setInputValue(newInputValue);
                    }}
                    renderInput={(params) => (
                        <TextField variant='standard' {...params} fullWidth {...rest} />
                    )}
                    renderOption={(props, option) => {
                        const matches = option.structured_formatting.main_text_matched_substrings;
                        const parts = parse(
                            option.structured_formatting.main_text,
                            matches.map((match: any) => [match.offset, match.offset + match.length]),
                        );

                        return (
                            <li {...props}>
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <Box
                                            component={GoLocation}
                                            sx={{ color: 'text.secondary', mr: 2 }}
                                        />
                                    </Grid>
                                    <Grid item xs>
                                        {parts.map((part, index) => (
                                            <span
                                                key={index}
                                                style={{
                                                    fontWeight: part.highlight ? 700 : 400,
                                                }}
                                            >
                                                {part.text}
                                            </span>
                                        ))}
                                        <Typography variant="body2" color="text.secondary">
                                            {option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </li>
                        );
                    }}
                />
            }

        </TextFieldContainer>
    );
}

export default FormikGoogleMapAutoComplete;