import { LookupType, UPDATE_AVAILAIBLE_INVOICE__DROPDOWN, UPDATE_BILLING_DROPDOWN, UPDATE_CURRENCIES_DROPDOWN, UPDATE_INVOICE_REFERENCE_DROPDOWN, UPDATE_LANGUAGE_TAGS_DROPDOWN, UPDATE_ROLES_DROPDOWN, UPDATE_SERVICE_BILLING_DROPDOWN, UPDATE_SERVICE_CATEGORIES_DROPDOWN, UPDATE_SERVICE_FIXED_UNIT_DROPDOWN, UPDATE_STANDING_CHARGE_DROPDOWN, UPDATE_TARIFF_KIND_DROPDOWN, UPDATE_TIMEZONES_DROPDOWN, UPDATE_UNIT_ID_DROPDOWN, UPDATE_UNIT_RATE_DROPDOWN, UPDATE_XERO_ACCOUNTING_CODE_DROPDOWN, UPDATE_XERO_INVOICE_DROPDOWN, UPDATE_XERO_INVOICE_STATUS_DROPDOWN } from "./Type";
import _cloneDeep from "lodash/cloneDeep";
import { ActionPayload } from "../../common/Base";

const initialState: LookupType = {
    billingCyclesDropdown: [{
        label: "Billing period",
        value: "none"
    }],
    currenciesDropdown: [{
        label: "Currency",
        value: "none"
    }],
    error: "",
    isLoading: false,
    languageTagsDropdown: [{
        value: "none",
        label: "Locale"
    }],
    rolesDropdown: [{
        value: "none",
        label: "Roles"
    }],
    timezonesDropdown: [{
        value: "none",
        label: "Timezone"
    }],
    serviceBillingTypesDropdown: [{
        value: "none",
        label: "Billing type"
    }],
    serviceCategoriesDropdown: [{
        label: "Service Category",
        value: "none"
    }],
    xeroAccountingCodeDropdown: [{
        label: "Xero Accounting Code",
        value: "none"
    }],
    tariffKindDropdown: [{
        label: "Standing Charge",
        value: "none"
    }],
    UnitIdDropdown: [{
        label: "Unit Rate",
        value: "none"
    }],
    serviceFixedUnitDropdown: [{
        label: "Service",
        value: "none"
    }],
    invoiceReferenceDropdown: [],
    xeroAccountInvoiceOptions: [],
    xeroInvoiceStatusDropdown: [{
        value: "none",
        label: "Xero Invoice status",
    }],
    availableInvoiceStatusDropdown: []
};

export const LookupDropdownReducer = (state = initialState, action: ActionPayload<any>): any => {
    const stateClone = _cloneDeep(state);
    switch (action.type) {
        case UPDATE_BILLING_DROPDOWN: {
            stateClone.billingCyclesDropdown = [stateClone.billingCyclesDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_CURRENCIES_DROPDOWN: {
            stateClone.currenciesDropdown = [stateClone.currenciesDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_SERVICE_FIXED_UNIT_DROPDOWN: {
            stateClone.serviceFixedUnitDropdown = [stateClone.serviceFixedUnitDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_XERO_INVOICE_DROPDOWN: {
            stateClone.xeroAccountInvoiceOptions = action.payload;
            return stateClone;
        }
        case UPDATE_XERO_INVOICE_STATUS_DROPDOWN: {
            stateClone.xeroInvoiceStatusDropdown = [stateClone.xeroInvoiceStatusDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_LANGUAGE_TAGS_DROPDOWN: {
            stateClone.languageTagsDropdown = [stateClone.languageTagsDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_INVOICE_REFERENCE_DROPDOWN: {
            stateClone.invoiceReferenceDropdown = action.payload;
            return stateClone;
        }
        case UPDATE_ROLES_DROPDOWN: {
            stateClone.rolesDropdown = [stateClone.rolesDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_TIMEZONES_DROPDOWN: {
            stateClone.timezonesDropdown = [stateClone.timezonesDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_SERVICE_BILLING_DROPDOWN: {
            stateClone.serviceBillingTypesDropdown = [stateClone.serviceBillingTypesDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_SERVICE_CATEGORIES_DROPDOWN: {
            stateClone.serviceCategoriesDropdown = [stateClone.serviceCategoriesDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_XERO_ACCOUNTING_CODE_DROPDOWN: {
            stateClone.xeroAccountingCodeDropdown = [stateClone.xeroAccountingCodeDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_TARIFF_KIND_DROPDOWN: {
            stateClone.tariffKindDropdown = [stateClone.tariffKindDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_UNIT_ID_DROPDOWN: {
            stateClone.UnitIdDropdown = [stateClone.UnitIdDropdown[0], ...action.payload];
            return stateClone;
        }
        case UPDATE_AVAILAIBLE_INVOICE__DROPDOWN: {
            stateClone.availableInvoiceStatusDropdown = [...action.payload];
            return stateClone;
        }
        default: {
            return state;
        }
    }
}