import { ActionPayload } from "../../common/Base";
import { IS_ERROR, IS_LOADING } from "../CommonType";
import { SiteType } from "../sites/Type";
import { OrgSiteListType, OrgSwitchListType, OrgSwitchType, OrgType, ORG_LIST, SELECTED_ORG_DETAIL, UPDATE_ORG_ALL_SITE_LIST, UPDATE_ORG_ALL_USER_LIST, UPDATE_ORG_IMAGE, UPDATE_ORG_SELECTED_SITE_LIST, UPDATE_ORG_SELECTED_USER_LIST, UPDATE_ORG_SITE_LIST, UPDATE_ORG_USER_LIST } from "./Type";

export const updateOrgList = (userList: OrgType[]): ActionPayload<OrgType[]> => ({
    type: ORG_LIST,
    payload: userList
});

export const updateOrgListIsLoading = (isLoading: boolean): ActionPayload<boolean> => ({
    type: IS_LOADING,
    payload: isLoading
});

export const updateOrgListIsError = (isError: string): ActionPayload<string> => ({
    type: IS_ERROR,
    payload: isError
});

export const updateSelectedOrgDetail = (userDetail?: OrgType): ActionPayload<OrgType | undefined> => ({
    type: SELECTED_ORG_DETAIL,
    payload: userDetail
});

export const updateOrgUserList = (userLists: OrgSwitchListType): ActionPayload<OrgSwitchListType> => ({
    type: UPDATE_ORG_USER_LIST,
    payload: userLists
});

export const updateOrgSelectedSiteList = (siteLists: SiteType[]): ActionPayload<SiteType[]> => ({
    type: UPDATE_ORG_SELECTED_SITE_LIST,
    payload: siteLists
});

export const updateOrgSelectedUserList = (userList: OrgSwitchType[]): ActionPayload<OrgSwitchType[]> => ({
    type: UPDATE_ORG_SELECTED_USER_LIST,
    payload: userList
});

export const updateOrgSiteList = (userList: OrgSiteListType): ActionPayload<OrgSiteListType> => ({
    type: UPDATE_ORG_SITE_LIST,
    payload: userList
});

export const updateOrgAllUserList = (userList: OrgSwitchType[]): ActionPayload<OrgSwitchType[]> => ({
    type: UPDATE_ORG_ALL_USER_LIST,
    payload: userList
});

export const updateOrgAllSiteList = (userList: SiteType[]): ActionPayload<SiteType[]> => ({
    type: UPDATE_ORG_ALL_SITE_LIST,
    payload: userList
});

export const updateOrgImage = (imgSrc: string): ActionPayload<string> => ({
    type: UPDATE_ORG_IMAGE,
    payload: imgSrc
});
