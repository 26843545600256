import { Grid, TextField, TextFieldProps } from "@mui/material";
import { format } from "date-fns";
import { FC, forwardRef, useState } from "react";
import DatePicker, { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { AiOutlineCalendar, AiOutlineRight, AiOutlineLeft } from "react-icons/ai";
import { ApplyDateSelectButton, CancelDateSelectButton, DatePickerHeaderContainer, MonthText, StyledCustomDatePicker } from "./CommonStyle";
import { TextButton } from "./designs/Button";
import { Label } from "./designs/Text";

type CustomSingleDatePickerProps = {
    date: Date | null;
    setDate: (date: Date | null) => void;
    label?: string;
    onBlur?: () => void;
    error?: boolean;
    errorMessage?: string;
    type?: "primary" | "secondary";
    helperText?: string;
    minDate?: Date;
    showClearDate?: boolean;
} & TextFieldProps;
const CustomSingleDatePicker: FC<CustomSingleDatePickerProps> = ({
    date,
    setDate,
    label,
    onBlur,
    error = false,
    errorMessage,
    type = "primary",
    helperText,
    minDate,
    showClearDate,
    ...rest
}) => {
    const [value, setValue] = useState<Date | null>(date ? new Date(date) : new Date());
    const [openDatePicker, setOpenDatePicker] = useState(false);

    const DatePickerButton = forwardRef((props: any, ref: any) => {
        if (type === "primary") {
            return (
                <>
                    <Label style={{ fontSize: 8 }}>{label}</Label>
                    <TextButton additionalStyles={{
                        paddingTop: 0
                    }} ref={ref} label={date ? format(date, "dd/MM/yyyy") : label || "Select Date"} onClick={props.onClick} icon={<AiOutlineCalendar />} />

                </>
            );
        }
        return (
            <TextField
                ref={ref}
                fullWidth
                value={date ? format(date, "dd/MM/yyyy") : label || "Select Date"}
                onClick={props.onClick}
                variant={"outlined"}
                helperText={helperText}
                {...rest}
            />
        )
    });

    const DatePickerHeader = (props: ReactDatePickerCustomHeaderProps): JSX.Element => {
        return (
            <DatePickerHeaderContainer>
                <div>
                    <MonthText>
                        {format(props.monthDate, "MMM yyyy")}
                    </MonthText>
                </div>
                <div style={{ display: "flex" }}>
                    <AiOutlineLeft onClick={() => props.decreaseMonth()} style={{ fontSize: 20, cursor: "pointer" }} />
                    <AiOutlineRight onClick={() => props.increaseMonth()} style={{ fontSize: 20, cursor: "pointer" }} />
                </div>
            </DatePickerHeaderContainer>
        );
    }

    return (
        <StyledCustomDatePicker>
            <DatePicker
                selected={date}
                open={openDatePicker}
                onBlur={onBlur}
                renderCustomHeader={DatePickerHeader}
                popperModifiers={[
                    {
                        name: "preventOverflow",
                        options: {
                            rootBoundary: "document",
                            tether: false,
                            altAxis: true,
                        },
                    },
                ]}
                yearDropdownItemNumber={15}
                scrollableYearDropdown
                minDate={minDate}
                onInputClick={() => setOpenDatePicker(true)}
                onClickOutside={() => setOpenDatePicker(false)}
                onChange={(date: Date) => setValue(date)}
                customInput={<DatePickerButton />}
            >
                <div>
                    <Grid container py={"10px"} px={"10px"} justifyContent={"center"} spacing={"10px"}>
                        <Grid item xs={6}>
                            <CancelDateSelectButton
                                fullWidth
                                onClick={() => {
                                    date && setDate(date);
                                    setOpenDatePicker(false);
                                }}
                            >
                                Cancel
                            </CancelDateSelectButton>
                        </Grid>
                        <Grid item xs={6}>
                            <ApplyDateSelectButton
                                fullWidth
                                variant="contained"
                                onClick={() => {
                                    value && setDate(value);
                                    setOpenDatePicker(false);
                                }}
                            >
                                Apply
                            </ApplyDateSelectButton>
                        </Grid>
                        {
                            showClearDate && <Grid item xs={6}>
                                <ApplyDateSelectButton
                                    fullWidth
                                    variant="contained"
                                    onClick={() => {
                                        setDate(null);
                                        setOpenDatePicker(false);
                                    }}
                                >
                                    Clear Date
                                </ApplyDateSelectButton>
                            </Grid>
                        }

                    </Grid>
                </div>
            </DatePicker>
            {error && errorMessage && <div style={{ color: "red" }}>{errorMessage}</div>}
        </StyledCustomDatePicker>
    );
};

export default CustomSingleDatePicker;