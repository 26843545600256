import { CircularProgress, Grid } from '@mui/material';
import Login from '../login';
import { useConfirmUserHook } from './Hooks';

const ConfirmUser = () => {

    const { isConfirmed, isLoading } = useConfirmUserHook();

    if (isLoading) {
        return (
            <Grid container height={"100vh"} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Grid>
        )
    }
    return (
        <div>
            <Login isLoginConfirming={!isConfirmed}/>
        </div>
    );
};

export default ConfirmUser;
