import { Grid, Menu } from '@mui/material'
import { FC } from 'react'
import { PrimaryButton } from '../../commoncomponents/designs/Button';
import DeleteModal from '../../commoncomponents/modal/DeleteModal';
import { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { updateSiteStatus } from '../../../store/sites/ActionCreator';
import { xeroConnect } from '../../../store/siteservice/ActionCreator';
import { colors } from '../../../constants/Colors';
import { TbExternalLink } from 'react-icons/tb';
import { useGetAuthInfo } from '../../../store/xeroconnect/Selector';
import { Paragraph2Mobile } from '../../commoncomponents/designs/Text';
import { doesLoggedinUserHavePermission } from '../../../util/SiteUtil';
import { useGetUserRole } from '../../../store/auth/Selector';
import { PERMISSIONS, USER_ROLES } from '../../../util/Type';
import { useNavigate } from 'react-router-dom';

type OptionActionPopupProps = {
    anchorEl: HTMLElement | null;
    handleClose: () => void;
    siteId: string;
    isActive?: boolean;
}
const OptionActionPopup: FC<OptionActionPopupProps> = ({
    anchorEl,
    handleClose,
    siteId,
    isActive
}) => {
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const dispatch = useDispatch();
    const authInfo = useSelector(useGetAuthInfo);
    const role = useSelector(useGetUserRole);
    const navigate = useNavigate();

    const handleSiteStatus = () => {
        dispatch(updateSiteStatus(siteId, !isActive))
            .finally(() => {
                setOpenStatusModal(false);
            })
    };
    return (
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            PaperProps={{
                style: {
                    width: 190
                }
            }}
            onClose={handleClose}
        >
            <Grid p={"10px"} spacing={"10px"} container>
                <Grid item xs={12}>
                    {
                        doesLoggedinUserHavePermission(role, USER_ROLES.LINK_XERO_TO_SITE, PERMISSIONS.VIEW)
                        && <PrimaryButton
                            onClick={() => xeroConnect(siteId)}
                            backgroundcolor={colors.primary.light}
                            textcolor={colors.secondary.neutral}
                            fullWidth
                            disabled={!doesLoggedinUserHavePermission(role, USER_ROLES.LINK_XERO_TO_SITE, PERMISSIONS.VIEW)}
                            label={authInfo?.valid ? 'Reconnect xero' : 'Connect Xero'}
                            icon={<TbExternalLink />}
                        />
                    }

                </Grid>
                <Grid item xs={12}>
                    <Paragraph2Mobile color={colors.tertiary.dark} textAlign={"center"} mt={"5px"}>
                        {authInfo ? `Connected to ${authInfo.tenantName}` : "Not connected"}
                    </Paragraph2Mobile>
                </Grid>
                <Grid item xs={12}>
                    <PrimaryButton buttonview='sm' onClick={() => navigate(`/site/${siteId}/usage`)} fullWidth label={"Usage for multiple properties"} />
                </Grid>
                <Grid item xs={12}>
                    <PrimaryButton buttonview='sm' fullWidth label={"Invoice previous billing period"} />
                </Grid>
                <Grid item xs={12}>
                    <PrimaryButton
                        onClick={() => setOpenStatusModal(true)}
                        buttonview='sm'
                        fullWidth
                        disabled={!doesLoggedinUserHavePermission(role, USER_ROLES.SITE, PERMISSIONS.DELETE)}
                        label={isActive ? 'Archive this site' : "Unarchive this site"} />
                </Grid>
                {
                    openStatusModal &&
                    <DeleteModal
                        message={`Are you sure you want to ${isActive ? "archive" : "unarchive"} this site.`}
                        title={`Site Status`}
                        onClose={() => setOpenStatusModal(false)}
                        onSubmit={handleSiteStatus}
                    />
                }
            </Grid>
        </Menu>

    )
}

export default OptionActionPopup;