import Styled from "@emotion/styled";
import { Box, Button, Paper, Typography } from "@mui/material";
import { colors } from "../../../constants/Colors";
import { fontFamily } from "../../../constants/FontFamily";

type StyleProps = {
    buttontype?: "primary" | "secondary";
    backgroundcolor?: string;
    buttonview?: "lg" | "sm" | "md";
    textcolor?: string;
}
export const StyledButton = Styled(Button) <StyleProps>`
    background: ${(props) => props.backgroundcolor || colors.primary.neutral};
    border-radius: 4px;
    ${(props) => props.buttontype === "primary" && `
    font-weight: 500;
    `}
    height: 100%;
    ${(props) => props.buttontype === "secondary" && `
    font-weight: 700;
    `}
    font-family: ${fontFamily.primary};
    font-style: normal;
    font-size: 16px;
    line-height: 28px;
    ${(props) => props.buttonview === "sm" && `
        font-size: 9px;
        line-height: 11px;
        padding: 9.5px 20px;
    `}
    
    display: flex;
    align-items: center;
    text-transform: none;
    color: ${(props) => props.textcolor || colors.primary.dark};
    &:hover {
        background: ${(props) => props.backgroundcolor || colors.primary.neutral};
    }
`;

export const StyledTextButton = Styled(Button) <StyleProps>`
    font-family: ${fontFamily.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: ${(props) => props.textcolor || colors.primary.dark};
`;

export const StyledBigButton = Styled.div<StyleProps>`
    min-height: 120px;
    padding-bottom: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 10px;
    background: ${colors.secondary.neutral};
    border: 1px solid ${colors.secondary.light};
    border-radius: 4px;
    cursor: pointer;
    &:hover {
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    }
`;

type TabContainerProps = {
    variant?: 'default' | 'secondary';
    tabWidth?: string;
    selectedTextColor?: string;
}
export const TabContainer = Styled(Box) <TabContainerProps>`
    & .MuiButtonBase-root {
        font-family: ${fontFamily.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    padding: 13.5px 37px;
    display: flex;
    width: ${(props) => props.tabWidth};
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    }
    & .Mui-selected {
        background: ${colors.secondary.light};
        border-radius: 4px;
        color: ${(props) => props.selectedTextColor || colors.secondary.dark} !important;
        font-weight: 700;
    }
    
    ${(props) => props.variant === 'secondary' && `
    & .MuiButtonBase-root {
        font-weight: 700;
        font-family: ${fontFamily.primary};
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 28px;
        background: ${colors.secondary.neutral};
        color: ${colors.primary.dark};
        text-transform: capitalize;
    }
    & .Mui-selected {
        background: ${colors.primary.light};
        border-radius: 4px;
        color: ${colors.secondary.light};
    }
    & .MuiTabs-flexContainer {
        justify-content: center;
    }
    
    @media (max-width: 768px) {
        & .MuiTabs-flexContainer {
            display: block;
            width: 100%;
        }

        & .MuiButtonBase-root {
            width: 100%;
        }

        & .MuiTabs-scroller {
            width: 100%;
        }
    }
    `}
      
`;

export const ToastText = Styled(Typography)`
    font-family: ${fontFamily.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    text-align: center;
    justify-content: center;
    color: ${colors.primary.dark};
`;

type ToastProps = {
    background?: string;
    color?: string;
}
export const ToastContainer = Styled(Paper) <ToastProps>`
    background: ${(props) => props.background};
    padding: 12px 20px;
    border-radius: 6px;
`;

export const ToastPrimaryContainer = Styled(Paper) <ToastProps>`
    background: ${(props) => props.background};
    padding: 12px 20px;
    border: 1px solid ${(props) => props.background};
    border-radius: 6px;
    display: flex;
    box-shadow: 1px 1px 10px ${(props) => props.background};
    gap: 10px;
    color: ${(props) => props.color};
    svg {
        color: ${(props) => props.color};
    }
`;
