import { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useField } from "formik";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig, readFile } from "../../../util/SiteUtil";
import { VALID_IMAGE } from "../../../constants/SiteConstants";
import { FileUploadContainer, FileUploadSubMessage, FileUploadTitle, LogoContainer } from "./Style";
import { RiGalleryLine } from "react-icons/ri";
import { FormHelperText } from "@mui/material";
import { colors } from "../../../constants/Colors";

type ImageDropProps = {
    logoInfo?: string;
    logoSubMessage?: string;
    imageSize?: number;
    setImageUrl?: (image: string) => void;
    name: string;
};

const ImageDrop: FC<ImageDropProps> = ({ logoInfo, logoSubMessage, imageSize, name, setImageUrl }) => {
    const [_, { error }, { setError, setValue }] = useField(name);

    const onDrop = useCallback(
        (acceptedFiles: any) => {
            acceptedFiles[0].id = Math.random();
            const file = acceptedFiles[0];
            if (setError && !checkIfFilesAreCorrectType(acceptedFiles, VALID_IMAGE)) {
                setError("Please upload image only");
                return;
            }

            if (imageSize && !checkIfFilesAreTooBig(acceptedFiles, imageSize)) {
                setError(`Image must be less then ${imageSize} MB`);
                return;
            }
            setImageUrl && readFile(file).then((res) => {
                setImageUrl(res);
            });
            setValue(file);
        },
        [setValue, imageSize, setError]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div>
            {isDragActive ? (
                <LogoContainer {...getRootProps({ className: "dropzone height-100per" })}>
                    <input {...getInputProps()} />
                    <FileUploadContainer>
                        <RiGalleryLine style={{ fontSize: 26, marginBottom: 15 }} />
                        <FileUploadTitle textAlign={"center"}>{logoInfo}</FileUploadTitle>
                        <FileUploadSubMessage>{logoSubMessage}</FileUploadSubMessage>
                    </FileUploadContainer>
                </LogoContainer>
            ) : (
                <LogoContainer {...getRootProps({ className: "dropzone height-100per" })}>
                    <input {...getInputProps()} />
                    <FileUploadContainer>
                        <RiGalleryLine style={{ fontSize: 26, marginBottom: 15 }} />
                        <FileUploadTitle textAlign={"center"}>{logoInfo}</FileUploadTitle>
                        <FileUploadSubMessage>{logoSubMessage}</FileUploadSubMessage>
                    </FileUploadContainer>
                </LogoContainer>
            )}
            {
                error && <FormHelperText style={{
                    color: colors.tertiary.errorTitle,
                }}>{error}</FormHelperText>
            }
        </div>
    );
};

export default ImageDrop;
