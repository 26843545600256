import { RootReducerType } from "../CombineReducer";
import { LookupType } from "./Type";

export const useGetBiilingDropdown = (state: RootReducerType) => state.lookupDropdown.billingCyclesDropdown;
export const useGetCurrenciesDropdown = (state: RootReducerType) => state.lookupDropdown.currenciesDropdown;
export const useGetLanguageTagsDropdown = (state: RootReducerType) => state.lookupDropdown.languageTagsDropdown;
export const useGetRolesDropdown = (state: RootReducerType) => state.lookupDropdown.rolesDropdown;
export const useGetTimezonesDropdown = (state: RootReducerType) => state.lookupDropdown.timezonesDropdown;
export const useGetServiceBillingDropdown = (state: RootReducerType) => state.lookupDropdown.serviceBillingTypesDropdown;
export const useGetServiceCategoryDropdown = (state: RootReducerType) => state.lookupDropdown.serviceCategoriesDropdown;
export const useGetXeroAccountDropdown = (state: RootReducerType) => state.lookupDropdown.xeroAccountingCodeDropdown;
export const useGetTariffKindDropdown = (state: RootReducerType) => state.lookupDropdown.tariffKindDropdown;
export const useGetUnitRateDropdown = (state: RootReducerType) => state.lookupDropdown.UnitIdDropdown;
export const useGetInvoiceRefParamsDropdown = (state: RootReducerType) => state.lookupDropdown.invoiceReferenceDropdown;
export const useGetXeroInvoiceDropdown = (state: RootReducerType) => state.lookupDropdown.xeroAccountInvoiceOptions;
export const useServiceFixedUnitDropdown = (state: RootReducerType) => state.lookupDropdown.serviceFixedUnitDropdown;
export const useGetXeroInvoiceStatusDropdown = (state: RootReducerType) => state.lookupDropdown.xeroInvoiceStatusDropdown;
export const useGetAvailaibleInvoiceStatusDropdown = (state: RootReducerType) => state.lookupDropdown.availableInvoiceStatusDropdown;