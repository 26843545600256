import { ApiState } from "../CommonType";

export interface AuthType extends ApiState {
    userInfo: UserInfoType | null;
    isAuthenticating: boolean;
    isLogined: boolean;
};

export type UserInfoType = {
    email: string;
    userRole: string;
};

export type UserLoginType = {
    username: string;
    password: string;
};

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_IS_AUTHENTICATING = "LOGIN_IS_AUTHENTICATING";
export const LOGIN_ERROR = "LOGIN_ERROR";
export const LOGOUT_USER = "LOGOUT_USER";
export const USER_LOGINED = "USER_LOGINED";