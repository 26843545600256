import { Dialog, Grid, TextField } from "@mui/material";
import { FC, useEffect, useMemo, useState } from "react";
import { colors } from "../../../constants/Colors";
import { PrimaryButton, TextButton } from "../designs/Button";
import { Heading3, Label, Paragraph1 } from "../designs/Text";
import { TextFieldContainer } from "../CommonStyle";

type ConfirmModalProps = {
  title: string;
  message: string;
  confirmTextToType?: string;
  primaryButtonText?: string;
  onClose: () => void;
  onSubmit: () => void;
};
const ConfirmModal: FC<ConfirmModalProps> = ({
  message,
  title,
  confirmTextToType,
  primaryButtonText,
  onClose,
  onSubmit,
}) => {
  const [confirmTextValue, setConfirmTextValue] = useState("");

  const disabledSubmit = useMemo(() => {
    if (!confirmTextToType) return false;
    return confirmTextValue.toLowerCase() !== confirmTextToType.toLowerCase();
  }, [confirmTextValue, confirmTextToType]);

  return (
    <Dialog onClose={onClose} open>
      <Grid container p={"30px"}>
        <Grid item xs={12}>
          <Heading3>{title}</Heading3>
        </Grid>
        <Grid mt={"40px"} item xs={12}>
          <Paragraph1>{message}</Paragraph1>
        </Grid>
        {confirmTextToType && (
          <Grid item xs={12} mt={3}>
            <Grid>
              <TextFieldContainer>
                <TextField
                  name={"confirmTextToType"}
                  id={"confirmTextToType"}
                  fullWidth
                  value={confirmTextValue}
                  variant={"standard"}
                  onChange={(e) => setConfirmTextValue(e.target.value)}
                  label={
                    <>
                      Please type <strong>PUBLISH</strong> to confirm
                    </>
                  }
                />
              </TextFieldContainer>
            </Grid>
          </Grid>
        )}
        <Grid item mt={"40px"} xs={12}>
          <Grid container justifyContent={"center"} alignItems={"center"}>
            <Grid item xs={4}>
              <TextButton fullWidth onClick={onClose} label={"Cancel"} />
            </Grid>
            <Grid item xs={4}>
              <PrimaryButton
                fullWidth
                textcolor={colors.secondary.light}
                backgroundcolor={colors.primary.dark}
                onClick={onSubmit}
                label={primaryButtonText || "Proceed"}
                disabled={disabledSubmit}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ConfirmModal;
