import Styled from "@emotion/styled";

export const OrgLogo = Styled.img`
    object-fit: contain;
    border-radius: 10px;
    height: 200px;
    aspect-ratio: 16/9;
`;

export const OrgLogoContainer = Styled.div`
    display: flex;
    align-items: center;
    gap: 30px;

    @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
        gap: 10px;
    }
`;
