import { FC } from 'react';
import Login from '../components/login';
import { useSelector } from 'react-redux';
import { useUserInfo } from '../store/auth/Selector';
import ConfirmUser from '../components/confirmuser';

type ProtectedRouteProps = {
    children: React.ReactNode;
}

const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
    const userInfo = useSelector(useUserInfo);

    if (window.location.pathname.includes("/confirm")) {
        return <ConfirmUser />;
    }
    if (!userInfo) {
        return <Login />
    }

    return <>{children}</>
};

export default ProtectedRoute;