import { Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useGetUserRole } from '../../../../store/auth/Selector';
import { getServiceUsage } from '../../../../store/sites/ActionCreator';
import { useGetSiteServiceList } from '../../../../store/siteservice/Selector';
import { ErrorToast } from '../../../commoncomponents/designs/Toasts';
import { ActiveBillingType, CustomerUsageListType, UsageListType } from './Type';
import { useGetPropertyCustomer } from '../../../../store/sites/Selector';
import CustomerCard from './component/CustomerCard';
import { getActiveBillingPeriod, sortCustomerData } from './Util';

const Usage = () => {
    const serviceList = useSelector(useGetSiteServiceList);
    const [serviceUsageList, setServiceUsageList] = useState<UsageListType[]>([]);
    const { id, homeid } = useParams<{
        id: string;
        homeid: string;
    }>();
    const role = useSelector(useGetUserRole);
    const customerList = useSelector(useGetPropertyCustomer);
    const [activeBilling, setActiveBilling] = useState<ActiveBillingType | null>(null);
    const [customerUsageList, setCustomerUsageList] = useState<CustomerUsageListType[] | null>(null);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        id && homeid && getServiceUsage(id, homeid).then((res: any) => {
            setCustomerUsageList(res);
        });
    }

    useEffect(() => {
        id && getActiveBillingPeriod(id).then((res) => setActiveBilling(res))
    }, [id]);

    if (!activeBilling || !customerUsageList) {
        return null;
    }
    if (customerUsageList.length === 0) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ErrorToast title='Site Usage ' message='No customer found.' />
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid>
            {
                sortCustomerData(customerUsageList, customerList).map((item) =>
                    <Grid container key={item.id}>
                        <Grid item xs={12}>
                            <CustomerCard
                                siteId={id || ""}
                                propertyId={homeid || ""}
                                role={role}
                                refreshList={fetchData}
                                activeBilling={activeBilling}
                                serviceUsageList={item.usages.map((item) => ({
                                    amount: Number(item.amount),
                                    billingType: serviceList.find((serv) => Number(serv.id) === Number(item.serviceId))?.billingType || 'metered',
                                    id: Number(item.serviceId),
                                    name: serviceList.find((serv) => Number(serv.id) === Number(item.serviceId))?.name || ''
                                }))}
                                customerData={item}
                            />
                        </Grid>
                    </Grid>
                )
            }
        </Grid>
    );
}

export default Usage;