import { ActionPayload } from "../../common/Base";
import { OptionType } from "../../components/commoncomponents/fields/Type";
import { UPDATE_AVAILAIBLE_INVOICE__DROPDOWN, UPDATE_BILLING_DROPDOWN, UPDATE_CURRENCIES_DROPDOWN, UPDATE_INVOICE_REFERENCE_DROPDOWN, UPDATE_LANGUAGE_TAGS_DROPDOWN, UPDATE_ROLES_DROPDOWN, UPDATE_SERVICE_BILLING_DROPDOWN, UPDATE_SERVICE_CATEGORIES_DROPDOWN, UPDATE_SERVICE_FIXED_UNIT_DROPDOWN, UPDATE_TARIFF_KIND_DROPDOWN, UPDATE_TIMEZONES_DROPDOWN, UPDATE_UNIT_ID_DROPDOWN, UPDATE_XERO_ACCOUNTING_CODE_DROPDOWN, UPDATE_XERO_INVOICE_DROPDOWN, UPDATE_XERO_INVOICE_STATUS_DROPDOWN, xeroAccountInvoiceOptionsType } from "./Type";

export const updateBillingCyclesDropdown = (billingCyclesDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_BILLING_DROPDOWN,
    payload: billingCyclesDropdown
});

export const updateCurrenciesDropdown = (currenciesDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_CURRENCIES_DROPDOWN,
    payload: currenciesDropdown
});

export const updateLanguageTagsDropdown = (languageTagsDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_LANGUAGE_TAGS_DROPDOWN,
    payload: languageTagsDropdown
});

export const updateRolesDropdown = (rolesDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_ROLES_DROPDOWN,
    payload: rolesDropdown
});

export const updateTimezonesDropdown = (timezonesDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_TIMEZONES_DROPDOWN,
    payload: timezonesDropdown
});

export const updateServiceBillingDropdown = (serviceBillingDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_SERVICE_BILLING_DROPDOWN,
    payload: serviceBillingDropdown
});

export const updateServiceCategoryDropdown = (serviceCategoryDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_SERVICE_CATEGORIES_DROPDOWN,
    payload: serviceCategoryDropdown
});

export const updateXeroAccountDropdown = (xeroAccountDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_XERO_ACCOUNTING_CODE_DROPDOWN,
    payload: xeroAccountDropdown
});

export const updateTariffKindDropdown = (tariffKindDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_TARIFF_KIND_DROPDOWN,
    payload: tariffKindDropdown
});

export const updateUnitIdDropdown = (unitIdDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_UNIT_ID_DROPDOWN,
    payload: unitIdDropdown
});

export const updateInvoiceRefDropdown = (invoiceDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_INVOICE_REFERENCE_DROPDOWN,
    payload: invoiceDropdown
});

export const updateXeroInvoiceDropdown = (invoiceDropdown: xeroAccountInvoiceOptionsType[]): ActionPayload<xeroAccountInvoiceOptionsType[]> => ({
    type: UPDATE_XERO_INVOICE_DROPDOWN,
    payload: invoiceDropdown
});

export const updateXeroInvoiceStatusDropdown = (invoiceDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_XERO_INVOICE_STATUS_DROPDOWN,
    payload: invoiceDropdown
});

export const updateServiceFixedUnitDropdown = (serviceDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_SERVICE_FIXED_UNIT_DROPDOWN,
    payload: serviceDropdown
});

export const updateAvailaibleInvoiceDropdown = (invoiceDropdown: OptionType[]): ActionPayload<OptionType[]> => ({
    type: UPDATE_AVAILAIBLE_INVOICE__DROPDOWN,
    payload: invoiceDropdown
});