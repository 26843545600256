import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { Client } from "../../common/Client";
import { ServiceSettingType } from "../../components/manageproperty/managepropertyedit/service/serviceupdate/Type";
import {
  downloadTimeOfUseApiUrl,
  getSiteXeroAuthApiUrl,
  loginXeroAuth,
  propertyServiceUsageApiUrl,
  siteServicesApiUrl,
  updateSiteServiceApiUrl,
  updateSiteTimeOfScheduleUrl,
} from "../../constants/ApiConstants";
import { ToastErrorConfig } from "../../constants/SiteConstants";
import { LoginHelper } from "../../util/LoginHelper";
import { getSitePropertyItem } from "../sites/ActionCreator";
import {
  updateSiteServiceList,
  updateSiteServiceListIsError,
  updateSiteServiceListLoading,
} from "./Action";
import { ServiceListType } from "./Type";

const transformServiceList = (res: any): ServiceListType[] => {
  if (!res) {
    return [];
  }
  return res.map((item: any) => ({
    category: {
      created: item.category.created,
      id: item.category.id,
      modified: item.category.modified,
      name: item.category.name,
      siteId: item.category.siteId,
    },
    billingType: item?.billingType || "",
    chronoUnits: item?.chronoUnits || "",
    created: item.created,
    description: item.description,
    enabled: item.enabled,
    modified: item.modified,
    id: item.id,
    name: item.name,
    siteId: item.siteId,
    tariffKind: {
      created: item.tariffKind.created,
      id: item.tariffKind.id,
      modified: item.tariffKind.modified,
      name: item.tariffKind.name,
      organisationId: item.tariffKind.organisationId,
    },
    unitRate: item.unitRate,
    units: {
      created: item?.units?.created || "",
      id: item?.units?.id || "",
      modified: item?.units?.modified || "",
      name: item?.units?.name || "",
      organisationId: item?.units?.organisationId || "",
    },
    xeroDetails: {
      accountCode: item.xeroDetails.accountCode,
      created: item.xeroDetails.created,
      id: item.xeroDetails.id,
      modified: item.xeroDetails.modified,
      taxType: item.xeroDetails.taxType,
    },
    invoiceTransactionDescription: item?.invoiceItemDescription || ""
  }) as ServiceListType);
};
export const getSiteServiceList =
  (siteId: string, forceRefresh = false): any =>
    (dispatch: Dispatch) => {
      dispatch(updateSiteServiceListLoading(true));
      return Client.getInstance()
        .getData(siteServicesApiUrl(siteId), {}, forceRefresh)
        .then((res) =>
          dispatch(
            updateSiteServiceList(transformServiceList(res.data.data.results))
          )
        )
        .catch(() =>
          dispatch(updateSiteServiceListIsError("Something went wrong"))
        )
        .finally(() => dispatch(updateSiteServiceListLoading(false)));
    };

const reverseTransformData = (data: ServiceSettingType) => {
  return {
    billingType: data.simpleTarif.billingType,
    categoryId: data.serviceCategory,
    description: data.description,
    enabled: true,
    name: data.name,
    tariffKindId: data.tariffId,
    unitId: ["Metered", "Manual"].includes(data.simpleTarif.billingType)
      ? data.simpleTarif.standingUnitRate
      : null,
    unitRate: ["Metered", "Manual"].includes(data.simpleTarif.billingType)
      ? data.simpleTarif.standingUnits
      : data.simpleTarif.standingChargeUnit,
    chronoUnits: !["Metered", "Manual"].includes(data.simpleTarif.billingType)
      ? data.simpleTarif.standingCharge
      : null,
    xeroDetails: {
      accountCode: data.xeroDetails?.value || "",
      taxType: data.xeroDetails?.type || "",
    },
    invoiceItemDescription: data.invoiceTransactionDescription,
  };
};

export const addSiteServiceList =
  (siteId: string, value: ServiceSettingType): any =>
    (dispatch: Dispatch) => {
      return Client.getInstance()
        .createData(siteServicesApiUrl(siteId), reverseTransformData(value))
        .then(() => Promise.resolve())
        .catch((err) => {
          err.length > 0
            ? err.forEach((e: any) =>
              toast.error(
                e?.message || "Something went wrong",
                ToastErrorConfig
              )
            )
            : toast.error(err || "Something went wrong", ToastErrorConfig);
          return Promise.reject();
        });
    };

export const updateSiteService =
  (siteId: string, serviceId: string, value: ServiceSettingType): any =>
    (dispatch: Dispatch) => {
      return Client.getInstance()
        .updateData(
          updateSiteServiceApiUrl(siteId, serviceId),
          reverseTransformData(value)
        )
        .then(() => Promise.resolve())
        .catch(() => Promise.reject());
    };

export const updatePropertySourceServices = (
  siteId: string,
  propertyId: string,
  serviceId: string,
  serviceInfo: ServiceSettingType
): any => (dispatch: Dispatch) => {
  return Client.getInstance()
    .updateData(propertyServiceUsageApiUrl(siteId, propertyId, serviceId),
      {
        datumStream: {
          nodeId: Number(serviceInfo.nodeId),
          metric: serviceInfo.metric,
          sourceId: serviceInfo.sourceId
        },
        invoiceItemDescription: serviceInfo.invoiceTransactionDescription,
        enabled: serviceInfo.enabled
      }
    ).then(() => {
      dispatch(getSitePropertyItem(siteId, propertyId, true));
      return Promise.resolve();
    }).catch((err) => {
      toast.error(err || "Something went wrong", ToastErrorConfig);
      dispatch(getSitePropertyItem(siteId, propertyId, true));
      return Promise.reject();
    })
};


export const deleteSiteService =
  (siteId: string, serviceId: string): any =>
    (dispatch: Dispatch) => {
      return Client.getInstance()
        .deleteData(updateSiteServiceApiUrl(siteId, serviceId))
        .then(() => {
          dispatch(getSiteServiceList(siteId, true));
          return Promise.resolve();
        })
        .catch(() => Promise.reject());
    };

export const downloadTemplate = () =>
  Client.getInstance()
    .getData(downloadTimeOfUseApiUrl, {}, true)
    .then((res) => Promise.resolve(res))
    .catch(() => Promise.reject());

const reverseTransformTouCSV = (value: ServiceSettingType) => {
  const formData = new FormData();
  value.tou.file && formData.append("data", value.tou.file as Blob);
  return formData;
};

export const uploadServiceTou =
  (
    siteId: string,
    value: ServiceSettingType,
    setUploadPercent: (percent: number) => void
  ): any =>
    (dispatch: Dispatch) => {
      const options = {
        onUploadProgress: (progressEvent: any) => {
          const { loaded, total } = progressEvent;
          const percent = Math.floor((loaded * 100) / total);
          setUploadPercent(percent);
        },
      };
      return Client.getInstance()
        .createData(
          updateSiteTimeOfScheduleUrl(siteId, value.name),
          reverseTransformTouCSV(value),
          true,
          options
        )
        .then(() => {
          dispatch(getSiteServiceList(siteId, true));
          return Promise.resolve();
        })
        .catch((err) => {
          toast.error(err || "Something went wrong", ToastErrorConfig);
          return Promise.reject();
        });
    };

export const xeroConnect = (siteId: string) => {
  return Client.getInstance()
    .getData(getSiteXeroAuthApiUrl(siteId))
    .then((res) => {
      LoginHelper.setXeroRedirectUrl(window.location.pathname);
      window.open(
        loginXeroAuth(
          encodeURIComponent(res.data.data.response_type),
          encodeURIComponent(res.data.data.client_id),
          encodeURIComponent(res.data.data.scope),
          encodeURIComponent(res.data.data.state)
        ),
        "_self"
      );
      return Promise.resolve(res);
    })
    .catch((err) => {
      toast.error(err || "Something went wrong", ToastErrorConfig);
      return Promise.reject();
    });
};