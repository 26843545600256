import { AuthType } from "./auth/Type";
import { combineReducers } from "redux";
import { AuthReducer } from "./auth/Reducer";
import { UserListType } from "./user/Type";
import { UserListReducer } from "./user/Reducer";
import { OrgListType } from "./organisation/Type";
import { OrgListReducer } from "./organisation/Reducer";
import { SiteListType } from "./sites/Type";
import { SiteListReducer } from "./sites/Reducer";
import { LookupType } from "./lookup/Type";
import { LookupDropdownReducer } from "./lookup/Reducer";
import { ProfileInfoType } from "./profile/Type";
import { ProfileInfoReducer } from "./profile/Reducer";
import { SiteServiceType } from "./siteservice/Type";
import { SiteServiceReducer } from "./siteservice/Reducer";
import { AuthInfoType, XeroAuthInfoType } from "./xeroconnect/Type";
import { XeroAuthInfoReducer } from "./xeroconnect/Reducer";

export type RootReducerType = {
    auth: AuthType;
    users: UserListType;
    organisation: OrgListType;
    sites: SiteListType;
    lookupDropdown: LookupType;
    profileInfo: ProfileInfoType;
    siteService: SiteServiceType;
    xeroInfo: XeroAuthInfoType | null;
};

export const rootReducer = combineReducers({
    auth: AuthReducer,
    users: UserListReducer,
    organisation: OrgListReducer,
    sites: SiteListReducer,
    lookupDropdown: LookupDropdownReducer,
    profileInfo: ProfileInfoReducer,
    siteService: SiteServiceReducer,
    xeroInfo: XeroAuthInfoReducer
});
