import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useEffect } from 'react';
import { MdAdd } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserRole } from '../../../store/auth/Selector';
import { getUserList } from '../../../store/user/ActionCreator';
import { doesLoggedinUserHavePermission } from '../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../util/Type';
import BreadcrumbWrapper from '../../commoncomponents/designs/BreadcrumbWrapper';
import { TextButton } from '../../commoncomponents/designs/Button';
import SideNavbar from '../../commoncomponents/sidenavbar';
import MobileNavbarMenu from '../../commoncomponents/sidenavbar/MobileNavbar';
import LeftSection from '../../commoncomponents/sitesection/LeftSection';
import AddUser from '../addusers';
import { useUserListHooks } from '../Hooks';
import AddItemHeader from '../mobile/AddItemHeader';
import { UserSideMenu } from '../Util';
import CurrentUserList from './CurrentUserList';
import { CurrentUserTabOption } from './Util';

const CurrentUser = () => {

    const { openAddNewUserModal, toggleModal } = useUserListHooks();
    const dispatch = useDispatch();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const role = useSelector(useGetUserRole);

    if (mobileView) {
        return (
            <Grid>
                <MobileNavbarMenu
                    menuOptions={UserSideMenu(role)}
                    selectedMenuId={2}
                />
                <Grid item xs={7}
                    style={{
                        pointerEvents:
                            !doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.VIEW)
                                ? "none" : "all"
                    }}
                >
                    <AddItemHeader handleAddClick={toggleModal} />
                    <CurrentUserList />
                    {
                        openAddNewUserModal && <AddUser handleClose={toggleModal} />
                    }
                </Grid>
            </Grid>
        )
    }

    useEffect(() => {
        dispatch(getUserList());
    }, []);

    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar selectedMenuId={2} sidebarOptions={UserSideMenu(role)} />
                </LeftSection>
            </Grid>
            <Grid item xs={10}
                style={{
                    pointerEvents:
                        !doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.VIEW)
                            ? "none" : "all"
                }}>
                <Grid container>
                    <Grid item xs={8}>
                        <Grid container>
                            <Grid item xs={8}>
                                <BreadcrumbWrapper
                                    tabType={CurrentUserTabOption}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <TextButton
                                    disabled={!doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.CREATE)}
                                    onClick={toggleModal} label='ADD NEW USER' icon={<MdAdd />} />
                            </Grid>
                            {
                                openAddNewUserModal && <AddUser handleClose={toggleModal} />
                            }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid mt={"79.5px"}>
                    <CurrentUserList />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default CurrentUser