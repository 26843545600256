import { Grid } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { colors } from '../../../constants/Colors';
import { Small2Mobile } from '../../commoncomponents/designs/Text';
import { MobilePropertyButton } from './Style';
import { SideBarOptionsType } from './Util';
import { FC } from "react";

type MobileNavbarMenuProps = {
  menuOptions: SideBarOptionsType[];
  selectedMenuId: number;
}

const MobileNavbarMenu: FC<MobileNavbarMenuProps> = ({
  menuOptions,
  selectedMenuId
}) => {

  const navigate = useNavigate();

  return (
    <Grid>
      <Grid mt={"20px"} container spacing={"5px"}>
        {
          menuOptions.filter((item) => item.isAvailaible).map((item, index) => (
            <MobilePropertyButton
              key={item.id}
              onClick={() => navigate(item.url)}
              isSelected={selectedMenuId === index + 1} item xs={3}>
              {item.icons}
              <Small2Mobile color={item.isSelected ? colors.primary.dark : colors.primary.light} fontWeight={"bold"}>{item.label}</Small2Mobile>
            </MobilePropertyButton>
          ))
        }
      </Grid>
    </Grid>
  );
};

export default MobileNavbarMenu;