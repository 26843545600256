import { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { createNewUser } from "../../store/auth/ActionCreator";
import { updateProfileImageAction } from "../../store/profile/ActionCreator";
import { addNewUser } from "../../store/user/ActionCreator";
import { SignupFormType } from "./Type";

export const useSignupFormHook = () => {
    
    const [uploadPercent, setUploadPercent] = useState(0);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const createAccountDetail = (
        value: SignupFormType,
        setLoading: (isLoading: boolean) => void,
    ) => {
        dispatch(addNewUser({
            firstName: value.firstName,
            lastName: value.lastName,
            email: value.email,
            password: value.password,
            role: "ADMIN",
            confirmEmail: value.confirmEmail,
            confirmPassword: value.confirmPassword,
            image: value.profilePicture,
            imageUrl: value.profilePictureUrl,
            receiveEmail: value.receiveEmail,
        }))
            .then((res: number) => {
                if (!value.profilePicture) {
                    navigate("/login");
                    return;
                }
                setIsImageUploading(true);
                value.profilePicture && dispatch(updateProfileImageAction({
                    image: value.profilePicture,
                    id: res,
                    firstName: value.firstName,
                    lastName: value.lastName,
                    email: value.email,
                    password: value.password,
                    imageUrl: value.profilePictureUrl,
                    role: "ADMIN",
                }, setUploadPercent)).then(() => {
                    setIsImageUploading(false);
                    navigate("/login");
                })
            })
            .finally(() => setLoading(false))
    }

    return {
        createAccountDetail,
        uploadPercent,
        isImageUploading
    }
}