import { object, string } from "yup";
import { Client } from "../../../common/Client";
import { downloadPropertyTemplateCsv } from "../../../constants/ApiConstants";
import { ManagePropertyDetailType } from "../../../store/sites/Type";
import { downloadFile } from "../../../util/SiteUtil";

export const manageAddPropertyTabOption = (id: string, isMultiple = false) => [
    {
        label: 'Portfolio',
        url: '/portfolio',
    },
    {
        label: 'Manage Site',
        url: `/site/${id}/info`,
    },
    {
        label: 'Manage Property',
        url: `/site/${id}/manageproperties`,
    },
    {
        label: isMultiple ? 'Add Properties' : 'Add Property'
    },
];

export const AddPropertyInitialValue: ManagePropertyDetailType = {
    address: "",
    xeroCategory1: "",
    xeroCategory2: "",
    xeroOption1: "",
    xeroOption2: "",
    name: '',
    type: '',
    services: [],
    imageUrl: '',
    image: null,
};

export const PropertyValidationSchema = object().shape({
    name: string().required('Name is required'),
    type: string().required('Type is required'),
});

export const downloadPropertyTemplate = () =>
    Client.getInstance().getData(downloadPropertyTemplateCsv, {}, true)
        .then((res) => {
            downloadFile(res.data, "property.csv", "text/csv;charset=utf-8;");
        });
