import { Client } from "../../common/Client";
import { SignupFormType } from "../../components/signup/Type";
import { confirmNewlyCreatedUrl, createAccountApiUrl, loginApiUrl } from "../../constants/ApiConstants";
import { LoginHelper } from "../../util/LoginHelper";
import { loginError, loginUser, setUserLogined } from "./Action";
import { UserLoginType } from "./Type";

export const login = (data: UserLoginType): any => (dispatch: any) =>
    Client.getInstance().getData(loginApiUrl, {
        auth: {
            username: data.username,
            password: data.password
        }
    }, true)
        .then((response) => {
            dispatch(loginUser({
                email: response.data.username,
                userRole: response.data.role
            }));
            LoginHelper.setUserSession({
                email: response.data.username,
                password: data.password,
                userRole: response.data.role
            });
            Client.getInstance().setAuthenticationClient(data.username, data.password);
            dispatch(setUserLogined(true));
            dispatch(loginError(""));
            return Promise.resolve();
        })
        .catch(error => {
            dispatch(loginError("Email or password is incorrect"));
            LoginHelper.removeUserSession();
            Client.getInstance().setAuthenticationClient("", "");
            dispatch(setUserLogined(false));
            return Promise.reject(error);
        });


export const getConfirmLogin = (code: string) =>
    Client.getInstance().createData(confirmNewlyCreatedUrl(code), {})
        .then((res) => Promise.resolve())
        .catch((err) => Promise.reject(err));

export const createNewUser = (data: SignupFormType) =>
    Client.getInstance().createData(createAccountApiUrl, data)
        .then(() => Promise.resolve())
        .catch((err) => Promise.reject(err));