import { object, ref, string } from "yup";
import { ProfileFormValueType } from "../../store/profile/Type";
import { ProfileDetailFormValueType } from "./Type";

export const ProfileInitialValue = (initialValue?: ProfileFormValueType): ProfileDetailFormValueType => ({
    image: null,
    imageUrl: initialValue?.imageUrl || "",
    id: initialValue?.id || -1,
    role: initialValue?.role || "",
    firstName: initialValue?.firstName || "",
    lastName: initialValue?.lastName || "",
    email: initialValue?.email || "",
    password: "",
    confirmPassword: "",
    changePassword: false,
});

export const ProfileValidationSchema = object().shape({
    firstName: string().required("First name is required"),
    lastName: string().required("Last name is required"),
    email: string().email("Please enter valid email").required("Email is required"),
    password: string().when("changePassword", {
        is: (val: boolean) => val,
        then: string().required("Password is required"),
        otherwise: string(),
    }),
    confirmPassword: string().when("changePassword", {
        is: (val: boolean) => val,
        then: string()
            .oneOf([ref('password'), null], 'Passwords must match').required("Confirm password"),
        otherwise: string(),
    })
});
