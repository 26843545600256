import { GridCellParams } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { BsArchive } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { updateArchivedUser } from "../../../store/user/ActionCreator";
import { useGetUserList, useGetUserListLoading } from "../../../store/user/Selector";
import { StyledHeader } from "../../commoncomponents/datagrid/DataGridStyle";
import { TextButton } from "../../commoncomponents/designs/Button";
import { useUserListHooks } from "../Hooks";
import { Grid } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { UserType } from "../../../store/user/Type";
import { doesLoggedinUserHavePermission } from "../../../util/SiteUtil";
import { PERMISSIONS, USER_ROLES } from "../../../util/Type";
import { useGetUserRole } from "../../../store/auth/Selector";
import { permissionDisabledField } from "../../../constants/SiteConstants";

export const useCurrentUserHooks = () => {
    const { userListColumns } = useUserListHooks();
    const currentUserList = useSelector(useGetUserList);
    const isLoading = useSelector(useGetUserListLoading);
    const dispatch = useDispatch();
    const [selectedUserDetail, setSelectedUserDetail] = useState<UserType | null>(null);
    const role = useSelector(useGetUserRole);

    const updateArchive = (id: string) => {
        dispatch(updateArchivedUser(id, true));
    }
    const currentListColumns = () => useMemo(() => ([
        ...userListColumns(),
        {
            field: "id",
            flex: 1,
            minWidth: 300,
            renderHeader: () => <StyledHeader label="ACTION" />,
            renderCell: (params: GridCellParams) => <Grid container gap={"10px"}>
                <Grid item>
                    <TextButton
                        disabled={permissionDisabledField(role, params.row.role) || !doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.EDIT)}
                        onClick={() => setSelectedUserDetail(params.row)}
                        label="EDIT" icon={<FiEdit />} />
                </Grid>
                <Grid item>
                    <TextButton
                        disabled={!doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.DELETE)}
                        onClick={() => updateArchive(params.row.id)} label="ARCHIVE" icon={<BsArchive />}
                    />
                </Grid>

            </Grid>
        },
    ]
    ), []);

    return {
        currentListColumns,
        currentUserList,
        isLoading,
        selectedUserDetail,
        setSelectedUserDetail
    }
};
