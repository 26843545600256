import  { FC, useState, useEffect } from "react";
import { Grid, Box } from "@mui/material";
import {
  Heading3,
  Paragraph1,
} from "../../../../commoncomponents/designs/Text";
import { ActiveBillingType, CustomerInfoType, UsageListType } from "../Type";
import { UsageContainer } from "../Style";
import UsageView from "../UsageView";
import { doesLoggedinUserHavePermission } from "../../../../../util/SiteUtil";
import { PrimaryButton } from "../../../../commoncomponents/designs/Button";
import { colors } from "../../../../../constants/Colors";
import { PERMISSIONS, USER_ROLES } from "../../../../../util/Type";
import { AiOutlineCloud } from "react-icons/ai";
import { updateCustomerUsageDetail } from "../Util";
import format from "date-fns/format";
import { ErrorToast } from "../../../../commoncomponents/designs/Toasts";
import { toast } from "react-toastify";
import { subDays } from "date-fns";

type CustomerCardProps = {
  customerData: CustomerInfoType;
  serviceUsageList: UsageListType[];
  activeBilling: ActiveBillingType;
  role: string;
  siteId: string;
  propertyId: string;
  refreshList: () => void;
};
const CustomerCard: FC<CustomerCardProps> = ({
  customerData,
  serviceUsageList,
  role,
  activeBilling,
  propertyId,
  siteId,
  refreshList,
}) => {
  const [customerserviceUsageList, setCustomerServiceUsageList] = useState<
    UsageListType[]
  >([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setCustomerServiceUsageList(serviceUsageList);
  }, [serviceUsageList]);

  const handleValueChange = (id: number, amount: number) => {
    const findList = serviceUsageList.find((o) => o.id === id);
    setCustomerServiceUsageList((prev) =>
      prev.map((obj) =>
        obj.id === findList?.id
          ? {
              ...findList,
              amount: amount,
            }
          : obj
      )
    );
  };

  const updateCustomerServiceUsage = () => {
    setIsLoading(true);
    updateCustomerUsageDetail(
      siteId,
      propertyId,
      customerData.id.toString(),
      customerserviceUsageList
    )
      .then(() => {
        toast.success("Updated successfully");
      })
      .catch(() => {
        toast.error("Something Went wrong");
        refreshList();
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderView = () => {
    if (customerserviceUsageList.length === 0) {
      return (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <ErrorToast title="Site Usage " message="No usage found." />
          </Grid>
        </Grid>
      );
    }
    return (
      <Grid item xs={12}>
        {customerserviceUsageList.map((item) => (
          <UsageContainer
            key={item.id}
            container
            justifyContent={"space-between"}
          >
            <Grid item xs={10}>
              {item.name}
            </Grid>
            <Grid item xs={2}>
              <UsageView
                usageInfo={item}
                handleValueChange={handleValueChange}
              />
            </Grid>
          </UsageContainer>
        ))}
        <Grid
          mt={"12px"}
          item
          xs={12}
          md={12}
          style={{ display: "flex", justifyContent: "center" }}
        >
          <PrimaryButton
            style={{
              width: 300,
              opacity: !doesLoggedinUserHavePermission(
                role,
                USER_ROLES.HISTORIC_METERED_USAGE,
                PERMISSIONS.EDIT
              )
                ? 0.6
                : 1,
              pointerEvents: !doesLoggedinUserHavePermission(
                role,
                USER_ROLES.HISTORIC_METERED_USAGE,
                PERMISSIONS.EDIT
              )
                ? "none"
                : "all",
            }}
            isLoading={isLoading}
            onClick={updateCustomerServiceUsage}
            label={`Update ${customerData.name}`}
            backgroundcolor={colors.primary.dark}
            textcolor={colors.secondary.neutral}
            fullWidth
            icon={<AiOutlineCloud />}
          />
        </Grid>
      </Grid>
    );
  };
  return (
    <Grid container mb={10}>
      <Grid item xs={12}>
        <Box display={"flex"} justifyContent={"space-between"}>
          <Box>
            <Heading3>{customerData.name}</Heading3>
          </Box>
          <Box>
            <Paragraph1 color={"gray"} textsize={"16px"}>
              {format(new Date(activeBilling.startDate), "dd/MM")}{" "}
              - {format(subDays(new Date(activeBilling.endDate), 1), "dd/MM")}
            </Paragraph1>
          </Box>
        </Box>
      </Grid>
      {renderView()}
    </Grid>
  );
};

export default CustomerCard;
