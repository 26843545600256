import { ActionPayload } from "../../common/Base";
import { LOGIN_ERROR, LOGIN_IS_AUTHENTICATING, LOGIN_USER, LOGOUT_USER, UserInfoType, USER_LOGINED } from "./Type";


export const loginUser = (userInfo: UserInfoType): ActionPayload<UserInfoType> => {
    return {
        type: LOGIN_USER,
        payload: userInfo
    };
};

export const loginIsAuthenticating = (isAuthenticating: boolean): ActionPayload<boolean> => {
    return {
        type: LOGIN_IS_AUTHENTICATING,
        payload: isAuthenticating
    };
};

export const loginError = (errorMessage: string): ActionPayload<string> => {
    return {
        type: LOGIN_ERROR,
        payload: errorMessage
    };
};

export const logoutUser = ():ActionPayload<any> => {
    return {
        type: LOGOUT_USER,
        payload: {}
    };
};

export const setUserLogined = (isLogined: boolean):ActionPayload<boolean> => {
    return {
        type: USER_LOGINED,
        payload: isLogined
    };
};