import { differenceBy } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateOrgAllUserList } from "../../../../store/organisation/Action";
import { getSelectedOrgUserList, updateSelectedOrgUserList } from "../../../../store/organisation/ActionCreator";
import { useGetOrgUserList, useGetOrgListLoading } from "../../../../store/organisation/Selector";
import { OrgSwitchListType, OrgSwitchType } from "../../../../store/organisation/Type";
import { useGetUserList } from "../../../../store/user/Selector";

export const useOrgUserHook = () => {
    const users = useSelector(useGetUserList);
    const dispatch = useDispatch();
    const userList = useSelector(useGetOrgUserList);
    const { id } = useParams<{
        id: string;
    }>();
    const isLoading = useSelector(useGetOrgListLoading);
    useEffect(() => {
        id && dispatch(getSelectedOrgUserList(id, true));
    }, []);

    useEffect(() => {
        if (users.length > 0 && userList) {
            const copyUser = users.map((item) => ({
                id: item.id,
                name: `${item.firstName} ${item.lastName}`
            }));
            const filteredList = differenceBy(copyUser, userList?.selectedList, "id");
            dispatch(updateOrgAllUserList(filteredList));
        }
    }, [userList?.selectedList.length]);

    const updateSelectedUser = (user: OrgSwitchType) => {
        let currentListInfo = {};
        if (userList?.selectedList && userList.selectedList.find((item) => item.id === user.id)) {
            currentListInfo = {
                allList: [...userList.allList, user],
                selectedList: userList.selectedList.filter((item) => item.id !== user.id)
            };
            id && dispatch(updateSelectedOrgUserList(id, currentListInfo as OrgSwitchListType));
            return;
        }

        if (userList?.selectedList && userList.allList) {
            currentListInfo = {
                selectedList: [...userList.selectedList, user],
                allList: userList.allList.filter((item) => item.id !== user.id)
            };
            id && dispatch(updateSelectedOrgUserList(id, currentListInfo as OrgSwitchListType));
        }
    }

    return {
        userList,
        updateSelectedUser,
        isLoading
    }
};
