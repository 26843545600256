import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../../store/auth/Selector';
import BreadcrumbWrapper from '../../commoncomponents/designs/BreadcrumbWrapper';
import SideNavbar from '../../commoncomponents/sidenavbar';
import MobileNavbarMenu from '../../commoncomponents/sidenavbar/MobileNavbar';
import LeftSection from '../../commoncomponents/sitesection/LeftSection';
import ManagerPropertyHeader from '../mobile/ManagePropertyHeader';
import { manageSiteOptions, SiteManageProperty } from '../Util';
import AddPropertyForm from './AddPropertyForm';
import { useAddManagerPropertyHook } from './Hooks';
import { AddPropertyInitialValue, PropertyValidationSchema, manageAddPropertyTabOption } from './Util';

const AddProperty = () => {

    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const role = useSelector(useGetUserRole);
    const {
        propertyInfo,
        addNewProperty,
        id,
        addAnotherActivity,
        setAddAnotherActivity,
        percent,
        isImageUploading
    } = useAddManagerPropertyHook();

    if (mobileView) {
        return (
            <Grid>
                {
                    propertyInfo && <ManagerPropertyHeader
                        hideAction
                        propertyInfo={propertyInfo} />
                }
                <MobileNavbarMenu
                    menuOptions={manageSiteOptions(id || "", role)}
                    selectedMenuId={1}
                />
                <Formik
                    initialValues={AddPropertyInitialValue}
                    validationSchema={PropertyValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        addNewProperty(values, setSubmitting, resetForm);
                        return;
                    }}
                    enableReinitialize
                >
                    {() =>
                        <>
                            <AddPropertyForm
                                percent={percent}
                                isImageUploading={false}
                                addAnotherActivity={addAnotherActivity}
                                setAddAnotherActivity={setAddAnotherActivity} />
                        </>
                    }
                </Formik>
            </Grid>
        )
    }

    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar
                        selectedMenuId={2}
                        sidebarOptions={SiteManageProperty(id || "", role)}
                    />
                </LeftSection>
            </Grid>
            <Grid item xs={7}>
                <BreadcrumbWrapper
                    tabType={id ? manageAddPropertyTabOption(id) : []}
                />
                <Formik
                    initialValues={AddPropertyInitialValue}
                    validationSchema={PropertyValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        addNewProperty(values, setSubmitting, resetForm);
                        return;
                    }}
                    enableReinitialize
                >
                    {() => <AddPropertyForm
                        isImageUploading={isImageUploading}
                        percent={percent}
                        addAnotherActivity={addAnotherActivity}
                        setAddAnotherActivity={setAddAnotherActivity} />}
                </Formik>
            </Grid>
        </Grid>
    );
};

export default AddProperty;