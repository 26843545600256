import { Grid } from '@mui/material';
import { Formik } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../constants/Colors';
import { login } from '../../store/auth/ActionCreator';
import { useUserError } from '../../store/auth/Selector';
import { UserLoginType } from '../../store/auth/Type';
import { CustomTextfieldWrapper } from '../commoncomponents/CustomTextfieldWrapper';
import { PrimaryButton } from '../commoncomponents/designs/Button';
import { Label2, StyledLink } from '../commoncomponents/designs/Text';
import { loginInitialValue, loginValidationSchema } from './Util';
import { FC } from "react";
import { usePressEnterHook } from '../../common/Hooks';

type LoginFormProps = {
    isLoginConfirming?: boolean;
}
const LoginForm: FC<LoginFormProps> = ({
    isLoginConfirming = false
}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const errorMessage = useSelector(useUserError);

    const handleLogin = (values: UserLoginType, setSubmitting: (isSubmitting: boolean) => void) => {
        setSubmitting(true);
        dispatch(login({
            username: values.username,
            password: values.password
        })).then(() => {
            navigate("/portfolio");
        }).finally(() => {
            setSubmitting(false);
        });
    };

    return (
        <Formik
            initialValues={loginInitialValue}
            validationSchema={loginValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
                handleLogin(values, setSubmitting);
            }}
        >
            {
                ({ handleSubmit, isSubmitting }) => {
                    usePressEnterHook(
                        handleSubmit
                    );
                    return (
                        <Grid container gap={"30px"} direction={"column"}>
                            <Grid item xs={12}>
                                <CustomTextfieldWrapper
                                    name='username'
                                    id='username'
                                    fullWidth
                                    label={"Username"}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <CustomTextfieldWrapper
                                    name='password'
                                    id='password'
                                    label={"Password"}
                                    type={"password"}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {
                                    errorMessage && <Label2 color={colors.tertiary.errorMessage}>{errorMessage}</Label2>
                                }
                            </Grid>
                            <Grid container justifyContent={"center"}>
                                <PrimaryButton
                                    id={"login"}
                                    disabled={isLoginConfirming}
                                    isLoading={isSubmitting}
                                    textcolor={colors.secondary.light} backgroundcolor={colors.primary.dark} fullWidth onClick={() => handleSubmit()} label='Login' />
                            </Grid>
                            <StyledLink
                                onClick={() => navigate("/forgotpassword")}
                                color={colors.tertiary.link}>Forgot password</StyledLink>
                        </Grid>
                    )
                }
            }
        </Formik>
    )
}

export default LoginForm