import { ActionPayload } from "../../common/Base";
import { IS_ERROR, IS_LOADING } from "../CommonType";
import { ServiceListType, UPDATE_SITE_SERVICE_LIST } from "./Type";

export const updateSiteServiceList = (serviceList: ServiceListType[]): ActionPayload<ServiceListType[]> => ({
    type: UPDATE_SITE_SERVICE_LIST,
    payload: serviceList
});

export const updateSiteServiceListLoading = (isLoading: boolean): ActionPayload<boolean> => ({
    type: IS_LOADING,
    payload: isLoading
});

export const updateSiteServiceListIsError = (isError: string): ActionPayload<string> => ({
    type: IS_ERROR,
    payload: isError
});
