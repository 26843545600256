export const LoginHelper = {
    getUser: () => {
        const userStr = localStorage.getItem("revolveuserinfo");
        return userStr ? JSON.parse(userStr) : null;
    },
    removeUserSession: () => localStorage.removeItem("revolveuserinfo"),
    setUserSession: (userEmail: {
        email: string;
        userRole: string;
        password: string;
    }) => localStorage.setItem("revolveuserinfo", JSON.stringify(userEmail)),
    getXeroRedirectUrl: () => {
        const redirectUrl = localStorage.getItem("xeroredirecturl");
        return redirectUrl ? redirectUrl : null;
    },
    setXeroRedirectUrl: (redirectUrl: string) =>  localStorage.setItem("xeroredirecturl", redirectUrl)
};
