import { ActionPayload } from "../../common/Base";
import { IS_ERROR, IS_LOADING } from "../CommonType";
import { ProfileFormValueType, UPDATE_PROFILE_DATA, UPDATE_PROFILE_IMAGE } from "./Type";

export const updateProfileData = (profileData: ProfileFormValueType): ActionPayload<ProfileFormValueType> => ({
    type: UPDATE_PROFILE_DATA,
    payload: profileData
});

export const updateProfileLoading = (isLoading: boolean): ActionPayload<boolean> => ({
    type: IS_LOADING,
    payload: isLoading
});

export const updateProfileError = (isError: string): ActionPayload<string> => ({
    type: IS_ERROR,
    payload: isError
});

export const updateProfileImage = (): ActionPayload<string> => ({
    type: UPDATE_PROFILE_IMAGE,
    payload: ""
});
