import { Grid } from '@mui/material';
import { CardContainer } from '../commoncomponents/CommonStyle';
import { Heading2 } from '../commoncomponents/designs/Text';
import { Formik } from 'formik';
import { resetPassword, resetPasswordInitialValue, resetPasswordValidationSchema } from './Util';
import { CustomTextfieldWrapper } from '../commoncomponents/CustomTextfieldWrapper';
import { usePressEnterHook } from '../../common/Hooks';
import { PrimaryButton } from '../commoncomponents/designs/Button';
import { colors } from '../../constants/Colors';
import { toast } from 'react-toastify';
import { ToastSuccessConfig } from '../../constants/SiteConstants';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';

const ResetLink = () => {
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const [code, setCode] = useState("");

    useEffect(() => {
        const code = searchParams.get("code");
        code && setCode(code);
    }, []);

    const handleResetpassword = (password: string, setSubmitting: (isSubmitting: boolean) => void) => {
        resetPassword(code, password)
            .then(() => {
                toast.success("Password reset", ToastSuccessConfig);
                navigate("/login");
            }).finally(() => {
                setSubmitting(false);
            });
    }
    return (
        <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid mt={"100px"} item xs={11} sm={6} lg={4}>
                <CardContainer elevation={0}>
                    <Heading2 mb={"30px"} textAlign={"center"}>Reset Password</Heading2>
                    <Formik
                        initialValues={resetPasswordInitialValue}
                        validationSchema={resetPasswordValidationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            handleResetpassword(values.password, setSubmitting);
                        }}
                    >
                        {
                            ({ handleSubmit, isSubmitting }) => {
                                usePressEnterHook(
                                    handleSubmit
                                );
                                return (
                                    <Grid container gap={"30px"} direction={"column"}>
                                        <Grid item xs={12}>
                                            <CustomTextfieldWrapper
                                                name='password'
                                                id='password'
                                                fullWidth
                                                type={"password"}
                                                label={"Password"}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <CustomTextfieldWrapper
                                                name='confirmPassword'
                                                id='confirmPassword'
                                                fullWidth
                                                type={"password"}
                                                label={"Confirm password"}
                                            />
                                        </Grid>
                                        <Grid container justifyContent={"center"}>
                                            <PrimaryButton
                                                isLoading={isSubmitting}
                                                textcolor={colors.secondary.light} backgroundcolor={colors.primary.dark} fullWidth onClick={() => handleSubmit()} label='Reset link' />
                                        </Grid>
                                    </Grid>
                                )
                            }}
                    </Formik>
                </CardContainer>
            </Grid>
        </Grid>
    )
}

export default ResetLink