import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { Client } from "../../common/Client";
import { OptionType } from "../../components/commoncomponents/fields/Type";
import { getSiteXeroAccountCodesApiUrl, getSiteXeroInvoiceOptionApiUrl, lookupAvailaibleInvoiceItemApiUrl, lookupBiilingCycleApiUrl, lookupCurrenciesApiUrl, lookupInvoiceReferenceParamsApiUrl, lookupLanguagesApiUrl, lookupRolesApiUrl, lookupServiceChronoUnitsApiUrl, lookupSiteServiceBillingApiUrl, lookupTimeZoneApiUrl, lookupXeroInvoiceStatusApiUrl, orgServiceTariffApiUrl, orgServiceUnitsApiUrl, serviceCategoriesApiUrl } from "../../constants/ApiConstants";
import { ToastErrorConfig } from "../../constants/SiteConstants";
import { updateAvailaibleInvoiceDropdown, updateBillingCyclesDropdown, updateCurrenciesDropdown, updateInvoiceRefDropdown, updateLanguageTagsDropdown, updateRolesDropdown, updateServiceBillingDropdown, updateServiceCategoryDropdown, updateServiceFixedUnitDropdown, updateTariffKindDropdown, updateTimezonesDropdown, updateUnitIdDropdown, updateXeroAccountDropdown, updateXeroInvoiceDropdown, updateXeroInvoiceStatusDropdown } from "./Action";
import { xeroAccountInvoiceOptionsType } from "./Type";

const transformData = (data: any): OptionType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        value: item.id,
        label: item.id
    }));
}

const transformLanguageData = (data: any): OptionType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: string) => ({
        value: item,
        label: item
    }));
}


const transformRolesData = (data: any): OptionType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: {
        key: string,
        value: string
    }) => ({
        value: item.key,
        label: item.value
    }));
}

const transformServiceCategoriesData = (data: any): OptionType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: {
        id: string,
        name: string
    }) => ({
        value: item.id,
        label: item.name
    }));
}

const transformInvoiceReferenceParamsData = (data: any): OptionType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: {
        key: string,
        value: string
    }) => ({
        value: item.key,
        label: item.value
    }));
};

const transformXeroInvoiceData = (data: any): xeroAccountInvoiceOptionsType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: {
        key: string,
        value: string,
        options: {
            key: string,
            value: string
        }[]
    }) => ({
        value: item.key,
        label: item.value,
        options: item.options.map(option => ({
            value: option.key,
            label: option.value
        }))
    }))
};

export const getLookupDropdowns = (forceRefresh = false): any => (dispatch: Dispatch) => {
    getBiilingCycleDropdown(forceRefresh).then((data) =>
        dispatch(updateBillingCyclesDropdown(data)));

    getCurrencyCycleDropdown(forceRefresh).then((data) => dispatch(updateCurrenciesDropdown(data)));
    getLanguageCycleDropdown(forceRefresh).then((data) => dispatch(updateLanguageTagsDropdown(data)));
    getRoleDropdown(forceRefresh).then((data) => dispatch(updateRolesDropdown(data)));
    getTimeZoneDropdown(forceRefresh).then((data) => dispatch(updateTimezonesDropdown(data)));
    getServiceBillingDropdown(forceRefresh).then((data) => dispatch(updateServiceBillingDropdown(data)));
    getInvoiceReferenceParamsDropdown(forceRefresh).then((data) => dispatch(updateInvoiceRefDropdown(data)));
    getFixedServiceDropdown(forceRefresh).then((data) => dispatch(updateServiceFixedUnitDropdown(data)));
    getXeroInvoiceStatusDropdown(forceRefresh).then((data) => dispatch(updateXeroInvoiceStatusDropdown(data)));
    getAvailaibleInvoiceItemDropdown(forceRefresh).then((data) => dispatch(updateAvailaibleInvoiceDropdown(data)));
};

export const getBiilingCycleDropdown = (forceRefresh = false) =>
    Client.getInstance()
        .getData(lookupBiilingCycleApiUrl, {}, forceRefresh)
        .then((res) => Promise.resolve(transformRolesData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const getServiceBillingDropdown = (forceRefresh = false) =>
    Client.getInstance()
        .getData(lookupSiteServiceBillingApiUrl, {}, forceRefresh)
        .then((res) => Promise.resolve(transformRolesData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const getInvoiceReferenceParamsDropdown = (forceRefresh = false) =>
    Client.getInstance()
        .getData(lookupInvoiceReferenceParamsApiUrl, {}, forceRefresh)
        .then((res) => Promise.resolve(transformInvoiceReferenceParamsData(res.data.data)))
        .catch(() => Promise.reject());

export const getXeroInvoiceStatusDropdown = (forceRefresh = false) =>
    Client.getInstance()
        .getData(lookupXeroInvoiceStatusApiUrl, {}, forceRefresh)
        .then((res) => {
            return Promise.resolve(transformInvoiceReferenceParamsData(res.data.data));
        })
        .catch(() => Promise.reject());

export const getXeroAccountInvoiceDropdown = (siteId: string, forceRefresh = false): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .getData(getSiteXeroInvoiceOptionApiUrl(siteId), {}, forceRefresh)
        .then((res) => {
            dispatch(updateXeroInvoiceDropdown(transformXeroInvoiceData(res.data.data)));
            return Promise.resolve();
        })
        .catch(() => Promise.reject());

export const getCurrencyCycleDropdown = (forceRefresh = false) =>
    Client.getInstance()
        .getData(lookupCurrenciesApiUrl, {}, forceRefresh)
        .then((res) => Promise.resolve(transformRolesData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const getLanguageCycleDropdown = (forceRefresh = false) =>
    Client.getInstance()
        .getData(lookupLanguagesApiUrl, {}, forceRefresh)
        .then((res) => Promise.resolve(transformLanguageData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const getRoleDropdown = (forceRefresh = false) =>
    Client.getInstance()
        .getData(lookupRolesApiUrl, {}, forceRefresh)
        .then((res) => Promise.resolve(transformRolesData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const getTimeZoneDropdown = (forceRefresh = false) =>
    Client.getInstance()
        .getData(lookupTimeZoneApiUrl, {}, forceRefresh)
        .then((res) => Promise.resolve(transformData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const getAvailaibleInvoiceItemDropdown = (forceRefresh = false) =>
    Client.getInstance()
        .getData(lookupAvailaibleInvoiceItemApiUrl, {}, forceRefresh)
        .then((res) => Promise.resolve(transformRolesData(res.data.data)))
        .catch((err) => Promise.reject(err));

export const getServiceCategoryDropdown = (siteId: string, forceRefresh = false): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .getData(serviceCategoriesApiUrl(siteId), {}, forceRefresh)
        .then((res) => {
            dispatch(updateServiceCategoryDropdown(transformServiceCategoriesData(res.data.data.results)))
            return Promise.resolve();
        })
        .catch((err) => Promise.reject(err));


const transformTariffData = (data: any): OptionType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        value: item.id,
        label: item.name
    }));
}
export const getTariffDropdown = (orgId: string, forceRefresh = false): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .getData(orgServiceTariffApiUrl(orgId), {}, forceRefresh)
        .then((res) => {
            dispatch(updateTariffKindDropdown(transformTariffData(res.data.data.results)));
            return Promise.resolve();
        })
        .catch((err) => Promise.reject(err));

export const getUnitIdDropdown = (orgId: string, forceRefresh = false): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .getData(orgServiceUnitsApiUrl(orgId), {}, forceRefresh)
        .then((res) => {
            dispatch(updateUnitIdDropdown(transformTariffData(res.data.data.results)));
            return Promise.resolve();
        })
        .catch((err) => Promise.reject(err));

export const addSiteServiceCategory = (siteId: string, name: string): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .createData(serviceCategoriesApiUrl(siteId), {
            name: name
        })
        .then((res) => {
            dispatch(getServiceCategoryDropdown(siteId, true));
            return Promise.resolve(res.data.data.id);
        })
        .catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            return Promise.reject(err);
        });

export const addServiceUnits = (organisationId: string, name: string): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .createData(orgServiceUnitsApiUrl(organisationId), {
            name: name
        })
        .then((res) => {
            dispatch(getUnitIdDropdown(organisationId, true));
            return Promise.resolve(res.data.data.id);
        })
        .catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            return Promise.reject(err);
        });

const transformAccountCodeData = (data: any): OptionType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        value: item.code,
        label: item.name,
        type: item.taxType
    }));
}

export const getXeroAccountCodeDropdown = (siteId: string, forceRefresh = false): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .getData(getSiteXeroAccountCodesApiUrl(siteId), {}, forceRefresh)
        .then((res) => {
            dispatch(updateXeroAccountDropdown(transformAccountCodeData(res.data.data)));
            return Promise.resolve();
        })
        .catch((err) => Promise.reject(err));

export const getFixedServiceDropdown = (forceRefresh = false) =>
    Client.getInstance()
        .getData(lookupServiceChronoUnitsApiUrl, {}, forceRefresh)
        .then((res) => Promise.resolve(transformRolesData(res.data.data)))
        .catch((err) => Promise.reject(err));