import { Grid, IconButton } from '@mui/material'
import { FC } from 'react'
import { TextButton } from '../commoncomponents/designs/Button'
import { ErrorToast, TitleToast } from '../commoncomponents/designs/Toasts'
import { SettingContainer, StyledSwitch, SwitchLabel } from '../manageproperty/managepropertyedit/Style'
import { GoSettings } from "react-icons/go";
import { ServiceListType } from '../../store/siteservice/Type'
import { AiOutlineDelete } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useGetUserRole } from '../../store/auth/Selector'
import { PERMISSIONS, USER_ROLES } from '../../util/Type'
import { doesLoggedinUserHavePermission } from '../../util/SiteUtil'

type SiteSeriveListProps = {
    siteServiceList: ServiceListType[];
    setSelectedServiceInfo: (serviceInfo: ServiceListType) => void;
    handleServiceDelete: (serviceInfo: ServiceListType) => void;
}
const SiteServicesList: FC<SiteSeriveListProps> = ({
    siteServiceList,
    setSelectedServiceInfo,
    handleServiceDelete
}) => {

    const role = useSelector(useGetUserRole);

    if (siteServiceList.length === 0) {
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <ErrorToast title='Site services' message='No service found. Add new service' />
                </Grid>
            </Grid>
        );
    }
    return (
        <Grid>
            <TitleToast title='Site services' message='Set up and configure services that are available for this site.' />
            {
                siteServiceList.map((item) => (
                    <SettingContainer key={item.id} container p={"26px"} justifyContent={"space-between"} alignItems={"center"}>
                        <Grid item xs={9}>
                            <SwitchLabel
                                disabled={!doesLoggedinUserHavePermission(role, USER_ROLES.SERVICE_FOR_SITE, PERMISSIONS.EDIT)}
                                checked={item.enabled}
                                control={<StyledSwitch />}
                                label={item.name}
                            />
                        </Grid>
                        {
                            doesLoggedinUserHavePermission(role, USER_ROLES.SERVICE_FOR_SITE, PERMISSIONS.EDIT)
                            && <Grid item mt={["10px", "0px"]} xs={9} md={2} lg={1}>
                                <TextButton onClick={() => setSelectedServiceInfo(item)} icon={<GoSettings />} label='SETTINGS' />
                            </Grid>
                        }
                        {
                            doesLoggedinUserHavePermission(role, USER_ROLES.SERVICE_FOR_SITE, PERMISSIONS.DELETE)
                            && <Grid item mt={["10px", "0px"]} xs={1}>
                                <IconButton onClick={() => handleServiceDelete(item)}>
                                    <AiOutlineDelete />
                                </IconButton>
                            </Grid>
                        }
                    </SettingContainer>
                ))
            }
        </Grid>
    )
}

export default SiteServicesList