import { Client } from "../../../../common/Client";
import { getSiteXeroCustomersApiUrl } from "../../../../constants/ApiConstants";
import { CustomerSearchType, FutureCustomerType, PropertyCustomerList } from "../../../../store/sites/Type";

const transformCustomerDetail = (data: any): CustomerSearchType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        email: item.email,
        identifier: item.identifier,
        name: item.name,
        themeIdentifier: item.identifier
    }));
}

export const getCustomerDetail = (siteId: string, text: string) =>
    Client.getInstance()
        .getData(getSiteXeroCustomersApiUrl(siteId, text), {}, true)
        .then((res) => Promise.resolve(transformCustomerDetail(res.data.data)))
        .catch(() => Promise.reject());

export const FutureCustomerInitialValue: FutureCustomerType = {
    customerInfo: null,
    inDate: null,
    outDate: null
};

export const CustomerLisInitialValue = (customerInfo: PropertyCustomerList): PropertyCustomerList => ({
    ...customerInfo
});
