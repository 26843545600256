import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom"
import { getPropertyCustomerList, getSiteIdDetail, getSitePropertyItem } from "../../../store/sites/ActionCreator";
import { useGetPropertyCustomer, useGetSiteDetail, useGetSitePropertyDetail } from "../../../store/sites/Selector";
import { getSiteServiceList } from "../../../store/siteservice/ActionCreator";
import { tabType } from "../../commoncomponents/designs/Type";
import { managePropertyEditTabOption } from "./Util";

export const useManagePropertyEditHook = () => {
    const { id, homeid } = useParams();
    const [stepper, setStepper] = useState<tabType[]>([]);
    const [selectedTab, setSelectedTab] = useState("customers");
    const siteInfo = useSelector(useGetSiteDetail);
    const dispatch = useDispatch();
    const propertyDetails = useSelector(useGetSitePropertyDetail);

    useEffect(() => {
        if (id) {
            dispatch(getSiteIdDetail(id));
            homeid && dispatch(getSitePropertyItem(id, homeid));
            dispatch(getSiteServiceList(id));
        }
    }, []);

    useEffect(() => {
        propertyDetails?.name && id && setStepper([
            ...managePropertyEditTabOption(id),
            {
                label: propertyDetails.name
            }
        ]);
    }, [propertyDetails]);

    return {
        id,
        homeid,
        siteInfo,
        stepper,
        selectedTab,
        setSelectedTab,
        propertyDetails
    }
};

export const useCustomerHook = () => {

    const dispatch = useDispatch();
    const customerList = useSelector(useGetPropertyCustomer);

    const { id, homeid } = useParams<{
        id: string;
        homeid: string;
    }>();

    useEffect(() => {
        id && homeid && dispatch(getPropertyCustomerList(id, homeid))
    }, []);

    const filterCustomerByDate = useCallback((type: string) => {
        if (!customerList) {
            return [];
        }
        if (type === "current") {
            return customerList.filter((item) => {
                if (item.end) {
                    if (new Date() > new Date(item.start) && new Date() < new Date(item.end)) {
                        return item
                    }
                } else {
                    if (new Date() > new Date(item.start)) {
                        return item;
                    }
                }
            })
        }
        if (type === "future") {
            return customerList.filter((item) => new Date() < new Date(item.start))
        }
        if (type === "past") {
            return customerList.filter((item) => new Date() > new Date(item.end))
        }
        return [];
    }, [customerList]);

    return {
        filterCustomerByDate,
        customerList,
        allCustomers: customerList
    }

}