import { ToastOptions } from "react-toastify";
import { OptionType } from "../components/commoncomponents/fields/Type";

export const VALID_IMAGE = ["image/jpg", "image/jpeg", "image/png"];
export const VALID_CSV_DOCUMENT = ["text/csv"];
// export const MAP_KEY = import.meta.env.VITE_MAP_KEY || "";
export const MAP_KEY = "AIzaSyAZEZemDkCODDvDA5dPt_xUCoePs0zIsi0";
export const API_BASE_URL =
  import.meta.env.VITE_API_BASE_URL || "http://localhost:8080/api/v1";
export const XERO_CONNECT_API_URL =
  import.meta.env.VITE_XERO_CONNECT_API_URL ||
  "https://login.xero.com/identity/connect/authorize";
export const XERO_REQUEST_URL =
  import.meta.env.VITE_XERO_REQUEST_URL || "http://localhost:3000/xerosuccess";

export const permissionDisabledField = (
  role: string,
  currentUserRole: string
) => {
  if (
    role === "ROLE_MANAGER" &&
    ["ROLE_SUPER_ADMIN", "ROLE_MANAGER", "ROLE_ADMIN"].includes(currentUserRole)
  ) {
    return true;
  }
  if (
    role === "ROLE_ADMIN" &&
    ["ROLE_SUPER_ADMIN", "ROLE_ADMIN"].includes(currentUserRole)
  ) {
    return true;
  }
  if (
    role === "ROLE_OPERATOR" &&
    [
      "ROLE_SUPER_ADMIN",
      "ROLE_MANAGER",
      "ROLE_ADMIN",
      "ROLE_OPERATOR",
    ].includes(currentUserRole)
  ) {
    return true;
  }
  return false;
};
export const permissionLevelOption: OptionType[] = [
  {
    value: "",
    label: "Site Manager",
  },
  {
    value: "ROLE_SUPER_ADMIN",
    label: "Super Admin",
  },
  {
    value: "ROLE_ADMIN",
    label: "Admin",
  },
  {
    value: "ROLE_MANAGER",
    label: "Manager",
  },
  {
    value: "ROLE_OPERATOR",
    label: "Operator",
  },
];

export const dueDaysOptions: OptionType[] = [
  {
    value: "none",
    label: "Due days",
  },
  {
    value: "Monday",
    label: "Monday",
  },
  {
    value: "Tuesday",
    label: "Tuesday",
  },
  {
    value: "Wednesday",
    label: "Wednesday",
  },
  {
    value: "Thursday",
    label: "Thursday",
  },
  {
    value: "Friday",
    label: "Friday",
  },
  {
    value: "Saturday",
    label: "Saturday",
  },
  {
    value: "Sunday",
    label: "Sunday",
  },
];

const getNumberOfDate = (upto: number) => {
  const date = new Date();
  const currentDate = date.getDate();
  const currentMonth = date.getMonth();
  const currentYear = date.getFullYear();
  const dateArray = [
    {
      value: "none",
      label: "Start date(bill after)",
    },
  ];
  for (let i = 0; i < upto; i++) {
    const newDate = new Date(currentYear, currentMonth, currentDate + i);
    dateArray.push({
      value: newDate.toLocaleDateString(),
      label: newDate.toLocaleDateString(),
    });
  }
  return dateArray;
};
export const dateOptionsDropdown = () => getNumberOfDate(30);

export const xeroInvoiceStatusDropdown: OptionType[] = [
  {
    value: "none",
    label: "Xero Invoice status",
  },
];

export const invoiceReferenceDropdown: OptionType[] = [
  {
    value: "none",
    label: "Invoice reference",
  },
];

export const solarNetworkTokenDropdown: OptionType[] = [
  {
    value: "none",
    label: "Solar Network Token",
  },
  {
    value: "123456789abc",
    label: "123456789abc",
  },
];

export const solarNetworkSecretDropdown: OptionType[] = [
  {
    value: "none",
    label: "Solar Network secret",
  },
  {
    value: "abc123",
    label: "abc123",
  },
];

export const invoiceTemplateDropdown: OptionType[] = [
  {
    value: "none",
    label: "Invoice template",
  },
  {
    value: "yada yada yada",
    label: "Invoice Template 1",
  },
];

export const PropertyTypeOptions: OptionType[] = [
  {
    value: "none",
    label: "Property type",
  },
  {
    value: "Apartment",
    label: "Apartment",
  },
  {
    value: "Chalet",
    label: "Chalet",
  },
  {
    value: "House",
    label: "House",
  },
];

export const ToastErrorConfig: ToastOptions<{}> = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export const ToastSuccessConfig: ToastOptions<{}> = {
  position: "top-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: false,
  draggable: false,
  progress: undefined,
};

export const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
