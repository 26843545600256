import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Formik } from "formik";
import { AiOutlineCloud } from "react-icons/ai";
import { MdArrowBackIos } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { colors } from "../../constants/Colors";
import { CustomTextfieldWrapper } from "../commoncomponents/CustomTextfieldWrapper";
import { PrimaryButton } from "../commoncomponents/designs/Button";
import {
  Heading1,
  Label,
  Paragraph1,
  Paragraph3,
  PlaceholderText,
  StyledLink,
} from "../commoncomponents/designs/Text";
import { StyledMenu } from "../commoncomponents/sidenavbar/Style";
import LeftSection from "../commoncomponents/sitesection/LeftSection";
import { HelpInFeedbackFormType } from "./Type";
import {
  helpFeedbackInitialValue,
  helpFeedbackValidationSchema,
  updateHelpAndFeedbackValue,
} from "./Util";

const HelpAndFeedback = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const renderNavigationMenu = () => {
    if (mobile) {
      return (
        <StyledMenu
          style={{ alignItems: "center" }}
          onClick={() => navigate("/")}
        >
          <MdArrowBackIos fontSize={"15px"} />
          <Paragraph1 textTransform={"capitalize"}>Back</Paragraph1>
        </StyledMenu>
      );
    }
    return (
      <Grid item xs={0} sm={2}>
        <LeftSection>
          <StyledMenu
            style={{ alignItems: "center" }}
            onClick={() => navigate("/")}
          >
            <MdArrowBackIos fontSize={"15px"} />
            <Paragraph1 textTransform={"capitalize"}>Back</Paragraph1>
          </StyledMenu>
        </LeftSection>
      </Grid>
    );
  };

  const handleSubmitForm = (
    values: HelpInFeedbackFormType,
    setIsSubmitting: (isSubmititng: boolean) => void
  ) => {
    updateHelpAndFeedbackValue(values).finally(() => setIsSubmitting(false));
  };

  return (
    <Grid container spacing={"20px"}>
      {renderNavigationMenu()}
      <Grid item xs={12} sm={8}>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={5}>
            <Heading1 textAlign={"center"} color={colors.primary.light}>
              Help {"&"} Feedback
            </Heading1>
            <Paragraph3
              mt={"10px"}
              textAlign={"center"}
              color={colors.primary.light}
            >
              Visit{" "}
              <StyledLink href={"http://billingappsupport.revolveenergy.co"}>
                http://billingappsupport.revolveenergy.co
              </StyledLink>{" "}
            </Paragraph3>
            <Paragraph3
              mt={"10px"}
              textAlign={"center"}
              color={colors.primary.light}
            >
              or
            </Paragraph3>
            <Paragraph3
              mt={"10px"}
              textAlign={"center"}
              color={colors.primary.light}
            >
              Email{" "}
              <StyledLink href={"mailto:billingappsupport@revolveenergy.co"}>
                billingappsupport@revolveenergy.co
              </StyledLink>
            </Paragraph3>
            <Paragraph3
              mt={"10px"}
              textAlign={"center"}
              color={colors.primary.light}
            >
              or
            </Paragraph3>
            <Paragraph3
              mt={"10px"}
              textAlign={"center"}
              color={colors.primary.dark}
            >
              Send us a message below and we will respond via email
            </Paragraph3>
            <Grid mt={"50px"}>
              <Formik
                initialValues={helpFeedbackInitialValue}
                validationSchema={helpFeedbackValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                  handleSubmitForm(values, setSubmitting);
                }}
                enableReinitialize
                validateOnMount={false}
              >
                {({ values, handleSubmit }) => (
                  <Grid>
                    <Grid item mt={"26px"}>
                      <Label>Message</Label>
                      <CustomTextfieldWrapper
                        name={"message"}
                        id={"message"}
                        fullWidth
                        multiline
                        rows={10}
                      />
                      <PlaceholderText>
                        {500 - values.message.length > 0
                          ? `${500 - values.message.length
                          } words remaining(limit)`
                          : ""}
                      </PlaceholderText>
                    </Grid>
                    <Grid item xs={12} mt={"26px"} md={12}>
                      <PrimaryButton
                        onClick={() => handleSubmit()}
                        label="Send"
                        backgroundcolor={colors.primary.dark}
                        textcolor={colors.secondary.neutral}
                        fullWidth
                        icon={<AiOutlineCloud />}
                      />
                    </Grid>
                  </Grid>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default HelpAndFeedback;
