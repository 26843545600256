import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastErrorConfig } from "../../../../constants/SiteConstants";
import { addOrgItem, getOrgDetail, getOrgImage, removeOrgImageAction, updateOrganisationImageAction, updateOrgItem } from "../../../../store/organisation/ActionCreator";
import { OrgDetailType } from "../../../../store/organisation/Type";

export const useOrgSettingHook = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [percent, setUploadPercent] = useState(0);
    const [isImageUploading, setIsImageUploading] = useState(false);

    const updateSetting = (
        values: OrgDetailType,
        setIsSubmitting: (isSubmitting: boolean) => void,
        setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void,
        orgInfo?: OrgDetailType,
    ) => {
        if (orgInfo) {
            dispatch(updateOrgItem(values.name, orgInfo.id))
                .then(() => {
                    setIsSubmitting(false);
                    dispatch(getOrgDetail(orgInfo.id)).then(() => {
                        dispatch(getOrgImage(orgInfo.id))
                    })
                })
                .catch((err: any) => Promise.reject(err));
            return;
        }
        dispatch(addOrgItem(values.name))
            .then((res: string) => {
                if (!values.image) {
                    navigate(`/organisation/${res}/edit`);
                    return;
                }
                setIsImageUploading(true);
                dispatch(updateOrganisationImageAction({
                    ...values,
                    id: res
                }, setUploadPercent)).then(() => {
                    navigate(`/organisation/${res}/edit`);
                    setIsImageUploading(true);
                }).catch((err: any) => {
                    navigate(`/organisation/${res}/edit`);
                    setIsImageUploading(false);
                    setFieldValue("imageUrl", "");
                    setFieldValue("image", null);
                    toast.error("Image not uploaded", ToastErrorConfig);
                })
            })
            .catch((err: any) => Promise.reject(err))
            .finally(() => setIsSubmitting(false));
    }

    return {
        updateSetting,
        percent,
        setUploadPercent,
        setIsImageUploading,
        isImageUploading
    }
}
export const useUserSettingFormHook = (
    value: OrgDetailType,
    setUploadPercent: (percent: number) => void,
    setIsImageUploading: (isImageUploading: boolean) => void,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
) => {
    const dispatch = useDispatch();
    const { id } = useParams<{
        id: string;
    }>();
    useEffect(() => {
        if (value.image && id) {
            setIsImageUploading(true);
            dispatch(updateOrganisationImageAction(value, setUploadPercent)).then(() => {
                setIsImageUploading(false);
            }).catch((err: string) => {
                setIsImageUploading(false);
                setFieldValue("imageUrl", "");
                setFieldValue("image", null);
            });
        }
    }, [value.image]);

    const removeOrgImage = () => {
        dispatch(removeOrgImageAction(value.id));
    }
    return {
        removeOrgImage,
        orgId: id
    }
}