import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useGetUserRole } from '../../../store/auth/Selector'
import BreadcrumbWrapper from '../../commoncomponents/designs/BreadcrumbWrapper'
import { FileUploadButton, TextButton } from '../../commoncomponents/designs/Button'
import { Heading3, Label2 } from '../../commoncomponents/designs/Text'
import SideNavbar from '../../commoncomponents/sidenavbar'
import MobileNavbarMenu from '../../commoncomponents/sidenavbar/MobileNavbar'
import LeftSection from '../../commoncomponents/sitesection/LeftSection'
import ManagerPropertyHeader from '../mobile/ManagePropertyHeader'
import { PaperContainer } from '../Style'
import { manageSiteOptions, SiteManageProperty } from '../Util'
import { useAddMulitpleProperty } from './Hooks'
import { downloadPropertyTemplate, manageAddPropertyTabOption } from './Util'
import ImageUploadModal from '../../commoncomponents/ImageUploadModal'

const AddMultipleProperty = () => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const role = useSelector(useGetUserRole)
    const { id, propertyInfo, setMultiplePropertyFile, mulitplePropertyFile, uploadFilePercent } = useAddMulitpleProperty();
    if (mobileView) {
        return (
            <Grid>
                {
                    propertyInfo && <ManagerPropertyHeader propertyInfo={propertyInfo} hideAction />
                }
                <MobileNavbarMenu
                    menuOptions={manageSiteOptions(id || "", role)}
                    selectedMenuId={1}
                />
                <Grid mt={"39px"}>
                    <PaperContainer padding={50} elevation={0}>
                        <Grid>
                            <Heading3>Pre-populate your property data</Heading3>
                            <Label2>Please download the csv file below to use as a template when creating properties. Then, upload it again here once you have finished filling it out.</Label2>
                        </Grid>
                        <Grid container spacing={"10px"} mt={"20px"}>
                            <Grid item md={6} xs={12}>
                                <TextButton
                                    onClick={() => downloadPropertyTemplate()}
                                    fullWidth label='Download csv template' icon={<AiOutlineCloudUpload />} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FileUploadButton
                                    fullWidth
                                    onFileSelect={(file) => setMultiplePropertyFile(file)}
                                    acceptFileTypes='.csv'
                                    label='Upload File'
                                    icon={<AiOutlineCloudUpload />}
                                />
                            </Grid>
                            {
                                mulitplePropertyFile && <ImageUploadModal
                                    progress={uploadFilePercent}
                                    file={mulitplePropertyFile as File}
                                    handleClose={() => {
                                        setMultiplePropertyFile(null);
                                    }}
                                />
                            }
                        </Grid>
                    </PaperContainer>
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar
                        selectedMenuId={2}
                        sidebarOptions={SiteManageProperty(id || "", role)}
                    />
                </LeftSection>
            </Grid>
            <Grid item xs={7}>
                <BreadcrumbWrapper
                    tabType={manageAddPropertyTabOption(id || "", true)}
                />
                <Grid mt={"39px"}>
                    <PaperContainer padding={50} elevation={0}>
                        <Grid>
                            <Heading3>Pre-populate your property data</Heading3>
                            <Label2>Please download the csv file below to use as a template when creating properties. Then, upload it again here once you have finished filling it out.</Label2>
                        </Grid>
                        <Grid container spacing={"10px"} mt={"20px"}>
                            <Grid item md={6} xs={12}>
                                <TextButton
                                    onClick={() => downloadPropertyTemplate()}
                                    fullWidth label='Download csv template' icon={<AiOutlineCloudUpload />} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FileUploadButton
                                    fullWidth
                                    acceptFileTypes='.csv'
                                    onFileSelect={(file) => setMultiplePropertyFile(file)}
                                    label='Upload File'
                                    icon={<AiOutlineCloudUpload />}
                                />
                            </Grid>
                            {
                                mulitplePropertyFile && <ImageUploadModal
                                    progress={uploadFilePercent}
                                    file={mulitplePropertyFile as File}
                                    handleClose={() => {
                                        setMultiplePropertyFile(null);
                                    }}
                                />
                            }
                        </Grid>
                    </PaperContainer>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddMultipleProperty