import { Grid, Menu } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserRole } from '../../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../util/Type';
import { PrimaryButton } from '../../commoncomponents/designs/Button';

type OptionalActionPopupProps = {
    anchorEl: HTMLElement | null;
    handleClose: () => void;
}
const OptionalActionPopup: FC<OptionalActionPopupProps> = ({
    anchorEl,
    handleClose
}) => {
    const navigate = useNavigate();
    const { id } = useParams<{
        id: string
    }>();
    const role = useSelector(useGetUserRole);

    return (
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            PaperProps={{
                style: {
                    width: 190
                }
            }}
            onClose={handleClose}
        >
            {
                doesLoggedinUserHavePermission(role, USER_ROLES.PROPERTY, PERMISSIONS.CREATE) &&
                <Grid p={"10px"} spacing={"10px"} container>
                    <Grid item xs={12}>
                        <PrimaryButton
                            onClick={() => navigate(`/site/${id}/manageProperties/add`)}
                            buttonview='sm' fullWidth label={"Add properties"} />
                    </Grid>
                    <Grid item xs={12}>
                        <PrimaryButton buttonview='sm' fullWidth label={"Add multiple properties"} />
                    </Grid>
                </Grid>
            }
        </Menu>
    )
}

export default OptionalActionPopup