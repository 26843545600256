import { ApiState } from "../CommonType";

export type AuthConnectType = {
    state: string;
    code: string;
    requestUri: string;
};

export type AuthInfoType = {
    siteId: string;
    tenantId: string;
    tenantName: string;
    valid: boolean;
};

export interface XeroAuthInfoType extends ApiState {
    authInfo: AuthInfoType | null;
};

export const UPDATE_AUTH_INFO_TYPE = "UPDATE_AUTH_INFO_TYPE";
