import { Dispatch } from "redux";
import { Client } from "../../common/Client";
import { SiteSettingType } from "../../components/sitesettings/Type";
import { FutureCustomerType, ManagePropertyDetailType, ManagePropertyListType, PropertyCustomerList, SiteDetailType, SiteType } from "./Type";
import { createNewSiteApiUrl, getSiteListApiUrl, sitePhotoUrl, updateSiteApiUrl, createNewPropertyApiUrl, getSiteSearchPropertiesApiUrl, propertyPhotosApiUrl, getpropertyDetailApiUrl, propertyServiceUsageApiUrl, sitePropertyCustomersApiUrl, updateSitePropertyCustomerApiUrl, getPropertyServiceUsagesApiUrl, updateSiteStatusApiUrl, deletepropertyDetailApiUrl, getSiteDetailApiUrl, uploadMulitpleProperty, uploadServiceUsageTemplateCsv, deleteSitePropertyCustomerApiUrl } from "../../constants/ApiConstants";
import { updateSelectedSiteItem, updateSitePropertyList, updateSiteImage, updateSiteIsError, updateSiteIsLoading, updateSitesList, updateSelectedSitePropertyItem, updatePropertyImage, updatePropertyCustomerList } from "./Action";
import { getTariffDropdown, getUnitIdDropdown, getXeroAccountCodeDropdown } from "../lookup/ActionCreator";
import { toast } from "react-toastify";
import { ToastErrorConfig } from "../../constants/SiteConstants";
import { format } from "date-fns";
import { isArray } from "lodash";
import { CustomerUsageListType, UsageType } from "../../components/manageproperty/managepropertyedit/usage/Type";

export const transformSiteData = (data: any): SiteType[] => {
    if (!data) {
        return [];
    }

    return data.map((item: any) => ({
        id: item.id,
        created: item.created,
        modified: item.modified,
        active: item.active,
        organisationId: item.organisationId,
        name: item.name,
        zoneId: item.zoneId,
        lang: item.lang,
        currencyCode: item.currencyCode,
        billingCycle: item.billingCycle,
        billAfterDays: item.billAfterDays,
        dueAfterDays: item.dueAfterDays,
        invoiceReference: item.invoiceReference,
        invoiceImage: null
    }));
};

export const getSiteList = (forceRefresh = false): any => (dispatch: Dispatch) => {
    dispatch(updateSiteIsLoading(true));
    return Client.getInstance()
        .getData(getSiteListApiUrl(""), {}, forceRefresh)
        .then((res) => dispatch(updateSitesList(transformSiteData(res.data.data.results))))
        .catch(() => dispatch(updateSiteIsError("Something went wrong")))
        .finally(() => dispatch(updateSiteIsLoading(false)));
};


const reverseTransformData = (data: SiteSettingType) => {
    return {
        organisationId: parseInt(data.organisationId),
        name: data.propertyName,
        zoneId: data.timezone === "none" ? "" : data.timezone,
        lang: data.locale === "none" ? "" : data.locale,
        currencyCode: data.currencyCode === "none" ? "" : data.currencyCode,
        billingCycle: data.billingPeriod === "none" ? "" : data.billingPeriod,
        billAfterDays: data.startDate,
        dueAfterDays: data.dueDays,
        invoiceStatus: data.xeroInvoiceStatus,
        invoiceReference: data.invoiceReference,
        invoiceItemDescription: data.invoiceTransactionDescription,
        solarNetworkTokenId: data.solarNetwork.token,
        solarNetworkTokenSecret: data.solarNetwork.secret,
    }
}
export const addSite = (site: SiteSettingType): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .createData(createNewSiteApiUrl, reverseTransformData(site))
        .then((res) => {
            dispatch(getSiteList(true));
            return Promise.resolve(res.data.data.id);
        })
        .catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            return Promise.reject(err)
        })
};

const transformGetDetailData = (data: any): SiteDetailType => {
    return {
        id: data.id,
        active: data.active,
        created: data.created,
        modified: data.modified,
        organisationId: data.organisationId,
        name: data.name,
        zoneId: data.zoneId,
        lang: data.lang,
        currencyCode: data.currencyCode,
        billingCycle: data.billingCycle,
        billAfterDays: data.billAfterDays,
        dueAfterDays: data.dueAfterDays,
        xeroInvoiceStatus: data.invoiceStatus,
        invoiceReference: data.invoiceReference,
        invoiceImage: null,
        solarNetworkTokenId: data.solarNetworkTokenId,
        solarNetworkTokenSecret: data.solarNetworkTokenSecret,
        invoiceImageUrl: "",
        invoiceTransactionDescription: data.invoiceItemDescription
    }
}
export const getSiteIdDetail = (siteId: string, forceRefresh = false): any => (dispatch: Dispatch) => {
    dispatch(updateSiteIsLoading(true));
    return Client.getInstance()
        .getData(getSiteDetailApiUrl(siteId), {}, forceRefresh)
        .then((res) => {
            dispatch(updateSelectedSiteItem(transformGetDetailData(res.data.data)));
            dispatch(getSiteImage(siteId));
            dispatch(getTariffDropdown(res.data.data.organisationId));
            dispatch(getUnitIdDropdown(res.data.data.organisationId));
            dispatch(getXeroAccountCodeDropdown(siteId));
            return Promise.resolve();
        })
        .catch(() => dispatch(updateSiteIsError("Something went wrong")))
        .finally(() => dispatch(updateSiteIsLoading(false)));
};

export const editSiteDetail = (site: SiteSettingType, id: string): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .updateData(updateSiteApiUrl(id), reverseTransformData(site))
        .then(() => {
            dispatch(getSiteIdDetail(id, true));
            dispatch(getTariffDropdown(site.organisationId));
            return Promise.resolve();
        })
        .catch((err) => Promise.reject(err))
};


const tranformSitePropertyListDetails = (data: any): ManagePropertyListType[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        id: item.id,
        created: item.created,
        modified: item.modified,
        siteid: item.siteid,
        name: item.name,
        type: item.type,
        address: item.address,
        invoiceOptions: []
    }));
}

const transformPropertyDetail = (data: any): ManagePropertyDetailType => {
    return {
        address: data.address.join(", "),
        xeroCategory1: data?.invoiceOptions?.[0]?.name || "",
        xeroCategory2: data?.invoiceOptions?.[1]?.name || "",
        xeroOption1: data?.invoiceOptions?.[0]?.value || "",
        xeroOption2: data?.invoiceOptions?.[1]?.value || "",
        type: data.type,
        name: data.name,
        services: data.services.map((item: any) => ({
            id: item.serviceId,
            isEnabled: item.enabled,
            datumStream: item.datumStream ? {
                nodeId: item.datumStream.nodeId,
                sourceId: item.datumStream.sourceId,
                metric: item.datumStream.metric
            } : undefined
        })),
        image: null,
        imageUrl: ""
    }
}

export const getSitePropertyList = (siteId: string, forceRefresh = false): any => (dispatch: Dispatch) => {
    dispatch(updateSiteIsLoading(true));
    return Client.getInstance()
        .getData(getSiteSearchPropertiesApiUrl(siteId), {}, forceRefresh)
        .then((res) => dispatch(updateSitePropertyList(tranformSitePropertyListDetails(res.data.data.results))))
        .catch(() => dispatch(updateSiteIsError("Something went wrong")))
        .finally(() => dispatch(updateSiteIsLoading(false)));
};

const reverseTransformAddPropertyData = (data: ManagePropertyDetailType) => {
    return {
        address: data.address.split(", "),
        name: data.name,
        type: data.type,
        invoiceOptions: [
            {
                key: data.xeroCategory1,
                value: data.xeroOption1
            },
            {
                key: data.xeroCategory2,
                value: data.xeroOption2
            },
        ]
    };
};

export const addSitePropertyAction = (siteId: string, values: ManagePropertyDetailType): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .createData(createNewPropertyApiUrl(siteId), reverseTransformAddPropertyData(values))
        .then((res) => {
            dispatch(getSitePropertyList(siteId, true));
            return Promise.resolve(res.data.data.id);
        })
        .catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            dispatch(updateSiteIsError("Something went wrong"))
        })
        .finally(() => dispatch(updateSiteIsLoading(false)));
};

const transformFile = (value: File) => {
    const formData = new FormData();
    value && formData.append("data", value as Blob);
    return formData;
}

export const addSiteMultiplePropertyAction = (
    siteId: string,
    value: File,
    setUploadPercent: (percent: number) => void): any =>
    (dispatch: Dispatch) => {
        const options = {
            onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                const percent = Math.floor((loaded * 100) / total);
                setUploadPercent(percent);
            }
        };
        return Client.getInstance()
            .createData(uploadMulitpleProperty(siteId), transformFile(value), true, options)
            .then(() => {
                dispatch(getSitePropertyList(siteId, true));
                return Promise.resolve();
            }).catch((err) => {
                toast.error(err || "Something went wrong", ToastErrorConfig);
                return Promise.reject();
            });
    };

export const addSiteServiceUsageMultiplePropertyAction = async (
    siteId: string,
    value: File,
    setUploadPercent: (percent: number) => void) => {
    const options = {
        onUploadProgress: (progressEvent: any) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setUploadPercent(percent);
        }
    };
    return Client.getInstance()
        .createData(uploadServiceUsageTemplateCsv(siteId), transformFile(value), true, options)
        .then(() => {
            return Promise.resolve();
        }).catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            return Promise.reject();
        });
};

const transformPhoto = (value: SiteSettingType) => {
    const formData = new FormData();
    value.invoiceImage && formData.append("photo", value.invoiceImage as Blob);
    return formData;
}

export const updateSiteImageAction = (value: SiteSettingType, setUploadPercent: (percent: number) => void): any => (dispatch: Dispatch) => {
    const options = {
        onUploadProgress: (progressEvent: any) => {
            const { loaded, total } = progressEvent;
            const percent = Math.floor((loaded * 100) / total);
            setUploadPercent(percent);
        }
    };
    return Client.getInstance()
        .updateData(sitePhotoUrl(value.id.toString()), transformPhoto(value), options)
        .then(() => {
            dispatch(getSiteImage(value.id, true));
            return Promise.resolve();
        }).catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            return Promise.reject();
        });
}

const transformPropertyPhoto = (value: ManagePropertyDetailType) => {
    const formData = new FormData();
    value.image && formData.append("photo", value.image as Blob);
    return formData;
}

export const updateSitePropertyImageAction = (
    siteId: string,
    propertyId: string,
    value: ManagePropertyDetailType,
    setUploadPercent: (percent: number) => void): any =>
    (dispatch: Dispatch) => {
        const options = {
            onUploadProgress: (progressEvent: any) => {
                const { loaded, total } = progressEvent;
                const percent = Math.floor((loaded * 100) / total);
                setUploadPercent(percent);
            }
        };
        return Client.getInstance()
            .updateData(propertyPhotosApiUrl(siteId, propertyId), transformPropertyPhoto(value), options)
            .then(() => {
                dispatch(getPropertyImage(siteId, propertyId));
                return Promise.resolve();
            }).catch((err) => {
                toast.error(err || "Something went wrong", ToastErrorConfig);
                return Promise.reject(err);
            });
    }

export const getSiteImage = (id: string, forceRefresh = false): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .getImage(sitePhotoUrl(id), {}, forceRefresh)
        .then((res) => {
            dispatch(updateSiteImage(res));
            return Promise.resolve();
        }).catch(() => {
            dispatch(updateSiteIsError("Something went wrong"));
        });

export const getPropertyImage = (id: string, propertyId: string, forceRefresh = false): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .getImage(propertyPhotosApiUrl(id, propertyId))
        .then((res) => {
            dispatch(updatePropertyImage(res as string));
        }).catch(() => {
            dispatch(updateSiteIsError("Something went wrong"));
        });

export const removeSiteImageAction = (id: string): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .deleteData(sitePhotoUrl(id))
        .then(() => {
            dispatch(updateSiteImage(""))
            return Promise.resolve();
        })
        .catch(() => dispatch(updateSiteIsError("Photo delete failed")));

export const getSitePropertyItem = (siteId: string, propertyId: string, forceRefresh = false): any => (dispatch: Dispatch) => {
    dispatch(updateSiteIsLoading(true));
    return Client.getInstance()
        .getData(getpropertyDetailApiUrl(siteId, propertyId), {}, forceRefresh)
        .then((res) => {
            dispatch(updateSelectedSitePropertyItem(transformPropertyDetail(res.data.data)));
            dispatch(getPropertyImage(siteId, propertyId));
            return Promise.resolve();
        })
        .catch(() => {
            dispatch(updateSiteIsError("Something went wrong"));
            return Promise.reject();
        })
        .finally(() => dispatch(updateSiteIsLoading(false)));
};

export const removePropertyImageAction = (siteId: string, propertyId: string): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .deleteData(propertyPhotosApiUrl(siteId, propertyId))
        .then(() => {
            dispatch(updatePropertyImage(""))
            return Promise.resolve();
        })
        .catch(() => dispatch(updateSiteIsError("Photo delete failed")));

export const addPropertyAction = (siteId: string, propertyId: string, values: ManagePropertyDetailType): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .updateData(getpropertyDetailApiUrl(siteId, propertyId), reverseTransformAddPropertyData(values))
        .then((res) => {
            dispatch(getSitePropertyList(siteId, true));
            dispatch(getSitePropertyItem(siteId, propertyId, true));
            return Promise.resolve(res.data.data.id);
        })
        .catch(() => dispatch(updateSiteIsError("Something went wrong")))
        .finally(() => dispatch(updateSiteIsLoading(false)));
};

export const getSiteListImages = (id: string, forceRefresh = false) =>
    Client.getInstance()
        .getImage(sitePhotoUrl(id), {}, forceRefresh)
        .then((res) => Promise.resolve(res))
        .catch(() => Promise.reject());

export const updatePropertyServices = (
    siteId: string,
    propertyId: string,
    serviceId: string,
    isEnabled: boolean
): any => (dispatch: Dispatch) => {
    Client.getInstance()
        .updateData(propertyServiceUsageApiUrl(siteId, propertyId, serviceId),
            {
                enabled: isEnabled
            }
        ).then(() => {
            dispatch(getSitePropertyItem(siteId, propertyId, true));
            return Promise.resolve();
        }).catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            dispatch(getSitePropertyItem(siteId, propertyId, true));
        })
};



export const addFutureCustomer = (siteId: string, propertyId: string, value: FutureCustomerType): any => (dispatch: Dispatch) =>
    Client.getInstance().createData(sitePropertyCustomersApiUrl(
        siteId,
        propertyId
    ), {
        start: value.inDate ? format(value.inDate || 0, "yyyy-MM-dd") : undefined,
        end: value.outDate ? format(value.outDate || 0, "yyyy-MM-dd") : undefined,
        name: value.customerInfo?.name,
        xeroDetails: {
            brandingThemeId: value.customerInfo?.themeIdentifier,
            contactId: value.customerInfo?.identifier
        }
    }).then(() => {
        dispatch(getPropertyCustomerList(siteId, propertyId, true))
        return Promise.resolve();
    }).catch((err) => {
        let error = "";
        error = isArray(err.length) ? err.map((item: any) => item.message).join(" ") : err;
        toast.error(error || "Something went wrong", ToastErrorConfig);
    });

const tranformCustomerList = (data: any): PropertyCustomerList[] => {
    if (!data) {
        return [];
    }
    return data.map((item: any) => ({
        id: item.id,
        propertyId: item.propertyId,
        name: item.name,
        start: item.start,
        end: item.end,
        hasPublishedInvoice: item.hasPublishedInvoice,
        xeroDetails: {
            id: item.xeroDetails.id,
            conatctId: item.xeroDetails.contactId,
            brandingThemeId: item.xeroDetails.brandingThemeId
        }
    }))
}
export const getPropertyCustomerList = (siteId: string, propertyId: string, forceRefresh = false): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .getData(sitePropertyCustomersApiUrl(siteId, propertyId), {}, forceRefresh)
        .then((res) => dispatch(updatePropertyCustomerList(tranformCustomerList(res.data.data.results))))
        .catch((err) => {
            toast.error(err, ToastErrorConfig);
        })
}

export const updateCustomerDetail = (siteId: string, propertyId: string, value: PropertyCustomerList): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .updateData(updateSitePropertyCustomerApiUrl(
            siteId,
            propertyId,
            value.id.toString()
        ), {
            start: format(new Date(value.start) || 0, "yyyy-MM-dd"),
            name: value.name,
            end: value.end ? format(new Date(value.end) || 0, "yyyy-MM-dd") : null,
            xeroDetails: {
                brandingThemeId: value.xeroDetails.brandingThemeId,
                contactId: value.xeroDetails.conatctId
            }
        }).then(() => {
            dispatch(getPropertyCustomerList(siteId, propertyId, true))
            return Promise.resolve();
        }).catch((err) => {
            toast.error(err, ToastErrorConfig);
            return Promise.reject();
        })
};
export const deleteCustomerDetail = (siteId: string, propertyId: string, value: PropertyCustomerList): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .deleteData(deleteSitePropertyCustomerApiUrl(
            siteId,
            propertyId,
            value.id.toString()
        )).then(() => {
            dispatch(getPropertyCustomerList(siteId, propertyId, true))
            return Promise.resolve();
        }).catch((err) => {
            toast.error(err, ToastErrorConfig);
            return Promise.reject();
        })
};
export const getServiceUsage = (siteId: string, propertyId: string) => {
    return Client.getInstance()
        .getData(getPropertyServiceUsagesApiUrl(siteId, propertyId), {}, true)
        .then((res) => {
            let data: CustomerUsageListType[] = [];
            Object.entries(res.data.data).forEach(([key, value]) => {
                data.push({
                    customerId: key,
                    usages: value as UsageType[]
                })
            })
            return Promise.resolve(data)
        }
        ).catch((err) => {
            toast.error(err, ToastErrorConfig);
        });
}

export const updateSiteStatus = (siteId: string, status: boolean): any => (dispatch: Dispatch) =>
    Client.getInstance()
        .createData(updateSiteStatusApiUrl(siteId, status), {})
        .then(() => {
            dispatch(getSiteIdDetail(siteId, true));
            dispatch(getSiteList(true));
            return Promise.resolve()
        })
        .catch(() => Promise.reject());

export const deleteSiteProperty = (siteId: string, propertyId: string): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .deleteData(deletepropertyDetailApiUrl(siteId, propertyId))
        .then(() => {
            dispatch(getSitePropertyList(siteId, true));
            return Promise.resolve()
        })
        .catch(() => Promise.reject())
};
