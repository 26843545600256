import Styled from "@emotion/styled";
import { FormControlLabel, Grid, styled, Switch, SwitchProps } from "@mui/material";
import { colors } from "../../../constants/Colors";
import { fontFamily } from "../../../constants/FontFamily";

export const SettingContainer = Styled(Grid)`
    background: ${colors.secondary.neutral};
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);

    & .MuiFormControlLabel-label {
        font-family: ${fontFamily.primary};
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: ${colors.primary.dark};
    }
`;

export const StyledSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    '& .MuiSwitch-switchBase': {
        padding: 0,
        margin: 2,
        transitionDuration: '300ms',
        '&.Mui-checked': {
            transform: 'translateX(16px)',
            color: `${colors.secondary.neutral}`,
            '& + .MuiSwitch-track': {
                backgroundColor: `${colors.primary.dark}`,
                opacity: 1,
                border: 0,
            },
            '&.Mui-disabled + .MuiSwitch-track': {
                opacity: 0.5,
            },
        },
        '&.Mui-focusVisible .MuiSwitch-thumb': {
            color: `${colors.primary.dark}`,
            border: `6px solid ${colors.secondary.neutral}`,
        },
        '&.Mui-disabled .MuiSwitch-thumb': {
            color: theme.palette.grey[100]
                    
        },
        '&.Mui-disabled + .MuiSwitch-track': {
            opacity: 0.7 ,
        },
    },
    '& .MuiSwitch-thumb': {
        boxSizing: 'border-box',
        width: 22,
        height: 22,
    },
    '& .MuiSwitch-track': {
        borderRadius: 26 / 2,
        backgroundColor: theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
        opacity: 1,
        transition: theme.transitions.create(['background-color'], {
            duration: 500,
        }),
    },
}));

export const SwitchLabel = styled(FormControlLabel)`
    & .MuiFormControlLabel-label {
        margin-left: 20px;
    }
`;