export const checkIfFilesAreCorrectType = (
  files: File[],
  fileValidation: string[]
) => {
  let valid = true;
  if (files) {
    files.map((file) => {
      if (!fileValidation.includes(file.type.toLowerCase())) {
        valid = false;
      }
    });
  }
  return valid;
};

export const checkIfFilesAreTooBig = (files: File[], fileSize: number) => {
  let valid = true;
  if (files) {
    files.map((file) => {
      const size = file.size / 1024 / 1024;
      if (size > fileSize) {
        valid = false;
      }
    });
  }
  return valid;
};

export const readFile = async (file: File): Promise<string> => {
  return new Promise((resolve) => {
    const reader = new FileReader();
    reader.addEventListener(
      "load",
      () => resolve(reader.result as string),
      false
    );
    reader.readAsDataURL(file);
  });
};

export const formatDateString = (date: string): string => {
  if (date === "") {
    return "";
  }
  return date.split("T")[0];
};

export const phoneNumberValidation =
  /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

export const loadScript = (
  src: string,
  position: HTMLElement | null,
  id: string
) => {
  if (!position) {
    return;
  }
  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
};

export const downloadFile = (
  content: string,
  filename: string,
  contentType: string
) => {
  const blob = new Blob([content], { type: contentType });
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", filename);
  link.click();
};

export const siteAccess: Record<string, Record<string, string[]>> = {
  superadmin: {
    site: ["create", "view", "edit", "delete"],
    siteSettings: ["view", "edit"],
    manageUser: ["create", "view", "edit", "delete"],
    manageSitePermission: ["create", "view", "edit", "delete"],
    adminUser: ["create", "view", "edit", "delete"],
    serviceForSite: ["create", "view", "edit", "delete"],
    serviceTariffs: ["create", "view", "edit", "delete"],
    property: ["create", "view", "edit", "delete"],
    sitePropertyServices: ["create", "view", "edit", "delete"],
    propertyService: ["create", "view", "edit", "delete"],
    newResident: ["create", "view", "edit", "delete"],
    residentMoveInOutDate: ["create", "view", "edit", "delete"],
    linkXeroToSite: ["create", "view", "edit", "delete"],
    addSolarNetworkTokenToSite: ["create", "view", "edit", "delete"],
    configureSolarNetwork: ["create", "view", "edit", "delete"],
    sharedService: [],
    // sharedService: ["create", "view", "edit", "delete"],
    historicMeteredUsage: ["create", "view", "edit", "delete"],
    manuallyAddUsage: ["create", "view", "edit", "delete"],
    organisationManagement: ["create", "view", "edit", "delete"],
    propertySettings: ["create", "view", "edit", "delete"],
  },
  admin: {
    site: ["create", "view", "edit", "delete"],
    siteSettings: ["view", "edit"],
    manageUser: ["create", "view", "edit", "delete"],
    manageSitePermission: ["create", "view", "edit", "delete"],
    adminUser: [],
    serviceForSite: ["create", "view", "edit", "delete"],
    serviceTariffs: ["create", "view", "edit", "delete"],
    property: ["create", "view", "edit", "delete"],
    sitePropertyServices: ["create", "view", "edit", "delete"],
    propertyService: ["create", "view", "edit", "delete"],
    newResident: ["create", "view", "edit", "delete"],
    residentMoveInOutDate: ["create", "view", "edit", "delete"],
    linkXeroToSite: ["view"],
    addSolarNetworkTokenToSite: ["create", "view", "edit", "delete"],
    configureSolarNetwork: ["create", "view", "edit", "delete"],
    // sharedService: [],
    sharedService: ["create", "view", "edit", "delete"],
    historicMeteredUsage: ["view"],
    manuallyAddUsage: ["create", "view", "edit"],
    organisationManagement: ["create", "view", "edit"],
    propertySettings: ["create", "view", "edit", "delete"],
  },
  manager: {
    site: ["view"],
    siteSettings: ["view", "edit"],
    manageUser: ["view", "edit"],
    manageSitePermission: [],
    adminUser: [],
    serviceForSite: ["view", "edit"],
    serviceTariffs: ["view", "edit"],
    property: ["view", "edit"],
    sitePropertyServices: ["view", "edit"],
    propertyService: [],
    newResident: ["create", "view", "edit"],
    residentMoveInOutDate: ["create", "view", "edit"],
    linkXeroToSite: ["create", "view", "edit", "delete"],
    addSolarNetworkTokenToSite: [],
    configureSolarNetwork: ["view"],
    sharedService: [],
    // sharedService: ["view"],
    historicMeteredUsage: ["view"],
    manuallyAddUsage: ["view", "edit"],
    organisationManagement: ["view", "edit"],
    propertySettings: ["create", "view", "edit", "delete"],
  },
  operator: {
    site: ["view"],
    siteSettings: [],
    manageUser: [],
    manageSitePermission: [],
    adminUser: [],
    serviceForSite: ["view"],
    serviceTariffs: [],
    property: ["view"],
    sitePropertyServices: [],
    propertyService: [],
    newResident: ["view", "edit"],
    residentMoveInOutDate: ["view", "edit"],
    linkXeroToSite: [],
    addSolarNetworkTokenToSite: [],
    configureSolarNetwork: [],
    sharedService: [],
    historicMeteredUsage: [],
    manuallyAddUsage: [],
    organisationManagement: [],
    propertySettings: [],
  },
};

export const USER_ROLES: Record<string, string> = {
  ROLE_SUPER_ADMIN: "superadmin",
  ROLE_ADMIN: "admin",
  ROLE_MANAGER: "manager",
  ROLE_OPERATOR: "operator",
};

export const doesLoggedinUserHavePermission = (
  userRole: string,
  siteAccesss: string,
  permission: string
) =>
  siteAccess?.[USER_ROLES?.[userRole]]?.[siteAccesss]?.includes(permission) ||
  false;
