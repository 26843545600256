import { BiBuilding } from "react-icons/bi";
import { BsTag } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { HiOutlineKey } from "react-icons/hi";
import { doesLoggedinUserHavePermission, downloadFile } from "../../util/SiteUtil";
import { PERMISSIONS, USER_ROLES } from "../../util/Type";
import { Client } from "../../common/Client";
import { downloadServiceUsageTemplateCsv } from "../../constants/ApiConstants";
import { MdArrowBackIos } from "react-icons/md";
import { SideBarOptionsType } from "../commoncomponents/sidenavbar/Util";

export const SiteServicesEditStepper = (id: string) => [
    {
        label: 'Portfolio',
        url: '/',
    },
    {
        label: 'Manage Site',
        url: `/site/${id}/info`,
    },
    {
        label: 'Site services',
    },
];

export const SiteServicesOptions = (id: string, role: string) => [
    {
        id: 2,
        label: 'Manage Properties',
        url: `/site/${id}/manageproperties`,
        icons: <BiBuilding fontSize={"30px"} />,
        isAvailaible: true
    },
    {
        id: 3,
        label: 'Site Services',
        url: `/site/${id}/services`,
        icons: <BsTag fontSize={"30px"} />,
        isSelected: true,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.SERVICE_FOR_SITE, PERMISSIONS.VIEW)
    },
    {
        id: 4,
        label: 'Site Settings',
        url: `/site/${id}/settings`,
        icons: <FiSettings fontSize={"30px"} />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.SITE_SETTINGS, PERMISSIONS.VIEW)
    },
    {
        id: 5,
        label: 'Shared Services',
        url: `/site/${id}/sharedservices`,
        icons: <HiOutlineKey fontSize={"30px"} />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.SHARED_SERVICE, PERMISSIONS.VIEW)
    },
];

export const downloadServiceUsageTemplate = () =>
    Client.getInstance().getData(downloadServiceUsageTemplateCsv, {}, true)
        .then((res) => {
            downloadFile(res.data, "service_usage.csv", "text/csv;charset=utf-8;");
        });

export const manageAddServiceUsageTabOption = (id: string) => [
    {
        label: 'Portfolio',
        url: '/portfolio',
    },
    {
        label: 'Manage Site',
        url: `/site/${id}/info`,
    },
    {
        label: 'Add service usage'
    },
];

export const SiteServiceOption = (id: string, role: string): SideBarOptionsType[] => [
    {
        id: 1,
        label: 'Manage Site',
        url: `/site/${id}/info`,
        icons: <MdArrowBackIos fontSize={"30px"} />,
        isAvailaible: true
    },
    ...manageSiteOptions(id, role)
];

export const manageSiteOptions = (id: string, role: string) => [
    {
        id: 2,
        label: 'Manage Properties',
        url: `/site/${id}/manageproperties`,
        icons: <BiBuilding fontSize={"30px"} />,
        isAvailaible: true
    },
    {
        id: 3,
        label: 'Site Services',
        url: `/site/${id}/services`,
        icons: <BsTag fontSize={"30px"} />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.SERVICE_FOR_SITE, PERMISSIONS.VIEW)
    },
    {
        id: 4,
        label: 'Site Settings',
        url: `/site/${id}/settings`,
        icons: <FiSettings fontSize={"30px"} />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.SITE_SETTINGS, PERMISSIONS.VIEW)
    },
    {
        id: 5,
        label: 'Shared Services',
        url: `/site/${id}/sharedservices`,
        icons: <HiOutlineKey fontSize={"30px"} />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.SHARED_SERVICE, PERMISSIONS.VIEW)
    },
];
