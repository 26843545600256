import { Grid, IconButton } from '@mui/material';
import { FC } from 'react'
import { AiOutlineMinusCircle } from 'react-icons/ai';
import { useSelector } from 'react-redux';
import { colors } from '../../../../constants/Colors';
import { useGetUserRole } from '../../../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../../util/Type';
import { CardContainer } from '../../../commoncomponents/CommonStyle';
import { Label2, PlaceholderText } from '../../../commoncomponents/designs/Text';
import LoadingWrapper from '../../../commoncomponents/LoadingWrapper';
import { OrgStyledTable } from '../user/Style';
import { OrgListType } from './Type';

type Props = {
    selectedItems: OrgListType[];
    title: string;
    subText: string;
    isLoading?: boolean;
    setSelectedItems: (selectedUser: OrgListType) => void;
    isOrgSites?: boolean;
};

const SelectedList: FC<Props> = ({
    selectedItems,
    setSelectedItems,
    title,
    subText,
    isLoading = false,
    isOrgSites = false
}) => {
    const role = useSelector(useGetUserRole);

    return (
        <CardContainer style={{ height: "100%" }} elevation={0}>
            <Label2 fontWeight={700} color={colors.primary.light} justifyContent={"center"}>{title}</Label2>
            <PlaceholderText justifyContent={"center"}>
                {subText}
            </PlaceholderText>
            <LoadingWrapper height={"100%"} isLoading={isLoading}>
                <Grid mt={"92px"}>
                    <Grid mt={"40px"}>
                        {selectedItems.map((item, index) => (
                            <OrgStyledTable p={"5px"} alignItems={"center"} isodd={index % 2 === 0} key={JSON.stringify(item)} container justifyContent={"space-between"}>
                                <Grid item xs={11}>
                                    <PlaceholderText fontWeight={700}>{item.name}</PlaceholderText>
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        disabled={!doesLoggedinUserHavePermission(role, isOrgSites ? USER_ROLES.MANAGE_SITE_PERMISSION : USER_ROLES.ADMIN_USER, PERMISSIONS.DELETE)}
                                        onClick={() => setSelectedItems(item)}>
                                        <AiOutlineMinusCircle />
                                    </IconButton>
                                </Grid>
                            </OrgStyledTable>
                        ))}
                    </Grid>
                </Grid>
            </LoadingWrapper>
        </CardContainer>
    )
}

export default SelectedList;