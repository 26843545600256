import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { colors } from '../../constants/Colors'
import { Heading1, Paragraph2 } from '../commoncomponents/designs/Text'
import LeftSection from '../commoncomponents/sitesection/LeftSection'
import RightSection from '../commoncomponents/sitesection/RightSection'
import PortfolioSites from './PortfolioSites'

const Portfolio = () => {
    const theme = useTheme();
    const mobile = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Grid container spacing={"20px"}>
            {
                !mobile && <Grid item xs={0} sm={2}>
                    <LeftSection />
                </Grid>
            }
            <Grid item xs={12} sm={8}>
                <Heading1 color={colors.primary.light}>Portfolio</Heading1>
                <Paragraph2 color={colors.primary.light}>Thanks for using Site Manager</Paragraph2>
                <PortfolioSites />
            </Grid>
            <Grid item xs={0} sm={2}>
                <RightSection />
            </Grid>
        </Grid>
    )
}

export default Portfolio