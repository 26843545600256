import { Grid } from '@mui/material';
import { id } from 'date-fns/locale';
import { useFormikContext } from 'formik'
import { BsCloud } from 'react-icons/bs';
import { OrgDetailType } from '../../../../store/organisation/Type';
import { CustomTextfieldWrapper } from '../../../commoncomponents/CustomTextfieldWrapper';
import { PrimaryButton } from '../../../commoncomponents/designs/Button';
import { Label } from '../../../commoncomponents/designs/Text';
import ImageDrop from '../../../commoncomponents/fields/ImageUpload';
import { OrgLogo, OrgLogoContainer } from '../Style';
import { colors } from '../../../../constants/Colors';
import { useUserSettingFormHook } from './Hooks';
import { FC } from "react";

type SettingFormProps = {
    setUploadPercent: (percent: number) => void;
    setIsImageUploading: (isImageUploading: boolean) => void;
}
const SettingsForm: FC<SettingFormProps> = ({
    setUploadPercent,
    setIsImageUploading
}) => {

    const { values, setFieldValue, handleSubmit, isSubmitting, setValues } = useFormikContext();
    const data = values as OrgDetailType;

    const { removeOrgImage, orgId } = useUserSettingFormHook(data, setUploadPercent, setIsImageUploading, setFieldValue);

    const renderImageDrop = () => {
        if (!id) {
            return <></>;
        }

        if (data.imageUrl) {
            return (
                <OrgLogoContainer>
                    <Grid item xs={12} md={"auto"}>
                        <OrgLogo src={data.imageUrl} />
                    </Grid>
                    <Grid item xs={12} md={"auto"}>
                        <Label
                            onClick={() => {
                                orgId ? removeOrgImage() : setValues({
                                    ...data,
                                    imageUrl: "",
                                    image: null
                                });
                            }}
                            style={{ cursor: "pointer" }}>Remove</Label>
                    </Grid>
                </OrgLogoContainer>
            );
        }

        return (
            <ImageDrop
                name='image'
                setImageUrl={(url) => setFieldValue("imageUrl", url)}
                imageSize={1}
                logoInfo={"Upload a file or drag and drop"}
                logoSubMessage={"PNG, JPG, GIF up to 1MB"}
            />
        );
    }

    return (
        <Grid>
            <Label>Organisation name</Label>
            <CustomTextfieldWrapper
                name={"name"}
                id={'name'}
                placeholder={"Type here..."}
                variant={"standard"}
                fullWidth
            />
            <Grid mt={"43px"} item xs={4}>
                {renderImageDrop()}
            </Grid>
            <Grid container>
                <Grid item xs={12} mt={"43px"}>
                    <PrimaryButton
                        isLoading={isSubmitting}
                        onClick={() => handleSubmit()}
                        backgroundcolor={colors.primary.dark}
                        textcolor={colors.secondary.neutral}
                        fullWidth
                        label={orgId ? "Update" : "Save"}
                        icon={<BsCloud />}
                    />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SettingsForm