import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { AiOutlineCloud } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { colors } from '../../../../constants/Colors'
import { PropertyTypeOptions } from '../../../../constants/SiteConstants'
import { useGetUserRole } from '../../../../store/auth/Selector'
import { ManagePropertyDetailType } from '../../../../store/sites/Type'
import { doesLoggedinUserHavePermission } from '../../../../util/SiteUtil'
import { PERMISSIONS, USER_ROLES } from '../../../../util/Type'
import { LogoContainer, StyledLogo } from '../../../commoncomponents/CommonStyle'
import { CustomTextfieldWrapper } from '../../../commoncomponents/CustomTextfieldWrapper'
import { PrimaryButton } from '../../../commoncomponents/designs/Button'
import { Label, Label2 } from '../../../commoncomponents/designs/Text'
import FormikGoogleMapAutoComplete from '../../../commoncomponents/fields/FormikGoogleMapAutoComplete'
import { FormikSelect } from '../../../commoncomponents/fields/FormikSelect'
import ImageDrop from '../../../commoncomponents/fields/ImageUpload'
import ImageUploadModal from '../../../commoncomponents/ImageUploadModal'
import { usePropertySettingFormHooks } from './Hooks'

const EditPropertyForm = () => {
    const { values, setFieldValue, setValues, isSubmitting, handleSubmit } = useFormikContext();
    const data = values as ManagePropertyDetailType;

    const { removePropertyImage, isImageUploading, percent, xeroInvoiceDropdown } = usePropertySettingFormHooks(data, setFieldValue);
    const role = useSelector(useGetUserRole);

    const renderImage = () => {
        if (data.imageUrl) {
            return (
                <LogoContainer>
                    <Grid item>
                        <StyledLogo src={data.imageUrl} />
                    </Grid>
                    <Grid item>
                        <Label
                            onClick={() => removePropertyImage(data, setValues)}
                            style={{ cursor: "pointer" }}>Remove</Label>
                    </Grid>
                </LogoContainer>
            );
        }
        return (
            <ImageDrop
                name='image'
                setImageUrl={(url) => setFieldValue('imageUrl', url)}
                imageSize={1}
                logoInfo={"Upload a file or drag and drop"}
                logoSubMessage={"PNG, JPG, GIF up to 1MB"}
            />
        );
    }

    return (
        <div>
            <Grid mt={"40px"}>
                <Label>Full name</Label>
            </Grid>
            <Grid>
                <CustomTextfieldWrapper
                    name={"name"}
                    id={"name"}
                    fullWidth
                    variant={"standard"}
                />
            </Grid>
            <Grid mt={"40px"}>
                <Label>Property type</Label>
            </Grid>
            <Grid mt={"10px"}>
                <FormikSelect
                    name={"type"}
                    id={'type'}
                    options={PropertyTypeOptions}
                    placeholder={"Select"}
                />
            </Grid>
            <Grid mt={"20px"}>
                <Label>Physical Address</Label>
                <FormikGoogleMapAutoComplete
                    name={"address"}
                    id={'address'}
                    variant={"standard"}
                    helperText={"Search Natural Database"}
                    fullWidth
                    isEditable
                    initialText={data.address}
                    placeholder={"Type here"}
                />
            </Grid>
            <Grid item xs={12} md={4} mt={"20px"}>
                {renderImage()}
                {
                    data.image && isImageUploading && <ImageUploadModal
                        file={data.image}
                        progress={percent}
                        handleClose={() => setFieldValue("image", null)}
                    />
                }
            </Grid>
            <Grid mt={"30px"}>
                <Label2 fontWeight={700} color={colors.primary.light}>Xero tracking</Label2>
            </Grid>
            <Grid container spacing={"30px"}>
                <Grid item xs={12} md={6} mt={"10px"}>
                    <Label>Xero category 1</Label>
                    <FormikSelect
                        name={"xeroCategory1"}
                        id={'xeroCategory1'}
                        options={[{
                            value: "none",
                            label: "Xero Category 1",
                        }, ...xeroInvoiceDropdown.map((item) => ({
                            label: item.label,
                            value: item.value,
                        }))]}
                        placeholder={"Select"}
                    />
                </Grid>
                <Grid item xs={12} md={6} mt={"10px"}>
                    <Label>Xero category 2</Label>
                    <FormikSelect
                        name={"xeroCategory2"}
                        id={'xeroCategory2'}
                        options={[{
                            value: "none",
                            label: "Xero Category 2",
                        }, ...xeroInvoiceDropdown.map((item) => ({
                            label: item.label,
                            value: item.value,
                        }))]}
                        placeholder={"Select"}
                    />
                </Grid>
                <Grid item xs={12} md={6} mt={"10px"}>
                    <Label>Xero option 1</Label>
                    <FormikSelect
                        name={"xeroOption1"}
                        id={'xeroOption1'}
                        options={[{
                            value: "none",
                            label: "Xero Option 1",
                        }, ...xeroInvoiceDropdown.find((item) => item.value === data.xeroCategory1)?.options || []]}
                        placeholder={"Select"}
                    />
                </Grid>
                <Grid item xs={12} md={6} mt={"10px"}>
                    <Label>Xero option 2</Label>
                    <FormikSelect
                        name={"xeroOption2"}
                        id={'xeroOption2'}
                        options={[{
                            value: "none",
                            label: "Xero Option 1",
                        }, ...xeroInvoiceDropdown.find((item) => item.value === data.xeroCategory2)?.options || []]}
                        placeholder={"Select"}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <PrimaryButton
                        isDisabled={!doesLoggedinUserHavePermission(role, USER_ROLES.PROPERTY, PERMISSIONS.EDIT)}
                        onClick={() => handleSubmit()}
                        isLoading={isSubmitting}
                        label='Update'
                        backgroundcolor={colors.primary.dark}
                        textcolor={colors.secondary.neutral}
                        fullWidth
                        icon={<AiOutlineCloud />}
                    />
                </Grid>
            </Grid>
        </div>
    )
}

export default EditPropertyForm