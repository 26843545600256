import { BiBuilding } from "react-icons/bi";
import { BsTag } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { HiOutlineKey } from "react-icons/hi";
import { MdArrowBackIos } from "react-icons/md";
import { doesLoggedinUserHavePermission } from "../../util/SiteUtil";
import { PERMISSIONS, USER_ROLES } from "../../util/Type";
import { SideBarOptionsType } from "../commoncomponents/sidenavbar/Util";
import { Client } from "../../common/Client";
import { publishInvoicesApiUrl } from "../../constants/ApiConstants";

export const manageSiteTabOption = [
  {
    label: "Portfolio",
    url: "/portfolio",
  },
  {
    label: "Manage Site",
  },
];

export const SiteSideBarOptions = (
  id: string,
  role: string
): SideBarOptionsType[] => [
  {
    id: 5,
    label: "Portfolio",
    url: "/portfolio",
    icons: <MdArrowBackIos fontSize={"30px"} />,
    isAvailaible: true,
  },
  ...SiteMenus(id, role),
];

export const SiteMenus = (id: string, role: string): SideBarOptionsType[] => [
  {
    id: 1,
    label: "Manage Properties",
    url: `/site/${id}/manageproperties`,
    icons: <BiBuilding fontSize={"30px"} />,
    isAvailaible: true,
  },
  {
    id: 2,
    label: "Site Services",
    url: `/site/${id}/services`,
    icons: <BsTag fontSize={"30px"} />,
    isAvailaible: doesLoggedinUserHavePermission(
      role,
      USER_ROLES.SERVICE_FOR_SITE,
      PERMISSIONS.VIEW
    ),
  },
  {
    id: 3,
    label: "Site Settings",
    url: `/site/${id}/settings`,
    icons: <FiSettings fontSize={"30px"} />,
    isAvailaible: doesLoggedinUserHavePermission(
      role,
      USER_ROLES.SITE_SETTINGS,
      PERMISSIONS.VIEW
    ),
  },
  {
    id: 4,
    label: "Shared Services",
    url: `/site/${id}/sharedservices`,
    icons: <HiOutlineKey fontSize={"30px"} />,
    isAvailaible: doesLoggedinUserHavePermission(
      role,
      USER_ROLES.SHARED_SERVICE,
      PERMISSIONS.VIEW
    ),
  },
];
export const publishInvoices = (siteId: string) =>
  Client.getInstance()
    .createData(publishInvoicesApiUrl(siteId), {})
    .then(() => {
      return Promise.resolve();
    })
    .catch(() => Promise.reject());
