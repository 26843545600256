import { toast } from "react-toastify";
import { Dispatch } from "redux";
import { Client } from "../../common/Client"
import { connectSiteXeroAPiUrl, getXeroDetailAuthInfo } from "../../constants/ApiConstants"
import { ToastErrorConfig } from "../../constants/SiteConstants";
import { updateAuthInfoDropdown } from "./Action";
import { AuthConnectType, AuthInfoType } from "./Type"

export const connectAuth = (siteId: string, authConnectValue: AuthConnectType) => {
    return Client.getInstance().createData(
        connectSiteXeroAPiUrl(
            siteId,
            authConnectValue.code,
            authConnectValue.state,
        ), null, true)
        .then(() => Promise.resolve())
        .catch((err) => {
            toast.error(err || "Something went wrong", ToastErrorConfig);
            return Promise.reject();
        });
};

const transformData = (data: any): AuthInfoType => {
    return {
        siteId: data.siteId,
        tenantId: data.tenantId,
        tenantName: data.tenantName,
        valid: data.valid
    }
}

export const xeroAuthInfo = (siteId: string): any => (dispatch: Dispatch) => {
    return Client.getInstance()
        .getData(getXeroDetailAuthInfo(siteId))
        .then((res) => {
            dispatch(updateAuthInfoDropdown(transformData(res.data.data)));
            return Promise.resolve(res);
        }).catch((err) => {
            dispatch(updateAuthInfoDropdown(transformData({
                siteId: siteId,
                tenantId: '',
                tenantName: '',
                valid: false
            })));
            toast.error(err || "Something went wrong", ToastErrorConfig);
            return Promise.reject();
        });
};
