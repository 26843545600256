import Styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { colors } from "../../../constants/Colors";

export const StyledLogo = Styled.img`
    width: 100%;
`;

export const UserInfoCard = Styled(Paper)`
   background: ${colors.secondary.neutral};
    border: 1px solid ${colors.secondary.light};
    border-radius: 6px; 
    padding: 14px;    
    position: absolute;
    right: 50px;
`;

export const UserInfoContainer = Styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;