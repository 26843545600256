import { Grid, InputAdornment, Popover, Box } from "@mui/material";
import { useFormikContext } from "formik";
import { FC, useState } from "react";
import {
  AiOutlineDollarCircle,
  AiOutlineCalendar,
  AiOutlineFileAdd,
} from "react-icons/ai";
import { BiTimeFive } from "react-icons/bi";
import { BsGlobe, BsClipboardCheck, BsCloudUpload } from "react-icons/bs";
import { FiCopy, FiFlag } from "react-icons/fi";
import { HiOutlineClipboardList } from "react-icons/hi";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { colors } from "../../constants/Colors";
import { useGetUserRole } from "../../store/auth/Selector";
import { getOrgDropdownList } from "../../store/organisation/ActionCreator";
import { doesLoggedinUserHavePermission } from "../../util/SiteUtil";
import { PERMISSIONS, USER_ROLES } from "../../util/Type";
import { StyledLogo } from "../commoncomponents/CommonStyle";
import { CustomTextfieldWrapper } from "../commoncomponents/CustomTextfieldWrapper";
import { PrimaryButton } from "../commoncomponents/designs/Button";
import { Label, StyledLink } from "../commoncomponents/designs/Text";
import {
  FormikSelect,
  FormikAutoComplete,
} from "../commoncomponents/fields/FormikSelect";
import ImageDrop from "../commoncomponents/fields/ImageUpload";
import { useFormikSiteFormHook } from "./Hooks";
import { SocialNetworkContainer } from "./Style";
import { SiteSettingType } from "./Type";
import AvailaibleInvoiceStatusPopup from "./AvailaibleInvoiceStatusPopup";

type FormikSiteFormProps = {
  setUploadPercent: (percent: number) => void;
  setIsImageUploading: (isImageUploading: boolean) => void;
};

const FormikSiteForm: FC<FormikSiteFormProps> = ({
  setUploadPercent,
  setIsImageUploading,
}) => {
  const { values, setFieldValue, handleSubmit, setValues, isSubmitting } =
    useFormikContext();
  const data = values as SiteSettingType;
  const { id } = useParams<{
    id: string;
  }>();
  const {
    removeSiteImage,
    orgDropdown,
    billingDropdown,
    currencyDropdown,
    languageTagDropdown,
    timezoneDropdown,
    invoiceRefDropdown,
    xeroInvoiceStatusDropdown,
    availaibleInvoiceStatusDropdown,
  } = useFormikSiteFormHook(
    data,
    setUploadPercent,
    setIsImageUploading,
    setFieldValue
  );
  const [anchorEl, setAnchorEl] = useState<HTMLAnchorElement | null>(null);
  const [anchorAvailaibleStatusEl, setAnchorAvalaibleStatusEl] =
    useState<HTMLAnchorElement | null>(null);
  const role = useSelector(useGetUserRole);

  const renderImage = () => {
    if (data.invoiceImageUrl) {
      return (
        <Grid container spacing={"30px"} alignItems={"center"}>
          <Grid item xs={12} md={10}>
            <StyledLogo src={data.invoiceImageUrl} />
          </Grid>
          <Grid item xs={12} md={2}>
            <Label
              textAlign={"center"}
              width={"100%"}
              display={["flex", "contents"]}
              justifyContent={["center", "initial"]}
              onClick={() => {
                id
                  ? removeSiteImage(setValues)
                  : setValues({
                      ...data,
                      invoiceImageUrl: "",
                      invoiceImage: null,
                    });
              }}
              style={{ cursor: "pointer" }}
            >
              Remove
            </Label>
          </Grid>
        </Grid>
      );
    }
    return (
      <ImageDrop
        name="invoiceImage"
        setImageUrl={(url) => setFieldValue("invoiceImageUrl", url)}
        imageSize={1}
        logoInfo={"Upload a file or drag and drop"}
        logoSubMessage={"PNG, JPG up to 1MB"}
      />
    );
  };

  return (
    <Grid container spacing={"20px"}>
      <Grid item xs={12}>
        <CustomTextfieldWrapper
          label={"Site Title"}
          name={"propertyName"}
          id={"propertyName"}
          fullWidth
          variant={"standard"}
        />
      </Grid>
      <Grid item xs={12}>
        <FormikSelect
          name={"organisationId"}
          options={getOrgDropdownList(orgDropdown)}
          label={"Select organisation"}
          startAdornment={
            <InputAdornment position="start">
              <FiCopy style={{ marginRight: 10 }} />
            </InputAdornment>
          }
          id={"organisationId"}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikAutoComplete
          name={"timezone"}
          options={timezoneDropdown}
          inputIcon={<BiTimeFive style={{ marginRight: 2, marginLeft: 7 }} />}
          label={"Timezone"}
          id={"timezone"}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikSelect
          name={"locale"}
          options={languageTagDropdown}
          id={"locale"}
          label={"Locale"}
          startAdornment={
            <InputAdornment position="start">
              <BsGlobe style={{ marginRight: 10 }} />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikSelect
          name={"currencyCode"}
          options={currencyDropdown}
          id={"currencyCode"}
          label={"Currency"}
          startAdornment={
            <InputAdornment position="start">
              <AiOutlineDollarCircle style={{ marginRight: 10 }} />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikSelect
          name={"billingPeriod"}
          label={"Billing Period"}
          options={billingDropdown}
          id={"billingPeriod"}
          startAdornment={
            <InputAdornment position="start">
              <AiOutlineCalendar style={{ marginRight: 10 }} />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextfieldWrapper
          name={"dueDays"}
          id={"dueDays"}
          label={"Due Days"}
          placeholder={"Due days"}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <HiOutlineClipboardList style={{ marginRight: 10 }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextfieldWrapper
          name={"startDate"}
          label={"Start Date"}
          placeholder={"Start date"}
          id={"startDate"}
          fullWidth
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <FiFlag style={{ marginRight: 10 }} />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <FormikSelect
          name={"xeroInvoiceStatus"}
          options={xeroInvoiceStatusDropdown}
          id={"xeroInvoiceStatus"}
          label={"Xero invoice status"}
          startAdornment={
            <InputAdornment position="start">
              <BsClipboardCheck style={{ marginRight: 10 }} />
            </InputAdornment>
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <CustomTextfieldWrapper
          multiline
          name={"invoiceReference"}
          id={"invoiceReference"}
          placeholder={"Invoice reference"}
          fullWidth
          label={"Invoice reference"}
        />
        <StyledLink
          style={{
            position: "relative",
            bottom: 20,
          }}
          color={colors.tertiary.link}
          onClick={(e) => setAnchorEl(e.currentTarget)}
        >
          See list of available parameters
        </StyledLink>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box>
          <CustomTextfieldWrapper
            multiline
            name={"invoiceTransactionDescription"}
            id={"invoiceTransactionDescription"}
            placeholder={"Invoice transaction description composer"}
            fullWidth
            InputLabelProps={{
              shrink: true,
            }}
            label={"Invoice transaction description composer"}
          />
          <StyledLink
            color={colors.tertiary.link}
            onClick={(e) => setAnchorAvalaibleStatusEl(e.currentTarget)}
          >
            See list of available parameters
          </StyledLink>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        {renderImage()}
      </Grid>
      {doesLoggedinUserHavePermission(
        role,
        USER_ROLES.ADD_SOLAR_NETWORK_TOKEN_TO_SITE,
        PERMISSIONS.VIEW
      ) && (
        <Grid
          style={{
            pointerEvents:
              !doesLoggedinUserHavePermission(
                role,
                USER_ROLES.ADD_SOLAR_NETWORK_TOKEN_TO_SITE,
                PERMISSIONS.EDIT
              ) ||
              !doesLoggedinUserHavePermission(
                role,
                USER_ROLES.ADD_SOLAR_NETWORK_TOKEN_TO_SITE,
                PERMISSIONS.CREATE
              )
                ? "none"
                : "all",
          }}
          item
          xs={12}
        >
          <SocialNetworkContainer>
            <Label>Solar Network (optional)</Label>
            <Grid mt={"30px"} container spacing={"20px"}>
              <Grid item xs={12} md={6}>
                <CustomTextfieldWrapper
                  label={"Solar Network token"}
                  name={"solarNetwork.token"}
                  id={"solarNetwork.token"}
                  placeholder="Solar Network token"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AiOutlineFileAdd style={{ marginRight: 10 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <CustomTextfieldWrapper
                  label={"Solar Network secret"}
                  name={"solarNetwork.secret"}
                  id={"solarNetwork.secret"}
                  type={"password"}
                  placeholder="Leave empty to keep existing secret"
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AiOutlineFileAdd style={{ marginRight: 10 }} />
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </SocialNetworkContainer>
        </Grid>
      )}
      {doesLoggedinUserHavePermission(
        role,
        USER_ROLES.SITE_SETTINGS,
        PERMISSIONS.EDIT
      ) && (
        <Grid item xs={12}>
          <PrimaryButton
            isLoading={isSubmitting}
            onClick={() => handleSubmit()}
            backgroundcolor={colors.primary.dark}
            textcolor={colors.secondary.light}
            fullWidth
            label="Update"
            icon={<BsCloudUpload />}
          />
        </Grid>
      )}
      {anchorAvailaibleStatusEl && (
        <AvailaibleInvoiceStatusPopup
          anchorEl={anchorAvailaibleStatusEl}
          invoiceTransactionDescription={data.invoiceTransactionDescription}
          handleClose={() => setAnchorAvalaibleStatusEl(null)}
          options={availaibleInvoiceStatusDropdown}
          setValue={(value) =>
            setFieldValue("invoiceTransactionDescription", value)
          }
        />
      )}
      <Popover
        id={id}
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Grid width={"540px"} padding={"10px"}>
          {invoiceRefDropdown.map((item) => (
            <Grid key={item.value} mt={"10px"} container spacing={"10px"}>
              <Grid item xs={4}>
                <Label style={{ wordBreak: "break-all" }}>{item.value}</Label>
              </Grid>
              <Grid item xs={7}>
                <Label style={{ wordBreak: "break-all" }}>{item.label}</Label>
              </Grid>
              <Grid item xs={1}>
                <FiCopy
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setFieldValue(
                      "invoiceReference",
                      `${data.invoiceReference} {${item.value}}`
                    );
                  }}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Popover>
    </Grid>
  );
};

export default FormikSiteForm;
