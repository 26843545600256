import { Grid } from '@mui/material';
import { AiOutlineUpload } from 'react-icons/ai';
import { FiDelete, FiEdit } from 'react-icons/fi';
import { TextButton } from '../../../commoncomponents/designs/Button';
import { Paragraph1 } from '../../../commoncomponents/designs/Text';
import CustomSingleDatePicker from '../../../commoncomponents/CustomSingleDatePicker';
import { useCustomeInfoCardHook } from './Hooks';
import EditCustomerModal from './EditCustomerModal';
import { PropertyCustomerList } from '../../../../store/sites/Type';
import { useFormikContext } from 'formik';
import { FC } from "react";
import { MdDelete } from 'react-icons/md';
import DeleteModal from '../../../commoncomponents/modal/DeleteModal';

type CustomerInfoCardProps = {
    dateType: "in" | "out"
    showMoveOutClearDate?: boolean
    hideRemoveCustomer?: boolean
}
const CustomerInfoCard: FC<CustomerInfoCardProps> = ({
    dateType,
    showMoveOutClearDate,
    hideRemoveCustomer
}) => {
    const { openEditModal, setOpenEditModal, openDeleteModal, setOpenDeleteModal, handleDeleteCustomer } = useCustomeInfoCardHook();
    const { values, setFieldValue, handleSubmit } = useFormikContext();
    const data = values as PropertyCustomerList;

    return (
        <Grid mt={"30px"} container alignItems={"center"} justifyContent={"space-between"} spacing={"30px"}>
            <Grid item xs={12} sm={2}>
                <Paragraph1 fontWeight={"bold"}>{data.name}</Paragraph1>
            </Grid>
            <Grid item xs={12} sm={2}>
                <CustomSingleDatePicker
                    date={data.start ? new Date(data.start) : null}
                    label={"MOVE IN DATE"}
                    setDate={(date) => setFieldValue("start", date)}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <CustomSingleDatePicker
                    date={data.end ? new Date(data.end) : null}
                    label={"MOVE OUT DATE"}
                    showClearDate={showMoveOutClearDate}
                    setDate={(date) => setFieldValue("end", date)}
                />
            </Grid>
            <Grid item xs={12} sm={1}>
                <TextButton onClick={() => setOpenEditModal(data)} fullWidth label={"EDIT"} icon={<FiEdit />} />
            </Grid>
            {
                !hideRemoveCustomer && <Grid item xs={12} sm={1}>
                    <TextButton onClick={() => setOpenDeleteModal(data)} fullWidth label={"DELETE"} icon={<MdDelete />} />
                </Grid>
            }

            <Grid item xs={12} sm={1}>
                <TextButton onClick={() => handleSubmit()} fullWidth label={"SAVE"} icon={<AiOutlineUpload />} />
            </Grid>
            {
                openEditModal && <EditCustomerModal
                    customerInfo={openEditModal}
                    showMoveOutClearDate={showMoveOutClearDate}
                    type={dateType === "out" ? "current" : "future"}
                    handleClose={() => setOpenEditModal(undefined)} />
            }
            {
                openDeleteModal && <DeleteModal
                    message={`Are you sure you want to remove the Customer.`}
                    title={`Customer Delete`}
                    onClose={() => setOpenDeleteModal(null)}
                    onSubmit={handleDeleteCustomer}
                />
            }
        </Grid>
    )
}

export default CustomerInfoCard