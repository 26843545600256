import { Grid } from '@mui/material'
import { BigTextButton } from '../commoncomponents/designs/Button'
import SiteCard from './SiteCard'
import { GrAddCircle } from "react-icons/gr";
import { useNavigate } from 'react-router-dom';
import { usePortfolioHook } from './Hooks';
import AddSite from './addsite';
import { SelectWrapper } from '../commoncomponents/fields/FormikSelect';
import { filterSites } from './Util';
import { format } from 'date-fns';
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../util/Type';

const PortfolioSites = () => {
    const naviate = useNavigate();
    const { siteList, openModal, toggleOpenModal, orgDropdown, selectedOrg, setSelectedOrg } = usePortfolioHook();
    const role = useSelector(useGetUserRole);

    return (
        <Grid item mt={"50px"}>
            <Grid container spacing={"30px"}>
                <Grid item xs={12}>
                    <Grid container justifyContent={"flex-start"}>
                        <Grid item xs={12} md={3}>
                            <SelectWrapper
                                value={selectedOrg}
                                name={"organisation"}
                                id={"organisation"}
                                options={orgDropdown}
                                label={"Filter by organisation"}
                                size={"small"}
                                onChange={(event) => setSelectedOrg(event.target.value as string)}
                            />
                        </Grid>
                    </Grid>
                </Grid>
                {
                    filterSites(selectedOrg, siteList).map((item) =>
                        <Grid item xs={12} sm={4} key={item.id}>
                            <SiteCard
                                isActive={item.active}
                                onClick={() => naviate(`/site/${item.id}/info`)}
                                description={format(new Date(item.created), "dd MMM yyyy")}
                                id={item.id}
                                title={item.name}
                            />
                        </Grid>
                    )
                }

            </Grid>
            {
                doesLoggedinUserHavePermission(role, USER_ROLES.SITE, PERMISSIONS.CREATE) && <Grid container spacing={"30px"} mt={"32px"}>
                    <Grid item xs={12} sm={4}>
                        <BigTextButton
                            label={"Add new site"}
                            icon={<GrAddCircle
                                style={{
                                    fontSize: 26
                                }}
                            />}
                            onClick={toggleOpenModal}
                        />
                    </Grid>
                </Grid>
            }
            {
                openModal && <AddSite handleClose={toggleOpenModal} />
            }
        </Grid>
    );
};

export default PortfolioSites