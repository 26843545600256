import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSiteIdDetail } from "../../store/sites/ActionCreator";
import { useGetSiteDetail } from "../../store/sites/Selector";

export const useSharedServicesHook = () => {
    const { id } = useParams();
    const propertyInfo = useSelector(useGetSiteDetail);
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) {
            dispatch(getSiteIdDetail(id));
        }
    }, []);

    return {
        id,
        propertyInfo,
    }
}
