import { ref, string, object } from "yup";
import { SignupFormType } from "./Type";

export const SignupInitialValue: SignupFormType = {
    firstName: "",
    lastName: "",
    email: "",
    confirmEmail: "",
    profilePicture: null,
    profilePictureUrl: "",
    password: "",
    confirmPassword: "",
    receiveEmail: false
}

export const SignUpValidationSchema = object().shape({
    firstName: string().required("First name is required"),
    lastName: string().required("Last name is required"),
    email: string().email("Email is invalid").required("Email is required"),
    confirmEmail: string().email("Email is invalid").oneOf([ref('email'), null], 'Email must match').required("Confirm email is required"),
    password: string().required('Password is required'),
    confirmPassword: string()
        .oneOf([ref('password'), null], 'Passwords must match')
});