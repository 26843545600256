import { Dialog, DialogContent, Grid, InputAdornment } from '@mui/material'
import { Formik } from 'formik';
import { FC } from 'react'
import { AiOutlineClose, AiOutlineUser } from 'react-icons/ai';
import { HiOutlineUser } from 'react-icons/hi';
import { RiUserAddLine } from 'react-icons/ri';
import { colors } from '../../../constants/Colors';
import { permissionLevelOption } from '../../../constants/SiteConstants';
import { CustomTextfieldWrapper } from '../../commoncomponents/CustomTextfieldWrapper';
import { PrimaryButton } from '../../commoncomponents/designs/Button';
import { Heading3, Label } from '../../commoncomponents/designs/Text';
import FormikCheckbox from '../../commoncomponents/fields/FormikCheckbox';
import { FormikSelect } from '../../commoncomponents/fields/FormikSelect';
import ImageDrop from '../../commoncomponents/fields/ImageUpload';
import ImageUploadModal from '../../commoncomponents/ImageUploadModal';
import { OrgLogo, OrgLogoContainer } from '../../organisation/edit/Style';
import { useAddUserHook } from './Hooks';
import { AddUserInitialValue, AddUserValidationSchema } from './Util';

type AddUserProps = {
    handleClose: () => void;
}
const AddUser: FC<AddUserProps> = ({
    handleClose
}) => {

    const {
        addUserDetail,
        uploadPercent,
        isImageUploading
    } = useAddUserHook();

    return (
        <Dialog maxWidth={"md"} fullWidth open onClose={handleClose}>
            <DialogContent>
                <Grid p={["10px", "50px"]}>
                    <Grid container alignItems={"center"} justifyContent={"space-between"}>
                        <Grid item xs={3}>
                            <HiOutlineUser style={{ fontSize: 50 }} />
                        </Grid>
                        <Grid item>
                            <AiOutlineClose onClick={handleClose} style={{ cursor: "pointer", fontSize: 30 }} />
                        </Grid>
                    </Grid>
                    <Grid mt={"20px"}>
                        <Heading3>Add people to Revolve Energy App</Heading3>
                    </Grid>
                    <Formik
                        initialValues={AddUserInitialValue}
                        validationSchema={AddUserValidationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            addUserDetail(
                                values,
                                setSubmitting,
                                handleClose
                            )
                        }}
                    >
                        {
                            ({ handleSubmit, values, setFieldValue, isSubmitting }) => (
                                <Grid mt={"50px"}>
                                    <Grid mt={"40px"}>
                                        <Label>An email will be sent to invite the user</Label>
                                    </Grid>
                                    <Grid mt={"10px"}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={12} md={6}>
                                                <CustomTextfieldWrapper
                                                    name={"firstName"}
                                                    id={"firstName"}
                                                    fullWidth
                                                    label={"First Name"}
                                                    placeholder='Enter firstname'
                                                    InputProps={{
                                                        endAdornment: (
                                                            <RiUserAddLine />
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} md={6}>
                                                <CustomTextfieldWrapper
                                                    name={"lastName"}
                                                    id={"lastName"}
                                                    fullWidth
                                                    label={"Last Name"}
                                                    placeholder='Enter lastname'
                                                    InputProps={{
                                                        endAdornment: (
                                                            <RiUserAddLine />
                                                        )
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid mt={"10px"}>
                                        <Label color={colors.primary.dark}>Permission</Label>
                                        <FormikSelect
                                            name={"role"}
                                            id={"role"}
                                            fullWidth
                                            options={permissionLevelOption}
                                            endAdornment={
                                                <InputAdornment position="start">
                                                    <AiOutlineUser style={{ marginRight: 10 }} />
                                                </InputAdornment>
                                            }
                                        />
                                    </Grid>
                                    <Grid mt={"10px"}>
                                        <CustomTextfieldWrapper
                                            name={"email"}
                                            id={"email"}
                                            fullWidth
                                            label={"Email"}
                                            placeholder='Type email address here...'
                                            InputProps={{
                                                endAdornment: (
                                                    <RiUserAddLine />
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid mt={"10px"}>
                                        <CustomTextfieldWrapper
                                            name={"confirmEmail"}
                                            id={"confirmEmail"}
                                            fullWidth
                                            label={"Confirm Email"}
                                            placeholder='Confirm email address here...'
                                            InputProps={{
                                                endAdornment: (
                                                    <RiUserAddLine />
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid mt={"10px"}>
                                        {
                                            values.imageUrl ?
                                                <OrgLogoContainer>
                                                    <Grid item>
                                                        <OrgLogo src={values.imageUrl} />
                                                    </Grid>
                                                    <Grid item>
                                                        <Label
                                                            onClick={() => {
                                                                setFieldValue("image", null);
                                                                setFieldValue("imageUrl", "");
                                                            }}
                                                            style={{ cursor: "pointer" }}>Remove</Label>
                                                    </Grid>
                                                </OrgLogoContainer>
                                                :
                                                <ImageDrop
                                                    name='image'
                                                    setImageUrl={(url) => setFieldValue("imageUrl", url)}
                                                    imageSize={1}
                                                    logoInfo={"Upload a file or drag and drop"}
                                                    logoSubMessage={"PNG, JPG, GIF up to 1MB"}
                                                />
                                        }
                                    </Grid>
                                    <Grid mt={"10px"}>
                                        <CustomTextfieldWrapper
                                            name={"password"}
                                            id={"password"}
                                            type={"password"}
                                            fullWidth
                                            label={"Create password"}
                                            placeholder='Create your password'
                                            InputProps={{
                                                endAdornment: (
                                                    <RiUserAddLine />
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid mt={"10px"}>
                                        <CustomTextfieldWrapper
                                            name={"confirmPassword"}
                                            id={"confirmPassword"}
                                            fullWidth
                                            type={"password"}
                                            label={"Confirm password"}
                                            placeholder='Confirm your password'
                                            InputProps={{
                                                endAdornment: (
                                                    <RiUserAddLine />
                                                )
                                            }}
                                        />
                                    </Grid>
                                    <Grid mt={"10px"}>
                                        <FormikCheckbox
                                            name='receiveEmail'
                                            id='receiveEmail'
                                            label='Receive system notifications via email'
                                        />
                                    </Grid>
                                    <Grid mt={"40px"} container justifyContent={"flex-end"}>
                                        <Grid item xs={2}>
                                            <PrimaryButton isLoading={isSubmitting} onClick={() => handleSubmit()} backgroundcolor={colors.primary.dark} textcolor={colors.secondary.light} fullWidth label='Add' />
                                        </Grid>
                                    </Grid>
                                    {
                                        values.image && isImageUploading && <ImageUploadModal
                                            progress={uploadPercent}
                                            file={values.image as File}
                                            handleClose={() => setFieldValue("image", null)}
                                        />
                                    }
                                </Grid>
                            )}
                    </Formik>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

export default AddUser