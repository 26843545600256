import { ApiState } from "../CommonType";

export interface UserListType extends ApiState {
    userList: UserType[];
    isLoading: boolean;
    error: string;
}

export type UserType = {
    id: number;
    createdAt: string;
    modifiedAt: string;
    email: string;
    archived: boolean;
    role: string;
    firstName: string;
    lastName: string;
    emailNotifications: boolean;
};

export type AddUserType = {
    email: string;
    confirmEmail: string;
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
    role: string;
    image: File | null;
    imageUrl: string;
    receiveEmail: boolean;
}

export type UpdateUserType = {
    email: string;
    firstName: string;
    lastName: string;
    role: string;
};

export const USER_LIST = "USER_LIST";