import { Grid, IconButton } from '@mui/material'
import { FC, useState } from 'react'
import { AiOutlinePlusCircle, AiOutlineSearch } from 'react-icons/ai'
import { colors } from '../../../../constants/Colors'
import { CardContainer } from '../../../commoncomponents/CommonStyle'
import { CustomSimpleTextfield } from '../../../commoncomponents/CustomTextfieldWrapper'
import { Label2, PlaceholderText } from '../../../commoncomponents/designs/Text'
import { OrgStyledTable } from '../user/Style'
import { OrgListType } from './Type'
import { filteredOrgUserList } from '../user/Util'
import { useSelector } from 'react-redux'
import { useGetUserRole } from '../../../../store/auth/Selector'
import { doesLoggedinUserHavePermission } from '../../../../util/SiteUtil'
import { PERMISSIONS, USER_ROLES } from '../../../../util/Type'

type Props = {
    allItems: OrgListType[];
    title: string;
    searchLabel: string;
    setSelectedItem: (selectedUser: OrgListType) => void;
    isOrgSites?: boolean;
};

const AllList: FC<Props> = ({
    allItems,
    setSelectedItem,
    title,
    searchLabel,
    isOrgSites
}) => {
    const [searchText, setSearchText] = useState('');
    const role = useSelector(useGetUserRole);

    return (
        <CardContainer style={{ height: "100%" }} elevation={0}>
            <Label2 fontWeight={700} color={colors.primary.light} justifyContent={"center"}>{title}</Label2>
            <Grid mt={"20px"}>
                <Grid container justifyContent={"center"}>
                    <Grid item xs={8}>
                        <PlaceholderText>
                            {searchLabel}
                        </PlaceholderText>
                        <CustomSimpleTextfield
                            id={"search"}
                            name={"search"}
                            variant={"standard"}
                            placeholder={"Type here..."}
                            fullWidth
                            onChange={(e) => setSearchText(e.target.value)}
                            InputProps={{
                                endAdornment: (
                                    <AiOutlineSearch />
                                )
                            }}
                        />
                    </Grid>
                </Grid>
                <Grid mt={"40px"}>
                    {filteredOrgUserList(allItems, searchText).map((item, index) => (
                        <OrgStyledTable p={"5px"} alignItems={"center"} isodd={index % 2 === 0} key={JSON.stringify(item)} container justifyContent={"space-between"}>
                            <Grid item xs={11}>
                                <PlaceholderText fontWeight={700}>{item.name}</PlaceholderText>
                            </Grid>
                            <Grid item xs={1}>
                                <IconButton
                                    disabled={!doesLoggedinUserHavePermission(role, isOrgSites? USER_ROLES.MANAGE_SITE_PERMISSION : USER_ROLES.ADMIN_USER, PERMISSIONS.EDIT)}
                                    onClick={() => setSelectedItem(item)}>
                                    <AiOutlinePlusCircle />
                                </IconButton>
                            </Grid>
                        </OrgStyledTable>
                    ))}
                </Grid>
            </Grid>
        </CardContainer>
    )
}

export default AllList