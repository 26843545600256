import _cloneDeep from "lodash/cloneDeep";
import { ActionPayload } from "../../common/Base";
import { IS_ERROR, IS_LOADING } from "../CommonType";
import { UserListType, USER_LIST } from "./Type";

const initialState: UserListType = {
    userList: [],
    error: "",
    isLoading: false
};

export const UserListReducer = (state = initialState, action: ActionPayload<any>): any => {
    const stateClone = _cloneDeep(state);
    switch (action.type) {
        case USER_LIST: {
            stateClone.userList = action.payload;
            return stateClone;
        }
        case IS_LOADING: {
            stateClone.isLoading = action.payload;
            return stateClone;
        }
        case IS_ERROR: {
            stateClone.error = action.payload;
            return stateClone;
        }
        default: {
            return state;
        }
    };
};
