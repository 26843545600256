import { ButtonProps, CircularProgress, Grid } from "@mui/material"
import { FC, useRef } from "react";
import { colors } from "../../../constants/Colors";
import { StyledBigButton, StyledButton, StyledTextButton } from "./Style";
import { Paragraph2 } from "./Text";

type TextButtonProps = {
    label: string;
    icon?: React.ReactNode;
    buttonType?: "primary" | "secondary",
    backgroundcolor?: string;
    buttonview?: "md" | "lg" | "sm";
    textcolor?: string;
    isLoading?: boolean;
    isDisabled?: boolean;
    additionalStyles?: Record<string, string | number>;
    acceptFileTypes?: string
    onFileSelect?: (file: File) => void;
} & ButtonProps;

export const PrimaryButton: FC<TextButtonProps> = ({
    label,
    icon,
    buttonType = "primary",
    backgroundcolor,
    textcolor,
    isLoading,
    isDisabled,
    ...rest
}) => {
    return (
        <StyledButton
            textcolor={textcolor}
            backgroundcolor={backgroundcolor}
            buttontype={"primary"}
            disabled={isDisabled}
            startIcon={icon} {...rest}>
            {
                isLoading ? <CircularProgress size={20} /> : label
            }
        </StyledButton>
    );
};

export const TextButton: FC<TextButtonProps> = ({
    label,
    icon,
    backgroundcolor,
    additionalStyles,
    ...rest
}) => {
    return (
        <StyledTextButton
            variant="text"
            backgroundcolor={backgroundcolor}
            startIcon={icon} style={additionalStyles} {...rest}>
            {label}
        </StyledTextButton>
    );
};

type BigTextButtonProps = {
    label: string;
    icon?: React.ReactNode;
    onClick: () => void;
    buttonview?: "md" | "lg" | "sm";
}
export const BigTextButton: FC<BigTextButtonProps> = ({
    label,
    icon,
    onClick
}) => {
    return (
        <StyledBigButton
            onClick={onClick}>
            <Grid height={"80%"} alignItems={"center"} container justifyContent={"center"}>
                {icon}
            </Grid>
            <Paragraph2 textTransform={"uppercase"} textAlign={"center"} color={colors.primary.light}>{label}</Paragraph2>
        </StyledBigButton>
    );
};

export const FileUploadButton: FC<TextButtonProps> = ({
    label,
    icon,
    buttonType = "primary",
    backgroundcolor,
    textcolor,
    isLoading,
    isDisabled,
    onFileSelect,
    acceptFileTypes = "image/*",
    ...rest
}) => {
    const uploadInputRef = useRef<HTMLInputElement>(null);
    return (
        <>
            <input
                ref={uploadInputRef}
                type="file"
                accept={`${acceptFileTypes}`}
                style={{ display: "none" }}
                onClick={(e: any) => e.target.value = null}
                onChange={(e) => {
                    onFileSelect && e.target.files && onFileSelect(e.target.files[0])
                }}
            />
            <StyledButton
                textcolor={textcolor}
                backgroundcolor={backgroundcolor}
                buttontype={"primary"}
                disabled={isDisabled}
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                startIcon={icon} {...rest}>
                {label}
                <input
                    type="file"
                    hidden
                />
            </StyledButton>
        </>
    );
};
