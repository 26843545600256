import { FC, createContext, useRef, useContext, useEffect, useState } from 'react'
import { googleMapAPiKey } from '../constants/ApiConstants';
import { loadScript } from '../util/SiteUtil';

interface MapContextType {
    autoCompleteService: any;
}

export const MapContext = createContext<MapContextType>({
    autoCompleteService: null
});

type MapContextDataProviderProps = {
    children: JSX.Element;
}


export const MapContextDataProvider: FC<MapContextDataProviderProps> = ({
    children
}) => {
    const loaded = useRef(false);
    const autoCompleteService = useRef<any>(null);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                googleMapAPiKey(),
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }

    useEffect(() => {
        if (!autoCompleteService.current && (window as any).google) {
            autoCompleteService.current = new (
                window as any
            ).google.maps.places.AutocompleteService();
        }
    }, []);

    const value = {
        autoCompleteService
    }
    return (
        <MapContext.Provider value={value}>
            {children}
        </MapContext.Provider>
    );
};

export const useMapContext = () => useContext(MapContext);