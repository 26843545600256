import { Dialog, Grid } from '@mui/material'
import { FC } from 'react'
import { AiOutlineClose, AiOutlineFileJpg } from "react-icons/ai";
import { colors } from '../../constants/Colors';
import { PrimaryButton } from './designs/Button';
import { Label2, Paragraph2Mobile, StyledLinearProgress } from './designs/Text';

type ImageUploadModalProps = {
    handleClose: () => void;
    file: File;
    progress: number;
}
const ImageUploadModal: FC<ImageUploadModalProps> = ({
    handleClose,
    file,
    progress
}) => {
    return (
        <Dialog open maxWidth={"sm"} fullWidth onClose={handleClose}>
            <Grid spacing={"20px"} alignItems={"center"} container p={"61px"} pb={progress === 100 ? "10px" : "61px"}>
                <Grid item>
                    <AiOutlineFileJpg />
                </Grid>
                <Grid item xs={10}>
                    <Label2>{file.name}</Label2>
                    <StyledLinearProgress
                        variant="determinate" value={progress} />
                    <Paragraph2Mobile mt={"8px"} color={colors.primary.light} fontFamily={"primary"}>
                        {file.size}KB - {progress}% uploaded
                    </Paragraph2Mobile>
                </Grid>
                <Grid item>
                    <AiOutlineClose />
                </Grid>
            </Grid>
            {
                progress === 100 && <Grid container p={"20px"} justifyContent={"flex-end"}>
                    <Grid item>
                        <PrimaryButton
                            textcolor={colors.secondary.light}
                            backgroundcolor={colors.primary.dark}
                            onClick={handleClose} label='Close' />
                    </Grid>
                </Grid>
            }
        </Dialog>
    );
};

export default ImageUploadModal