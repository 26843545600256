import { Grid } from '@mui/material';
import { FC } from 'react'
import { colors } from '../../constants/Colors';
import { SiteType } from '../../store/sites/Type';
import { Paragraph2 } from '../commoncomponents/designs/Text';
import { PaperContainer, PropertyCardImage } from '../manageproperty/Style';
import NoImage from "../../images/dummyimages/noimgproperty.svg";

type SiteInfoProps = {
    propertyInfo: SiteType;
}

const SiteInfo: FC<SiteInfoProps> = ({
    propertyInfo
}) => {
    const { name, invoiceReference, invoiceImageUrl } = propertyInfo
    return (
        <PaperContainer elevation={0}>
            <PropertyCardImage src={invoiceImageUrl || NoImage} />
            <Grid mt={"15px"}>
                <Paragraph2 textAlign={"center"} color={colors.primary.light}>{name}</Paragraph2>
                <Paragraph2 textAlign={"center"} color={colors.primary.light}>{invoiceReference}</Paragraph2>
            </Grid>
        </PaperContainer>
    )
}

export default SiteInfo