import { ActionPayload } from "../../common/Base";
import { IS_LOADING } from "../CommonType";
import { UserType, USER_LIST } from "./Type";

export const updateUserList = (userList: UserType[]): ActionPayload<UserType[]> => ({
    type: USER_LIST,
    payload: userList
});

export const updateListIsLoading = (isLoading: boolean): ActionPayload<boolean> => ({
    type: IS_LOADING,
    payload: isLoading
});

export const updateListIsError = (isError: string): ActionPayload<string> => ({
    type: IS_LOADING,
    payload: isError
});
