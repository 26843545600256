import { Grid } from '@mui/material';
import { FC, useState } from 'react';
import { MdArrowBackIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../../constants/Colors';
import { PrimaryButton, TextButton } from '../../../commoncomponents/designs/Button';
import ManagePropertyOptionActionPopup from './ManagePropertyOptionActionPopup';

type ManageSiteHeaderProps = {
    id: string;
}
const ManagePropertyHeader: FC<ManageSiteHeaderProps> = ({
    id
}) => {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const navigate = useNavigate();


    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    return (
        <Grid mt={"30px"}>
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={7}>
                    <TextButton onClick={() => navigate(`/site/${id}/manageproperties`)} buttonview='sm' label={"Portfolio"} icon={<MdArrowBackIos style={{ fontSize: 14 }} />} />
                </Grid>
                <Grid item>
                    <PrimaryButton onClick={handleClick} textcolor={colors.secondary.neutral} backgroundcolor={colors.primary.dark} buttonview='sm' label='OPTIONAL ACTIONS' />
                </Grid>
            </Grid>
            <ManagePropertyOptionActionPopup
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
            />
        </Grid>
    );
};

export default ManagePropertyHeader;