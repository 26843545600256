import { Client } from "../../../../common/Client";
import { createSitePropertyServiceUsageApiUrl, getActiveBillingPeriodApiUrl } from "../../../../constants/ApiConstants";
import { PropertyCustomerList } from "../../../../store/sites/Type";
import { ActiveBillingType, CustomerInfoType, CustomerUsageListType, UsageListType } from "./Type";

export const updateCustomerUsageDetail = (
    siteId: string,
    propertyId: string,
    customerId: string,
    usageList: UsageListType[]) => {
    return Client.getInstance().createData(createSitePropertyServiceUsageApiUrl(
        siteId,
        propertyId,
        customerId
    ), {
        usages: usageList.map((usage) => ({
            amount: usage.amount,
            serviceId: usage.id
        }))
    }).then((res) => {
        return Promise.resolve(res);
    })
}

export const sortCustomerData = (customerUsageList: CustomerUsageListType[], customerData: PropertyCustomerList[]): CustomerInfoType[] => {
    const transformData = customerUsageList.map((item) => ({
        ...item,
        ...customerData.find((cust) => cust.id.toString() === item.customerId)
    })) as CustomerInfoType[];
    console.log('transformData::', transformData)
    if (transformData.length === 1) {
        return transformData;
    }
    return transformData.sort((a, b) => new Date(a.start) > new Date(b.start) ? 1 : -1)
}


const tranformData = (data: any): ActiveBillingType | null => {
    if (!data) return null

    return {
        date: data.date,
        endDate: data.endDate,
        endDateInclusive: data.endDateInclusive,
        startDate: data.startDate
    }
}

export const getActiveBillingPeriod = (siteId: string) => {
    return Client.getInstance().getData(getActiveBillingPeriodApiUrl(siteId)).then((res) => {
        return Promise.resolve(tranformData(res.data.data));
    }).catch(() => {
        return Promise.reject();
    })
}