import { useState } from "react"
import { PropertyCustomerList } from "../../../../store/sites/Type";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { deleteCustomerDetail } from "../../../../store/sites/ActionCreator";

export const useCustomeInfoCardHook = () => {
    const [date, setDate] = useState<Date | null>(null);
    const [openEditModal, setOpenEditModal] = useState<PropertyCustomerList | undefined>(undefined);
    const [openDeleteModal, setOpenDeleteModal] = useState<PropertyCustomerList | null>(null);
    const { id, homeid } = useParams<{
        id: string;
        homeid: string
    }>();
    const dispatch = useDispatch();
    const handleDeleteCustomer = () => {
        if (id && homeid && openDeleteModal) {
            dispatch(deleteCustomerDetail(
                id,
                homeid,
                openDeleteModal
            )).then(() => {
                setOpenDeleteModal(null);
            })
        }

    }
    return {
        date,
        setDate,
        openEditModal,
        setOpenEditModal,
        setOpenDeleteModal,
        openDeleteModal,
        handleDeleteCustomer,
    };
};