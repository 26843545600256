import { object, string } from "yup"

export const loginInitialValue = {
    username: '',
    password: '',
}

export const loginValidationSchema = object().shape({
    username: string().required('Username is required'),
    password: string().required('Password is required'),
});
