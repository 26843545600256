import { Autocomplete, FormHelperText, InputAdornment, InputLabel, MenuItem, Select, SelectProps, TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import { FC } from "react";
import { Label2, PlaceholderText } from "../designs/Text";
import { StyledSelectField } from "./Style";
import { OptionType } from "./Type";
import { colors } from "../../../constants/Colors";

type FormikSelectProps = {
    id: string;
    name: string;
    options: OptionType[];
    label?: string;
    inputIcon?: React.ReactElement;
} & SelectProps & TextFieldProps;

export const FormikSelect: FC<FormikSelectProps> = ({ id, name, options, label, ...rest }) => {
    const [field, { value, error, touched }] = useField(name);

    return (
        <StyledSelectField fullWidth error={!!error && !!touched}>
            {
                label && <InputLabel>{label}</InputLabel>
            }
            <Select
                label={label}
                renderValue={
                    value !== "none" ? undefined : () => <Label2 fontFamily={"primary"} fontWeight={400} color={colors.primary.light}>{options[0].label}</Label2>
                }
                {...field} {...rest}>
                {options.slice(1).map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
            {!!error && !!touched && <FormHelperText>{error}</FormHelperText>}
        </StyledSelectField>
    );
};

export const SelectWrapper: FC<FormikSelectProps> = ({ id, name, options, label, ...rest }) => {
    return (
        <StyledSelectField fullWidth>
            {
                label && <InputLabel>{label}</InputLabel>
            }

            <Select
                label={label}
                renderValue={
                    rest.value !== "none" ? undefined : () => <Label2 fontWeight={400} fontFamily={"primary"} color={colors.primary.light}>{options[0].label}</Label2>
                }
                {...rest}>
                {options.slice(1).map(({ label, value }) => (
                    <MenuItem key={value} value={value}>
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </StyledSelectField>
    );
};

export const FormikAutoComplete: FC<FormikSelectProps> = ({ id, name, options, label, inputIcon, ...rest }) => {
    const [field, { value, error, touched }, { setValue }] = useField(name);
    return (
        <StyledSelectField fullWidth error={!!error && !!touched}>
            <Autocomplete
                {...field}
                disablePortal
                id={id}
                value={value === "" ? options[0] : options.find((item) => item.value === value)}
                options={options}
                fullWidth
                onChange={(_, values: any) => {
                    setValue(values.value);
                }}
                renderInput={(params) => <TextField
                    {...params}
                    label={label}
                    InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                            <InputAdornment position="start">
                                {inputIcon}
                            </InputAdornment>
                        )
                    }}
                />}

            />
            {!!error && !!touched && <FormHelperText>{error}</FormHelperText>}
        </StyledSelectField>

    );
};
