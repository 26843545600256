export const mobileSideDrawer = [
    {
        id: 1,
        label: "My Profile & Settings",
        url: "/profile"
    },
    {
        id: 2,
        label: "Users & permissions",
        url: "/users/current"
    },
    {
        id: 3,
        label: "Help & feedback",
        url: "/help"
    },
    {
        id: 4,
        label: "Logout",
        action: "logout",
        url: "/"
    },
]