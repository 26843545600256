import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getSiteIdDetail } from "../../store/sites/ActionCreator";
import { useGetSiteDetail } from "../../store/sites/Selector";
import { xeroAuthInfo } from "../../store/xeroconnect/ActionCreator";

export const useManageSiteHook = () => {
    const { id } = useParams();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const dispatch = useDispatch();

    const propertyInfo = useSelector(useGetSiteDetail);

    const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (id) {
            dispatch(getSiteIdDetail(id));
            dispatch(xeroAuthInfo(id || ""))
        }
    }, []);

    return {
        propertyInfo,
        id,
        handleAnchorClick,
        anchorEl,
        setAnchorEl
    }
}

