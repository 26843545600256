import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../util/Type';
import BreadcrumbWrapper from '../commoncomponents/designs/BreadcrumbWrapper';
import LoadingWrapper from '../commoncomponents/LoadingWrapper';
import SideNavbar from '../commoncomponents/sidenavbar';
import MobileNavbarMenu from '../commoncomponents/sidenavbar/MobileNavbar';
import LeftSection from '../commoncomponents/sitesection/LeftSection';
import RightSection from '../commoncomponents/sitesection/RightSection';
import ManagerPropertyHeader from '../manageproperty/mobile/ManagePropertyHeader';
import { SiteManageProperty } from '../manageproperty/Util';
import { useSettingsHook } from './Hooks';
import SiteForm from './SiteForm';
import SiteInfo from './SiteInfo';
import { SiteSettingsEditTabOption, SiteSettingsMenus } from './Util';

const SiteSettings = () => {
    const { id, propertyInfo, isLoading } = useSettingsHook();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const role = useSelector(useGetUserRole);

    if (mobileView) {
        return (
            <Grid>
                {
                    propertyInfo && <ManagerPropertyHeader propertyInfo={propertyInfo} hideAction />
                }
                <MobileNavbarMenu
                    menuOptions={SiteSettingsMenus(id || "", role)}
                    selectedMenuId={3}
                />
                <Grid mt={"30px"}
                    style={{
                        pointerEvents:
                            !doesLoggedinUserHavePermission(role, USER_ROLES.SITE_SETTINGS, PERMISSIONS.VIEW)
                                ? "none" : "all"
                    }}
                >
                    <LoadingWrapper isLoading={isLoading}>
                        <SiteForm siteInfo={propertyInfo} />
                    </LoadingWrapper>
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar selectedMenuId={4} sidebarOptions={SiteManageProperty(id || "", role)} />
                </LeftSection>
            </Grid>
            <Grid item xs={7}>
                <BreadcrumbWrapper
                    tabType={SiteSettingsEditTabOption(id || "")}
                />
                <Grid mt={"55px"} style={{
                    pointerEvents:
                        !doesLoggedinUserHavePermission(role, USER_ROLES.SITE_SETTINGS, PERMISSIONS.VIEW)
                            ? "none" : "all"
                }}>
                    <Grid mt={"30px"}>
                        <LoadingWrapper isLoading={isLoading}>
                            <SiteForm siteInfo={propertyInfo} />
                        </LoadingWrapper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <RightSection>
                    {
                        propertyInfo && <SiteInfo propertyInfo={propertyInfo} />
                    }
                </RightSection>
            </Grid>
        </Grid>
    );
};

export default SiteSettings