import { OptionType } from "../../components/commoncomponents/fields/Type";
import { ApiState } from "../CommonType";

export interface LookupType extends ApiState {
    billingCyclesDropdown: OptionType[];
    currenciesDropdown: OptionType[];
    languageTagsDropdown: OptionType[];
    rolesDropdown: OptionType[];
    timezonesDropdown: OptionType[];
    serviceBillingTypesDropdown: OptionType[];
    serviceCategoriesDropdown: OptionType[];
    xeroAccountingCodeDropdown: OptionType[];
    tariffKindDropdown: OptionType[];
    UnitIdDropdown: OptionType[];
    invoiceReferenceDropdown: OptionType[];
    serviceFixedUnitDropdown: OptionType[];
    xeroAccountInvoiceOptions: xeroAccountInvoiceOptionsType[];
    xeroInvoiceStatusDropdown: OptionType[];
    availableInvoiceStatusDropdown: OptionType[];
}

export type xeroAccountInvoiceOptionsType = {
    value: string;
    label: string;
    options: OptionType[];
};

export const UPDATE_BILLING_DROPDOWN = "UPDATE_BILLING_DROPDOWN";
export const UPDATE_CURRENCIES_DROPDOWN = "UPDATE_CURRENCIES_DROPDOWN";
export const UPDATE_LANGUAGE_TAGS_DROPDOWN = "UPDATE_LANGUAGE_TAGS_DROPDOWN";
export const UPDATE_ROLES_DROPDOWN = "UPDATE_ROLES_DROPDOWN";
export const UPDATE_TIMEZONES_DROPDOWN = "UPDATE_TIMEZONES_DROPDOWN";
export const UPDATE_SERVICE_BILLING_DROPDOWN = "UPDATE_SERVICE_BILLING_DROPDOWN";
export const UPDATE_SERVICE_CATEGORIES_DROPDOWN = "UPDATE_SERVICE_CATEGORIES_DROPDOWN";
export const UPDATE_XERO_ACCOUNTING_CODE_DROPDOWN = "UPDATE_XERO_ACCOUNTING_CODE_DROPDOWN";
export const UPDATE_STANDING_CHARGE_DROPDOWN = "UPDATE_STANDING_CHARGE_DROPDOWN";
export const UPDATE_UNIT_RATE_DROPDOWN = "UPDATE_UNIT_RATE_DROPDOWN";
export const UPDATE_TARIFF_KIND_DROPDOWN = "UPDATE_TARIFF_KIND_DROPDOWN";
export const UPDATE_UNIT_ID_DROPDOWN = "UPDATE_UNIT_ID_DROPDOWN";
export const UPDATE_INVOICE_REFERENCE_DROPDOWN = "UPDATE_INVOICE_REFERENCE_DROPDOWN";
export const UPDATE_XERO_INVOICE_DROPDOWN = "UPDATE_XERO_INVOICE_DROPDOWN";
export const UPDATE_SERVICE_FIXED_UNIT_DROPDOWN = "UPDATE_SERVICE_FIXED_UNIT_DROPDOWN";
export const UPDATE_XERO_INVOICE_STATUS_DROPDOWN = "UPDATE_XERO_INVOICE_STATUS_DROPDOWN";
export const UPDATE_AVAILAIBLE_INVOICE__DROPDOWN = "UPDATE_AVAILAIBLE_INVOICE__DROPDOWN";