import { Grid } from '@mui/material';
import { FC, useState } from 'react'
import { colors } from '../../../constants/Colors';
import { PrimaryButton } from '../../commoncomponents/designs/Button';
import { Paragraph2 } from '../../commoncomponents/designs/Text';
import { PaperContainer, PropertyCardImage } from '../Style';
import NoImage from "../../../images/dummyimages/noimgproperty.svg";
import { useNavigate, useParams } from 'react-router-dom';
import { SiteType } from '../../../store/sites/Type';
import { useDispatch, useSelector } from 'react-redux';
import { deleteSiteProperty } from '../../../store/sites/ActionCreator';
import DeleteModal from '../../commoncomponents/modal/DeleteModal';
import { useGetUserRole } from '../../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../util/Type';

type EditOptionActionProps = {
    propertyInfo: SiteType;
}
const EditOptionAction: FC<EditOptionActionProps> = ({
    propertyInfo
}) => {
    const { name, invoiceReference, invoiceImageUrl } = propertyInfo;
    const navigate = useNavigate();
    const { id, homeid } = useParams<{
        id: string,
        homeid: string
    }>();
    const dispatch = useDispatch();
    const [openStatusModal, setOpenStatusModal] = useState(false);
    const role = useSelector(useGetUserRole);

    const handleDeleteProperty = () => {
        homeid && id && dispatch(deleteSiteProperty(id, homeid))
            .then(() => {
                navigate(`/site/${id}/manageproperties`);
            })
    }
    return (
        <PaperContainer elevation={0}>
            <PropertyCardImage src={invoiceImageUrl || NoImage} alt={"logo"} />
            <Grid mt={"15px"}>
                <Paragraph2 textAlign={"center"} color={colors.primary.light}>{name}</Paragraph2>
                <Paragraph2 textAlign={"center"} color={colors.primary.light}>{invoiceReference}</Paragraph2>
            </Grid>
            <Grid mt={"19px"}>
                <Paragraph2 textAlign={"center"} textTransform={"uppercase"} color={colors.primary.light}>Optional Actions</Paragraph2>
            </Grid>
            <Grid mt={"15px"} spacing={"20px"} container>
                {
                    doesLoggedinUserHavePermission(role, USER_ROLES.PROPERTY, PERMISSIONS.CREATE) &&
                    <>
                        <Grid item xs={12}>
                            <PrimaryButton variant='contained'
                                fullWidth
                                backgroundcolor={colors.secondary.light}
                                buttonType='secondary'
                                label='Add properties'
                                onClick={() => navigate(`/site/${id}/manageProperties/add`)}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PrimaryButton variant='contained'
                                fullWidth
                                backgroundcolor={colors.secondary.light}
                                buttonType='secondary'
                                onClick={() => navigate(`/site/${id}/manageProperties/addmultiple`)}
                                label='Add multiple properties'
                            />
                        </Grid>
                    </>
                }
                {
                    doesLoggedinUserHavePermission(role, USER_ROLES.PROPERTY, PERMISSIONS.DELETE) &&
                    <Grid item xs={12}>
                        <PrimaryButton variant='contained'
                            fullWidth
                            onClick={() => setOpenStatusModal(true)}
                            backgroundcolor={colors.secondary.light}
                            buttonType='secondary'
                            label='Delete Property'
                        />
                    </Grid>
                }

                {
                    openStatusModal &&
                    <DeleteModal
                        message={`Are you sure you want to remove this property.`}
                        title={`Property Delete`}
                        onClose={() => setOpenStatusModal(false)}
                        onSubmit={handleDeleteProperty}
                    />
                }
            </Grid>
        </PaperContainer>
    )
}

export default EditOptionAction;
