import { ActionPayload } from "../../common/Base";
import { IS_ERROR, IS_LOADING } from "../CommonType";
import { ManagePropertyDetailType, ManagePropertyListType, PropertyCustomerList, SiteDetailType, SiteType, UPDATE_CUSTOMER_PROPERTY_LIST, UPDATE_PROPERTY_IMAGE, UPDATE_PROPERTY_LIST, UPDATE_SITE_DETAIL, UPDATE_SITE_IMAGE, UPDATE_SITE_LIST, UPDATE_SITE_PROPERTY_DETAIL } from "./Type";

export const updateSitesList = (sitesList: SiteType[]): ActionPayload<SiteType[]> => ({
    type: UPDATE_SITE_LIST,
    payload: sitesList
});

export const updateSiteIsLoading = (isLoading: boolean): ActionPayload<boolean> => ({
    type: IS_LOADING,
    payload: isLoading
});

export const updateSiteIsError = (isError: string): ActionPayload<string> => ({
    type: IS_ERROR,
    payload: isError
});

export const updateSelectedSiteItem = (siteItem: SiteDetailType): ActionPayload<SiteDetailType> => ({
    type: UPDATE_SITE_DETAIL,
    payload: siteItem
});

export const updateSitePropertyList = (propertyList: ManagePropertyListType[]): ActionPayload<ManagePropertyListType[]> => ({
    type: UPDATE_PROPERTY_LIST,
    payload: propertyList
});

export const updateSelectedSitePropertyItem = (propertyDetail: ManagePropertyDetailType): ActionPayload<ManagePropertyDetailType> => ({
    type: UPDATE_SITE_PROPERTY_DETAIL,
    payload: propertyDetail
});

export const updateSiteImage = (imgSrc: string): ActionPayload<string> => ({
    type: UPDATE_SITE_IMAGE,
    payload: imgSrc
});

export const updatePropertyImage = (imgSrc: string): ActionPayload<string> => ({
    type: UPDATE_PROPERTY_IMAGE,
    payload: imgSrc
});

export const updatePropertyCustomerList = (customerList: PropertyCustomerList[]): ActionPayload<PropertyCustomerList[]> => ({
    type: UPDATE_CUSTOMER_PROPERTY_LIST,
    payload: customerList
});
