import Styled from "@emotion/styled";

export const LogoImage = Styled.img`
    height: 124px;
    width: 124px;
    border-radius: 50%;
`;

export const LogoContainer = Styled.div`
    display: flex;
    gap: 30px;
`;