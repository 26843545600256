import { Grid, useMediaQuery, useTheme } from '@mui/material';
import TabWrapper from '../commoncomponents/designs/BreadcrumbWrapper';
import SideNavbar from '../commoncomponents/sidenavbar';
import LeftSection from '../commoncomponents/sitesection/LeftSection';
import RightSection from '../commoncomponents/sitesection/RightSection';
import { useManageSiteHook } from './Hooks';
import OptionalActions from './OptionalActions';
import SiteInfo from './SiteInfo';
import SiteLinks from './SiteLinks';
import { manageSiteTabOption, SiteMenus, SiteSideBarOptions } from './Util';
import MobileNavbarMenu from '../commoncomponents/sidenavbar/MobileNavbar';
import ManageSiteHeader from './mobile/ManageSiteMobileHeader';
import { PrimaryButton } from '../commoncomponents/designs/Button';
import { colors } from '../../constants/Colors';
import OptionActionPopup from './mobile/OptionActionPopup';
import { useGetUserRole } from '../../store/auth/Selector';
import { useSelector } from 'react-redux';

const ManageSite = () => {
    const { propertyInfo, id, anchorEl, handleAnchorClick, setAnchorEl } = useManageSiteHook();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const ipadView = useMediaQuery(theme.breakpoints.down(1200));
    const role = useSelector(useGetUserRole);

    if (mobileView) {
        return (
            <Grid>
                <MobileNavbarMenu
                    menuOptions={SiteMenus(id || "", role)}
                    selectedMenuId={-1}
                />
                <ManageSiteHeader />
                <Grid item xs={7}>
                    <SiteInfo
                        siteInfo={propertyInfo}
                    />
                    <SiteLinks />
                </Grid>
            </Grid>
        )
    }

    if (ipadView) {
        return (
            <Grid container spacing={"40px"}>
                <Grid item xs={3}>
                    <LeftSection>
                        <SideNavbar selectedMenuId={-1} sidebarOptions={SiteSideBarOptions(id || "", role)} />
                    </LeftSection>
                </Grid>
                <Grid item xs={9}>
                    <TabWrapper
                        tabType={manageSiteTabOption}
                    />
                    <Grid mt={"47px"}>
                        <Grid container justifyContent={"flex-end"}>
                            <Grid item>
                                <PrimaryButton
                                    buttonview='sm'
                                    onClick={handleAnchorClick}
                                    textcolor={colors.secondary.neutral}
                                    backgroundcolor={colors.primary.dark}
                                    label='OPTIONAL ACTIONS' />
                            </Grid>
                        </Grid>
                        <OptionActionPopup
                            siteId={id as string}
                            anchorEl={anchorEl}
                            isActive={propertyInfo?.active}
                            handleClose={() => setAnchorEl(null)}
                        />
                    </Grid>
                    <SiteInfo
                        siteInfo={propertyInfo}
                    />
                    <SiteLinks />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar selectedMenuId={-1} sidebarOptions={SiteSideBarOptions(id || "", role)} />
                </LeftSection>
            </Grid>
            <Grid item xs={7}>
                <TabWrapper
                    tabType={manageSiteTabOption}
                />
                <SiteInfo
                    siteInfo={propertyInfo}
                />
                <SiteLinks />
            </Grid>
            <Grid item xs={3}>
                <RightSection>
                    <OptionalActions
                        isActive={propertyInfo?.active}
                        siteId={id as string} />
                </RightSection>
            </Grid>
        </Grid>
    );
};

export default ManageSite;