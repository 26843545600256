import { UserListType } from "../components/user/Type";
import Site1 from "../images/dummyimages/site1.jpg";
import Site2 from "../images/dummyimages/site2.jpg";

export type portfolioType = {
    id: number;
    imgSrc: string;
    title: string;
    description: string;
    propertyList: propertyListType[];
};

export type propertyListType = {
    type: string;
    label: string;
    id: number;
}
export const portfolioData: portfolioType[] = [
    {
        id: 1,
        imgSrc: Site1,
        title: "Coromandel Lodge",
        description: "Est: 18/05/23",
        propertyList: [
            {
                id: 1,
                type: "apartment",
                label: "Apartment #"
            },
            {
                id: 2,
                type: "house",
                label: "House #"
            },
            {
                id: 3,
                type: "chalet",
                label: "Chalet #"
            },
            {
                id: 4,
                type: "apartment",
                label: "Apartment #"
            },
            {
                id: 5,
                type: "apartment",
                label: "Apartment #"
            },
        ]
    },
    {
        id: 2,
        imgSrc: Site2,
        title: "Queenstown Village",
        description: "Est: 18/05/23",
        propertyList: [
            {
                id: 1,
                type: "apartment",
                label: "Apartment #"
            }
        ]
    },
    {
        id: 3,
        imgSrc: Site1,
        title: "unnamed",
        description: "Est: 18/05/23",
        propertyList: [
            {
                id: 1,
                type: "apartment",
                label: "Apartment #"
            }
        ]
    },
];

export const PastCustmerData = [
    {
        id: 1,
        name: "John Doe",
    },
    {
        id: 2,
        name: "Samanta Doodie",
    },
    {
        id: 3,
        name: "Carl young",
    },
];

export const dummySiteServiceCsv = `Tariff,Year,Months,Weekdays,Minutes Start,Minutes End,Rate,Name
Variable,2021,Jan-Apr,Mon-Fri,00:00,07:00,$1.23,T1 Weekday Morning
Variable,2021,Jan-Apr,Mon-Fri,07:00,20:00,$2.34,T1 Weekday Day
Variable,2021,Jan-Apr,Mon-Fri,20:00,24:00,$1.34,T1 Weekday Evening
Variable,2021,Jan-Apr,Sat-Sun,00:00,24:00,$1.78,T1 Weekend
Variable,2021,May-Aug,Mon-Fri,00:00,07:00,$2.23,T2 Weekday Morning
Variable,2021,May-Aug,Mon-Fri,07:00,20:00,$3.34,T2 Weekday Day
Variable,2021,May-Aug,Mon-Fri,20:00,24:00,$2.34,T2 Weekday Evening
Variable,2021,May-Aug,Sat-Sun,00:00,24:00,$2.78,T2 Weekend
Variable,2021,Sep-Dec,Mon-Fri,00:00,07:00,$2.23,T3 Weekday Morning
Variable,2021,Sep-Dec,Mon-Fri,07:00,20:00,$1.24,T3 Weekday Day
Variable,2021,Sep-Dec,Mon-Fri,20:00,24:00,$1.67,T3 Weekday Evening
Variable,2021,Sep-Dec,Sat-Sun,00:00,24:00,$2.13,T3 Weekend
Fixed,2021,Jan-Apr,Mon-Sun,00:00,24:00,$0.12,T1 Fixed
Fixed,2021,May-Aug,Mon-Sun,00:00,24:00,$0.14,T2 Fixed
Fixed,2021,Sep-Dec,Mon-Sun,00:00,24:00,$0.16,T3 Fixed
`