import { Grid } from '@mui/material';
import { Formik } from 'formik';
import { OrgDetailType } from '../../../../store/organisation/Type';
import { useOrgSettingHook } from './Hooks';
import { SettingFormInitialValue, SettingValidationSchema } from '../Util';
import { FC } from "react";
import SettingsForm from './SettingsForm';
import ImageUploadModal from '../../../commoncomponents/ImageUploadModal';

type OrgSettingsProps = {
    orgInfo?: OrgDetailType;
}

const OrgSettings: FC<OrgSettingsProps> = ({
    orgInfo
}) => {

    const { updateSetting, percent, setUploadPercent, isImageUploading, setIsImageUploading } = useOrgSettingHook();

    return (
        <Grid>
            <Formik
                initialValues={SettingFormInitialValue(orgInfo)}
                validationSchema={SettingValidationSchema}
                onSubmit={(values, { setSubmitting, setFieldValue }) => {
                    updateSetting(values, setSubmitting, setFieldValue, orgInfo);
                }}
                enableReinitialize
            >
                {({ values, setFieldValue }) => <>
                    <SettingsForm
                        setIsImageUploading={setIsImageUploading}
                        setUploadPercent={setUploadPercent} />
                    {
                        values.image && isImageUploading && <ImageUploadModal
                            progress={percent}
                            file={values.image as File}
                            handleClose={() => setFieldValue("image", null)}
                        />
                    }
                </>}
            </Formik>
        </Grid>
    );
}

export default OrgSettings;