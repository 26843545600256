import _cloneDeep from "lodash/cloneDeep";
import { ActionPayload } from "../../common/Base";
import { IS_ERROR, IS_LOADING } from "../CommonType";
import { OrgListType, ORG_LIST, SELECTED_ORG_DETAIL, UPDATE_ORG_ALL_SITE_LIST, UPDATE_ORG_ALL_USER_LIST, UPDATE_ORG_IMAGE, UPDATE_ORG_SELECTED_SITE_LIST, UPDATE_ORG_SELECTED_USER_LIST, UPDATE_ORG_SITE_LIST, UPDATE_ORG_USER_LIST } from "./Type";

const initialState: OrgListType = {
    orgList: [],
    error: "",
    orgDetail: undefined,
    isLoading: false
};

export const OrgListReducer = (state = initialState, action: ActionPayload<any>): any => {
    const stateClone = _cloneDeep(state);
    switch (action.type) {
        case ORG_LIST: {
            stateClone.orgList = action.payload;
            return stateClone;
        }
        case SELECTED_ORG_DETAIL: {
            stateClone.orgDetail = action.payload;
            return stateClone;
        }
        case UPDATE_ORG_IMAGE: {
            if (stateClone.orgDetail) {
                stateClone.orgDetail.imageUrl = action.payload;
                stateClone.orgDetail.image = null;
            }
            return stateClone;
        }
        case UPDATE_ORG_USER_LIST: {
            if (stateClone.orgDetail) {
                stateClone.orgDetail.userList.allList = action.payload.allList;
                stateClone.orgDetail.userList.selectedList = action.payload.selectedList;
            }
            return stateClone;
        }
        case UPDATE_ORG_SITE_LIST: {
            if (stateClone.orgDetail) {
                stateClone.orgDetail.siteList.allList = action.payload.allList;
                stateClone.orgDetail.siteList.selectedList = action.payload.selectedList;
            }
            return stateClone;
        }
        case UPDATE_ORG_SELECTED_USER_LIST: {
            if (stateClone.orgDetail) {
                stateClone.orgDetail.userList.selectedList = action.payload;
            }
            return stateClone;
        }
        case UPDATE_ORG_ALL_USER_LIST: {
            if (stateClone.orgDetail) {
                stateClone.orgDetail.userList.allList = action.payload;
            }
            return stateClone;
        }
        case UPDATE_ORG_SELECTED_SITE_LIST: {
            if (stateClone.orgDetail) {
                stateClone.orgDetail.siteList.selectedList = action.payload;
            }
            return stateClone;
        }
        case UPDATE_ORG_ALL_SITE_LIST: {
            if (stateClone.orgDetail) {
                stateClone.orgDetail.siteList.allList = action.payload;
            }
            return stateClone;
        }
        case IS_LOADING: {
            stateClone.isLoading = action.payload;
            return stateClone;
        }
        case IS_ERROR: {
            stateClone.error = action.payload;
            return stateClone;
        }
        default: {
            return state;
        }
    };
};
