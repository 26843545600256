import { Grid } from '@mui/material';
import { TitleToast } from '../../../commoncomponents/designs/Toasts';
import { useServicesHook } from './Hooks';
import ServiceUpdate from './serviceupdate';
import ServiceListCard from './ServiceListCard';
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../../util/Type';

const Service = () => {
    const { selectedServices, handleServiceChange, setSelectedServices, serviceList, propertyService } = useServicesHook();
    const role = useSelector(useGetUserRole);

    return (
        <Grid>
            <TitleToast title='Services for Apartment #' message='Select and configure which services are used by this property.' />
            <Grid container
                style={{
                    pointerEvents:
                        !doesLoggedinUserHavePermission(role, USER_ROLES.SITE_PROPERTY_SERVICES, PERMISSIONS.VIEW)
                            ? "none" : "all"
                }}
            >
                {
                    serviceList.map((item) => (
                        <ServiceListCard
                            isEnabled={!!propertyService?.find((service) => service.id === item.id)?.isEnabled}
                            serviceInfo={item}
                            key={item.id}
                            handleServiceChange={handleServiceChange}
                            setSelectedServices={setSelectedServices}
                        />
                    ))
                }
                {
                    selectedServices && <ServiceUpdate isMeteredSettings hideTariff selectedServiceInfo={{
                        ...selectedServices,
                        dataEnum: propertyService?.find((service) => service.id === selectedServices.id)?.datumStream
                    }} handleClose={() => setSelectedServices(undefined)} />
                }
            </Grid>
        </Grid>
    );
};

export default Service