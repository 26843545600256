import { ref } from "yup";
import { object, string } from "yup";
import { Client } from "../../common/Client";
import { resetpasswordApiUrl } from "../../constants/ApiConstants";

export const resetPasswordInitialValue = {
    password: "",
    confirmPassword: "",
}

export const resetPasswordValidationSchema = object().shape({
    password: string().required('Password is required'),
    confirmPassword: string()
        .oneOf([ref('password'), null], 'Passwords must match')
});

export const resetPassword = (code: string, password: string) => Client.getInstance()
    .createData(resetpasswordApiUrl, { code, newPassword: password })
    .then(() => Promise.resolve());
