import { differenceBy } from "lodash";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateOrgAllSiteList } from "../../../../store/organisation/Action";
import { getSelectedOrgSiteList, updateSelectedOrgSiteList } from "../../../../store/organisation/ActionCreator";
import { useGetOrgSiteList } from "../../../../store/organisation/Selector";
import { OrgSiteListType } from "../../../../store/organisation/Type";
import { useGetSiteList, useSiteLoading } from "../../../../store/sites/Selector";
import { SiteType } from "../../../../store/sites/Type";
import { OrgListType } from "../listselect/Type";

export const useOrgSiteHook = () => {
    const sitesList = useSelector(useGetSiteList);
    const { id } = useParams<{
        id: string;
    }>();
    const orgSitesList = useSelector(useGetOrgSiteList);
    const isLoading = useSelector(useSiteLoading);
    const dispatch = useDispatch();

    useEffect(() => {
        id && dispatch(getSelectedOrgSiteList(id, true));
    }, []);

    useEffect(() => {
        if (sitesList.length > 0 && orgSitesList) {
            const filteredList = differenceBy(sitesList, orgSitesList.selectedList, "id");
            dispatch(updateOrgAllSiteList(filteredList));
        }
    }, [orgSitesList?.selectedList.length]);

    const updateSelectedSite = (site: OrgListType) => {
        let currentListInfo = {};
        if (orgSitesList?.selectedList && orgSitesList.selectedList.find((item) => item.id === site.id)) {
            const selecetdSite = orgSitesList.selectedList.find((item) => item.id === site.id);
            currentListInfo = {
                allList: [...orgSitesList.allList, selecetdSite],
                selectedList: orgSitesList.selectedList.filter((item) => item.id !== site.id)
            };
            id && dispatch(updateSelectedOrgSiteList(id, currentListInfo as OrgSiteListType, selecetdSite as SiteType));
            return;
        }

        if (orgSitesList?.selectedList && orgSitesList.allList) {
            const selectedSite = orgSitesList.allList.find((item) => item.id === site.id);
            currentListInfo = {
                selectedList: [...orgSitesList.selectedList, selectedSite],
                allList: orgSitesList.allList.filter((item) => item.id !== site.id)
            };
            id && dispatch(updateSelectedOrgSiteList(id, currentListInfo as OrgSiteListType, selectedSite as SiteType));
        }
    }

    return {
        allSites: orgSitesList?.allList.map((item) => ({
            id: item.id,
            name: item.name
        })) || [],
        selectedSites: orgSitesList?.selectedList.map((item) => ({
            id: item.id,
            name: item.name
        })) || [],
        updateSelectedSite,
        isLoading
    }
};
