import _cloneDeep from "lodash/cloneDeep";
import { ActionPayload } from "../../common/Base";
import { Client } from "../../common/Client";
import { LoginHelper } from "../../util/LoginHelper";
import { AuthType, LOGIN_ERROR, LOGIN_IS_AUTHENTICATING, LOGIN_USER, LOGOUT_USER, USER_LOGINED } from "./Type";


const initialState: AuthType = {
    userInfo: null,
    error: "",
    isAuthenticating: true,
    isLoading: false,
    isLogined: false
};

export const AuthReducer = (state = initialState, action: ActionPayload<any>): any => {
    const stateClone = _cloneDeep(state);
    switch (action.type) {
        case LOGIN_USER: {
            stateClone.userInfo = action.payload;
            stateClone.isAuthenticating = false;
            return stateClone;
        }
        case LOGIN_IS_AUTHENTICATING: {
            stateClone.isAuthenticating = action.payload;
            return stateClone;
        }
        case LOGIN_ERROR: {
            stateClone.error = action.payload;
            return stateClone;
        }
        case LOGOUT_USER: {
            stateClone.userInfo = null;
            stateClone.isAuthenticating = false;
            stateClone.isLogined = false;
            return stateClone;
        }
        case USER_LOGINED: {
            stateClone.isLogined = action.payload;
            return stateClone;
        }
        default: {
            return state;
        }
    }
};
