import { Grid } from '@mui/material';
import { FC } from 'react'
import { portfolioType } from '../../constants/DummyData'
import { Heading3, Paragraph2 } from '../commoncomponents/designs/Text';
import { SiteImage } from './Style';
import NoImage from "../../images/dummyimages/noimgproperty.svg";
import { SiteType } from '../../store/sites/Type';
import { format } from 'date-fns';

type SiteInfoProps = {
    siteInfo?: SiteType;
}
const SiteInfo: FC<SiteInfoProps> = ({
    siteInfo
}) => {
    if (!siteInfo) {
        return null;
    }

    const { name, invoiceImageUrl, created } = siteInfo;

    return (
        <Grid container mt={"50px"}>
            <Grid item xs={12}>
                <SiteImage src={invoiceImageUrl || NoImage} />
                <Grid container justifyContent={"flex-end"} mt={"50px"}>
                    <Grid item xs={12}>
                        <Grid container justifyContent={"center"}>
                            <Grid>
                                <Heading3 textAlign={"center"}>{name}</Heading3>
                                <Paragraph2 textAlign={"center"}>{format(new Date(created), "dd MMM yyyy")}</Paragraph2>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SiteInfo