import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../constants/Colors';
import { CardContainer } from '../commoncomponents/CommonStyle';
import { Heading2, Heading3, Paragraph1, StyledLink } from '../commoncomponents/designs/Text';
import SignupForm from './SignupForm';

const Signup = () => {
    const navigate = useNavigate();
    return (
        <Grid container alignItems={"center"} justifyContent={"center"}>
            <Grid mt={"50px"} item xs={11} md={4}>
                <CardContainer elevation={0}>
                    <Heading3 mb={"30px"} textAlign={"center"}>REVOLVE ENERGY</Heading3>
                    <Heading2 mb={"30px"} textAlign={"center"}>Sign up</Heading2>
                    <Heading3 mb={"30px"} textAlign={"center"}>Sign up with your personal details</Heading3>
                    <SignupForm />
                    <Paragraph1 mt={"20px"} color={colors.primary.light}>Already have an account?{" "}
                        <StyledLink onClick={() => navigate("/register")}>Login</StyledLink>
                    </Paragraph1>
                </CardContainer>
            </Grid>
        </Grid>
    );
};

export default Signup;