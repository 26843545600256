import { Grid } from '@mui/material';
import React, { FC, useState } from 'react'
import { MdArrowBackIos } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { colors } from '../../../constants/Colors';
import { SiteType } from '../../../store/sites/Type';
import { PrimaryButton, TextButton } from '../../commoncomponents/designs/Button';
import OptionalActionPopup from './OptionalActionPopup';

type ManagerPropertyHeaderProps = {
    propertyInfo: SiteType;
    hideAction?: boolean;
}
const ManagerPropertyHeader: FC<ManagerPropertyHeaderProps> = ({
    propertyInfo,
    hideAction = false
}) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { name } = propertyInfo;
    const navigate = useNavigate();
    const { id } = useParams<{
        id: string
    }>();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Grid>
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={7}>
                    <TextButton
                        onClick={() => navigate(`/site/${id}/info`)}
                        buttonview='sm' label={name} icon={<MdArrowBackIos style={{ fontSize: 14 }} />} />
                </Grid>
                {
                    !hideAction && (
                        <Grid item>
                            <PrimaryButton onClick={handleClick} textcolor={colors.secondary.neutral} backgroundcolor={colors.primary.dark} buttonview='sm' label='OPTIONAL ACTIONS' />
                        </Grid>
                    )
                }
            </Grid>
            <OptionalActionPopup
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
            />
        </Grid>
    )
}

export default ManagerPropertyHeader;