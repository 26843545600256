import Styled from '@emotion/styled';
import { Grid } from '@mui/material';
import { colors } from '../../constants/Colors';

export const PopMenuContainer = Styled(Grid)`
    border-radius: 16px;
    p {
        color: ${colors.secondary.neutral};
        cursor: pointer;
    }
`;

export const MenuContainer = Styled.div`
    & .MuiTooltip-tooltip {
        background: ${colors.primary.dark};
        border-radius: 16px;
        padding: 16px;
    }
`;