import { Grid } from '@mui/material';
import React, { useState } from 'react'
import { MdArrowBackIos } from 'react-icons/md';
import { useNavigate, useParams } from 'react-router-dom';
import { colors } from '../../../constants/Colors';
import { PrimaryButton, TextButton } from '../../commoncomponents/designs/Button';
import OptionActionPopup from './OptionActionPopup';

const ManageSiteHeader = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const { id } = useParams<{
        id: string
    }>();

    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <Grid mt={"30px"}>
            <Grid container justifyContent={"space-between"}>
                <Grid item xs={7}>
                    <TextButton onClick={() => navigate("/")} buttonview='sm' label={"Portfolio"} icon={<MdArrowBackIos style={{ fontSize: 14 }} />} />
                </Grid>
                <Grid item>
                    <PrimaryButton onClick={handleClick} textcolor={colors.secondary.neutral} backgroundcolor={colors.primary.dark} buttonview='sm' label='OPTIONAL ACTIONS' />
                </Grid>
            </Grid>
            <OptionActionPopup
                siteId={id as string}
                anchorEl={anchorEl}
                handleClose={() => setAnchorEl(null)}
            />
        </Grid>
    )
}

export default ManageSiteHeader;