import './App.css';
import { BrowserRouter } from 'react-router-dom';
import Main from './components/main/Main';
import { Provider } from "react-redux";
import { store } from './store/Store';
import SiteRoutes from './protectedroute/SiteRoutes';
import { MapContextDataProvider } from './context/MapContext';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <MapContextDataProvider>
          <Main>
            <ToastContainer />
            <SiteRoutes />
          </Main>
        </MapContextDataProvider>
      </BrowserRouter>
    </Provider>
  );
}

export default App;
