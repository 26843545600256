import { Grid } from '@mui/material';
import { FC } from 'react'

type RightSectionProps = {
    children?: React.ReactNode;
}
const RightSection: FC<RightSectionProps> = ({ children }) => (
    <Grid mt={"200px"}>
        {children}
    </Grid>
);

export default RightSection