import { toast } from "react-toastify";
import { object, string } from "yup";
import { Client } from "../../common/Client";
import { sendHelpANdFeedbackMessageApiUrl } from "../../constants/ApiConstants";
import { ToastSuccessConfig } from "../../constants/SiteConstants";
import { HelpInFeedbackFormType } from "./Type";
import "yup-phone";

export const helpFeedbackInitialValue: HelpInFeedbackFormType = {
    message: "",
};

const phoneSchema = string().phone();

export const helpFeedbackValidationSchema = object().shape({
    message: string().min(1, "Message must be greater the 1 character").max(500, "Message must be less then 500 character").required("Please enter message"),
});

export const updateHelpAndFeedbackValue = (values: HelpInFeedbackFormType) => Client.getInstance().createData(sendHelpANdFeedbackMessageApiUrl, {
    message: values.message,
})
    .then((res) => {
        toast.success(res.data.message, ToastSuccessConfig);
        return Promise.resolve();
    })
    .catch(() => Promise.reject());