import { useEffect } from "react"
import { useDispatch } from "react-redux";
import { getOrgList } from "../../store/organisation/ActionCreator";

export const OrganisationHooks = () => {
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOrgList());
    }, []);
    return {};
};
