import Styled from "@emotion/styled";
import { Button, Grid, Paper } from "@mui/material";
import { colors } from "../../constants/Colors";
import { fontFamily } from "../../constants/FontFamily";

export const CardContainer = Styled(Paper)`
    padding: 14px;
    background: ${colors.secondary.neutral};
    border: 1px solid ${colors.secondary.light};
    border-radius: 6px;
`;

export const StyledCustomDatePicker = Styled.div`
 & .react-datepicker {
  background: ${colors.secondary.neutral};
  border: 1px solid #E5E7EB;
  box-shadow: 0px 4px 6px rgba(17, 24, 39, 0.05), 0px 12px 16px rgba(17, 24, 39, 0.1);
  border-radius: 6px;
 }
 & .react-datepicker__header {
  background: ${colors.secondary.neutral};
  border: 0px;
 }
  
& .react-datepicker__day {
  font-family: ${fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  padding: 5px;
  line-height: 26px;
  text-align: center;
  color: ${colors.primary.dark};
  }

& .react-datepicker__day--keyboard-selected {
  background: ${colors.primary.dark};
  border-radius: 100px;
  font-family: ${fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  padding: 5px;
  text-align: center;
  color: ${colors.secondary.neutral};
  }

& .react-datepicker__day--selected:hover, & .react-datepicker__day--selected {
  background: ${colors.primary.dark};
  border-radius: 100px;
  font-family: ${fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 26px;
  padding: 5px;
  text-align: center;
  color: ${colors.secondary.neutral};
}
  & .react-datepicker__day-name {
    padding: 5px;
    font-family: ${fontFamily.primary};
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 26px;
    align-items: center;
    text-align: center;
    color: ${colors.primary.dark};
  }

  & .react-datepicker__day--disabled {
    color: gray;
  }
`

export const MonthText = Styled.span`
  font-family: ${fontFamily.primary};
font-style: normal;
font-weight: 600;
font-size: 21.33px;
line-height: 26px;
align-items: center;
color: ${colors.primary.dark};
`;

export const CancelDateSelectButton = Styled(Button)`
  border: 1px solid ${colors.primary.neutral};
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 6px;
  font-family: ${fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.tertiary.dark};
`;

export const ApplyDateSelectButton = Styled(Button)`
  background: ${colors.primary.dark};
  border-radius: 6px;
  font-family: ${fontFamily.primary};
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${colors.secondary.neutral};
  &:hover {
    opacity: 0.8;
    background: ${colors.primary.dark};
  }
`;

export const DatePickerHeaderContainer = Styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #E5E7EB;
`;

type TextFieldContainerProps = {
  isnotmultiLine?: boolean;
}
export const TextFieldContainer = Styled.div<TextFieldContainerProps>`
  
  width: 100%;
  height: 100%;

  & .MuiFormControl-root{
    height: 100%;
  }

  & .MuiOutlinedInput-root {
    background: ${colors.secondary.neutral};
    ${(props) => props.isnotmultiLine && `
      height: 56px
    `}
  }
  input {
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    color: #000000;
  }
  & .Mui-disabled {
    -webkit-text-fill-color: #000000 !important;
  }
`;


export const StyledDivider = Styled.hr`
    border: 1px solid ${colors.primary.neutral};
`;

export const SecondaryContainer = Styled(Grid)`
    background: #F0F3F7;
    border-radius: 6px;
    padding: 20px;
`;

export const LogoContainer = Styled.div`
  display: flex;
  align-items: center;
  gap: 30px;
`;

export const StyledLogo = Styled.img`
    object-fit: contain;
    border-radius: 10px;
    height: 200px;
    width: 100%;
    aspect-ratio: 16/9;
`;
