import { Avatar, ClickAwayListener, Grid, IconButton, Tooltip, useMediaQuery, useTheme } from '@mui/material';
import { FC, useState } from 'react'
import { BiMenuAltLeft } from 'react-icons/bi';
import { colors } from '../../constants/Colors';
import { Paragraph1, Paragraph2 } from '../commoncomponents/designs/Text';
import { UserInfoCard, UserInfoContainer } from '../commoncomponents/sitesection/Style';
import ProfileImage from "../../images/profileimage.svg";
import MobileMain from './mobile/MobileMain';
import SideDrawer from './mobile/SideDrawer';
import PopUpMenu from './PopUpMenu';
import { MenuContainer } from './Style';
import { useSelector } from 'react-redux';
import { useUserInfo } from '../../store/auth/Selector';
import { useGetProfileImage } from '../../store/profile/Selector';

type MainProps = {
    children?: React.ReactNode;
}
const Main: FC<MainProps> = ({ children }) => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const ipadView = useMediaQuery(theme.breakpoints.down(1200));
    const [openDrawer, setOpenDrawer] = useState(false);
    const userInfo = useSelector(useUserInfo);
    const profileImage = useSelector(useGetProfileImage);

    if (mobileView) {
        return (
            <MobileMain>
                {children}
            </MobileMain>
        )
    }

    if (ipadView) {
        return (
            <Grid padding={"35px 30px 30px 30px"}>
                {
                    userInfo && !["/forgotpassword", "/confirm"].includes(window.location.pathname) &&
                    <UserInfoCard
                        elevation={0}>
                        <Grid alignItems={"center"} gap={"30px"} container justifyContent={"space-between"}>
                            <BiMenuAltLeft onClick={() => setOpenDrawer(true)} style={{ fontSize: 24 }} />
                        </Grid>
                    </UserInfoCard>
                }
                {
                    openDrawer && <SideDrawer handleClose={() => setOpenDrawer(false)} />
                }
                {children}
            </Grid>
        )
    };

    const renderUserInfoCard = () => {
        return (
            <UserInfoCard
                elevation={0}>
                <Grid alignItems={"center"} gap={"30px"} container justifyContent={"space-between"}>
                    <UserInfoContainer>
                        <Avatar sx={{ width: 56, height: 56 }} src={profileImage || ProfileImage} />
                        <Grid>
                            <Paragraph1 color={colors.primary.light}>{userInfo?.email || ""}</Paragraph1>
                            <Paragraph2>Revolve Energy</Paragraph2>
                        </Grid>
                    </UserInfoContainer>
                    <ClickAwayListener onClickAway={() => setOpenDrawer(false)}>
                        <MenuContainer>
                            <Tooltip
                                title={<PopUpMenu closeMenu={() => setOpenDrawer(false)} />}
                                PopperProps={{
                                    disablePortal: true,
                                }}
                                placement={"bottom-start"}
                                open={openDrawer}
                                disableFocusListener
                                disableHoverListener
                                disableTouchListener
                                arrow
                            >
                                <IconButton onClick={() => {
                                    setOpenDrawer(true);
                                }}>
                                    <BiMenuAltLeft cursor={"pointer"} style={{ fontSize: 24 }} />
                                </IconButton>
                            </Tooltip>
                        </MenuContainer>
                    </ClickAwayListener>
                </Grid>
            </UserInfoCard>
        );
    };

    return (
        <Grid padding={"35px 30px 30px 30px"}>
            {
                userInfo && !["/forgotpassword", "/confirm"].includes(window.location.pathname) && renderUserInfoCard()
            }
            {children}
        </Grid>
    );
};

export default Main