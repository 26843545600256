export const colors = {
    primary: {
        dark: "#262A36",
        light: "#73738C",
        neutral: "#D1D5DB",
        neutral800: "#1F2937",
        neutral900: "#111827"
    },
    secondary: {
        dark: "#F5F8FB",
        light: "#F0F3F7",
        neutral: "#FFFFFF",
        neutral800: "#111827"
    },
    tertiary: {
        dark:"#374151",
        success: "#F0FDF4",
        warning: "#FEF2F2",
        info: "#FEFAEC",
        title: "#F5F5FF",
        titleText: "#0408E7",
        save:  "F0FDF4",
        saveText: "#166534",
        warningLight: "#FFFBEB",
        warningText: "#92400E",
        link: "#2077d3",
        warningMessage: "#B45309",
        errorLight: "FEF2F2",
        errorTitle: "#991B1B",
        errorMessage: "#B91C1C"
    }
};
