import Styled from "@emotion/styled";
import { Grid, Typography } from "@mui/material";
import { colors } from "../../../constants/Colors";
import { fontFamily } from "../../../constants/FontFamily";

type ColumnsTextProps = {
    ismobile?: boolean;
}
export const ColumnsText = Styled(Typography) <ColumnsTextProps>`
    font-family: ${fontFamily.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    ${(props) => props.ismobile && `
        font-size: 12px;
        line-height: 15px;
    `}
    display: flex;
    align-items: center;
    gap: 20px;
    color: ${colors.primary.dark};
`;

export const ColumnAction = Styled.div`
    display: flex;
    gap: 10px;
`;


export const ColumnHeader = Styled(Typography)`
    font-family: ${fontFamily.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    color: ${colors.primary.dark};
`;

export const StyledGrid = Styled(Grid)`
    & .MuiDataGrid-cell {
        border-bottom: 1px solid #E5E7EB;
        outline: none !important;
    }
    & .MuiDataGrid-columnHeader {
        outline: none !important;
    }
    & .MuiDataGrid-root {
        border: 0px !important;
    }
`;
