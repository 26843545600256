import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updateSelectedOrgDetail } from "../../../store/organisation/Action";
import { getOrgDetail, getOrgImage } from "../../../store/organisation/ActionCreator";
import { useGetSelectedOrgDetail } from "../../../store/organisation/Selector";

export const useEditOrganisationHook = () => {
    const [selectedTab, setSelectedTab] = useState('settings');
    const orgInfo = useSelector(useGetSelectedOrgDetail);
    const dispatch = useDispatch();
    const { id } = useParams<{
        id: string
    }>();

    useEffect(() => {
        if (id) {
            dispatch(getOrgDetail(id))
                .then(() => dispatch(getOrgImage(id)));
        }
        return () => {
            dispatch(updateSelectedOrgDetail());
        }
    }, []);

    return {
        selectedTab,
        setSelectedTab,
        orgInfo,
        id
    }
};
