import { ApiState } from "../CommonType";
import { DatumStreamType } from "../sites/Type";

export interface SiteServiceType extends ApiState {
    serviceList: ServiceListType[];
}

export interface ServiceListType {
    category: {
        created: string;
        id: number;
        modified: string;
        name: string,
        siteId: number;
    },
    id: number;
    billingType: string;
    chronoUnits: string;
    created: string;
    description: string;
    enabled: boolean;
    modified: string;
    name: string;
    siteId: number;
    tariffKind: {
        created: string;
        id: number;
        modified: string;
        name: string;
        organisationId: number;
    },
    unitRate: number;
    units: {
        created: string;
        id: number;
        modified: string;
        name: string;
        organisationId: number;
    },
    xeroDetails: {
        accountCode: string,
        created: string;
        id: number;
        modified: string;
        taxType: string;
    },
    dataEnum?: DatumStreamType
    invoiceTransactionDescription: string;
};

export const UPDATE_SITE_SERVICE_LIST = "UPDATE_SITE_SERVICE_LIST";