import { ApiState } from "../CommonType";

export interface ProfileInfoType extends ApiState {
    profileInfo: ProfileFormValueType | undefined;
}

export interface ProfileFormValueType {
    image: File | null;
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    password: string;
    imageUrl: string;
    role: string;
}

export const UPDATE_PROFILE_DATA = "UPDATE_PROFILE_DATA";
export const UPDATE_PROFILE_IMAGE = "UPDATE_PROFILE_IMAGE";
