import Styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { colors } from "../../constants/Colors";

type SiteCardContainerProps = {
    isdisabled: number;
}
export const SiteCardContainer = Styled(Paper) <SiteCardContainerProps>`
    cursor: pointer;
    border: 1px solid ${colors.secondary.light};
    ${props => props.isdisabled === 0 && `
        background: ${colors.secondary.dark} !important;
    `}
    img {
        width: 100%;
        object-fit: contain;
        border-radius: 4px;
    }
    &:hover {
        box-shadow: 0px 4px 6px -1px rgba(16, 24, 40, 0.1), 0px 2px 4px -2px rgba(16, 24, 40, 0.1);
    }
`;