import { Grid } from '@mui/material'
import React, { FC, useState } from 'react'
import { BiMenuAltLeft } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import Logo from "../../../images/logo.jpg";
import { useUserInfo } from '../../../store/auth/Selector';
import SideDrawer from './SideDrawer';

type MobileMainProps = {
    children?: React.ReactNode;
}
const MobileMain: FC<MobileMainProps> = ({ children }) => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const userInfo = useSelector(useUserInfo);

    return (
        <Grid p={"20px"}>
            {
                userInfo && !["/forgotpassword", "/confirm"].includes(window.location.pathname) && (
                    <Grid mb={"27px"} container justifyContent={"space-between"}>
                        <Grid item>
                            <img src={Logo} style={{ height: 30 }} />
                        </Grid>
                        <Grid item>
                            <BiMenuAltLeft onClick={() => setOpenDrawer(true)} style={{ fontSize: 24 }} />
                        </Grid>
                    </Grid>
                )
            }
            {
                openDrawer && <SideDrawer handleClose={() => setOpenDrawer(false)} />
            }
            {children}
        </Grid>
    )
}

export default MobileMain