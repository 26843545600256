import { Grid } from '@mui/material';
import { Formik } from "formik";
import { PropertySettingInitialValue } from './Util';
import { usePropertySettingHook } from './Hooks';
import EditPropertyForm from './EditPropertyForm';
import { PropertyValidationSchema } from '../../addproperty/Util';

const PropertySettings = () => {

  const { propertyDetails, updatePropertyDetail } = usePropertySettingHook();

  return (
    <Grid>
      {
        <Formik
          initialValues={PropertySettingInitialValue(propertyDetails)}
          enableReinitialize
          validationSchema={PropertyValidationSchema}
          onSubmit={(values, { setSubmitting }) => updatePropertyDetail(values, setSubmitting)}
        >
          {() => <EditPropertyForm />}
        </Formik>
      }
    </Grid>
  );
};

export default PropertySettings;
