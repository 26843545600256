import { CircularProgress, Grid } from '@mui/material'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import ProtectedRoute from '.'
import { Client } from '../common/Client'
import ConfirmUser from '../components/confirmuser'
import ForgotPassword from '../components/forgotpassword'
import HelpAndFeedback from '../components/helpandfeedback'
import Login from '../components/login'
import ManageProperty from '../components/manageproperty'
import AddProperty from '../components/manageproperty/addproperty'
import AddMultipleProperty from '../components/manageproperty/addproperty/AddMultipleProperty'
import ManagePropertyEdit from '../components/manageproperty/managepropertyedit'
import ManageSite from '../components/managesite'
import Organisation from '../components/organisation'
import OrganisationEdit from '../components/organisation/edit'
import Portfolio from '../components/portfolio'
import ResetLink from '../components/resetlink'
import SharedServices from '../components/sharedservice'
import Signup from '../components/signup'
import SiteServices from '../components/siteservices'
import SiteSettings from '../components/sitesettings'
import CurrentUser from '../components/user/currentusers'
import PastUsers from '../components/user/pastusers'
import UserProfile from '../components/userprofile'
import XeroSuccess from '../components/xerosuccess'
import { ToastErrorConfig } from '../constants/SiteConstants'
import { loginIsAuthenticating, loginUser, setUserLogined } from '../store/auth/Action'
import { useGetUserRole, useLoginAuthenticating, useUserInfo, useUserLogined } from '../store/auth/Selector'
import { getLookupDropdowns } from '../store/lookup/ActionCreator'
import { getOrgList } from '../store/organisation/ActionCreator'
import { getSiteList } from '../store/sites/ActionCreator'
import { getUserList } from '../store/user/ActionCreator'
import { LoginHelper } from '../util/LoginHelper'
import { doesLoggedinUserHavePermission } from '../util/SiteUtil'
import { PERMISSIONS, USER_ROLES } from '../util/Type'
import AddMultipleService from '../components/siteservices/AddMultipleService'

const SiteRoutes = () => {
    const dispatch = useDispatch();
    const currentUserDetail = useSelector(useUserInfo);
    const isAuthenticating = useSelector(useLoginAuthenticating);
    const role = useSelector(useGetUserRole);
    const navigate = useNavigate();
    const isLogined = useSelector(useUserLogined);

    useEffect(() => {
        const userInfo = LoginHelper.getUser();
        if (userInfo || isLogined) {
            dispatch(loginUser({
                email: userInfo.email,
                userRole: userInfo.userRole
            }));
            Client.getInstance().setAuthenticationClient(userInfo.email, userInfo.password);
            dispatch(setUserLogined(true));
            dispatch(getSiteList());
            dispatch(getUserList());
            dispatch(getLookupDropdowns());
            dispatch(getOrgList());
        }
        dispatch(loginIsAuthenticating(false));
    }, [isLogined]);

    if (isAuthenticating) {
        return (
            <Grid container style={{ height: "100vh" }} justifyContent={"center"} alignItems={"center"}>
                <CircularProgress />
            </Grid>
        );
    }

    const checkValidRoute = (
        userRole: string,
        component: JSX.Element
    ) => {
        if (!doesLoggedinUserHavePermission(role, userRole, PERMISSIONS.VIEW)) {
            return <Navigate to={"/portfolio"} />
        }
        return component;
    };

    return (
        <Routes>
            <Route path="/" element={<Navigate to={currentUserDetail ? "/portfolio" : "/login"} />} />
            <Route path="/login" element={currentUserDetail ? <Navigate to={"/portfolio"} /> : <Login />} />
            <Route path="/register" element={<Signup />} />
            <Route path="/forgotpassword" element={<ForgotPassword />} />
            <Route path="/resetPassword" element={<ResetLink />} />
            <Route path="/portfolio" element={<ProtectedRoute children={<Portfolio />} />} />
            <Route path="/site/:id/info" element={<ProtectedRoute children={<ManageSite />} />} />
            <Route path="/site/:id/usage" element={<ProtectedRoute children={<AddMultipleService />} />} />
            <Route path="/site/:id/manageProperties" element={
                checkValidRoute(
                    USER_ROLES.PROPERTY,
                    <ProtectedRoute children={<ManageProperty />} />
                )}
            />
            <Route path="/site/:id/manageProperties/:homeid/edit" element={
                checkValidRoute(
                    USER_ROLES.PROPERTY,
                    <ProtectedRoute children={<ManagePropertyEdit />} />
                )}
            />
            <Route path="/site/:id/manageProperties/add" element={
                checkValidRoute(
                    USER_ROLES.PROPERTY,
                    <ProtectedRoute children={<AddProperty />} />
                )}
            />
            <Route path="/site/:id/manageProperties/addmultiple" element={
                checkValidRoute(
                    USER_ROLES.PROPERTY,
                    <ProtectedRoute children={<AddMultipleProperty />} />
                )}
            />
            <Route path="/profile" element={<ProtectedRoute children={<UserProfile />} />} />
            <Route path="/site/:id/settings" element={
                checkValidRoute(
                    USER_ROLES.SITE_SETTINGS,
                    <ProtectedRoute children={<SiteSettings />} />
                )}
            />
            <Route path="/site/:id/services" element={
                checkValidRoute(
                    USER_ROLES.SERVICE_FOR_SITE,
                    <ProtectedRoute children={<SiteServices />} />
                )}
            />
            <Route path="/site/:id/sharedservices" element={
                checkValidRoute(
                    USER_ROLES.SHARED_SERVICE,
                    <ProtectedRoute children={<SharedServices />} />
                )}
            />
            <Route path="/users/current" element={
                checkValidRoute(
                    USER_ROLES.MANAGE_USER,
                    <ProtectedRoute children={<CurrentUser />} />
                )}
            />
            <Route path="/users/past" element={
                checkValidRoute(
                    USER_ROLES.MANAGE_USER,
                    <ProtectedRoute children={<PastUsers />} />
                )}
            />
            <Route path="/organisation" element={
                checkValidRoute(
                    USER_ROLES.ORGANISATION_MANAGEMENT,
                    <ProtectedRoute children={<Organisation />} />
                )}
            />
            <Route path="/organisation/add" element={
                checkValidRoute(
                    USER_ROLES.ORGANISATION_MANAGEMENT,
                    <ProtectedRoute children={<OrganisationEdit />} />
                )}
            />
            <Route path="/organisation/:id/edit" element={
                checkValidRoute(
                    USER_ROLES.ORGANISATION_MANAGEMENT,
                    <ProtectedRoute children={<OrganisationEdit />} />
                )}
            />
            <Route path="/confirm" element={<ProtectedRoute children={<ConfirmUser />} />} />
            <Route path="/help" element={<ProtectedRoute children={<HelpAndFeedback />} />} />
            <Route path="/xerosuccess" element={<ProtectedRoute children={<XeroSuccess />} />} />
        </Routes>
    )
}

export default SiteRoutes