import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../store/auth/Action";
import { removeProfileImageAction, updateProfileDataAction, updateProfileImageAction } from "../../store/profile/ActionCreator";
import { useGetProfileData } from "../../store/profile/Selector";
import { ProfileFormValueType } from "../../store/profile/Type";
import { useGetUserListLoading } from "../../store/user/Selector";
import { LoginHelper } from "../../util/LoginHelper";

export const useProfileHook = () => {
    const profileInfo = useSelector(useGetProfileData);
    const isLoading = useSelector(useGetUserListLoading);
    const dispatch = useDispatch();

    const profileUpdate = (values: ProfileFormValueType, setSubmitting: (isSubmitting: boolean) => void) => {
        dispatch(updateProfileDataAction(values))
            .then(() => {
                if (values.password === "") {
                    LoginHelper.setUserSession({
                        email: values.email,
                        password: values.password,
                        userRole: "SUPER_ADMIN"
                    });
                } else {
                    dispatch(logoutUser());
                }
                setSubmitting(false);
            })
    }

    return {
        profileInfo,
        isLoading,
        profileUpdate
    }
}

export const useProfileFormHook = (value: ProfileFormValueType) => {
    const dispatch = useDispatch();
    const [percent, setUploadPercent] = useState(0);

    useEffect(() => {
        value.image && dispatch(updateProfileImageAction(value, setUploadPercent));
    }, [value.image]);

    const removeProfileImage = (values: ProfileFormValueType, setValue: (values: unknown, shouldValidate?: boolean | undefined) => void) => {
        dispatch(removeProfileImageAction(values.id.toString())).then(() => {
            setValue({
                ...values,
                imageUrl: '',
                image: null
            });
        });
    };

    return {
        percent,
        removeProfileImage
    }
};
