import { Box, Dialog, Grid, IconButton, InputAdornment } from "@mui/material";
import { Formik, FormikErrors } from "formik";
import { FC, useState } from "react";
import {
  AiOutlineCalendar,
  AiOutlineDollarCircle,
  AiOutlineFileAdd,
} from "react-icons/ai";
import { BsCloud, BsCodeSlash } from "react-icons/bs";
import { RiCloseCircleLine } from "react-icons/ri";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import { colors } from "../../../../../constants/Colors";
import { VALID_CSV_DOCUMENT } from "../../../../../constants/SiteConstants";
import { useGetUserRole } from "../../../../../store/auth/Selector";
import { ServiceListType } from "../../../../../store/siteservice/Type";
import { doesLoggedinUserHavePermission } from "../../../../../util/SiteUtil";
import { PERMISSIONS, USER_ROLES } from "../../../../../util/Type";
import { SecondaryContainer } from "../../../../commoncomponents/CommonStyle";
import { CustomTextfieldWrapper } from "../../../../commoncomponents/CustomTextfieldWrapper";
import { PrimaryButton } from "../../../../commoncomponents/designs/Button";
import TabWrapper from "../../../../commoncomponents/designs/TabWrapper";
import {
  AddText,
  Heading3,
  Paragraph1,
  PlaceholderText,
  StyledLink,
} from "../../../../commoncomponents/designs/Text";
import FileDrop from "../../../../commoncomponents/fields/FileUpload";
import { FormikSelect } from "../../../../commoncomponents/fields/FormikSelect";
import ImageUploadModal from "../../../../commoncomponents/ImageUploadModal";
import CreateCategoryModal from "./CreateCategoryModal";
import CreateUnitModal from "./CreateUnitModal";
import { useUpdateServiceHook } from "./Hooks";
import { ServiceSettingType } from "./Type";
import {
  ServiceSettingInitialValue,
  ServiceValidationSchema,
  tabOptions,
} from "./Util";
import AvailaibleInvoiceStatusPopup from "../../../../sitesettings/AvailaibleInvoiceStatusPopup";

type ServiceUpdateProps = {
  handleClose: () => void;
  selectedServiceInfo?: ServiceListType;
  hideTariff?: boolean;
  isMeteredSettings?: boolean;
};

const ServiceUpdate: FC<ServiceUpdateProps> = ({
  handleClose,
  selectedServiceInfo,
  hideTariff,
  isMeteredSettings,
}) => {
  const {
    serviceBillingDropdown,
    serviceCategoryDropdown,
    xeroAccountDropdown,
    unitDropdown,
    handleUpdateSubmit,
    handleDownloadTemplate,
    percent,
    isFileUploading,
    setCategoryModal,
    categoryModal,
    selectedSiteDetail,
    serviceFixedUnitDropdown,
    openUnitModal,
    setOpenUnitModal,
    tariffDropdown,
    availaibleInvoiceStatusDropdown,
  } = useUpdateServiceHook(handleClose);

  const role = useSelector(useGetUserRole);
  const [anchorAvailaibleStatusEl, setAnchorAvalaibleStatusEl] =
    useState<HTMLAnchorElement | null>(null);
  const renderServiceUpload = (
    values: ServiceSettingType,
    setFieldValue: (field: string, value: any) => void,
    errors: FormikErrors<ServiceSettingType>
  ) => {
    if (values.selectedTariffTab === "simple") {
      return (
        <Grid container spacing={"20px"}>
          {!["Manual", "Metered"].includes(values.simpleTarif.billingType) && (
            <Grid item xs={12}>
              <Grid container spacing={"20px"} alignItems={"baseline"}>
                <Grid item xs={12} md={6}>
                  <FormikSelect
                    name={"simpleTarif.standingCharge"}
                    options={serviceFixedUnitDropdown}
                    label={"Fixed units"}
                    id={"simpleTarif.standingCharge"}
                    startAdornment={
                      <InputAdornment position="start">
                        <AiOutlineFileAdd style={{ marginRight: 10 }} />
                      </InputAdornment>
                    }
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextfieldWrapper
                    name={"simpleTarif.standingChargeUnit"}
                    id={"simpleTarif.standingChargeUnit"}
                    fullWidth
                    label={"Fixed units rate"}
                    placeholder="Unit rate"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AiOutlineFileAdd style={{ marginRight: 10 }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          {selectedSiteDetail?.currencyCode || ""}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
          {values.simpleTarif.billingType !== "Recurring" && (
            <Grid item xs={12}>
              <Grid container spacing={"20px"} alignItems={"baseline"}>
                <Grid item xs={12} md={6}>
                  <FormikSelect
                    name={"simpleTarif.standingUnitRate"}
                    options={unitDropdown}
                    label={"Variable units"}
                    id={"simpleTarif.standingUnitRate"}
                    startAdornment={
                      <InputAdornment position="start">
                        <AiOutlineFileAdd style={{ marginRight: 10 }} />
                      </InputAdornment>
                    }
                    helperText={"helper"}
                  />
                  <AddText
                    iserror={!!errors?.simpleTarif?.standingUnitRate}
                    onClick={() => setOpenUnitModal(true)}
                    color={colors.tertiary.link}
                  >
                    Create unit
                  </AddText>
                </Grid>
                <Grid item xs={12} md={6}>
                  <CustomTextfieldWrapper
                    name={"simpleTarif.standingUnits"}
                    id={"simpleTarif.standingUnits"}
                    fullWidth
                    disabled={
                      !doesLoggedinUserHavePermission(
                        role,
                        USER_ROLES.SERVICE_TARIFFS,
                        PERMISSIONS.EDIT
                      )
                    }
                    label={"Variable units rate"}
                    placeholder="Unit rate"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AiOutlineFileAdd style={{ marginRight: 10 }} />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="start">
                          {selectedSiteDetail?.currencyCode || ""}
                        </InputAdornment>
                      ),
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      );
    }

    return (
      <>
        {!["Manual", "Metered"].includes(values.simpleTarif.billingType) && (
          <Grid item xs={12}>
            <Grid container spacing={"20px"} alignItems={"baseline"}>
              <Grid item xs={12} md={6}>
                <FormikSelect
                  name={"simpleTarif.standingCharge"}
                  options={serviceFixedUnitDropdown}
                  label={"Fixed units"}
                  id={"simpleTarif.standingCharge"}
                  startAdornment={
                    <InputAdornment position="start">
                      <AiOutlineFileAdd style={{ marginRight: 10 }} />
                    </InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )}
        {values.simpleTarif.billingType !== "Recurring" && (
          <Grid item xs={12}>
            <Grid container spacing={"20px"} alignItems={"baseline"}>
              <Grid item xs={12} md={6}>
                <FormikSelect
                  name={"simpleTarif.standingUnitRate"}
                  options={unitDropdown}
                  label={"Variable units"}
                  id={"simpleTarif.standingUnitRate"}
                  startAdornment={
                    <InputAdornment position="start">
                      <AiOutlineFileAdd style={{ marginRight: 10 }} />
                    </InputAdornment>
                  }
                  helperText={"helper"}
                />
                <AddText
                  iserror={!!errors?.simpleTarif?.standingUnitRate}
                  onClick={() => setOpenUnitModal(true)}
                  color={colors.tertiary.link}
                >
                  Create unit
                </AddText>
              </Grid>
            </Grid>
          </Grid>
        )}
        <Grid item xs={12} mt={"30px"}>
          <Grid
            container
            key={JSON.stringify(values.tou.file)}
            alignItems={values.tou.file ? "center" : "flex-start"}
            justifyContent={"center"}
            spacing={"20px"}
          >
            <Grid item md={4} xs={12}>
              <PrimaryButton
                onClick={() => handleDownloadTemplate(values)}
                textcolor={colors.secondary.neutral}
                backgroundcolor={colors.primary.dark}
                label="Download template"
                fullWidth
              />
            </Grid>
            <Grid item md={4} xs={12}>
              {values.tou.file ? (
                <Grid container>
                  <Grid item xs={10}>
                    <PlaceholderText textAlign={"center"}>
                      {values.tou.file.name}
                    </PlaceholderText>
                  </Grid>
                  <Grid item xs={2}>
                    <PlaceholderText
                      style={{ cursor: "pointer" }}
                      onClick={() => {
                        setFieldValue("tou.file", null);
                      }}
                      textAlign={"center"}
                    >
                      Remove
                    </PlaceholderText>
                  </Grid>
                </Grid>
              ) : (
                <FileDrop
                  buttonLabel="Upload csv"
                  fileSize={5}
                  name={"tou.file"}
                  errorMessage={"Please upload csv only"}
                  validDocumentTypes={VALID_CSV_DOCUMENT}
                />
              )}
            </Grid>
          </Grid>
        </Grid>
      </>
    );
  };
  return (
    <Dialog maxWidth={"lg"} fullWidth open onClose={handleClose}>
      <ToastContainer />
      <Grid
        style={{
          pointerEvents: !doesLoggedinUserHavePermission(
            role,
            isMeteredSettings
              ? USER_ROLES.SITE_PROPERTY_SERVICES
              : USER_ROLES.CONFIGURE_SOLAR_NETWORK,
            PERMISSIONS.VIEW
          )
            ? "none"
            : "all",
        }}
        padding={["30px", "60px"]}
      >
        <Grid container justifyContent={"flex-end"}>
          <IconButton onClick={handleClose}>
            <RiCloseCircleLine />
          </IconButton>
        </Grid>
        <Grid>
          <Heading3 textAlign={"center"}>
            {selectedServiceInfo
              ? `${selectedServiceInfo.name} meter settings`
              : "Add service"}
          </Heading3>
          <Grid container justifyContent={"center"}>
            <Grid item xs={12} md={8}>
              <Formik
                initialValues={ServiceSettingInitialValue(selectedServiceInfo)}
                validationSchema={ServiceValidationSchema(isMeteredSettings)}
                onSubmit={(values, { setSubmitting }) =>
                  handleUpdateSubmit(
                    values,
                    setSubmitting,
                    isMeteredSettings,
                    selectedServiceInfo?.id.toString()
                  )
                }
              >
                {({
                  values,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  errors,
                }) => (
                  <Grid container mt={"45px"} spacing={"40px"}>
                    <Grid item xs={12}>
                      <PlaceholderText mb={"10px"} fontFamily={"secondary"}>
                        Name of service
                      </PlaceholderText>
                      <CustomTextfieldWrapper
                        name="name"
                        id="name"
                        fullWidth
                        disabled={isMeteredSettings}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <PlaceholderText mb={"10px"} fontFamily={"secondary"}>
                        Description of service
                      </PlaceholderText>
                      <CustomTextfieldWrapper
                        name="description"
                        id="description"
                        fullWidth
                        disabled={isMeteredSettings}
                        variant="standard"
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={"20px"}>
                        <Grid item xs={12}>
                          <Grid
                            container
                            alignItems={"baseline"}
                            spacing={"20px"}
                          >
                            {isMeteredSettings ? (
                              <Grid
                                style={{
                                  display: !doesLoggedinUserHavePermission(
                                    role,
                                    USER_ROLES.MANUAL_ADD_USAGE,
                                    PERMISSIONS.VIEW
                                  )
                                    ? "none"
                                    : "inherit",
                                }}
                                item
                                xs={12}
                                md={6}
                              >
                                <CustomTextfieldWrapper
                                  name={"sourceId"}
                                  id={"sourceId"}
                                  disabled={
                                    !doesLoggedinUserHavePermission(
                                      role,
                                      USER_ROLES.MANUAL_ADD_USAGE,
                                      PERMISSIONS.EDIT
                                    )
                                  }
                                  label={"Source Id"}
                                  fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AiOutlineCalendar
                                          style={{ marginRight: 10 }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={12} md={6}>
                                <FormikSelect
                                  name={"simpleTarif.billingType"}
                                  options={serviceBillingDropdown}
                                  id={"simpleTarif.billingType"}
                                  label={"Billing type"}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <AiOutlineCalendar
                                        style={{ marginRight: 10 }}
                                      />
                                    </InputAdornment>
                                  }
                                />
                              </Grid>
                            )}
                            <Grid item xs={12} md={6}>
                              <FormikSelect
                                name={"tariffId"}
                                options={tariffDropdown}
                                id={"tariffId"}
                                label={"Tariff Id"}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <AiOutlineDollarCircle
                                      style={{ marginRight: 10 }}
                                    />
                                  </InputAdornment>
                                }
                              />
                            </Grid>

                            {categoryModal && (
                              <CreateCategoryModal
                                handleClose={() => setCategoryModal(false)}
                              />
                            )}
                            {openUnitModal && (
                              <CreateUnitModal
                                handleClose={() => setOpenUnitModal(false)}
                              />
                            )}

                            {isMeteredSettings ? (
                              <Grid
                                style={{
                                  display: !doesLoggedinUserHavePermission(
                                    role,
                                    USER_ROLES.MANUAL_ADD_USAGE,
                                    PERMISSIONS.VIEW
                                  )
                                    ? "none"
                                    : "inherit",
                                }}
                                item
                                xs={12}
                                md={6}
                              >
                                <CustomTextfieldWrapper
                                  name={"metric"}
                                  id={"metric"}
                                  fullWidth
                                  disabled={
                                    !doesLoggedinUserHavePermission(
                                      role,
                                      USER_ROLES.MANUAL_ADD_USAGE,
                                      PERMISSIONS.EDIT
                                    )
                                  }
                                  label={"Metric"}
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <BsCodeSlash
                                          style={{ marginRight: 10 }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={12} md={6}>
                                <FormikSelect
                                  name={"xeroAccountingCode"}
                                  options={xeroAccountDropdown}
                                  id={"xeroAccountingCode"}
                                  label={"Xero Accounting Code"}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <BsCodeSlash
                                        style={{ marginRight: 10 }}
                                      />
                                    </InputAdornment>
                                  }
                                />
                                <PlaceholderText
                                  mt={"8px"}
                                  fontFamily={"secondary"}
                                >
                                  Current Xero accounting codes can be found
                                  here.
                                </PlaceholderText>
                              </Grid>
                            )}
                            {isMeteredSettings ? (
                              <Grid
                                style={{
                                  display: !doesLoggedinUserHavePermission(
                                    role,
                                    USER_ROLES.MANUAL_ADD_USAGE,
                                    PERMISSIONS.VIEW
                                  )
                                    ? "none"
                                    : "inherit",
                                }}
                                item
                                xs={12}
                                md={6}
                              >
                                <CustomTextfieldWrapper
                                  name={"nodeId"}
                                  id={"nodeId"}
                                  disabled={
                                    !doesLoggedinUserHavePermission(
                                      role,
                                      USER_ROLES.MANUAL_ADD_USAGE,
                                      PERMISSIONS.EDIT
                                    )
                                  }
                                  label={"Node id"}
                                  variant={"outlined"}
                                  fullWidth
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <AiOutlineDollarCircle
                                          style={{ marginRight: 10 }}
                                        />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Grid>
                            ) : (
                              <Grid item xs={12} md={6}>
                                <FormikSelect
                                  name={"serviceCategory"}
                                  options={serviceCategoryDropdown}
                                  id={"serviceCategory"}
                                  label={"Service Category"}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <AiOutlineDollarCircle
                                        style={{ marginRight: 10 }}
                                      />
                                    </InputAdornment>
                                  }
                                />
                                <AddText
                                  onClick={() => setCategoryModal(true)}
                                  iserror={!!errors.serviceCategory}
                                  color={colors.tertiary.link}
                                >
                                  Create category
                                </AddText>
                              </Grid>
                            )}
                          </Grid>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <Box>
                            <CustomTextfieldWrapper
                              multiline
                              name={"invoiceTransactionDescription"}
                              id={"invoiceTransactionDescription"}
                              placeholder={
                                "Invoice transaction description composer"
                              }
                              fullWidth
                              InputLabelProps={{
                                shrink: true,
                              }}
                              label={"Invoice transaction description composer"}
                            />
                            <StyledLink
                              color={colors.tertiary.link}
                              onClick={(e) =>
                                setAnchorAvalaibleStatusEl(e.currentTarget)
                              }
                            >
                              See list of available parameters
                            </StyledLink>
                          </Box>
                          {anchorAvailaibleStatusEl && (
                            <AvailaibleInvoiceStatusPopup
                              anchorEl={anchorAvailaibleStatusEl}
                              invoiceTransactionDescription={
                                values.invoiceTransactionDescription
                              }
                              handleClose={() =>
                                setAnchorAvalaibleStatusEl(null)
                              }
                              options={availaibleInvoiceStatusDropdown}
                              setValue={(value) =>
                                setFieldValue(
                                  "invoiceTransactionDescription",
                                  value
                                )
                              }
                            />
                          )}
                        </Grid>
                        {!hideTariff && (
                          <Grid item xs={12} mt={"30px"}>
                            <SecondaryContainer>
                              <Grid container spacing={"20px"}>
                                <Grid item xs={12}>
                                  <TabWrapper
                                    tabOptions={tabOptions}
                                    setValue={(value) =>
                                      setFieldValue("selectedTariffTab", value)
                                    }
                                    value={values.selectedTariffTab}
                                    variant={"secondary"}
                                  />
                                </Grid>
                                <Grid item xs={12}>
                                  {renderServiceUpload(
                                    values,
                                    setFieldValue,
                                    errors
                                  )}
                                </Grid>
                              </Grid>
                            </SecondaryContainer>
                          </Grid>
                        )}
                      </Grid>
                      <Grid item xs={12} mt={"30px"}>
                        <PrimaryButton
                          disabled={
                            !doesLoggedinUserHavePermission(
                              role,
                              isMeteredSettings
                                ? USER_ROLES.SITE_PROPERTY_SERVICES
                                : USER_ROLES.CONFIGURE_SOLAR_NETWORK,
                              PERMISSIONS.VIEW
                            )
                          }
                          isLoading={isSubmitting}
                          onClick={() => handleSubmit()}
                          backgroundcolor={colors.primary.dark}
                          textcolor={colors.secondary.light}
                          fullWidth
                          label={selectedServiceInfo ? "Update" : "Add"}
                          icon={<BsCloud />}
                        />
                      </Grid>
                    </Grid>
                    {isFileUploading && values.tou.file && (
                      <ImageUploadModal
                        progress={percent}
                        file={values.tou.file as File}
                        handleClose={() => setFieldValue("tou.file", null)}
                      />
                    )}
                  </Grid>
                )}
              </Formik>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default ServiceUpdate;
