import { Dialog, Grid, IconButton } from '@mui/material'
import { Formik, useFormikContext } from 'formik';
import { FC } from 'react'
import { AiFillCloud, AiOutlineClose } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { colors } from '../../../../../constants/Colors';
import { addSiteServiceCategory } from '../../../../../store/lookup/ActionCreator';
import { CustomTextfieldWrapper } from '../../../../commoncomponents/CustomTextfieldWrapper';
import { PrimaryButton } from '../../../../commoncomponents/designs/Button';
import { Heading3 } from '../../../../commoncomponents/designs/Text';
import { CategoryInitialValue, CategoryValidationSchema } from './Util';

type CreateCategoryModalProps = {
    handleClose: () => void;
}
const CreateCategoryModal: FC<CreateCategoryModalProps> = ({
    handleClose
}) => {
    const dispatch = useDispatch();
    const { id } = useParams<{
        id: string
    }>();
    const { setFieldValue } = useFormikContext();
    const handleAddCategory = (value: {
        name: string
    }, setSubmitting: (submitting: boolean) => void) => {
        id && dispatch(addSiteServiceCategory(id, value.name))
            .then((id: string) => {
                setFieldValue("serviceCategory", id);
            })
            .finally(() => {
                setSubmitting(false);
                handleClose();
            });
    }
    return (
        <Dialog maxWidth={"sm"} fullWidth open onClose={handleClose}>
            <Grid p={"30px"} container justifyContent={"space-between"}>
                <Grid item xs={6}>
                    <Heading3>Create Category</Heading3>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleClose}>
                        <AiOutlineClose />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid spacing={"20px"} container px={"30px"} pb={"20px"}>
                <Formik
                    initialValues={CategoryInitialValue}
                    validationSchema={CategoryValidationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        handleAddCategory(values, setSubmitting);
                        return;
                    }}
                >
                    {({ isSubmitting, handleSubmit }) => <>
                        <Grid item xs={12}>
                            <CustomTextfieldWrapper
                                name={"name"}
                                id={"id"}
                                label={"Category name"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PrimaryButton
                                fullWidth
                                onClick={() => handleSubmit()}
                                textcolor={colors.secondary.light}
                                backgroundcolor={colors.primary.dark}
                                label='Save'
                                isLoading={isSubmitting}
                                icon={<AiFillCloud />}
                            />
                        </Grid>
                    </>}
                </Formik>
            </Grid>
        </Dialog>
    )
}

export default CreateCategoryModal