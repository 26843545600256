import { Avatar, Drawer, Grid, useMediaQuery, useTheme } from "@mui/material";
import { FC } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { colors } from "../../../constants/Colors";
import { useGetProfileImage } from "../../../store/profile/Selector";
import { Paragraph2Mobile } from "../../commoncomponents/designs/Text";
import { mobileSideDrawer } from "./Util";
import ProfileImage from "../../../images/profileimage.svg";
import { useUserInfo } from "../../../store/auth/Selector";
import { logoutUser } from "../../../store/auth/Action";
import { LoginHelper } from "../../../util/LoginHelper";

type SideDrawerProps = {
  handleClose: () => void;
};
const SideDrawer: FC<SideDrawerProps> = ({ handleClose }) => {
  const theme = useTheme();
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const userImage = useSelector(useGetProfileImage);
  const userInfo = useSelector(useUserInfo);
  const dispatch = useDispatch();

  const handleNavigate = (url: string) => {
    if (url === "/") {
      dispatch(logoutUser());
    }
    navigate(url);
    handleClose();
  };
  return (
    <Drawer
      anchor={"right"}
      open
      PaperProps={{
        style: {
          width: mobileView ? "60%" : "30%",
          backgroundColor: colors.primary.dark,
        },
      }}
      onClose={handleClose}
    >
      <Grid p={"28px"}>
        <Grid container spacing={"21px"}>
          <Grid item xs={12}>
            <Grid container justifyContent={"flex-end"}>
              <Grid item>
                <AiOutlineClose
                  style={{ color: colors.secondary.neutral, fontSize: 20, cursor: "pointer" }}
                  onClick={handleClose}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Avatar
              sx={{ width: 56, height: 56 }}
              src={userImage || ProfileImage}
            />
          </Grid>
          <Grid item xs={12}>
            <Paragraph2Mobile fontWeight={"bold"}>
              {userInfo?.email || ""}
            </Paragraph2Mobile>
            <Paragraph2Mobile fontWeight={"bold"}>
              Revolve Energy
            </Paragraph2Mobile>
          </Grid>
          <Grid item xs={12}>
            {mobileSideDrawer.map((item) => (
              <Grid my={"30px"} key={item.id}>
                <Paragraph2Mobile
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleNavigate(item.url);
                    if (item.action === "logout") {
                      LoginHelper.removeUserSession();
                    }
                  }}
                >
                  {item.label}
                </Paragraph2Mobile>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Drawer>
  );
};

export default SideDrawer;
