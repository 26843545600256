import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { MdAdd } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useGetUserRole } from '../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../util/Type';
import BreadcrumbWrapper from '../commoncomponents/designs/BreadcrumbWrapper';
import { TextButton } from '../commoncomponents/designs/Button';
import SideNavbar from '../commoncomponents/sidenavbar';
import MobileNavbarMenu from '../commoncomponents/sidenavbar/MobileNavbar';
import LeftSection from '../commoncomponents/sitesection/LeftSection';
import { UserSideMenu } from '../user/Util';
import { OrganisationHooks } from './Hooks';
import OrganisationList from './list';
import { OrganisationTabOption } from './Util';

const Organisation = () => {
    const navigate = useNavigate();
    const { } = OrganisationHooks();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const role = useSelector(useGetUserRole);

    if (mobileView) {
        return (
            <Grid>
                <MobileNavbarMenu
                    menuOptions={UserSideMenu(role)}
                    selectedMenuId={4}
                />
                <Grid item xs={7}>
                    <Grid my={"30px"} container justifyContent={"flex-end"}>
                        <TextButton
                            disabled={!doesLoggedinUserHavePermission(role, USER_ROLES.ORGANISATION_MANAGEMENT, PERMISSIONS.CREATE)}
                            onClick={() => navigate("/organisation/add")}
                            label='NEW ORGANISATION' icon={<MdAdd />} />
                    </Grid>
                    <OrganisationList />
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar selectedMenuId={4} sidebarOptions={UserSideMenu(role)} />
                </LeftSection>
            </Grid>
            <Grid item xs={10}>
                <Grid container>
                    <Grid item xs={8}>
                        <Grid container>
                            <Grid item xs={8}>
                                <BreadcrumbWrapper
                                    tabType={OrganisationTabOption}
                                />
                            </Grid>

                            <Grid item xs={4}>
                                <TextButton
                                    disabled={!doesLoggedinUserHavePermission(role, USER_ROLES.ORGANISATION_MANAGEMENT, PERMISSIONS.CREATE)}
                                    onClick={() => navigate("/organisation/add")}
                                    label='NEW ORGANISATION' icon={<MdAdd />} />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid mt={"79.5px"}>
                    <OrganisationList />
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Organisation;