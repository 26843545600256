import { Grid } from '@mui/material';
import { PrimaryButton } from '../../commoncomponents/designs/Button';
import { FC } from "react";
import { colors } from '../../../constants/Colors';
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../util/Type';

type AddItemHeaderProps = {
    handleAddClick: () => void;
}

const AddItemHeader: FC<AddItemHeaderProps> = ({
    handleAddClick
}) => {
    const role = useSelector(useGetUserRole);

    return (
        <Grid my={"30px"}>
            <Grid container justifyContent={"flex-end"}>
                <Grid item>
                    {
                        doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.CREATE) &&
                        <PrimaryButton
                            onClick={handleAddClick}
                            textcolor={colors.secondary.neutral}
                            backgroundcolor={colors.primary.dark}
                            buttonview='sm'
                            label='ADD NEW USER'
                        />
                    }
                </Grid>
            </Grid>
        </Grid>
    )
};

export default AddItemHeader;