import { PropertyCustomerList } from '../../../../store/sites/Type';
import { Paragraph1 } from '../../../commoncomponents/designs/Text'
import { PaperContainer } from '../../Style';
import { FC } from "react";
import { Grid } from '@mui/material';
import { Formik } from 'formik';
import { InfoToast } from '../../../commoncomponents/designs/Toasts';
import { CustomerLisInitialValue } from '../futurecustomer/Util';
import CustomerInfoCard from './CustomerInfoCard';
import { useDispatch } from 'react-redux';
import { updateCustomerDetail } from '../../../../store/sites/ActionCreator';
import { useParams } from 'react-router-dom';
import { getMovOutClearDateState } from './util';

type CurrentCustomerProps = {
    customerList: PropertyCustomerList[];
    allCustomers: PropertyCustomerList[];
}

const CurrentCustomer: FC<CurrentCustomerProps> = ({
    customerList,
    allCustomers
}) => {
    const dispatch = useDispatch();
    const { id, homeid } = useParams<{
        id: string;
        homeid: string
    }>();

    return (
        <PaperContainer elevation={0} padding={30}>
            <Paragraph1>Current Customer</Paragraph1>
            <Grid>
                {
                    customerList.length === 0 ? <Grid mt={"35px"}>
                        <InfoToast message='No customer assigned yet' />
                    </Grid> :
                        customerList.map((item) => (
                            <Formik
                                key={item.id}
                                initialValues={CustomerLisInitialValue(item)}
                                onSubmit={(values) => {
                                    id && homeid && dispatch(updateCustomerDetail(id, homeid, values));
                                }}
                            >
                                {
                                    () => <CustomerInfoCard
                                        dateType='out'
                                        hideRemoveCustomer={!item.hasPublishedInvoice}
                                        showMoveOutClearDate={getMovOutClearDateState(allCustomers, item)}
                                    />
                                }
                            </Formik>
                        ))
                }
            </Grid>
        </PaperContainer>
    )
}

export default CurrentCustomer