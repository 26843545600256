import { Dialog, DialogContent, Grid } from "@mui/material";
import { Formik } from "formik";
import { FC } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { HiOutlineUser } from "react-icons/hi";
import { RiUserAddLine } from "react-icons/ri";
import { useDispatch } from "react-redux";
import { colors } from "../../../constants/Colors";
import { updateUserDetail } from "../../../store/user/ActionCreator";
import { UserType } from "../../../store/user/Type";
import { CustomTextfieldWrapper } from "../../commoncomponents/CustomTextfieldWrapper";
import { PrimaryButton } from "../../commoncomponents/designs/Button";
import { Label } from "../../commoncomponents/designs/Text";
import FormikCheckbox from "../../commoncomponents/fields/FormikCheckbox";
import ImageDrop from "../../commoncomponents/fields/ImageUpload";
import ImageUploadModal from "../../commoncomponents/ImageUploadModal";
import DeleteModal from "../../commoncomponents/modal/DeleteModal";
import { OrgLogo, OrgLogoContainer } from "../../organisation/edit/Style";
import { useUpdateUser } from "./hooks";
import { UpdateUserValidationSchema, userDetailInitialValue } from "./Util";

type UpdateUserProps = {
  handleClose: () => void;
  userInfo: UserType;
};

const UpdateUser: FC<UpdateUserProps> = ({ userInfo, handleClose }) => {
  const dispatch = useDispatch();

  const handleUpdateUser = (
    data: any,
    handleClose: () => void,
    setIsSubmitting: (isSubmitting: boolean) => void
  ) => {
    dispatch(updateUserDetail(data)).then(() => {
      setIsSubmitting(false);
      handleClose();
    });
  };

  return (
    <Dialog maxWidth={"md"} open onClose={handleClose}>
      <DialogContent>
        <Grid p={["10px", "50px"]}>
          <Grid
            container
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Grid item>
              <Grid container alignItems={"center"}>
                <Grid item>
                  <HiOutlineUser style={{ fontSize: 50 }} />
                </Grid>
                <Grid item>
                  <Label>Update User</Label>
                </Grid>
              </Grid>
            </Grid>
            <Grid item>
              <AiOutlineClose
                onClick={handleClose}
                style={{ cursor: "pointer", fontSize: 30 }}
              />
            </Grid>
          </Grid>
          <Formik
            initialValues={userDetailInitialValue(userInfo)}
            validationSchema={UpdateUserValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleUpdateUser(
                {
                  id: userInfo.id,
                  ...values,
                },
                handleClose,
                setSubmitting
              );
            }}
          >
            {({ handleSubmit, values, setFieldValue, isSubmitting }) => {
              const {
                percent,
                openResetPasswordModal,
                handleForgotPassword,
                setOpenResetPasswordModal,
              } = useUpdateUser(userInfo.id, values, setFieldValue);
              return (
                <Grid mt={"50px"}>
                  <Grid mt={"10px"}>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={6}>
                        <CustomTextfieldWrapper
                          name={"firstName"}
                          id={"firstName"}
                          fullWidth
                          label={"First Name"}
                          placeholder="Enter firstname"
                          InputProps={{
                            endAdornment: <RiUserAddLine />,
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <CustomTextfieldWrapper
                          name={"lastName"}
                          id={"lastName"}
                          fullWidth
                          label={"Last Name"}
                          placeholder="Enter lastname"
                          InputProps={{
                            endAdornment: <RiUserAddLine />,
                          }}
                        />
                      </Grid>
                    </Grid>
                    <Grid mt={"10px"}>
                      <CustomTextfieldWrapper
                        name={"email"}
                        id={"email"}
                        fullWidth
                        label={"Email"}
                        placeholder="Type email address here..."
                        InputProps={{
                          endAdornment: <RiUserAddLine />,
                        }}
                      />
                    </Grid>
                    <Grid mt={"10px"}>
                      {values.imageUrl ? (
                        <OrgLogoContainer>
                          <Grid item>
                            <OrgLogo src={values.imageUrl} />
                          </Grid>
                          <Grid item>
                            <Label
                              onClick={() => {
                                setFieldValue("image", null);
                                setFieldValue("imageUrl", "");
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              Remove
                            </Label>
                          </Grid>
                        </OrgLogoContainer>
                      ) : (
                        <ImageDrop
                          name="image"
                          setImageUrl={(url) => setFieldValue("imageUrl", url)}
                          imageSize={1}
                          logoInfo={"Upload a file or drag and drop"}
                          logoSubMessage={"PNG, JPG, GIF up to 1MB"}
                        />
                      )}
                    </Grid>
                    {values.image && (
                      <ImageUploadModal
                        progress={percent}
                        file={values.image as File}
                        handleClose={() => setFieldValue("image", null)}
                      />
                    )}
                    <Grid
                      mt={"40px"}
                      spacing={"10px"}
                      container
                      justifyContent={"flex-end"}
                    >
                      <Grid item xs={6} md={4}>
                        <PrimaryButton
                          onClick={() => setOpenResetPasswordModal(true)}
                          backgroundcolor={colors.primary.dark}
                          textcolor={colors.secondary.light}
                          fullWidth
                          label="Reset password"
                        />
                      </Grid>
                      <Grid item xs={6} md={3}>
                        <PrimaryButton
                          isLoading={isSubmitting}
                          onClick={() => handleSubmit()}
                          backgroundcolor={colors.primary.dark}
                          textcolor={colors.secondary.light}
                          fullWidth
                          label="Update"
                        />
                      </Grid>
                    </Grid>
                    {openResetPasswordModal && (
                      <DeleteModal
                        message={`Are you sure you want to reset ${userInfo.firstName}'s password. This will send ${userInfo.firstName} an email with the link and instructions to reset their password.`}
                        title={`Password reset`}
                        onClose={() => setOpenResetPasswordModal(false)}
                        onSubmit={() => handleForgotPassword(values.email)}
                      />
                    )}
                  </Grid>
                </Grid>
              );
            }}
          </Formik>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default UpdateUser;
