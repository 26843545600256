import { ActionPayload } from "../../common/Base";
import { ProfileInfoType, UPDATE_PROFILE_DATA, UPDATE_PROFILE_IMAGE } from "./Type";
import _cloneDeep from "lodash/cloneDeep";
import { IS_ERROR, IS_LOADING } from "../CommonType";

const initialState: ProfileInfoType = {
    profileInfo: undefined,
    isLoading: false,
    error: ""
};

export const ProfileInfoReducer = (state = initialState, action: ActionPayload<any>): any => {
    const stateClone = _cloneDeep(state);
    switch (action.type) {
        case UPDATE_PROFILE_DATA: {
            stateClone.profileInfo = action.payload;
            return stateClone;
        }
        case UPDATE_PROFILE_IMAGE: {
            if (stateClone.profileInfo) {
                stateClone.profileInfo.imageUrl = "";
                stateClone.profileInfo.image = null
            }
            return stateClone;
        }
        case IS_LOADING: {
            stateClone.isLoading = action.payload;
            return stateClone;
        }
        case IS_ERROR: {
            stateClone.error = action.payload;
            return stateClone;
        }
        default: {
            return stateClone;
        }
    }
}