import Styled from "@emotion/styled";
import { Grid, IconButton } from "@mui/material";
import { colors } from "../../../../constants/Colors";

export const UsageButton = Styled(IconButton)`
    background: #F1F5F9;
    border-radius: 5px;
`;

export const UsageContainer = Styled(Grid)`
    background: ${colors.secondary.neutral};
    box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
`;