import { Tab, Tabs, useMediaQuery, useTheme } from '@mui/material';
import { FC } from 'react';
import { TabContainer } from './Style';
import { tabOptionType } from './Type';

type TabProps = {
    value: string;
    setValue: (value: string) => void;
    tabOptions: tabOptionType[];
    variant?: 'default' | 'secondary';
    selectedTextColor?: string;
}
const TabWrapper: FC<TabProps> = ({
    setValue,
    value,
    tabOptions,
    variant = 'default',
    selectedTextColor
}) => {

    const handleChange = (_: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };

    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <TabContainer
            tabWidth={mobileView ? "" : `${(100 / tabOptions.length).toFixed(0)}%`}
            variant={variant}
            selectedTextColor={selectedTextColor}
        >
            <Tabs variant={"scrollable"} scrollButtons={"auto"} TabIndicatorProps={{
                sx: {
                    display: "none",
                }
            }} value={value} onChange={handleChange} aria-label="basic tabs example">
                {
                    tabOptions.filter((item) => item.isAvailaible).map((item) => <Tab icon={item.icon} iconPosition={"start"} key={item.value} label={item.label} value={item.value} />)
                }
            </Tabs>
        </TabContainer>
    );
}

export default TabWrapper