import { Dialog, DialogContent, Grid } from '@mui/material';
import { FC } from 'react';
import { AiOutlineClose } from 'react-icons/ai';
import { HiOutlineUser } from 'react-icons/hi';
import { Heading3, Label } from '../../../commoncomponents/designs/Text';
import { Formik } from "formik";
import { CustomTextfieldWrapper } from '../../../commoncomponents/CustomTextfieldWrapper';
import { PrimaryButton } from '../../../commoncomponents/designs/Button';
import { BsCloudUpload } from 'react-icons/bs';
import { colors } from '../../../../constants/Colors';
import CustomSingleDatePicker from '../../../commoncomponents/CustomSingleDatePicker';
import { PropertyCustomerList } from '../../../../store/sites/Type';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateCustomerDetail } from '../../../../store/sites/ActionCreator';

type EditCustomerModalProps = {
    handleClose: () => void;
    customerInfo: PropertyCustomerList;
    type: "current" | "past" | "future";
    showMoveOutClearDate?: boolean;
}
const EditCustomerModal: FC<EditCustomerModalProps> = ({
    handleClose,
    customerInfo,
    type,
    showMoveOutClearDate
}) => {

    const dispatch = useDispatch();
    const { id, homeid } = useParams<{
        id: string;
        homeid: string;
    }>();

    return (
        <Dialog maxWidth={"sm"} PaperProps={{
            style: {
                height: "100vh"
            }
        }} fullWidth open onClose={handleClose}>
            <DialogContent style={{
                padding: 50
            }}>
                <Grid container alignItems={"center"} justifyContent={"space-between"}>
                    <Grid item>
                        <HiOutlineUser style={{ fontSize: 50 }} />
                    </Grid>
                    <Grid item>
                        <AiOutlineClose onClick={handleClose} style={{ cursor: "pointer", fontSize: 30 }} />
                    </Grid>
                </Grid>
                {
                    <Formik
                        initialValues={{
                            ...customerInfo
                        }}
                        onSubmit={(values, { setSubmitting }) => {
                            if (id && homeid) {
                                dispatch(updateCustomerDetail(id, homeid, values))
                                    .then(() => handleClose())
                                    .finally(() => setSubmitting(false))
                            }
                            return;
                        }}
                    >
                        {
                            ({ values, setFieldValue, isSubmitting, handleSubmit }) => (
                                <>
                                    <Grid mt={"20px"}>
                                        <Heading3>Customer details</Heading3>
                                    </Grid>
                                    <Grid mt={"40px"}>
                                        <Label>Full name</Label>
                                    </Grid>
                                    <Grid>
                                        <CustomTextfieldWrapper
                                            name={"name"}
                                            id={"name"}
                                            fullWidth
                                            helperText={"Customer name(imported from Xero)"}
                                            variant={"standard"}
                                        />
                                    </Grid>
                                    <Grid mt={"20px"}>
                                        <Label>
                                            Customer Start date
                                        </Label>
                                    </Grid>
                                    <Grid>
                                        <CustomSingleDatePicker
                                            date={
                                                values.start ? new Date(values.start) : null
                                            }
                                            setDate={(date) => {
                                                setFieldValue("start", date)
                                            }}
                                            helperText={"Confirm details with your resident before updating."}
                                            variant={"standard"}
                                            type={"secondary"}
                                        />
                                    </Grid>
                                    <Grid mt={"20px"}>
                                        <Label>
                                            Customer End date
                                        </Label>
                                        <CustomSingleDatePicker
                                            date={
                                                values.end ? new Date(values.end) : null
                                            }
                                            showClearDate={showMoveOutClearDate}
                                            minDate={new Date(values.start)}
                                            setDate={(date) => {
                                                setFieldValue("end", date)
                                            }}
                                            helperText={"Confirm details with your resident before updating."}
                                            variant={"standard"}
                                            type={"secondary"}
                                        />
                                    </Grid>
                                    <Grid item xs={12} mt={"60px"}>
                                        <PrimaryButton
                                            onClick={() => handleSubmit()}
                                            isLoading={isSubmitting}
                                            backgroundcolor={colors.primary.dark} textcolor={colors.secondary.light} fullWidth label='Save' icon={<BsCloudUpload />} />
                                    </Grid>
                                </>
                            )
                        }
                    </Formik>
                }
            </DialogContent>
        </Dialog>
    )
}

export default EditCustomerModal;