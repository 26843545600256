import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Label2 } from '../commoncomponents/designs/Text'
import { PopMenuContainer } from './Style'
import { FC } from "react";
import { LoginHelper } from '../../util/LoginHelper'
import { useGetUserRole } from '../../store/auth/Selector'
import { PERMISSIONS, USER_ROLES } from '../../util/Type'
import { doesLoggedinUserHavePermission } from '../../util/SiteUtil'

type PopMenuProps = {
    closeMenu: () => void
}
const PopUpMenu: FC<PopMenuProps> = ({
    closeMenu
}) => {
    const navigate = useNavigate();
    const role = useSelector(useGetUserRole);

    const handleNavigate = (path: string) => {
        navigate(path);
        closeMenu();
    }
    const getUserLink = () => {
        if (doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.VIEW)) {
            return "/users/current";
        }
        if (doesLoggedinUserHavePermission(role, USER_ROLES.ORGANISATION_MANAGEMENT, PERMISSIONS.VIEW)) {
            return "/organisation";
        }
        return ""
    }
    return (
        <PopMenuContainer>
            <Label2 mt={"20px"} onClick={() => handleNavigate("/profile")}>My Profile {'&'} settings</Label2>
            {
                (doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.VIEW)
                    ||
                    doesLoggedinUserHavePermission(role, USER_ROLES.ORGANISATION_MANAGEMENT, PERMISSIONS.VIEW))
                && <Label2 mt={"20px"} onClick={() => handleNavigate(getUserLink())}>Users {'&'} organisations</Label2>
            }
            <Label2 mt={"20px"} onClick={() => handleNavigate("/help")}>Help {'&'} feedback</Label2>
            <Label2 mt={"20px"} onClick={() => {
                window.open("/", "_self");
                LoginHelper.removeUserSession();
            }}>Logout</Label2>
        </PopMenuContainer>
    )
}

export default PopUpMenu