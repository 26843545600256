import { ActionPayload } from "../../common/Base";
import { SiteServiceType, UPDATE_SITE_SERVICE_LIST } from "./Type";
import _cloneDeep from "lodash/cloneDeep";
import { IS_ERROR, IS_LOADING } from "../CommonType";

const initialState: SiteServiceType = {
    serviceList: [],
    error: "",
    isLoading: false
};

export const SiteServiceReducer = (state = initialState, action: ActionPayload<any>): any => {
    const stateClone = _cloneDeep(state);
    switch (action.type) {
        case UPDATE_SITE_SERVICE_LIST: {
            stateClone.serviceList = action.payload;
            return stateClone;
        }
        case IS_LOADING: {
            stateClone.isLoading = action.payload;
            return stateClone;
        }
        case IS_ERROR: {
            stateClone.error = action.payload; 
            return stateClone;
        }
        default: {
            return stateClone;
        }
    };
};
