import { Grid } from '@mui/material'
import AllList from '../listselect/AllList'
import SelectedList from '../listselect/SelectedList'
import { useOrgSiteHook } from './Hooks'
import { FC } from "react";
import { OrgDetailType } from '../../../../store/organisation/Type';
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../../util/Type';

type Props = {
    orgInfo?: OrgDetailType;
};

const OrganisationSiteList: FC<Props> = ({
    orgInfo
}) => {

    const { allSites, selectedSites, updateSelectedSite, isLoading } = useOrgSiteHook();
    const role = useSelector(useGetUserRole);

    return (
        <Grid
            style={{
                pointerEvents:
                    !doesLoggedinUserHavePermission(role, USER_ROLES.SERVICE_FOR_SITE, PERMISSIONS.VIEW)
                        ? "none" : "all"
            }}
            container alignItems={"stretch"} spacing={"30px"}>
            <Grid item xs={6}>
                <AllList
                    searchLabel='Search site name'
                    title='All sites'
                    isOrgSites
                    allItems={allSites} setSelectedItem={updateSelectedSite} />
            </Grid>
            <Grid item xs={6}>
                <SelectedList
                    isLoading={isLoading}
                    title={orgInfo?.name || ""}
                    subText={"Add sites to this organisation by selecting them from the left column."}
                    selectedItems={selectedSites} setSelectedItems={updateSelectedSite} />
            </Grid>
        </Grid>
    );
};

export default OrganisationSiteList;
