import { GridCellParams } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { MdAddCircleOutline } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { updateArchivedUser } from "../../../store/user/ActionCreator";
import { useGetUserList } from "../../../store/user/Selector";
import { StyledHeader } from "../../commoncomponents/datagrid/DataGridStyle";
import { TextButton } from "../../commoncomponents/designs/Button";
import { useUserListHooks } from "../Hooks";
import { Grid } from "@mui/material";
import { FiEdit } from "react-icons/fi";
import { UserType } from "../../../store/user/Type";
import { useGetUserRole } from "../../../store/auth/Selector";
import { PERMISSIONS, USER_ROLES } from "../../../util/Type";
import { doesLoggedinUserHavePermission } from "../../../util/SiteUtil";

export const usePastUserHooks = () => {
    const { userListColumns } = useUserListHooks();
    const pastUserList = useSelector(useGetUserList);
    const dispatch = useDispatch();
    const [selectedUserDetail, setSelectedUserDetail] = useState<UserType | null>(null);
    const role = useSelector(useGetUserRole);


    const updateArchive = (id: string) => {
        dispatch(updateArchivedUser(id, false));
    }

    const pastListColumns = () => useMemo(() => ([
        ...userListColumns(),
        {
            field: "id",
            flex: 1,
            minWidth: 300,
            renderHeader: () => <StyledHeader label="ACTION" />,
            renderCell: (params: GridCellParams) =>
                <Grid container gap={"10px"}>
                    <Grid item>
                        <TextButton
                            disabled={!doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.EDIT)}
                            onClick={() => setSelectedUserDetail(params.row)}
                            label="EDIT" icon={<FiEdit />} />
                    </Grid>
                    <Grid item>
                        <TextButton onClick={() => updateArchive(params.row.id)} label="ADD BACK" icon={<MdAddCircleOutline />} />
                    </Grid>
                </Grid>
        },
    ]
    ), []);

    return {
        pastListColumns,
        pastUserList,
        selectedUserDetail,
        setSelectedUserDetail
    }
};