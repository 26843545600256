import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import React from "react";
import { useGetSiteDetail } from "../../../store/sites/Selector";
import { addSiteMultiplePropertyAction, addSitePropertyAction, getSiteIdDetail, updateSitePropertyImageAction } from "../../../store/sites/ActionCreator";
import { ManagePropertyDetailType } from "../../../store/sites/Type";
import { FormikState } from "formik";
import { toast } from "react-toastify";

export const useAddManagerPropertyHook = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [addAnotherActivity, setAddAnotherActivity] = useState(false);
    const [percent, setUploadPercent] = useState(0);
    const [isImageUploading, setIsImageUploading] = useState(false);

    const dispatch = useDispatch();
    const propertyInfo = useSelector(useGetSiteDetail);

    useEffect(() => {
        if (id) {
            dispatch(getSiteIdDetail(id));
        }
    }, []);

    const handleAnchorClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const addNewProperty = (
        values: ManagePropertyDetailType,
        setSubmitting: (isSubmitting: boolean) => void,
        resetForm: (nextState?: Partial<FormikState<ManagePropertyDetailType>> | undefined) => void
    ) => {
        id && dispatch(addSitePropertyAction(id, values))
            .then((res: any) => {
                setIsImageUploading(true);
                if (values.image && values.imageUrl) {
                    dispatch(updateSitePropertyImageAction(
                        id,
                        res,
                        values,
                        setUploadPercent
                    )).then(() => {
                        !addAnotherActivity && navigate(`/site/${id}/manageproperties`);
                        setSubmitting(false);
                        setAddAnotherActivity(false);
                        setIsImageUploading(false);
                        resetForm();
                    })
                    return;
                }
                !addAnotherActivity && navigate(`/site/${id}/manageproperties`);
                setSubmitting(false);
                setAddAnotherActivity(false);
                resetForm();
            }).finally(() => setSubmitting(false));
    };

    return {
        id,
        navigate,
        handleAnchorClick,
        anchorEl,
        propertyInfo,
        addNewProperty,
        setAddAnotherActivity,
        addAnotherActivity,
        percent,
        isImageUploading,

    }
};

export const useAddMulitpleProperty = () => {
    const { id } = useParams();
    const propertyInfo = useSelector(useGetSiteDetail);
    const [mulitplePropertyFile, setMultiplePropertyFile] = useState<File | null>(null);
    const [uploadFilePercent, setUploadFilePercent] = useState(0);
    const dispatch = useDispatch();
    useEffect(() => {
        mulitplePropertyFile && addMultipleProperty();
    }, [mulitplePropertyFile]);


    const addMultipleProperty = () => {
        if (id && mulitplePropertyFile) {
            dispatch(addSiteMultiplePropertyAction(id, mulitplePropertyFile, setUploadFilePercent)).then(() => {
                toast.success('File Uploaded Successfully');
                setMultiplePropertyFile(null);
            }).catch((res: any) => {
                toast.error(res.message);
            }).finally(() => {
                setMultiplePropertyFile(null);
            })
        }

    }
    return {
        propertyInfo,
        id,
        addMultipleProperty,
        setMultiplePropertyFile,
        uploadFilePercent,
        mulitplePropertyFile,
    }
}