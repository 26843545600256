import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getXeroAccountInvoiceDropdown } from "../../../../store/lookup/ActionCreator";
import { useGetXeroInvoiceDropdown } from "../../../../store/lookup/Selector";
import { addPropertyAction, removePropertyImageAction, updateSitePropertyImageAction } from "../../../../store/sites/ActionCreator";
import { useGetSitePropertyDetail } from "../../../../store/sites/Selector";
import { ManagePropertyDetailType } from "../../../../store/sites/Type";


export const usePropertySettingHook = () => {
    const propertyDetails = useSelector(useGetSitePropertyDetail);
    const dispatch = useDispatch();
    const { id, homeid } = useParams();

    useEffect(() => {
        id && dispatch(getXeroAccountInvoiceDropdown(id));
    }, []);

    const updatePropertyDetail = (values: ManagePropertyDetailType,
        setSubmitting: (isSubmitting: boolean) => void
    ) => {
        id && homeid &&
            dispatch(addPropertyAction(id, homeid, values)).then(() => setSubmitting(false));
    }

    return {
        propertyDetails,
        updatePropertyDetail
    }
};

export const usePropertySettingFormHooks = (
    value: ManagePropertyDetailType,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
) => {
    const dispatch = useDispatch();
    const { id, homeid } = useParams();
    const [percent, setUploadPercent] = useState(0);
    const [isImageUploading, setIsImageUploading] = useState(false);
    const xeroInvoiceDropdown = useSelector(useGetXeroInvoiceDropdown);

    useEffect(() => {
        if (value.image && id && homeid) {
            setIsImageUploading(true);
            dispatch(updateSitePropertyImageAction(id, homeid, value, setUploadPercent))
                .then(() => {
                    setIsImageUploading(false);
                }).catch(() => {
                    setIsImageUploading(false);
                    setFieldValue("imageUrl", "");
                    setFieldValue("image", null);
                });
        }
    }, [value.image]);

    const removePropertyImage = (
        values: ManagePropertyDetailType,
        setValues: (values: React.SetStateAction<ManagePropertyDetailType>, shouldValidate?: boolean | undefined) => void
    ) => {
        id && homeid && dispatch(removePropertyImageAction(id, homeid)).then(() => {
            setValues({
                ...values,
                imageUrl: "",
                image: null
            });
        });
    }

    return {
        removePropertyImage,
        percent,
        isImageUploading,
        xeroInvoiceDropdown
    }
};
