import { Grid } from '@mui/material'
import DataGridWrapper from '../../commoncomponents/datagrid/DataGrid'
import UpdateUser from '../updateuser';
import { useCurrentUserHooks } from './Hooks'

const CurrentUserList = () => {
    const { currentListColumns, currentUserList, isLoading, selectedUserDetail, setSelectedUserDetail } = useCurrentUserHooks();

    return (
        <Grid>
            <DataGridWrapper
                rows={currentUserList.filter((item) => !item.archived) as any}
                columns={currentListColumns()}
                rowHeight={72}
                loading={isLoading}
            />
            {
                selectedUserDetail && <UpdateUser userInfo={selectedUserDetail} handleClose={() => setSelectedUserDetail(null)} />
            }
        </Grid>
    );
};

export default CurrentUserList;
