import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../util/Type';
import BreadcrumbWrapper from '../commoncomponents/designs/BreadcrumbWrapper';
import DeleteModal from '../commoncomponents/modal/DeleteModal';
import SideNavbar from '../commoncomponents/sidenavbar';
import MobileNavbarMenu from '../commoncomponents/sidenavbar/MobileNavbar';
import LeftSection from '../commoncomponents/sitesection/LeftSection';
import RightSection from '../commoncomponents/sitesection/RightSection';
import ServiceUpdate from '../manageproperty/managepropertyedit/service/serviceupdate';
import { SiteManageProperty } from '../manageproperty/Util';
import AddService from './AddService';
import { useServicesHook } from './Hooks';
import SiteServiceHeader from './mobile/SiteServiceHeader';
import SiteServicesList from './SiteServicesList';
import { SiteServicesEditStepper, SiteServicesOptions } from './Util';

const SiteServices = () => {
    const { id, propertyInfo, openAddModal, setOpenAddModal, serviceList,
        selectedServiceInfo,
        setSelectedServiceInfo,
        selectedDeleteService,
        setSelectedDeleteService,
        handleDelete
    } = useServicesHook();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const role = useSelector(useGetUserRole);

    const renderDeleteModal = () => {
        if (selectedDeleteService) {
            return (
                <DeleteModal
                    message={`This will remove ${selectedDeleteService.name} from all properties in ${propertyInfo?.name}`}
                    title={`Delete Service`}
                    onClose={() => setSelectedDeleteService(undefined)}
                    onSubmit={handleDelete}
                />
            )
        }
        return <></>;
    }

    if (mobileView) {
        return (
            <Grid>
                {
                    propertyInfo && <SiteServiceHeader
                        propertyInfo={propertyInfo}
                        handleAddService={() => setOpenAddModal(true)}
                    />
                }
                <MobileNavbarMenu
                    menuOptions={SiteServicesOptions(id || "", role)}
                    selectedMenuId={2}
                />
                <Grid mt={"39px"}
                    style={{
                        pointerEvents:
                            !doesLoggedinUserHavePermission(role, USER_ROLES.SERVICE_FOR_SITE, PERMISSIONS.VIEW)
                                ? "none" : "all"
                    }}
                >
                    <SiteServicesList
                        handleServiceDelete={setSelectedDeleteService}
                        setSelectedServiceInfo={setSelectedServiceInfo} siteServiceList={serviceList} />
                </Grid>
                {
                    (openAddModal || selectedServiceInfo) && <ServiceUpdate selectedServiceInfo={selectedServiceInfo} handleClose={() => {
                        setOpenAddModal(false);
                        setSelectedServiceInfo(undefined);
                    }} />
                }
                {renderDeleteModal()}
            </Grid>
        );
    }

    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar selectedMenuId={3} sidebarOptions={SiteManageProperty(id || "", role)} />
                </LeftSection>
            </Grid>
            <Grid item xs={7}>
                <BreadcrumbWrapper
                    tabType={SiteServicesEditStepper(id || "")}
                />
                <Grid mt={"39px"}
                    style={{
                        pointerEvents:
                            !doesLoggedinUserHavePermission(role, USER_ROLES.SERVICE_FOR_SITE, PERMISSIONS.VIEW)
                                ? "none" : "all"
                    }}>
                    <SiteServicesList
                        handleServiceDelete={setSelectedDeleteService}
                        setSelectedServiceInfo={setSelectedServiceInfo} siteServiceList={serviceList} />
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <RightSection>
                    {
                        propertyInfo && <AddService addServiceClick={() => setOpenAddModal(true)} propertyInfo={propertyInfo} />
                    }
                    {
                        (openAddModal || selectedServiceInfo) && <ServiceUpdate selectedServiceInfo={selectedServiceInfo} handleClose={() => {
                            setOpenAddModal(false);
                            setSelectedServiceInfo(undefined);
                        }} />
                    }
                    {renderDeleteModal()}
                </RightSection>
            </Grid>
        </Grid>
    );
}

export default SiteServices;
