import { Grid } from '@mui/material';
import { FC } from 'react';
import { BiLockAlt } from 'react-icons/bi';
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../../util/Type';
import { Paragraph2Mobile } from '../../../commoncomponents/designs/Text';
import { UsageButton } from './Style';
import { UsageListType } from './Type';

type UsageViewProps = {
    usageInfo: UsageListType;
    handleValueChange: (id: number, amount: number) => void;
}
const UsageView: FC<UsageViewProps> = ({
    usageInfo,
    handleValueChange
}) => {
    const role = useSelector(useGetUserRole);

    const { billingType, amount, id } = usageInfo;

    if (billingType === "Metered") {
        return (
            <Paragraph2Mobile display={"flex"} alignItems={"center"} color={"#1C212E"}>
                <BiLockAlt style={{ marginRight: 10 }} />   {amount}
            </Paragraph2Mobile>
        );
    };

    return (
        <Grid
            style={{
                pointerEvents:
                    !doesLoggedinUserHavePermission(role, USER_ROLES.MANUAL_ADD_USAGE, PERMISSIONS.VIEW)
                        ? "none" : "all"
            }}
            display={"flex"} flexWrap={"nowrap"} gap={"20px"} alignItems={"center"}>
            <Grid item>
                <UsageButton
                    disabled={amount === 0 || !doesLoggedinUserHavePermission(role, USER_ROLES.MANUAL_ADD_USAGE, PERMISSIONS.EDIT)}
                    onClick={() => handleValueChange(id, amount - 1)}>
                    <MdKeyboardArrowDown />
                </UsageButton>
            </Grid>
            <Grid item xs={3}>
                <Paragraph2Mobile color={"#1C212E"} textAlign={"center"}>{amount}</Paragraph2Mobile>
            </Grid>
            <Grid item>
                <UsageButton
                    disabled={amount === 0 || !doesLoggedinUserHavePermission(role, USER_ROLES.MANUAL_ADD_USAGE, PERMISSIONS.EDIT)}
                    onClick={() => handleValueChange(id, amount + 1)}>
                    <MdKeyboardArrowUp />
                </UsageButton>
            </Grid>
        </Grid>
    )
}

export default UsageView