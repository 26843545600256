import { Grid } from '@mui/material';
import AllList from '../listselect/AllList';
import { useOrgUserHook } from './Hooks';
import SelectedList from '../listselect/SelectedList';
import { OrgDetailType } from '../../../../store/organisation/Type';
import { FC } from "react";
import { useGetUserRole } from '../../../../store/auth/Selector';
import { useSelector } from 'react-redux';
import { doesLoggedinUserHavePermission } from '../../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../../util/Type';

type Props = {
    orgInfo?: OrgDetailType;
};

const OrganisationUserList: FC<Props> = ({
    orgInfo
}) => {

    const { userList, updateSelectedUser, isLoading } = useOrgUserHook();
    const role = useSelector(useGetUserRole);

    return (
        <Grid
            style={{
                pointerEvents:
                    !doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_SITE_PERMISSION, PERMISSIONS.VIEW)
                        ? "none" : "all"
            }}
            container alignItems={"stretch"} spacing={"30px"}>
            <Grid item xs={6}>
                <AllList
                    searchLabel='Search first or last name'
                    title='All users'
                    allItems={userList?.allList || []} setSelectedItem={updateSelectedUser} />
            </Grid>
            <Grid item xs={6}>
                <SelectedList
                    isLoading={isLoading}
                    title={orgInfo?.name || ""}
                    subText={"Add users to this organisation by selecting them from the left column."}
                    selectedItems={userList?.selectedList || []} setSelectedItems={updateSelectedUser} />
            </Grid>
        </Grid>
    );
};

export default OrganisationUserList;
