import { ActionPayload } from "../../common/Base";
import { UPDATE_AUTH_INFO_TYPE, XeroAuthInfoType } from "./Type";
import _cloneDeep from "lodash/cloneDeep";
import { IS_ERROR, IS_LOADING } from "../CommonType";

const initialState: XeroAuthInfoType = {
    authInfo: null,
    error: "",
    isLoading: false
};

export const XeroAuthInfoReducer = (state = initialState, action: ActionPayload<any>): any => {
    const stateClone = _cloneDeep(state);
    switch (action.type) {
        case UPDATE_AUTH_INFO_TYPE: {
            stateClone.authInfo = action.payload;
            return stateClone;
        }
        case IS_LOADING: {
            stateClone.isLoading = action.payload;
            return stateClone;
        }
        case IS_ERROR: {
            stateClone.error = action.payload;
            return stateClone;
        }
        default: {
            return stateClone;
        }
    }
};
