import { CircularProgress, Grid } from '@mui/material';
import { FC } from 'react';

type LoadingWrapperProps = {
    isLoading: boolean;
    isError?: string;
    children: JSX.Element;
    height?:string;
}

const LoadingWrapper: FC<LoadingWrapperProps> = ({
    isLoading,
    children,
    height = "400px"
}) => {

    if (isLoading) {
        return (
            <Grid container height={height} alignItems={"center"} justifyContent={"center"}>
                <CircularProgress />
            </Grid>
        )
    }
    return (
        <>{children}</>
    )
}

export default LoadingWrapper;