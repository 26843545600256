import { SideBarOptionsType } from "../commoncomponents/sidenavbar/Util";
import { FiUsers } from "react-icons/fi";
import { RiUserReceived2Line } from "react-icons/ri";
import { AiOutlineTeam } from "react-icons/ai";
import { MdArrowBackIos } from "react-icons/md";
import { doesLoggedinUserHavePermission } from "../../util/SiteUtil";
import { PERMISSIONS, USER_ROLES } from "../../util/Type";

export const UserSideMenu = (role: string): SideBarOptionsType[] => [
    {
        id: 1,
        label: 'Back to Site Manager',
        url: '/portfolio',
        icons: <MdArrowBackIos fontSize={"13px"} />,
        isAvailaible: true
    },
    {
        id: 2,
        label: 'Users',
        url: '/users/current',
        icons: <FiUsers fontSize={"13px"} />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.VIEW)
    },
    {
        id: 3,
        label: 'Past users',
        url: '/users/past',
        icons: <RiUserReceived2Line fontSize={"13px"} />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_USER, PERMISSIONS.VIEW)
    },
    {
        id: 4,
        label: 'Organisation',
        url: '/organisation',
        icons: <AiOutlineTeam fontSize={"13px"} />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.ORGANISATION_MANAGEMENT, PERMISSIONS.VIEW)
    },
];

export const UserTabOption = [
    {
        label: "Site Manager",
        url: "/portfolio",
    }
];
