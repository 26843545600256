import { ref } from "yup";
import { object, string } from "yup";
import { UserType } from "../../../store/user/Type";
import { UpdateUserType } from "./type";

export const userDetailInitialValue = (userInfo: UserType): UpdateUserType => ({
    email: userInfo.email || "",
    firstName: userInfo.firstName || "",
    lastName: userInfo.lastName || "",
    image: null,
    role: userInfo.role || "none",
    imageUrl: "",
    receiveEmail: userInfo.emailNotifications || false,
});

export const UpdateUserValidationSchema = object().shape({
    firstName: string().required("First name is required"),
    email: string().email("Please enter correct mail").required("Email is mandatory"),
    role: string().notOneOf(["none"], "Please select role").required("Role is mandatory"),
});
