import { Grid } from '@mui/material';
import { useFormikContext } from 'formik';
import { AiOutlineUpload } from "react-icons/ai";
import { FutureCustomerType } from '../../../../store/sites/Type';
import CustomSingleDatePicker from '../../../commoncomponents/CustomSingleDatePicker';
import { TextButton } from '../../../commoncomponents/designs/Button';
import CustomerSearch from './SearchCustomer';

const FutureInfoCard = () => {
    const { values, setFieldValue, handleSubmit } = useFormikContext();
    const data = values as FutureCustomerType;

    return (
        <Grid mt={"10px"} container alignItems={"center"} justifyContent={"space-between"}>
            <Grid item xs={12} sm={4}>
                <CustomerSearch
                    name={"customerInfo"}
                    helperText="Customers are imported via Xero API in Site settings."
                    fullWidth
                    placeholder='Search Xero customer database'
                />
            </Grid>
            <Grid item mt={["30px", "0px"]} xs={8} sm={2}>
                <CustomSingleDatePicker
                    date={data.inDate}
                    label={"SET MOVE IN DATE"}
                    setDate={(date) => setFieldValue("inDate", date)}
                />
            </Grid>
            <Grid item mt={["30px", "0px"]} xs={8} sm={2}>
                <CustomSingleDatePicker
                    date={data.outDate}
                    minDate={data.inDate ? new Date(data.inDate) : undefined}
                    label={"SET MOVE OUT DATE"}
                    setDate={(date) => setFieldValue("outDate", date)}
                />
            </Grid>
            <Grid item mt={["30px", "0px"]} xs={4} sm={2}>
                <TextButton onClick={() => handleSubmit()} fullWidth label={"SAVE"} icon={<AiOutlineUpload />} />
            </Grid>
        </Grid>
    );
}

export default FutureInfoCard