import { Grid } from '@mui/material'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserRole } from '../../store/auth/Selector';
import { BigTextButton } from '../commoncomponents/designs/Button'
import { SiteMenus } from './Util'

const SiteLinks = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const role = useSelector(useGetUserRole);
    return (
        <Grid alignItems={"stretch"} container mt={"42px"} spacing={"40px"}>
            {
                SiteMenus(id || "", role).filter((item) => item.isAvailaible).map((item) => (
                    <Grid item key={item.id} xs={12} md={6}>
                        <BigTextButton
                            buttonview='sm'
                            label={item.label}
                            icon={item.icons}
                            onClick={() => navigate(item.url)}
                        />
                    </Grid>
                ))
            }
        </Grid>
    )
};
export default SiteLinks