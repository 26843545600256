import { Breadcrumbs } from '@mui/material'
import { tabType } from './Type'
import { FC } from "react";
import { Paragraph1 } from './Text';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../../constants/Colors';

type BreadcrumbProp = {
    tabType: tabType[];
}
const BreadcrumbWrapper: FC<BreadcrumbProp> = ({
    tabType
}) => {
    const navigate = useNavigate();

    return (
        <Breadcrumbs separator="›" aria-label="breadcrumb">
            {
                tabType.map(({ label,
                    url
                }) => (
                    <Paragraph1
                        color={colors.primary.light}
                        fontWeight={!url ? 700 : 500}
                        style={{ cursor: 'pointer' }}
                        key={label}
                        onClick={() => url && navigate(url)}
                    >{label}</Paragraph1>
                ))
            }
        </Breadcrumbs>
    )
}

export default BreadcrumbWrapper;