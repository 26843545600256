import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { ToastErrorConfig } from "../../constants/SiteConstants";
import { getConfirmLogin } from "../../store/auth/ActionCreator";

export const useConfirmUserHook = () => {

    const [isLoading, setIsLoading] = useState(true);
    const [isConfirmed, setIsConfirmed] = useState(false);

    const searchParams = new URLSearchParams(window.location.search);
    const navigate = useNavigate();
    useEffect(() => {
        const code  = searchParams.get("code");
        if (code) {
            setIsLoading(true);
            getConfirmLogin(code).then(() => {
                setIsConfirmed(true);
                navigate("/login");
                toast.success("Code confirmed.", ToastErrorConfig);
            }).catch(() => {
                setIsConfirmed(false);
                toast.error("Code not found", ToastErrorConfig);
            }).finally(() => setIsLoading(false));
        }
    }, []);

    return {
        isLoading,
        isConfirmed
    }
}