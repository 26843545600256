import Styled from "@emotion/styled";
import { Paper } from "@mui/material";
import { colors } from "../../constants/Colors";

type PaperProps = {
    padding?: number;
}
export const PaperContainer = Styled(Paper)<PaperProps>`
    padding: ${(props) => props.padding || 14}px; 
    background: ${colors.secondary.neutral};
    border: 1px solid ${colors.secondary.light};
    border-radius: 6px;
`;

export const PropertyCardImage = Styled.img`
    width: 100%;
    border-radius: 6px;
`;