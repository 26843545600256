import { Checkbox, CheckboxProps, FormControl, FormControlLabel, FormControlLabelProps, FormHelperText } from '@mui/material';
import { useField } from 'formik';
import { FC } from 'react'

type FormikCheckboxProps = {
    name: string;
    id: string;
    label: string;
} & CheckboxProps;

const FormikCheckbox: FC<FormikCheckboxProps> = ({
    id,
    name,
    label,
    ...rest
}) => {

    const [field, { error, touched }] = useField(name);
    return (
        <FormControl component="fieldset" error={!!error}>
            <FormControlLabel
                value="top"
                control={<Checkbox  {...field} name={name} id={id} {...rest} />}
                labelPlacement={"end"}
                label={label}
            />
            {
                !!error && !!touched && <FormHelperText>{error}</FormHelperText>
            }
        </FormControl>
    )
}

export default FormikCheckbox