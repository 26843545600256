import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { updatePropertyServices } from "../../../../store/sites/ActionCreator";
import { useGetPropertyServices } from "../../../../store/sites/Selector";
import { useGetSiteServiceList } from "../../../../store/siteservice/Selector";
import { ServiceListType } from "../../../../store/siteservice/Type";

export const useServicesHook = () => {
    const [selectedServices, setSelectedServices] = useState<ServiceListType>();
    const serviceList = useSelector(useGetSiteServiceList);
    const propertyService = useSelector(useGetPropertyServices);
    const dispatch = useDispatch();
    const { id, homeid } = useParams();

    const handleServiceChange = (serviceId: string, isEnabled: boolean) => {
        id && homeid && dispatch(updatePropertyServices(id, homeid, serviceId, isEnabled))
    }
    return {
        selectedServices,
        setSelectedServices,
        serviceList,
        propertyService,
        handleServiceChange
    };
};
