import { Grid } from '@mui/material'
import { useFormikContext } from 'formik'
import { AiOutlineCloud } from 'react-icons/ai'
import { colors } from '../../../constants/Colors'
import { PropertyTypeOptions } from '../../../constants/SiteConstants'
import { CustomTextfieldWrapper } from '../../commoncomponents/CustomTextfieldWrapper'
import { PrimaryButton } from '../../commoncomponents/designs/Button'
import { Label } from '../../commoncomponents/designs/Text'
import { FormikSelect } from '../../commoncomponents/fields/FormikSelect'
import ImageDrop from '../../commoncomponents/fields/ImageUpload'
import { FC } from "react";
import { ManagePropertyDetailType } from '../../../store/sites/Type'
import { LogoContainer, StyledLogo } from '../../commoncomponents/CommonStyle'
import ImageUploadModal from '../../commoncomponents/ImageUploadModal'
import FormikGoogleMapAutoComplete from '../../commoncomponents/fields/FormikGoogleMapAutoComplete'

type AddPropertyFormProps = {
    setAddAnotherActivity: (value: boolean) => void;
    addAnotherActivity: boolean;
    percent: number;
    isImageUploading: boolean;
}
const AddPropertyForm: FC<AddPropertyFormProps> = ({
    setAddAnotherActivity,
    addAnotherActivity,
    percent,
    isImageUploading
}) => {
    const { values, setFieldValue, handleSubmit, isSubmitting, setValues } = useFormikContext();

    const data = values as ManagePropertyDetailType;

    const renderImage = () => {
        if (data.imageUrl) {
            return (
                <LogoContainer>
                    <Grid item>
                        <StyledLogo src={data.imageUrl} />
                    </Grid>
                    <Grid item>
                        <Label
                            onClick={() => setValues({
                                ...data,
                                imageUrl: "",
                                image: null
                            })}
                            style={{ cursor: "pointer" }}>Remove</Label>
                    </Grid>
                </LogoContainer>
            );
        }
        return (
            <ImageDrop
                name='image'
                setImageUrl={(url) => setFieldValue('imageUrl', url)}
                imageSize={1}
                logoInfo={"Upload a file or drag and drop"}
                logoSubMessage={"PNG, JPG, GIF up to 1MB"}
            />
        );
    }

    return (
        <Grid container mt={"60px"}>
            <Grid item xs={12}>
                <Label>Full name</Label>
                <Grid>
                    <CustomTextfieldWrapper
                        name={"name"}
                        id={"name"}
                        fullWidth
                        variant={"standard"}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} mt={"20px"}>
                <Label>Property type</Label>
                <Grid mt={"10px"}>
                    <FormikSelect
                        name={"type"}
                        id={'type'}
                        options={PropertyTypeOptions}
                        placeholder={"Select"}
                    />
                </Grid>
            </Grid>
            <Grid item xs={12} mt={"20px"}>
                <Label>Physical Address</Label>
                <FormikGoogleMapAutoComplete
                    name={"address"}
                    id={'address'}
                    variant={"standard"}
                    helperText={"Search Natural Database"}
                    fullWidth
                    placeholder={"Type here"}
                />
            </Grid>
            <Grid item xs={12} md={4} mt={"20px"}>
                {renderImage()}
            </Grid>
            <Grid item xs={12} mt={"30px"} md={12}>
                <Grid container justifyContent={"center"} spacing={"20px"}>
                    <Grid item xs={12} md={4}>
                        <PrimaryButton
                            label='Done'
                            isLoading={!addAnotherActivity && isSubmitting}
                            onClick={() => handleSubmit()}
                            backgroundcolor={colors.primary.dark}
                            textcolor={colors.secondary.neutral}
                            fullWidth
                            icon={<AiOutlineCloud />} />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PrimaryButton
                            label='Done & another property'
                            backgroundcolor={colors.primary.dark}
                            textcolor={colors.secondary.neutral}
                            isLoading={addAnotherActivity && isSubmitting}
                            onClick={() => {
                                setAddAnotherActivity(true);
                                setTimeout(() => {
                                    handleSubmit();
                                }, 300);
                            }}
                            fullWidth
                            icon={<AiOutlineCloud />} />
                    </Grid>
                </Grid>
            </Grid>

            {
                isImageUploading && data.image && <ImageUploadModal
                    progress={percent}
                    file={data.image as File}
                    handleClose={() => setFieldValue("image", null)}
                />
            }
        </Grid>
    )
}

export default AddPropertyForm