import { FC, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useField } from "formik";
import { checkIfFilesAreCorrectType, checkIfFilesAreTooBig, readFile } from "../../../util/SiteUtil";
import { VALID_IMAGE } from "../../../constants/SiteConstants";
import { FileUploadContainer, FileUploadSubMessage, FileUploadTitle, LogoContainer } from "./Style";
import { RiGalleryLine } from "react-icons/ri";
import { FormHelperText } from "@mui/material";
import { colors } from "../../../constants/Colors";
import { PrimaryButton } from "../designs/Button";

type FileUploadProps = {
    buttonLabel: string;
    fileSize?: number;
    name: string;
    validDocumentTypes?: string[];
    errorMessage?: string;
};

const FileDrop: FC<FileUploadProps> = ({
    buttonLabel,
    fileSize,
    name,
    validDocumentTypes,
    errorMessage
}) => {
    const [_, { error }, { setError, setValue }] = useField(name);

    const onDrop = useCallback(
        (acceptedFiles: any) => {
            acceptedFiles[0].id = Math.random();
            const file = acceptedFiles[0];
            if (setError && !checkIfFilesAreCorrectType(acceptedFiles, validDocumentTypes || [])) {
                setError(errorMessage || "Invalid file type");
                return;
            }

            if (fileSize && !checkIfFilesAreTooBig(acceptedFiles, fileSize)) {
                setError(`File must be less then ${fileSize} MB`);
                return;
            }
            setValue(file);
        },
        [setValue, fileSize, setError]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div>
            {isDragActive ? (
                <div {...getRootProps({ className: "dropzone height-100per" })}>
                    <input {...getInputProps()} />
                    <PrimaryButton
                        textcolor={colors.primary.dark}
                        label={buttonLabel}
                        fullWidth

                    />
                </div>
            ) : (
                <div {...getRootProps({ className: "dropzone height-100per" })}>
                    <input {...getInputProps()} />
                    <PrimaryButton
                        textcolor={colors.primary.dark}
                        label={buttonLabel}
                        fullWidth
                    />
                </div>
            )}
            {
                error && <FormHelperText style={{
                    color: colors.tertiary.errorTitle,
                }}>{error}</FormHelperText>
            }
        </div>
    );
};

export default FileDrop;
