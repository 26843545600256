import { Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "../../constants/Colors";
import { CardContainer } from "../commoncomponents/CommonStyle";
import { PrimaryButton } from "../commoncomponents/designs/Button";
import { Paragraph2, Paragraph2Mobile } from "../commoncomponents/designs/Text";
import { useState, FC } from "react";
import DeleteModal from "../commoncomponents/modal/DeleteModal";
import { updateSiteStatus } from "../../store/sites/ActionCreator";
import { xeroConnect } from "../../store/siteservice/ActionCreator";
import { TbExternalLink } from "react-icons/tb";
import { useGetAuthInfo } from "../../store/xeroconnect/Selector";
import { useGetUserRole } from "../../store/auth/Selector";
import { doesLoggedinUserHavePermission } from "../../util/SiteUtil";
import { PERMISSIONS, USER_ROLES } from "../../util/Type";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  ToastErrorConfig,
  ToastSuccessConfig,
} from "../../constants/SiteConstants";
import { publishInvoices } from "./Util";
import ConfirmModal from "../commoncomponents/modal/ConfirmModal";

type OptionalActionsProps = {
  siteId: string;
  isActive?: boolean;
};
const OptionalActions: FC<OptionalActionsProps> = ({ siteId, isActive }) => {
  const dispatch = useDispatch();
  const [openStatusModal, setOpenStatusModal] = useState(false);
  const [showConfirmPublishModal, setShowConfirmPublishModal] = useState(false);
  const authInfo = useSelector(useGetAuthInfo);
  const role = useSelector(useGetUserRole);
  const navigate = useNavigate();
  const handleSiteStatus = () => {
    dispatch(updateSiteStatus(siteId, !isActive)).finally(() => {
      setOpenStatusModal(false);
    });
  };

  const onPublishInvoicesNow = () => {
    publishInvoices(siteId)
      .then(() => {
        setShowConfirmPublishModal(false);
        toast.success("Request submitted.", ToastSuccessConfig);
      })
      .catch(() => {
        toast.error("There was an error.", ToastErrorConfig);
      });
  };

  return (
    <>
      {doesLoggedinUserHavePermission(
        role,
        USER_ROLES.LINK_XERO_TO_SITE,
        PERMISSIONS.VIEW
      ) && (
        <PrimaryButton
          onClick={() => xeroConnect(siteId)}
          backgroundcolor={colors.primary.light}
          textcolor={colors.secondary.neutral}
          fullWidth
          disabled={
            !doesLoggedinUserHavePermission(
              role,
              USER_ROLES.LINK_XERO_TO_SITE,
              PERMISSIONS.CREATE
            )
          }
          label={authInfo?.valid ? "Reconnect to Xero" : "Connect to Xero"}
          icon={<TbExternalLink />}
        />
      )}
      <Paragraph2Mobile
        color={colors.tertiary.dark}
        textAlign={"center"}
        mt={"5px"}
      >
        {authInfo?.valid
          ? `Connected to ${authInfo.tenantName}`
          : "Not connected"}
      </Paragraph2Mobile>
      <Grid mt={"20px"}>
        <CardContainer elevation={0}>
          <Paragraph2 textAlign={"center"}>OPTIONAL ACTIONS</Paragraph2>
          <Grid mt={"15px"} spacing={"20px"} container>
            <Grid item xs={12}>
              <PrimaryButton
                variant="contained"
                fullWidth
                onClick={() => navigate(`/site/${siteId}/usage`)}
                backgroundcolor={colors.secondary.light}
                buttonType="secondary"
                label="Usage for multiple properties"
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                variant="contained"
                fullWidth
                backgroundcolor={colors.secondary.light}
                buttonType="secondary"
                label="Publish invoices now"
                onClick={() => setShowConfirmPublishModal(true)}
              />
            </Grid>
            <Grid item xs={12}>
              <PrimaryButton
                variant="contained"
                onClick={() => setOpenStatusModal(true)}
                fullWidth
                disabled={
                  !doesLoggedinUserHavePermission(
                    role,
                    USER_ROLES.SITE,
                    PERMISSIONS.DELETE
                  )
                }
                backgroundcolor={colors.secondary.light}
                buttonType="secondary"
                label={isActive ? "Archive this site" : "Unarchive this site"}
              />
            </Grid>
            {openStatusModal && (
              <DeleteModal
                message={`Are you sure you want to ${
                  isActive ? "archive" : "unarchive"
                } this site.`}
                title={`Site Status`}
                onClose={() => setOpenStatusModal(false)}
                onSubmit={handleSiteStatus}
              />
            )}
          </Grid>
        </CardContainer>
      </Grid>
      {showConfirmPublishModal && (
        <ConfirmModal
          message="Are you sure you want to publish all pending invoices to Xero?"
          title="Confirm Publish"
          confirmTextToType="PUBLISH"
          primaryButtonText="Confirm"
          onClose={() => setShowConfirmPublishModal(false)}
          onSubmit={onPublishInvoicesNow}
        />
      )}
    </>
  );
};

export default OptionalActions;
