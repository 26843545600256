import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getServiceCategoryDropdown } from "../../../../../store/lookup/ActionCreator";
import { useGetAvailaibleInvoiceStatusDropdown, useGetServiceBillingDropdown, useGetServiceCategoryDropdown, useGetTariffKindDropdown, useGetUnitRateDropdown, useGetXeroAccountDropdown, useServiceFixedUnitDropdown } from "../../../../../store/lookup/Selector";
import { useGetSiteDetail } from "../../../../../store/sites/Selector";
import { addSiteServiceList, getSiteServiceList, updatePropertySourceServices, updateSiteService, uploadServiceTou } from "../../../../../store/siteservice/ActionCreator";
import { ServiceSettingType } from "./Type";
import { downloadSiteServiceCsv } from "./Util";
import { INVOICE_AVAILABLE_VALUE } from "../../../../sitesettings/Util";

export const useUpdateServiceHook = (handleClose: () => void) => {
    const serviceBillingDropdown = useSelector(useGetServiceBillingDropdown);
    const dispatch = useDispatch();
    const { id, homeid } = useParams();
    const serviceCategoryDropdown = useSelector(useGetServiceCategoryDropdown);
    const xeroAccountDropdown = useSelector(useGetXeroAccountDropdown);
    const tariffDropdown = useSelector(useGetTariffKindDropdown);
    const unitDropdown = useSelector(useGetUnitRateDropdown);
    const [percent, setUploadPercent] = useState(0);
    const [isFileUploading, setIsFileUploading] = useState(false);
    const [categoryModal, setCategoryModal] = useState(false);
    const selectedSiteDetail = useSelector(useGetSiteDetail);
    const serviceFixedUnitDropdown = useSelector(useServiceFixedUnitDropdown);
    const [openUnitModal, setOpenUnitModal] = useState(false);
    const availaibleInvoiceStatusDropdown = useSelector(useGetAvailaibleInvoiceStatusDropdown);

    useEffect(() => {
        id && dispatch(getServiceCategoryDropdown(id));
    }, []);

    const handleUpdateSubmit = (values: ServiceSettingType,
        setIsSubmitting: (isSubmitting: boolean) => void,
        isMeteredSettings?: boolean,
        serviceId?: string
    ) => {

        if (isMeteredSettings) {
            serviceId && id && homeid &&
                dispatch(updatePropertySourceServices(
                    id,
                    homeid,
                    serviceId,
                    values
                )).then(() => {
                    handleClose();
                }).finally(() => {
                    setIsSubmitting(false);
                });
            return;
        }
        if (id && values.selectedTariffTab !== "simple") {
            setIsFileUploading(true);
            dispatch(uploadServiceTou(id, values, setUploadPercent)).then(() => {
                setIsSubmitting(false);
                dispatch(getSiteServiceList(id, true));
                handleClose();
            }).catch(() => {
                setIsSubmitting(false);
                setIsFileUploading(false);
            })
            return;
        }

        if (id) {
            dispatch(serviceId ? updateSiteService(id, serviceId, {
                ...values,
                tariffId: values.tariffId,
                xeroDetails: xeroAccountDropdown.find((item) => item.value === values.xeroAccountingCode),
            }) : addSiteServiceList(id, {
                ...values,
                tariffId: values.tariffId,
                xeroDetails: xeroAccountDropdown.find((item) => item.value === values.xeroAccountingCode)
            }))
                .then(() => {
                    if (values.tou.file) {
                        setIsFileUploading(true);
                        return;
                    }
                    dispatch(getSiteServiceList(id, true));
                    handleClose();
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
        }

    };

    const handleDownloadTemplate = (values: ServiceSettingType) => {
        downloadSiteServiceCsv(values.name);
    }

    return {
        serviceBillingDropdown,
        serviceCategoryDropdown,
        xeroAccountDropdown,
        unitDropdown,
        handleUpdateSubmit,
        handleDownloadTemplate,
        isFileUploading,
        percent,
        categoryModal,
        setCategoryModal,
        selectedSiteDetail,
        serviceFixedUnitDropdown,
        setOpenUnitModal,
        openUnitModal,
        tariffDropdown,
        availaibleInvoiceStatusDropdown: availaibleInvoiceStatusDropdown.filter((item) => INVOICE_AVAILABLE_VALUE.includes(item.value))
    }
};