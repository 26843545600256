import { Grid } from '@mui/material'
import React from 'react'
import { AiOutlineCloudUpload } from 'react-icons/ai'
import { useSelector } from 'react-redux'
import { useGetUserRole } from '../../store/auth/Selector'
import { doesLoggedinUserHavePermission } from '../../util/SiteUtil'
import { PERMISSIONS, USER_ROLES } from '../../util/Type'
import { FileUploadButton, PrimaryButton, TextButton } from '../commoncomponents/designs/Button'
import { Heading3, Label2 } from '../commoncomponents/designs/Text'
import { PaperContainer } from '../manageproperty/Style'

const UploadService = () => {
    const role = useSelector(useGetUserRole);

    return (
        <PaperContainer padding={50} elevation={0}>
            <Grid>
                <Heading3>Pre-populate your property data</Heading3>
                <Label2>Please download the csv file below to use as a template when creating shared services. Then, upload it again here once you have finished filling it out.</Label2>
            </Grid>
            <Grid container spacing={"10px"} mt={"20px"}>
                <Grid item md={6} xs={12}>
                    <TextButton
                        fullWidth label='Download csv template' icon={<AiOutlineCloudUpload />} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <FileUploadButton
                        isDisabled={!doesLoggedinUserHavePermission(role, USER_ROLES.SHARED_SERVICE, PERMISSIONS.CREATE)}
                        fullWidth
                        acceptFileTypes='.csv'
                        label='Upload File'
                        icon={<AiOutlineCloudUpload />}
                    />
                </Grid>
            </Grid>
        </PaperContainer>
    )
}

export default UploadService