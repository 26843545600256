import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { colors } from '../../../constants/Colors'
import { useGetUserRole } from '../../../store/auth/Selector'
import BreadcrumbWrapper from '../../commoncomponents/designs/BreadcrumbWrapper'
import TabWrapper from '../../commoncomponents/designs/TabWrapper'
import SideNavbar from '../../commoncomponents/sidenavbar'
import MobileNavbarMenu from '../../commoncomponents/sidenavbar/MobileNavbar'
import LeftSection from '../../commoncomponents/sitesection/LeftSection'
import RightSection from '../../commoncomponents/sitesection/RightSection'
import { SiteManageProperty } from '../Util'
import CustomerView from './CustomerView'
import EditOptionAction from './EditOptionActions'
import { useManagePropertyEditHook } from './Hooks'
import ManagePropertyHeader from './mobile/ManagePropertyHeader'
import PropertySettings from './propertysettings'
import Service from './service'
import Usage from './usage'
import { tabOptions } from './Util'

const ManagePropertyEdit = () => {
    const { id, stepper, selectedTab, setSelectedTab, siteInfo } = useManagePropertyEditHook();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const role = useSelector(useGetUserRole);

    const renderView = () => {
        if (selectedTab === "customers") {
            return <CustomerView />
        }
        if (selectedTab === "services") {
            return <Service />
        }
        if (selectedTab === "settings") {
            return <PropertySettings />
        }
        return <Usage />;
    };

    if (mobileView) {
        return (
            <Grid>
                <MobileNavbarMenu
                    menuOptions={SiteManageProperty(id || "", role)}
                    selectedMenuId={2}
                />
                {
                    id && <ManagePropertyHeader id={id} />
                }
                <Grid mt={"20px"}>
                    <TabWrapper
                        tabOptions={tabOptions(role)}
                        setValue={setSelectedTab}
                        value={selectedTab}
                        selectedTextColor={colors.primary.dark}
                    />
                </Grid>
                <Grid mt={"40px"}>
                    {renderView()}
                </Grid>
            </Grid>
        )
    }

    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar
                        selectedMenuId={2}
                        sidebarOptions={SiteManageProperty(id || "", role)}
                    />
                </LeftSection>
            </Grid>
            <Grid item xs={7}>
                <BreadcrumbWrapper
                    tabType={stepper}
                />
                <Grid mt={"35px"}>
                    <TabWrapper
                        tabOptions={tabOptions(role)}
                        setValue={setSelectedTab}
                        value={selectedTab}
                        selectedTextColor={colors.primary.dark}
                    />
                </Grid>
                <Grid mt={"40px"}>
                    {renderView()}
                </Grid>
            </Grid>
            <Grid item xs={3}>
                <RightSection>
                    {
                        siteInfo && <EditOptionAction propertyInfo={siteInfo} />
                    }
                </RightSection>
            </Grid>
        </Grid>
    )
}

export default ManagePropertyEdit