import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { addSiteServiceUsageMultiplePropertyAction, getSiteIdDetail } from "../../store/sites/ActionCreator";
import { useGetSiteDetail } from "../../store/sites/Selector";
import { deleteSiteService, getSiteServiceList } from "../../store/siteservice/ActionCreator";
import { useGetSiteServiceList } from "../../store/siteservice/Selector";
import { ServiceListType } from "../../store/siteservice/Type";
import { toast } from "react-toastify";

export const useServicesHook = () => {
    const { id } = useParams();

    const propertyInfo = useSelector(useGetSiteDetail);
    const [openAddModal, setOpenAddModal] = useState(false);
    const dispatch = useDispatch();
    const serviceList = useSelector(useGetSiteServiceList);
    const [selectedServiceInfo, setSelectedServiceInfo] = useState<ServiceListType | undefined>();
    const [selectedDeleteService, setSelectedDeleteService] = useState<ServiceListType | undefined>();

    useEffect(() => {
        if (id) {
            dispatch(getSiteIdDetail(id));
            dispatch(getSiteServiceList(id));
        }
    }, []);

    const handleDelete = () => {
        selectedDeleteService && id &&
            dispatch(deleteSiteService(id, selectedDeleteService.id.toString())).then(() => {
                setSelectedDeleteService(undefined);
            });
    }
    return {
        id,
        propertyInfo,
        setOpenAddModal,
        openAddModal,
        serviceList,
        setSelectedServiceInfo,
        selectedServiceInfo,
        setSelectedDeleteService,
        selectedDeleteService,
        handleDelete
    }
}

export const useUsageMultipleProperty = () => {
    const { id } = useParams();
    const propertyInfo = useSelector(useGetSiteDetail);
    const [mulitplePropertyFile, setMultiplePropertyFile] = useState<File | null>(null);
    const [uploadFilePercent, setUploadFilePercent] = useState(0);

    useEffect(() => {
        mulitplePropertyFile && addMultipleServiceUsage();
    }, [mulitplePropertyFile]);


    const addMultipleServiceUsage = () => {
        if (id && mulitplePropertyFile) {
            addSiteServiceUsageMultiplePropertyAction(id, mulitplePropertyFile, setUploadFilePercent).then((res) => {
                toast.success('File Uploaded Successfully');
                setMultiplePropertyFile(null);
            }).catch((res) => {
                toast.error(res.message);
            }).finally(() => {
                setMultiplePropertyFile(null);
            })
        }
    }

    return {
        propertyInfo,
        id,
        addMultipleServiceUsage,
        setMultiplePropertyFile,
        uploadFilePercent,
        mulitplePropertyFile,
    }
}