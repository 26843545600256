import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { Formik } from 'formik';
import { MdArrowBackIos } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { colors } from '../../constants/Colors';
import { Heading1, Paragraph1 } from '../commoncomponents/designs/Text';
import LoadingWrapper from '../commoncomponents/LoadingWrapper';
import { StyledMenu } from '../commoncomponents/sidenavbar/Style';
import LeftSection from '../commoncomponents/sitesection/LeftSection';
import { useProfileHook } from './Hooks';
import ProfileForm from './ProfileForm';
import { ProfileInitialValue, ProfileValidationSchema } from './Util';

const UserProfile = () => {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();
  const { profileInfo, profileUpdate, isLoading } = useProfileHook();

  const renderNavigationMenu = () => {
    if (mobile) {
      return (
        <StyledMenu style={{ alignItems: "center" }} onClick={() => navigate("/")}>
          <MdArrowBackIos fontSize={"15px"} />
          <Paragraph1 textTransform={"capitalize"}>Back</Paragraph1>
        </StyledMenu>
      )
    }
    return (
      <Grid item xs={0} sm={2}>
        <LeftSection>
          <StyledMenu style={{ alignItems: "center" }} onClick={() => navigate("/")}>
            <MdArrowBackIos fontSize={"15px"} />
            <Paragraph1 textTransform={"capitalize"}>Back</Paragraph1>
          </StyledMenu>
        </LeftSection>
      </Grid>
    )
  }
  return (
    <Grid container spacing={"20px"}>
      {renderNavigationMenu()}
      <Grid item xs={12} sm={8}>
        <Grid container justifyContent={"center"}>
          <Grid item xs={12} md={5}>
            <Heading1 textAlign={"center"} color={colors.primary.light}>
              My profile {'&'} settings
            </Heading1>
            <Grid mt={"90px"}>
              <LoadingWrapper isLoading={isLoading}>
                <Formik
                  initialValues={ProfileInitialValue(profileInfo)}
                  onSubmit={(values, { setSubmitting }) => {
                    profileUpdate(values, setSubmitting);
                  }}
                  validationSchema={ProfileValidationSchema}
                  enableReinitialize
                  validateOnMount={false}
                >
                  {() => <ProfileForm />}
                </Formik>
              </LoadingWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserProfile;