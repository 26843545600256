import { Dialog, Grid, IconButton } from '@mui/material';
import { FC } from 'react'
import { MdClose } from 'react-icons/md';
import { Heading3 } from '../../commoncomponents/designs/Text';
import SiteForm from '../../sitesettings/SiteForm';

type Props = {
    handleClose: () => void;
}

const AddSite: FC<Props> = ({
    handleClose
}) => {
    return (
        <Dialog open maxWidth={"lg"} fullWidth onClose={handleClose}>
            <Grid container padding={["30px", "30px 100px"]} justifyContent={"space-between"}>
                <Grid item xs={6} md={3}>
                    <Heading3>Add Site</Heading3>
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={handleClose}>
                        <MdClose />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid padding={["20px", "20px 100px"]}>
                <SiteForm handleClose={handleClose} />
            </Grid>
        </Dialog>
    )
}

export default AddSite;