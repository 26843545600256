import Styled from "@emotion/styled";
import { FormControl, Typography } from "@mui/material";
import { colors } from "../../../constants/Colors";
import { fontFamily } from "../../../constants/FontFamily";

export const StyledSelectField = Styled(FormControl)`
& .MuiOutlinedInput-root {
    background: ${colors.secondary.neutral};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    height: 56px;
}

& .MuiSelect-select {
    display: flex;
    align-items: center;
}
`;

export const LogoContainer = Styled.div`
    background: ${colors.secondary.neutral};
    border: 1px solid ${colors.primary.neutral};
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 6px;
    padding: 19px;
`;

export const FileUploadContainer = Styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    height: 136px;
    justify-content: center;
`;

export const FileUploadTitle = Styled(Typography)`
    font-family: ${fontFamily.primary};
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 28px;
    display: flex;
    align-items: center;
    text-align: center;
    color: ${colors.primary.dark};
`;

export const FileUploadSubMessage = Styled(FileUploadTitle)`
    color: ${colors.primary.light};
`;

type StyledPhoneInputProps = {
    iserror: boolean;
}
export const StyledPhoneInput = Styled.div<StyledPhoneInputProps>`
    & .special-label {
        display: none !important;
    }
    & .form-control  {
        width: 100% !important;
        ${(props) => props.iserror && "border-color: red"}
    }
`;