import Axios, { AxiosRequestConfig } from "axios";

export class Client<T> {
    protected constructor() {
        this._data = {};
    }

    public static getInstance<U>() {
        if (!this._instance) {
            this._instance = new Client<U>();
        }

        return this._instance;
    }

    public getData(url: string,
        config?: AxiosRequestConfig,
        forceRefresh = false): Promise<any> {
        if (forceRefresh || !this._data[url]) {
            return Axios.get(url, config)
                .then((result: any) => {
                    this._data[url] = Promise.resolve(result);
                    return this._data[url];
                })
                .catch((err) => {
                    return Promise.reject(err);
                });
        }

        return this._data[url];
    }

    public getImage(
        url: string,
        config?: AxiosRequestConfig,
        forceRefresh = false
    ): Promise<any> {
        if (forceRefresh || !this._data[url]) {
            return Axios.get(url, {
                headers: {
                    accept: "image/*",
                },
                responseType: "blob",
                ...config,
            })
                .then(
                    (result) =>
                        new Promise((resolve, reject) => {
                            const reader = new FileReader();
                            reader.readAsDataURL(result.data);
                            reader.onloadend = () => resolve(reader.result);
                        })
                )
                .catch((err) => {
                    return Promise.reject(err);
                });
        }
        return this._data[url];
    }

    public updateData(url: string, data: any, config?: AxiosRequestConfig): Promise<any> {
        return Axios.put(url, data, config)
            .then((result: any) => {
                return result;
            })
            .catch((err) => {
                new Error(err);
                return Promise.reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
            });
    }

    public createData(
        url: string,
        data: any,
        forceRefresh = true,
        config?: AxiosRequestConfig
    ): Promise<any> {
        if (forceRefresh || !this._data[url]) {
            return Axios.post(url, data, config)
                .then((result: any) => {
                    this._data[url] = Promise.resolve(result);
                    return this._data[url];
                })
                .catch((err) => {
                    new Error(err);
                    return Promise.reject(err.response.data.errors ? err.response.data.errors : err.response.data.message);
                });
        }
        return this._data[url];
    }

    public deleteData(url: string): Promise<any> {
        return Axios.delete(url)
            .then((result: any) => {
                return result;
            })
            .catch((err) => {
                new Error(err);
                return Promise.reject(err.response.data.message);
            });
    }

    public setAuthenticationClient = (username: string, password: string): void => {
        Axios.interceptors.request.use(
            async (config: any): Promise<AxiosRequestConfig> => {
                // eslint-disable-next-line no-param-reassign
                config.headers.Authorization = `Basic ${btoa(`${username}:${password}`)}`;
                return config;
            }
        );
    };

    private static _instance: Client<any>;

    private _data: { [key: string]: Promise<T> };
}
