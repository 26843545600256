import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../store/auth/Selector';
import { PaperContainer } from '../manageproperty/Style';
import { Heading3, Label2 } from '../commoncomponents/designs/Text';
import { FileUploadButton, TextButton } from '../commoncomponents/designs/Button';
import { SiteServiceOption, downloadServiceUsageTemplate, manageAddServiceUsageTabOption } from './Util';
import { AiOutlineCloudUpload } from 'react-icons/ai';
import ImageUploadModal from '../commoncomponents/ImageUploadModal';
import LeftSection from '../commoncomponents/sitesection/LeftSection';
import SideNavbar from '../commoncomponents/sidenavbar';
import BreadcrumbWrapper from '../commoncomponents/designs/BreadcrumbWrapper';
import { SiteMenus, SiteSideBarOptions, manageSiteTabOption } from '../managesite/Util';
import MobileNavbarMenu from '../commoncomponents/sidenavbar/MobileNavbar';
import { useUsageMultipleProperty } from './Hooks';

const AddMultipleService = () => {
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const role = useSelector(useGetUserRole)
    const { id, setMultiplePropertyFile, mulitplePropertyFile, uploadFilePercent } = useUsageMultipleProperty();
    if (mobileView) {
        return (
            <Grid>
                <MobileNavbarMenu
                    menuOptions={SiteMenus(id || "", role)}
                    selectedMenuId={-1}
                />
                <Grid mt={"39px"}>
                    <PaperContainer padding={50} elevation={0}>
                        <Grid>
                            <Heading3>Pre-populate your service usgae</Heading3>
                            <Label2>Please download the csv file below to use as a template when creating service usage. Then, upload it again here once you have finished filling it out.</Label2>
                        </Grid>
                        <Grid container spacing={"10px"} mt={"20px"}>
                            <Grid item md={6} xs={12}>
                                <TextButton
                                    onClick={() => downloadServiceUsageTemplate()}
                                    fullWidth label='Download csv template' icon={<AiOutlineCloudUpload />} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FileUploadButton
                                    fullWidth
                                    onFileSelect={(file) => setMultiplePropertyFile(file)}
                                    acceptFileTypes='.csv'
                                    label='Upload File'
                                    icon={<AiOutlineCloudUpload />}
                                />
                            </Grid>
                            {
                                mulitplePropertyFile && <ImageUploadModal
                                    progress={uploadFilePercent}
                                    file={mulitplePropertyFile as File}
                                    handleClose={() => {
                                        setMultiplePropertyFile(null);
                                    }}
                                />
                            }
                        </Grid>
                    </PaperContainer>
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar selectedMenuId={-1} sidebarOptions={SiteSideBarOptions(id || "", role)} />
                </LeftSection>
            </Grid>
            <Grid item xs={7}>
                <BreadcrumbWrapper
                    tabType={manageAddServiceUsageTabOption(id || "")}
                />
                <Grid mt={"39px"}>
                    <PaperContainer padding={50} elevation={0}>
                        <Grid>
                            <Heading3>Pre-populate your service usage</Heading3>
                            <Label2>Please download the csv file below to use as a template when creating service usage. Then, upload it again here once you have finished filling it out.</Label2>
                        </Grid>
                        <Grid container spacing={"10px"} mt={"20px"}>
                            <Grid item md={6} xs={12}>
                                <TextButton
                                    onClick={() => downloadServiceUsageTemplate()}
                                    fullWidth label='Download csv template' icon={<AiOutlineCloudUpload />} />
                            </Grid>
                            <Grid item md={6} xs={12}>
                                <FileUploadButton
                                    fullWidth
                                    acceptFileTypes='.csv'
                                    onFileSelect={(file) => setMultiplePropertyFile(file)}
                                    label='Upload File'
                                    icon={<AiOutlineCloudUpload />}
                                />
                            </Grid>
                            {
                                mulitplePropertyFile && <ImageUploadModal
                                    progress={uploadFilePercent}
                                    file={mulitplePropertyFile as File}
                                    handleClose={() => {
                                        setMultiplePropertyFile(null);
                                    }}
                                />
                            }
                        </Grid>
                    </PaperContainer>
                </Grid>
            </Grid>
        </Grid>
    )
}

export default AddMultipleService