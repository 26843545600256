import { ApiState } from "../CommonType";

export interface SiteListType extends ApiState {
    siteList: SiteType[];
    selectedSiteDetail?: SiteDetailType;
    manageProperty: ManagePropertyListType[];
    selectedPropertyDetail?: ManagePropertyDetailType;
    propertyCustomers: PropertyCustomerList[];
}

export interface ManagePropertyListType extends ApiState {
    id: number;
    created: string;
    modified: string;
    siteid: number;
    name: string;
    type: string;
    address: string[];
    invoiceOptions: InvoiceOptionType[];
    services: ServiceType[];
};

export interface ManagePropertyDetailType {
    address: string;
    xeroCategory1: string;
    xeroCategory2: string;
    xeroOption1: string;
    xeroOption2: string;
    services: ServiceType[];
    name: string;
    type: string;
    imageUrl: string;
    image: File | null;

}

export interface InvoiceOptionType {
    value: string;
    label: string;
};

export interface ServiceType {
    id: number;
    isEnabled: string;
    datumStream: DatumStreamType;
};

export interface DatumStreamType {
    nodeId: number;
    sourceId: string;
    metric: string;
}
export interface SiteType {
    id: number;
    created: string;
    active: boolean;
    modified: string;
    organisationId: number;
    name: string;
    zoneId: string;
    lang: string;
    currencyCode: string;
    billingCycle: string;
    billAfterDays: number;
    dueAfterDays: number;
    invoiceReference: string;
    invoiceImage: string | null | File;
    invoiceImageUrl: string;
    invoiceTransactionDescription: string;
};

export interface SiteDetailType extends SiteType {
    solarNetworkTokenId: string;
    xeroInvoiceStatus: string;
    solarNetworkTokenSecret: string;
}

export type CustomerSearchType = {
    identifier: string;
    name: string;
    email: string;
    themeIdentifier: string;
};

export type FutureCustomerType = {
    customerInfo: CustomerSearchType | null;
    inDate: Date | null;
    outDate: Date | null;
};

export interface PropertyCustomerList {
    id: number;
    propertyId: number;
    name: string;
    start: string;
    end: string;
    hasPublishedInvoice: boolean;
    xeroDetails: {
        id: number;
        conatctId: string;
        brandingThemeId: string;
    }
}
export const UPDATE_SITE_LIST = 'UPDATE_SITE_LIST';
export const UPDATE_SITE_DETAIL = 'UPDATE_SITE_DETAIL';
export const UPDATE_PROPERTY_LIST = 'UPDATE_PROPERTY_LIST';
export const UPDATE_SITE_IMAGE = 'UPDATE_SITE_IMAGE';
export const UPDATE_SITE_PROPERTY_DETAIL = 'UPDATE_SITE_PROPERTY_DETAIL';
export const UPDATE_PROPERTY_IMAGE = "UPDATE_PROPERTY_IMAGE";
export const UPDATE_CUSTOMER_PROPERTY_LIST = "UPDATE_CUSTOMER_PROPERTY_LIST";
