import { ApiState } from "../CommonType";
import { SiteType } from "../sites/Type";

export interface OrgListType extends ApiState {
    orgList: OrgType[];
    orgDetail?: OrgDetailType;
    isLoading: boolean;
    error: string;
};

export interface OrgType  {
    id: string;
    createdAt: string;
    modifiedAt: string;
    name: string;
    userList: OrgSwitchListType,
    siteList: OrgSiteListType
};

export interface OrgDetailType extends OrgType {
    image: File | null;
    imageUrl: string;
};

export type OrgSwitchListType = {
    allList: OrgSwitchType[];
    selectedList: OrgSwitchType[];
};

export type OrgSwitchType = {
    id: number;
    name: string;
}

export type OrgSiteListType = {
    allList: SiteType[],
    selectedList: SiteType[]
}
export const ORG_LIST = "ORG_LIST";
export const SELECTED_ORG_DETAIL = "SELECTED_ORG_DETAIL";
export const UPDATE_ORG_USER_LIST = "UPDATE_ORG_USER_LIST";
export const UPDATE_ORG_SITE_LIST = "UPDATE_ORG_SITE_LIST";
export const UPDATE_ORG_SELECTED_SITE_LIST = "UPDATE_ORG_SELECTED_SITE_LIST";
export const UPDATE_ORG_SELECTED_USER_LIST = "UPDATE_ORG_SELECTED_USER_LIST";
export const UPDATE_ORG_ALL_USER_LIST = "UPDATE_ORG_ALL_USER_LIST";
export const UPDATE_ORG_ALL_SITE_LIST = "UPDATE_ORG_ALL_SITE_LIST";
export const UPDATE_ORG_IMAGE = "UPDATE_ORG_IMAGE";
