import { object, ref, string } from "yup";
import { AddUserType } from "../../../store/user/Type";

export const AddUserInitialValue: AddUserType = {
    email: "",
    confirmEmail: "",
    firstName: "",
    lastName: "",
    password: "",
    confirmPassword: "",
    image: null,
    role: "none",
    imageUrl: "",
    receiveEmail: false,
};

export const AddUserValidationSchema = object().shape({
    firstName: string().required("First name is required"),
    email: string().email("Please enter correct mail").required("Email is mandatory"),
    role: string().notOneOf(["none"], "Please select role").required("Role is mandatory"),
    confirmEmail: string().email("Please enter correct mail")
        .oneOf([ref('email'), null], 'Email must match').required("Confirm email is mandatory"),
    password: string().required('Password is required'),
    confirmPassword: string()
        .oneOf([ref('password'), null], 'Passwords must match')
});
