import { Grid } from '@mui/material'
import { Formik } from 'formik'
import { SiteSettingInitialValue, SiteSettingValidationSchema } from './Util'
import ImageUploadModal from '../commoncomponents/ImageUploadModal';
import { useSiteFormHook } from './Hooks';
import { FC } from "react";
import { SiteDetailType } from '../../store/sites/Type';
import FormikSiteForm from './FormikSiteForm';

type Props = {
    handleClose?: () => void;
    siteInfo?: SiteDetailType;
}
const SiteForm: FC<Props> = ({
    handleClose,
    siteInfo
}) => {

    const {
        setUploadPercentage,
        updateSite,
        uploadPercentage,
        setIsImageUploading,
        isImageUploading
    } = useSiteFormHook();

    return (
        <Grid>
            <Formik
                initialValues={SiteSettingInitialValue(siteInfo)}
                validationSchema={SiteSettingValidationSchema}
                onSubmit={(values, { setSubmitting, setFieldError }) => {
                    updateSite(values, setSubmitting, setFieldError, handleClose);
                }}
                enableReinitialize
            >
                {
                    ({ values, setFieldValue }) => (
                        <>
                            <FormikSiteForm
                                setIsImageUploading={setIsImageUploading}
                                setUploadPercent={setUploadPercentage}
                            />
                            {
                                values.invoiceImage && isImageUploading && <ImageUploadModal
                                    progress={uploadPercentage}
                                    file={values.invoiceImage as File}
                                    handleClose={() => setFieldValue("invoiceImage", null)}
                                />
                            }
                        </>
                    )
                }
            </Formik>
        </Grid>
    )
}

export default SiteForm