import { AiOutlineSetting } from "react-icons/ai";
import { BsPerson, BsTag } from "react-icons/bs";
import { MdDataUsage } from "react-icons/md";
import { PropertyCustomerList } from "../../../store/sites/Type";
import { doesLoggedinUserHavePermission } from "../../../util/SiteUtil";
import { PERMISSIONS, USER_ROLES } from "../../../util/Type";
import { tabOptionType } from "../../commoncomponents/designs/Type";

export const managePropertyEditTabOption = (id: string) => [
    {
        label: 'Portfolio',
        url: '/',
    },
    {
        label: 'Manage Site',
        url: `/site/${id}/info`,
    },
    {
        label: 'Manage Property',
        url: `/site/${id}/manageproperties`,
    },
];

export const tabOptions = (role: string): tabOptionType[] => [
    {
        label: 'Customers',
        value: 'customers',
        icon: <BsPerson />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.NEW_RESIDENT, PERMISSIONS.VIEW) || doesLoggedinUserHavePermission(role, USER_ROLES.RESIDENT_MOVE_IN_OUT_DATE, PERMISSIONS.VIEW)
    },
    {
        label: 'Services',
        value: 'services',
        icon: <BsTag />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.SITE_PROPERTY_SERVICES, PERMISSIONS.VIEW)
    },
    {
        label: 'Property Settings',
        value: 'settings',
        icon: <AiOutlineSetting />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.PROPERTY_SETTINGS, PERMISSIONS.VIEW)
    },
    {
        label: 'Usage',
        value: 'usage',
        icon: <MdDataUsage />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.MANUAL_ADD_USAGE, PERMISSIONS.VIEW)
    },
];

export const serviceTabSliderOptions = [
    {
        id: 'electricity',
        label: 'Electricity',
        isShowSettings: true,
    },
    {
        id: 'coldWater',
        label: 'Cold water',
        isShowSettings: true,
    },
    {
        id: 'hotWater',
        label: 'How water',
        isShowSettings: true,
    },
    {
        id: 'internet',
        label: 'Internet',
        isShowSettings: false,
    },
    {
        id: 'washing',
        label: 'Washing',
        isShowSettings: false,
    },
    {
        id: 'Dryer',
        label: 'Dryer',
        isShowSettings: false,
    },
    {
        id: 'guestRoom1',
        label: 'Guest Room 1',
        isShowSettings: false,
    },
    {
        id: 'guestRoom2',
        label: 'Guest Room 2',
        isShowSettings: false,
    },
    {
        id: 'carPark',
        label: 'Car park',
        isShowSettings: false,
    },
    {
        id: 'garden1',
        label: 'Garden 1',
        isShowSettings: false,
    }
];
