import { Grid } from "@mui/material";
import { GridCellParams } from "@mui/x-data-grid";
import { useMemo, useState } from "react";
import { AiOutlineDelete } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useGetUserRole } from "../../../store/auth/Selector";
import { deleteOrgListItem } from "../../../store/organisation/ActionCreator";
import { useGetOrgList, useGetOrgListLoading } from "../../../store/organisation/Selector";
import { doesLoggedinUserHavePermission, formatDateString } from "../../../util/SiteUtil";
import { PERMISSIONS, USER_ROLES } from "../../../util/Type";
import { StyledColumnText, StyledHeader } from "../../commoncomponents/datagrid/DataGridStyle";
import { TextButton } from "../../commoncomponents/designs/Button";
import { OrgListDeleteType } from "./Type";

export const useOrgListHooks = () => {
    const [selectedDeleteItem, setSelectedDeleteItem] = useState<OrgListDeleteType | null>(null);
    const navigate = useNavigate();
    const orgList = useSelector(useGetOrgList);
    const isLoading = useSelector(useGetOrgListLoading);
    const dispatch = useDispatch();
    const role = useSelector(useGetUserRole);

    const orgListColumns = () => useMemo(() => ([
        {
            field: "name",
            flex: 1,
            minWidth: 200,
            renderHeader: () => <StyledHeader label="ORG. TITLE" />,
            renderCell: (params: GridCellParams) => <StyledColumnText label={params.row.name} />
        },
        {
            field: "createdAt",
            flex: 1,
            minWidth: 200,
            renderHeader: () => <StyledHeader label="Created On" />,
            renderCell: (params: GridCellParams) => <StyledColumnText label={formatDateString(params.row.createdAt)} />
        },
        {
            field: "id",
            flex: 1,
            minWidth: 200,
            renderHeader: () => <StyledHeader label="ACTION" />,
            renderCell: (params: GridCellParams) => <Grid container gap={"10px"}>
                <Grid item>
                    <TextButton
                        disabled={!doesLoggedinUserHavePermission(role, USER_ROLES.ORGANISATION_MANAGEMENT, PERMISSIONS.EDIT)}
                        onClick={() => navigate(`/organisation/${params.row.id}/edit`)}
                        label="EDIT" icon={<FiEdit />} />
                </Grid>
                <Grid item>
                    <TextButton
                        disabled={!doesLoggedinUserHavePermission(role, USER_ROLES.ORGANISATION_MANAGEMENT, PERMISSIONS.DELETE)}
                        onClick={() => dispatch(deleteOrgListItem(params.row.id))}
                        label="DELETE"
                        icon={<AiOutlineDelete />} />
                </Grid>
            </Grid>
        },
    ]
    ), []);

    return {
        orgListColumns,
        selectedDeleteItem,
        orgList,
        isLoading
    };
};
