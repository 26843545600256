import { BiBuilding } from "react-icons/bi";
import { BsTag } from "react-icons/bs";
import { FiSettings } from "react-icons/fi";
import { HiOutlineKey } from "react-icons/hi";
import { number, object, string } from "yup";
import { SiteDetailType } from "../../store/sites/Type";
import { doesLoggedinUserHavePermission } from "../../util/SiteUtil";
import { PERMISSIONS, USER_ROLES } from "../../util/Type";
import { SideBarOptionsType } from "../commoncomponents/sidenavbar/Util";
import { SiteSettingType } from "./Type";

export const SiteSettingsMenus = (id: string, role: string): SideBarOptionsType[] => [
    {
        id: 1,
        label: 'Manage Properties',
        url: `/site/${id}/manageproperties`,
        icons: <BiBuilding fontSize={"30px"} />,
        isAvailaible: true
    },
    {
        id: 2,
        label: 'Site Services',
        url: `/site/${id}/services`,
        icons: <BsTag fontSize={"30px"} />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.SERVICE_FOR_SITE, PERMISSIONS.VIEW)
    },
    {
        id: 3,
        label: 'Site Settings',
        url: `/site/${id}/settings`,
        icons: <FiSettings fontSize={"30px"} />,
        isSelected: true,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.SITE_SETTINGS, PERMISSIONS.VIEW)
    },
    {
        id: 4,
        label: 'Shared Services',
        url: `/site/${id}/sharedservices`,
        icons: <HiOutlineKey fontSize={"30px"} />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.SHARED_SERVICE, PERMISSIONS.VIEW)
    },
];

export const SiteSettingsEditTabOption = (id: string) => [
    {
        label: 'Portfolio',
        url: '/',
    },
    {
        label: 'Manage Site',
        url: `/site/${id}/info`,
    },
    {
        label: 'Site settings',
    },
];

export const SiteSettingInitialValue = (siteInfo?: SiteDetailType): SiteSettingType => ({
    id: siteInfo?.id.toString() || "",
    organisationId: siteInfo?.organisationId.toString() || "none",
    timezone: siteInfo?.zoneId || "none",
    locale: siteInfo?.lang || "none",
    currencyCode: siteInfo?.currencyCode || "none",
    billingPeriod: siteInfo?.billingCycle || "none",
    dueDays: siteInfo?.dueAfterDays.toString() || "",
    startDate: siteInfo?.billAfterDays.toString() || "",
    propertyName: siteInfo?.name || "",
    xeroInvoiceStatus: siteInfo?.xeroInvoiceStatus || "none",
    invoiceReference: siteInfo?.invoiceReference || "",
    invoiceImage: null,
    invoiceImageUrl: siteInfo?.invoiceImageUrl || "",
    invoiceTransactionDescription: siteInfo?.invoiceTransactionDescription || "",
    solarNetwork: {
        token: siteInfo?.solarNetworkTokenId || "",
        secret: siteInfo?.solarNetworkTokenSecret || "",
    }
});

export const SiteSettingValidationSchema = object().shape({
    propertyName: string().required('Property name is required'),
    organisationId: string().notOneOf(["none"], "Organisation is required").required('Organisation is required'),
    invoiceReference: string().required('Invoice reference is required'),
    timezone: string().notOneOf(["none"], "Timezone is required").required('Timezone is required'),
    locale: string().notOneOf(["none"], "Locale is required").required('Locale is required'),
    currencyCode: string().notOneOf(["none"], "Currency is required").required('Currency is required'),
    billingPeriod: string().notOneOf(["none"], "Billing period is required").required('Billing period is required'),
    dueDays: number().required('Due days is required'),
    startDate: number().min(1, "Should be greater then 1").max(31, "Should not be greater then 31").required('Start date is required'),
})

export const INVOICE_AVAILABLE_VALUE = ['serviceName', 'serviceDescription', 'serviceCategory', 'billingType', 'tariffKind'];