import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';
import { colors } from '../../../constants/Colors';
import { useGetUserRole } from '../../../store/auth/Selector';
import BreadcrumbWrapper from '../../commoncomponents/designs/BreadcrumbWrapper';
import TabWrapper from '../../commoncomponents/designs/TabWrapper';
import SideNavbar from '../../commoncomponents/sidenavbar';
import MobileNavbarMenu from '../../commoncomponents/sidenavbar/MobileNavbar';
import LeftSection from '../../commoncomponents/sitesection/LeftSection';
import { UserSideMenu } from '../../user/Util';
import { useEditOrganisationHook } from './Hooks';
import OrgSettings from './settingform';
import OrganisationSiteList from './sites';
import OrganisationUserList from './user';
import { addOrganisationStepper, addUsertabOptions, editOrganisationStepper, updateUserTabOptions } from './Util';

const OrganisationEdit = () => {

    const { selectedTab, setSelectedTab, orgInfo, id } = useEditOrganisationHook();
    const role = useSelector(useGetUserRole);
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));

    const renderView = () => {
        if (selectedTab === "settings") {
            return <OrgSettings orgInfo={orgInfo} />;
        }
        if (selectedTab === "users") {
            return <OrganisationUserList orgInfo={orgInfo} />;
        }

        return <OrganisationSiteList orgInfo={orgInfo} />;
    };


    if (mobileView) {
        return (
            <Grid>
                <MobileNavbarMenu
                    menuOptions={UserSideMenu(role)}
                    selectedMenuId={4}
                />
                <Grid item xs={12}>
                    <Grid mt={"35px"}>
                        <TabWrapper
                            key={id}
                            tabOptions={id ? updateUserTabOptions(role) : addUsertabOptions(role)}
                            setValue={setSelectedTab}
                            value={selectedTab}
                            selectedTextColor={colors.primary.dark}
                        />
                    </Grid>
                    <Grid mt={"40px"}>
                        {renderView()}
                    </Grid>
                </Grid>
            </Grid>
        )
    }


    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar
                        selectedMenuId={-1}
                        sidebarOptions={UserSideMenu(role)}
                    />
                </LeftSection>
            </Grid>
            <Grid item xs={7}>
                <BreadcrumbWrapper
                    key={orgInfo?.name}
                    tabType={orgInfo?.name ? editOrganisationStepper(orgInfo?.name) : addOrganisationStepper}
                />
                <Grid mt={"35px"}>
                    <TabWrapper
                        key={id}
                        tabOptions={id ? updateUserTabOptions(role) : addUsertabOptions(role)}
                        setValue={setSelectedTab}
                        value={selectedTab}
                        selectedTextColor={colors.primary.dark}
                    />
                </Grid>
                <Grid mt={"40px"}>
                    {renderView()}
                </Grid>
            </Grid>
        </Grid>
    )
}

export default OrganisationEdit;