import { Grid, Paper } from '@mui/material';
import { FC, useEffect, useState } from 'react';
import { colors } from '../../constants/Colors';
import { getSiteListImages } from '../../store/sites/ActionCreator';
import { Paragraph2 } from '../commoncomponents/designs/Text';
import { SiteCardContainer } from './Style';
import NoImage from "../../images/dummyimages/noimgproperty.svg";

type SiteCardProps = {
    title: string;
    onClick: () => void;
    description: string;
    id: number;
    isActive?: boolean;
}
const SiteCard: FC<SiteCardProps> = ({
    title,
    description,
    onClick,
    id,
    isActive
}) => {

    const [logo, setLogo] = useState(NoImage);

    useEffect(() => {
        getSiteListImages(id.toString())
            .then((res: string) => setLogo(res))
            .catch(() => setLogo(NoImage))
    }, []);

    return (
        <SiteCardContainer isdisabled={isActive ? 1 : 0} onClick={onClick} elevation={0}>
            <img src={logo} height={200} alt="logo" />
            <Grid py={"12px"}>
                <Paragraph2 color={colors.primary.light} textAlign={"center"}>{title}</Paragraph2>
                <Paragraph2 color={colors.primary.light} textAlign={"center"}>{description}</Paragraph2>
            </Grid>
        </SiteCardContainer>
    )
};

export default SiteCard