import { Grid } from '@mui/material';
import DataGridWrapper from '../../commoncomponents/datagrid/DataGrid';
import { useOrgListHooks } from './Hooks';

const OrganisationList = () => {
    const { orgListColumns, isLoading, orgList } = useOrgListHooks();

    return (
        <Grid>
            <DataGridWrapper
                rows={orgList as any}
                columns={orgListColumns()}
                rowHeight={72}
                loading={isLoading}
            />
        </Grid>
    );
};

export default OrganisationList