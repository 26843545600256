import { Grid } from '@mui/material';
import { FC, useState } from 'react';
import { BsSpeedometer2 } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../../../store/auth/Selector';
import { ServiceListType } from '../../../../store/siteservice/Type';
import { doesLoggedinUserHavePermission } from '../../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../../util/Type';
import { TextButton } from '../../../commoncomponents/designs/Button';
import { SettingContainer, StyledSwitch, SwitchLabel } from '../Style';

type ServiceListCardProps = {
    isEnabled: boolean;
    serviceInfo: ServiceListType;
    handleServiceChange: (serviceid: string, isEnabled: boolean) => void;
    setSelectedServices: (service: ServiceListType) => void;
}

const ServiceListCard: FC<ServiceListCardProps> = ({
    handleServiceChange,
    isEnabled,
    serviceInfo,
    setSelectedServices
}) => {

    const [serviceEnabled, setServiceEnabled] = useState(isEnabled);
    const role = useSelector(useGetUserRole);

    return (
        <SettingContainer container p={"26px"} justifyContent={"space-between"} alignItems={"center"}>
            <Grid item xs={8}>
                <SwitchLabel
                    checked={serviceEnabled}
                    disabled={!doesLoggedinUserHavePermission(role, USER_ROLES.SITE_PROPERTY_SERVICES, PERMISSIONS.EDIT)}
                    control={<StyledSwitch
                        onClick={() => {
                            handleServiceChange(serviceInfo.id.toString(), !serviceEnabled);
                            setServiceEnabled(!serviceEnabled)
                        }}
                    />}
                    label={serviceInfo.name}
                />
            </Grid>
            {
                doesLoggedinUserHavePermission(role, USER_ROLES.SITE_PROPERTY_SERVICES, PERMISSIONS.EDIT)
                && serviceInfo.billingType === "Metered" &&
                <Grid item xs={4} md={"auto"}>
                    <TextButton onClick={() => setSelectedServices(serviceInfo)} icon={<BsSpeedometer2 />} label='METER SETTINGS' />
                </Grid>
            }
        </SettingContainer>
    )
}

export default ServiceListCard