import { FC, useState, MouseEvent, useEffect } from 'react'
import { IconButton, Avatar, Popover, Grid } from "@mui/material";
import { UserListType } from './Type';
import { Label } from '../commoncomponents/designs/Text';
import { format } from 'date-fns';
import { getUserImage } from '../../store/user/ActionCreator';

type UserInfoProps = {
    userDetail: UserListType;
}

const UserInfo: FC<UserInfoProps> = ({
    userDetail
}) => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [logo, setLogo] = useState("");

    useEffect(() => {
        getUserImage(userDetail.id.toString())
            .then((res) => {
                setLogo(res);
            })
    }, []);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <Avatar alt="Cindy Baker" src={logo} />
            </IconButton>
            <Popover
                id={Boolean(anchorEl) ? "user-info" : undefined}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => setAnchorEl(null)}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Grid p={"10px"}>
                    <Label>
                        {userDetail.email}
                    </Label>
                    <Label mt={"10px"}>
                        {format(new Date(userDetail.createdAt), "dd MMM yyyy")}
                    </Label>
                </Grid>
            </Popover>
        </div>
    );
}

export default UserInfo;
