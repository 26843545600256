import { Grid, Menu } from '@mui/material'
import { FC } from 'react'
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useGetUserRole } from '../../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../util/Type';
import { PrimaryButton } from '../../commoncomponents/designs/Button';

type AddServiceMobileProps = {
    anchorEl: HTMLElement | null;
    handleClose: () => void;
    handleAddService: () => void;
}
const AddServiceMobile: FC<AddServiceMobileProps> = ({
    anchorEl,
    handleClose,
    handleAddService
}) => {

    const role = useSelector(useGetUserRole);

    return (
        <Menu
            anchorEl={anchorEl}
            open={!!anchorEl}
            PaperProps={{
                style: {
                    width: 190
                }
            }}
            onClose={handleClose}
        >
            <Grid p={"10px"} spacing={"10px"} container>
                <Grid item xs={12}>
                    {
                        doesLoggedinUserHavePermission(role, USER_ROLES.SERVICE_FOR_SITE, PERMISSIONS.CREATE)
                        && <PrimaryButton
                            onClick={handleAddService}
                            buttonview='sm' fullWidth label={"Add Service"} />
                    }

                </Grid>
            </Grid>
        </Menu>

    )
}

export default AddServiceMobile