import { Grid } from '@mui/material';
import { FC } from 'react';
import { colors } from '../../constants/Colors';
import { SiteType } from '../../store/sites/Type';
import { PrimaryButton } from '../commoncomponents/designs/Button';
import { Paragraph2 } from '../commoncomponents/designs/Text';
import { PaperContainer, PropertyCardImage } from '../manageproperty/Style';
import NoImage from "../../images/dummyimages/noimgproperty.svg";
import { useSelector } from 'react-redux';
import { useGetUserRole } from '../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../util/Type';

type AddServiceProps = {
    propertyInfo: SiteType;
    addServiceClick: () => void;
}

const AddService: FC<AddServiceProps> = ({
    propertyInfo,
    addServiceClick
}) => {
    const { name, invoiceReference, invoiceImageUrl } = propertyInfo;
    const role = useSelector(useGetUserRole);

    return (
        <PaperContainer elevation={0}>
            <PropertyCardImage src={invoiceImageUrl || NoImage} />
            <Grid mt={"15px"}>
                <Paragraph2 textAlign={"center"} color={colors.primary.light}>{name}</Paragraph2>
                <Paragraph2 textAlign={"center"} color={colors.primary.light}>{invoiceReference}</Paragraph2>
            </Grid>
            <Grid mt={"15px"} spacing={"20px"} container >
                <Grid item xs={12}>
                    {
                        doesLoggedinUserHavePermission(role, USER_ROLES.SERVICE_FOR_SITE, PERMISSIONS.CREATE)
                        && <PrimaryButton variant='contained'
                            fullWidth
                            onClick={addServiceClick}
                            backgroundcolor={colors.secondary.light}
                            buttonType='secondary'
                            label='Add site services'
                        />
                    }
                </Grid>
            </Grid>
        </PaperContainer>
    );
};

export default AddService;
