import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { ToastSuccessConfig } from "../../../constants/SiteConstants";
import { getUserImage, updateUserDetail, updateUserProfileImageAction } from "../../../store/user/ActionCreator";
import { UserType } from "../../../store/user/Type";
import { forgotPassword } from "../../forgotpassword/Util";
import { UpdateUserType } from "./type";

export const useUpdateUser = (
    userId: number,
    value: UpdateUserType,
    setFieldValue: (type: string, value: any) => void
) => {
    const [percent, setUploadPercent] = useState(0);
    const [openResetPasswordModal, setOpenResetPasswordModal] = useState(false);
    const dispatch = useDispatch();

    useEffect(() => {
        value.image && updateUserProfileImageAction(userId.toString(), value, setUploadPercent)
            .then(() => {
                setFieldValue("image", null);
            })
    }, [value.image]);

    useEffect(() => {
        handleGetUserImage();
    }, []);

    const handleGetUserImage = () => {
        getUserImage(userId.toString())
            .then((res) => {
                setFieldValue("imageUrl", res);
            })
    }

    const handleForgotPassword = (email: string) => {
        forgotPassword(encodeURIComponent(email))
            .then(() => {
                toast.success("Please check email. It contains secret code to reset password", ToastSuccessConfig);
            }).finally(() => {
                setOpenResetPasswordModal(false);
            });
    }

    const handleUpdateUser = (data: UserType, handleClose: () => void, setIsSubmitting: (isSubmitting: boolean) => void) => {
        dispatch(
            updateUserDetail(data).then(() => {
                setIsSubmitting(false);
                handleClose();
            }))
    }
    return { percent, setOpenResetPasswordModal, openResetPasswordModal, handleForgotPassword, handleUpdateUser }
}