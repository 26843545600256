import { useEffect, useState } from "react"
import { useSelector } from "react-redux";
import { useGetOrgList } from "../../store/organisation/Selector";
import { useGetSiteList, useSiteLoading } from "../../store/sites/Selector";
import { OptionType } from "../commoncomponents/fields/Type";

export const usePortfolioHook = () => {

    const siteList = useSelector(useGetSiteList);
    const isLoading = useSelector(useSiteLoading);
    const [openModal, setOpenModal] = useState(false);
    const orgList = useSelector(useGetOrgList);
    const [selectedOrg, setSelectedOrg] = useState("all");
    const [orgDropdown, setOrgDropdown] = useState<OptionType[]>([{
        value: "none",
        label: "Organisation"
    },
    {
        value: "all",
        label: "All"
    }
    ]);

    useEffect(() => {
        setOrgDropdown((prev) => [
            ...prev,
            ...orgList.map((item) => ({
                label: item.name,
                value: item.id
            }))
        ]);
    }, [orgList.length]);

    const toggleOpenModal = () => {
        setOpenModal(!openModal);
    }

    return {
        siteList,
        isLoading,
        openModal,
        toggleOpenModal,
        orgDropdown,
        setSelectedOrg,
        selectedOrg
    };
};
