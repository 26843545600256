import LoadingWrapper from '../commoncomponents/LoadingWrapper';
import { useXeroSuccess } from './Hooks';
import { Grid } from "@mui/material";
import { Paragraph1 } from '../commoncomponents/designs/Text';
import { colors } from '../../constants/Colors';

const XeroSuccess = () => {

    const { isLoading } = useXeroSuccess();

    return (
        <LoadingWrapper height={"400px"} isLoading={isLoading}>
            <Grid container alignItems={"center"} height={"50vh"} justifyContent={"center"}>
                <Paragraph1 color={colors.tertiary.errorTitle}>Authentication failed</Paragraph1>
            </Grid>
        </LoadingWrapper>
    );
}

export default XeroSuccess;
