import { Avatar } from "@mui/material";
import { FC } from "react";
import { Paragraph2 } from "../designs/Text";
import { ColumnHeader, ColumnsText } from "./Style";

type StyledColumnProps = {
    label: JSX.Element | string;
    isMobileView?: boolean;
};
export const StyledHeader: FC<StyledColumnProps> = ({ label }) => <ColumnHeader>{label}</ColumnHeader>;

export const StyledColumnText: FC<StyledColumnProps> = ({ label, isMobileView }) => <ColumnsText ismobile={isMobileView}>{label}</ColumnsText>

type StyledColumnImageTextProps = {
    imgSrc: string;
} & StyledColumnProps;

export const StyledColumnImageText: FC<StyledColumnImageTextProps> = ({ label, isMobileView, imgSrc }) => <ColumnsText ismobile={isMobileView}>
    <Avatar src={imgSrc} />{label}
</ColumnsText>
