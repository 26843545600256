import { Dialog, Grid, IconButton } from '@mui/material'
import { Formik, useFormikContext } from 'formik';
import { FC } from 'react'
import { AiFillCloud, AiOutlineClose } from 'react-icons/ai';
import { useDispatch, useSelector } from 'react-redux';
import { colors } from '../../../../../constants/Colors';
import { addServiceUnits } from '../../../../../store/lookup/ActionCreator';
import { useGetSiteDetail } from '../../../../../store/sites/Selector';
import { CustomTextfieldWrapper } from '../../../../commoncomponents/CustomTextfieldWrapper';
import { PrimaryButton } from '../../../../commoncomponents/designs/Button';
import { Heading3 } from '../../../../commoncomponents/designs/Text';
import { UnitInitialValue, UnitValidationSchema } from './Util';

type CreateUnitModalProps = {
    handleClose: () => void;
}
const CreateUnitModal: FC<CreateUnitModalProps> = ({
    handleClose
}) => {
    const dispatch = useDispatch();
    const { setFieldValue } = useFormikContext();
    const selectedSite = useSelector(useGetSiteDetail);

    const handleAddUnit = (value: {
        name: string
    }, setSubmitting: (submitting: boolean) => void) => {
        selectedSite?.organisationId && dispatch(addServiceUnits(selectedSite.organisationId.toString() || "", value.name))
            .then((id: string) => {
                setFieldValue("simpleTarif.standingUnitRate", id)
            })
            .finally(() => {
                setSubmitting(false);
                handleClose();
            });
    }
    return (
        <Dialog maxWidth={"sm"} fullWidth open onClose={handleClose}>
            <Grid p={"30px"} container justifyContent={"space-between"}>
                <Grid item xs={6}>
                    <Heading3>Create Unit</Heading3>
                </Grid>
                <Grid item>
                    <IconButton onClick={handleClose}>
                        <AiOutlineClose />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid spacing={"20px"} container px={"30px"} pb={"20px"}>
                <Formik
                    initialValues={UnitInitialValue}
                    validationSchema={UnitValidationSchema}
                    onSubmit={(values, { setSubmitting }) => {
                        handleAddUnit(values, setSubmitting);
                        return;
                    }}
                >
                    {({ isSubmitting, handleSubmit }) => <>
                        <Grid item xs={12}>
                            <CustomTextfieldWrapper
                                name={"name"}
                                id={"id"}
                                label={"Unit name"}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <PrimaryButton
                                fullWidth
                                onClick={() => handleSubmit()}
                                textcolor={colors.secondary.light}
                                backgroundcolor={colors.primary.dark}
                                label='Save'
                                isLoading={isSubmitting}
                                icon={<AiFillCloud />}
                            />
                        </Grid>
                    </>}
                </Formik>
            </Grid>
        </Dialog>
    )
}

export default CreateUnitModal;
