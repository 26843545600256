import Styled from "@emotion/styled";
import { Grid } from "@mui/material";

type OrgStyledTable = {
    isodd?: boolean;
};

export const OrgStyledTable = Styled(Grid) <OrgStyledTable>`
    background: ${(props) => props.isodd ? "#F0F3F7" : "#fff"};
`;
