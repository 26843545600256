import { ToastContainer, ToastPrimaryContainer, ToastText } from "./Style"
import { FC } from "react";
import { TiInfoOutline } from "react-icons/ti";
import { colors } from "../../../constants/Colors";
import { Label } from "./Text";
import { BsInfoCircle } from "react-icons/bs";
import { Grid } from "@mui/material";
import { AiOutlineCheck } from "react-icons/ai";

type ToastProps = {
    message: string;
    title?: string;
}
export const InfoToast: FC<ToastProps> = ({
    message
}) => (
    <ToastContainer elevation={0} background={colors.tertiary.info}>
        <ToastText style={{ gap: 10 }}>
            <TiInfoOutline />
            {message}
        </ToastText>
    </ToastContainer>
);

export const TitleToast: FC<ToastProps> = ({
    message,
    title
}) => (
    <ToastPrimaryContainer elevation={0} background={colors.tertiary.title}>
        <BsInfoCircle style={{ color: colors.tertiary.titleText, marginTop: 4 }} />
        <Grid>
            <Label color={colors.tertiary.titleText}>
                {title}
            </Label>
            <Label fontWeight={400} mt={"8px"} color={colors.tertiary.titleText}>
                {message}
            </Label>
        </Grid>

    </ToastPrimaryContainer>
);

export const SavedToast: FC<ToastProps> = ({
    message,
    title
}) => (
    <ToastPrimaryContainer elevation={0} background={colors.tertiary.save}>
        <AiOutlineCheck style={{ color: colors.tertiary.titleText, marginTop: 4 }} />
        <Grid>
            <Label color={colors.tertiary.saveText}>
                {title}
            </Label>
            <Label fontWeight={400} mt={"8px"} color={colors.tertiary.saveText}>
                {message}
            </Label>
        </Grid>

    </ToastPrimaryContainer>
);

export const WarningToast: FC<ToastProps> = ({
    message,
    title
}) => (
    <ToastPrimaryContainer elevation={0} background={colors.tertiary.warningLight}>
        <AiOutlineCheck style={{ color: colors.tertiary.titleText, marginTop: 4 }} />
        <Grid>
            <Label color={colors.tertiary.warningText}>
                {title}
            </Label>
            <Label fontWeight={400} mt={"8px"} color={colors.tertiary.warningMessage}>
                {message}
            </Label>
        </Grid>

    </ToastPrimaryContainer>
);

export const ErrorToast: FC<ToastProps> = ({
    message,
    title
}) => (
    <ToastPrimaryContainer elevation={0} background={colors.tertiary.errorLight}>
        <Grid>
            <Label color={colors.tertiary.errorTitle}>
                {title}
            </Label>
            <Label fontWeight={400} mt={"8px"} color={colors.tertiary.errorMessage}>
                {message}
            </Label>
        </Grid>

    </ToastPrimaryContainer>
);
