import { Grid } from '@mui/material';
import React from 'react'
import DataGridWrapper from '../../commoncomponents/datagrid/DataGrid';
import UpdateUser from '../updateuser';
import { usePastUserHooks } from './Hooks';

const PastUserList = () => {
    const { pastListColumns, pastUserList, selectedUserDetail, setSelectedUserDetail } = usePastUserHooks();
    return (
        <Grid>
            <DataGridWrapper
                rows={pastUserList.filter((item) => item.archived) as any}
                columns={pastListColumns()}
                rowHeight={72}
            />
            {
                selectedUserDetail && <UpdateUser userInfo={selectedUserDetail} handleClose={() => setSelectedUserDetail(null)} />
            }
        </Grid>
    );
};

export default PastUserList