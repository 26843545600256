import { Grid } from '@mui/material';
import { Formik } from 'formik';
import { useParams } from 'react-router-dom';
import { addFutureCustomer, updateCustomerDetail } from '../../../../store/sites/ActionCreator';
import { PropertyCustomerList } from '../../../../store/sites/Type';
import { Paragraph1, Paragraph2 } from '../../../commoncomponents/designs/Text';
import { InfoToast } from '../../../commoncomponents/designs/Toasts';
import { PaperContainer } from '../../Style';
import FutureInfoCard from './FutureInfoCard';
import { CustomerLisInitialValue, FutureCustomerInitialValue } from './Util';
import { FC } from "react";
import CustomerInfoCard from '../currentcustomer/CustomerInfoCard';
import { useDispatch, useSelector } from 'react-redux';
import { useGetUserRole } from '../../../../store/auth/Selector';
import { doesLoggedinUserHavePermission } from '../../../../util/SiteUtil';
import { PERMISSIONS, USER_ROLES } from '../../../../util/Type';

type FutureCustomerProps = {
  customerList: PropertyCustomerList[];
}
const FutureCustomer: FC<FutureCustomerProps> = ({
  customerList
}) => {
  const dispatch = useDispatch();
  const { id, homeid } = useParams<{
    id: string;
    homeid: string;
  }>();
  const role = useSelector(useGetUserRole);

  return (
    <PaperContainer elevation={0} padding={30}>
      <Paragraph1>Future Customer</Paragraph1>
      {
        customerList.length === 0 ? <Grid mt={"35px"}>
          <InfoToast message='No customer assigned yet' />
        </Grid> :
          customerList.map((item) => (
            <Formik
              key={item.id}
              initialValues={CustomerLisInitialValue(item)}
              onSubmit={(values) => {
                id && homeid && dispatch(updateCustomerDetail(id, homeid, values));
              }}
            >
              {
                () => <CustomerInfoCard
                  dateType='in'
                />
              }
            </Formik>
          ))
      }
      <Grid mt={"35px"}>
        <Paragraph2 style={{ fontSize: 12 }}>Full name</Paragraph2>
      </Grid>
      <Grid style={{
        pointerEvents:
          !doesLoggedinUserHavePermission(role, USER_ROLES.RESIDENT_MOVE_IN_OUT_DATE, PERMISSIONS.EDIT)
            ||
            !doesLoggedinUserHavePermission(role, USER_ROLES.RESIDENT_MOVE_IN_OUT_DATE, PERMISSIONS.CREATE)
            ? "none" : "all"
      }}>
        {
          doesLoggedinUserHavePermission(role, USER_ROLES.NEW_RESIDENT, PERMISSIONS.VIEW)
          && <Formik
            initialValues={FutureCustomerInitialValue}
            onSubmit={(value) => {
              id && homeid && dispatch(addFutureCustomer(id, homeid, value));
            }}
          >
            {
              () => <FutureInfoCard />
            }
          </Formik>
        }
      </Grid>
    </PaperContainer>
  )
}

export default FutureCustomer