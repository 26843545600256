import { Grid, useMediaQuery, useTheme } from '@mui/material'
import { useSelector } from 'react-redux'
import { useGetUserRole } from '../../store/auth/Selector'
import { doesLoggedinUserHavePermission } from '../../util/SiteUtil'
import { PERMISSIONS, USER_ROLES } from '../../util/Type'
import BreadcrumbWrapper from '../commoncomponents/designs/BreadcrumbWrapper'
import SideNavbar from '../commoncomponents/sidenavbar'
import MobileNavbarMenu from '../commoncomponents/sidenavbar/MobileNavbar'
import LeftSection from '../commoncomponents/sitesection/LeftSection'
import ManagerPropertyHeader from '../manageproperty/mobile/ManagePropertyHeader'
import { SiteManageProperty } from '../manageproperty/Util'
import { useSharedServicesHook } from './Hooks'
import UploadService from './UploadService'
import { SiteServiceMenus, SiteSharedServicesEditStepper } from './Util'

const SharedServices = () => {
    const { id, propertyInfo } = useSharedServicesHook();
    const theme = useTheme();
    const mobileView = useMediaQuery(theme.breakpoints.down('sm'));
    const role = useSelector(useGetUserRole);

    if (mobileView) {
        return (
            <Grid>
                {
                    propertyInfo && <ManagerPropertyHeader propertyInfo={propertyInfo} hideAction />
                }
                <MobileNavbarMenu
                    menuOptions={SiteServiceMenus(id || "", role)}
                    selectedMenuId={4}
                />
                <Grid mt={"39px"}
                    style={{
                        pointerEvents:
                            !doesLoggedinUserHavePermission(role, USER_ROLES.SHARED_SERVICE, PERMISSIONS.VIEW)
                                ? "none" : "all"
                    }}
                >
                    <UploadService />
                </Grid>
            </Grid>
        );
    };

    return (
        <Grid container spacing={"40px"}>
            <Grid item xs={2}>
                <LeftSection>
                    <SideNavbar
                        selectedMenuId={5}
                        sidebarOptions={SiteManageProperty(id || "", role)}
                    />
                </LeftSection>
            </Grid>
            <Grid item xs={7}>
                <BreadcrumbWrapper
                    tabType={SiteSharedServicesEditStepper(id || "")}
                />
                <Grid mt={"39px"}
                    style={{
                        pointerEvents:
                            !doesLoggedinUserHavePermission(role, USER_ROLES.SHARED_SERVICE, PERMISSIONS.VIEW)
                                ? "none" : "all"
                    }}
                >
                    <UploadService />
                </Grid>
            </Grid>
        </Grid>
    )
}

export default SharedServices