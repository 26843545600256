import { TextField, TextFieldProps } from '@mui/material';
import { FC } from 'react';
import { useField } from "formik";
import { TextFieldContainer } from './CommonStyle';

type CustomTextfieldWrapperProps = {
    id: string;
    name: string;
    label?: string;
    descriptionText?: string;
} & TextFieldProps;

export const CustomTextfieldWrapper: FC<CustomTextfieldWrapperProps> = ({
    label,
    name,
    id,
    descriptionText,
    ...rest
}) => {

    const [field, { error, touched }, { setTouched }] = useField(name);

    return (
        <TextFieldContainer isnotmultiLine={!rest.multiline}>
            <TextField
                {...field}
                id={id}
                name={name}
                onBlur={() => setTouched(true)}
                autoComplete={"off"}
                size={"medium"}
                error={!!error && !!touched}
                helperText={!!error && !!touched ? error : descriptionText || ""}
                variant="outlined"
                label={label}
                {...rest}
            />
        </TextFieldContainer>
    )
}

export const CustomSimpleTextfield: FC<CustomTextfieldWrapperProps> = ({
    label,
    name,
    id,
    descriptionText,
    ...rest
}) => {
    return (
        <TextFieldContainer>
            <TextField
                id={id}
                name={name}
                autoComplete={"off"}
                variant="outlined"
                {...rest}
            />

        </TextFieldContainer>
    )
}