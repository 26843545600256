import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useGetAvailaibleInvoiceStatusDropdown, useGetBiilingDropdown, useGetCurrenciesDropdown, useGetInvoiceRefParamsDropdown, useGetLanguageTagsDropdown, useGetRolesDropdown, useGetTimezonesDropdown, useGetXeroInvoiceStatusDropdown } from "../../store/lookup/Selector";
import { useGetOrgList } from "../../store/organisation/Selector";
import { useGetSiteDetail, useSiteLoading } from "../../store/sites/Selector";
import { addSite, editSiteDetail, getSiteIdDetail, removeSiteImageAction, updateSiteImageAction } from "../../store/sites/ActionCreator";
import { SiteSettingType } from "./Type";
import { toast } from "react-toastify";
import { ToastErrorConfig } from "../../constants/SiteConstants";
import { xeroConnect } from "../../store/siteservice/ActionCreator";
import { INVOICE_AVAILABLE_VALUE } from "./Util";

export const useSettingsHook = () => {
    const { id } = useParams();
    const propertyInfo = useSelector(useGetSiteDetail);
    const dispatch = useDispatch();
    const isLoading = useSelector(useSiteLoading);

    useEffect(() => {
        if (id) {
            dispatch(getSiteIdDetail(id));
        }
    }, []);

    return {
        id,
        propertyInfo,
        isLoading
    }
}

export const useSiteFormHook = () => {

    const dispatch = useDispatch();

    const { id } = useParams<{
        id: string;
    }>();

    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [isImageUploading, setIsImageUploading] = useState(false);

    const updateSite = (
        value: SiteSettingType,
        setIsSubmitting: (isSubmitting: boolean) => void,
        setFieldError: (field: string, message: string | undefined) => void,
        handleClose?: () => void,
    ) => {
        setIsSubmitting(true);
        if (id) {
            dispatch(editSiteDetail(value, id))
                .then(() => {
                    return;
                })
                .catch((err: any) => {
                    err.forEach((element: any) => {
                        toast.error(element.message, ToastErrorConfig);
                        setFieldError(element.location, element.message);
                    });
                })
                .finally(() => {
                    setIsSubmitting(false);
                });
            return;
        }
        dispatch(addSite(value)).then((res: string) => {
            if (!value.invoiceImage) {
                handleClose && handleClose();
                return;
            }
            setIsImageUploading(true);
            dispatch(updateSiteImageAction({
                ...value,
                id: res
            }, setUploadPercentage)).then(() => {
                handleClose && handleClose();
            }).catch(() => setIsImageUploading(true));
        }).catch((err: any) => {
            err.forEach((element: any) => {
                toast.error(element.message, ToastErrorConfig);
                setFieldError(element.location, element.message);
            });
        }).finally(() => {
            setIsSubmitting(false);
        })
    }

    return {
        updateSite,
        setUploadPercentage,
        uploadPercentage,
        isImageUploading,
        setIsImageUploading
    }
};

export const useFormikSiteFormHook = (
    value: SiteSettingType,
    setUploadPercent: (percent: number) => void,
    setIsImageUploading: (isImageUploading: boolean) => void,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
) => {
    const billingDropdown = useSelector(useGetBiilingDropdown);
    const currencyDropdown = useSelector(useGetCurrenciesDropdown);
    const languageTagDropdown = useSelector(useGetLanguageTagsDropdown);
    const rolesDropdown = useSelector(useGetRolesDropdown);
    const timezoneDropdown = useSelector(useGetTimezonesDropdown);
    const orgDropdown = useSelector(useGetOrgList);
    const invoiceRefDropdown = useSelector(useGetInvoiceRefParamsDropdown);
    const [connectingXeroLoading, setConnectingXeroLoading] = useState(false);
    const xeroInvoiceStatusDropdown = useSelector(useGetXeroInvoiceStatusDropdown);
    const availaibleInvoiceStatusDropdown = useSelector(useGetAvailaibleInvoiceStatusDropdown);

    const dispatch = useDispatch();
    const { id } = useParams<{
        id: string;
    }>();

    useEffect(() => {
        if (value.invoiceImage && id) {
            setIsImageUploading(true);
            dispatch(updateSiteImageAction(value, setUploadPercent))
                .then(() => {
                    setIsImageUploading(false);
                }).catch(() => {
                    setIsImageUploading(false);
                    setFieldValue("invoiceImageUrl", "");
                    setFieldValue("invoiceImage", null);
                });
        }
    }, [value.invoiceImage]);

    const removeSiteImage = (
        setValues: (values: unknown, shouldValidate?: boolean | undefined) => void
    ) => {
        id && dispatch(removeSiteImageAction(id)).then(() => {
            setValues({
                ...value,
                invoiceImage: null,
                invoiceImageUrl: ""
            })
        })
    };

    const connectToXero = () => {
        if (id) {
            setConnectingXeroLoading(true);
            xeroConnect(id).then(() => {
                setConnectingXeroLoading(false);
            }).catch(() => {
                setConnectingXeroLoading(false);
            });
        }
    }
    return {
        billingDropdown,
        currencyDropdown,
        languageTagDropdown,
        rolesDropdown,
        timezoneDropdown,
        orgDropdown,
        removeSiteImage,
        invoiceRefDropdown,
        connectToXero,
        connectingXeroLoading,
        xeroInvoiceStatusDropdown,
        availaibleInvoiceStatusDropdown: availaibleInvoiceStatusDropdown.filter((item) => INVOICE_AVAILABLE_VALUE.includes(item.value))
    }
}