import { Grid } from '@mui/material';
import { CardContainer } from '../commoncomponents/CommonStyle';
import { Heading1 } from '../commoncomponents/designs/Text';
import LoginForm from './LoginForm';
import { FC } from "react";

type LoginProps = {
    isLoginConfirming?: boolean;
}
const Login: FC<LoginProps> = ({
    isLoginConfirming
}) => (
    <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid mt={"100px"} item xs={11} sm={6} lg={4}>
            <CardContainer elevation={0}>
                <Heading1 mb={"30px"} textAlign={"center"}>Login</Heading1>
                <LoginForm isLoginConfirming={isLoginConfirming} />
            </CardContainer>
        </Grid>
    </Grid>
);

export default Login;
