import { FC } from 'react'
import { OptionType } from '../commoncomponents/fields/Type'
import { Grid, Popover } from "@mui/material";
import { Label } from '../commoncomponents/designs/Text';
import { FiCopy } from 'react-icons/fi';
import { SiteSettingType } from './Type';

type AvailaibleInvoiceStatusPopupProps = {
    options: OptionType[];
    anchorEl: any;
    handleClose: () => void;
    invoiceTransactionDescription: string;
    setValue: (value: string) => void;
}
const AvailaibleInvoiceStatusPopup: FC<AvailaibleInvoiceStatusPopupProps> = ({
    options,
    anchorEl,
    handleClose,
    setValue,
    invoiceTransactionDescription
}) => {
    return (
        <Popover
            open={true}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
            }}
        >
            <Grid width={"540px"} padding={"10px"}>
                {options.map((item) => (
                    <Grid key={item.value} mt={"10px"} container spacing={"10px"}>
                        <Grid item xs={4}>
                            <Label style={{ wordBreak: "break-all" }}>{item.value}</Label>
                        </Grid>
                        <Grid item xs={7}>
                            <Label style={{ wordBreak: "break-all" }}>{item.label}</Label>
                        </Grid>
                        <Grid item xs={1}>
                            <FiCopy
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                    setValue(`${invoiceTransactionDescription} {${item.value}}`)
                                }}
                            />
                        </Grid>
                    </Grid>
                ))}
            </Grid>
        </Popover>
    )
}

export default AvailaibleInvoiceStatusPopup