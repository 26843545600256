
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { connectAuth, xeroAuthInfo } from "../../store/xeroconnect/ActionCreator";
import { LoginHelper } from "../../util/LoginHelper";

export const useXeroSuccess = () => {
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const searchParams = new URLSearchParams(window.location.search);
    const dispatch = useDispatch();

    useEffect(() => {
        const code = searchParams.get("code") || "";
        const state = searchParams.get("state") || "";
        const siteId = LoginHelper.getXeroRedirectUrl()?.split("/")[2];
        setIsLoading(true);
        connectAuth(siteId || "", {
            code: code,
            requestUri: `${window.location.origin}${LoginHelper.getXeroRedirectUrl()}` || "",
            state: state
        }).then(() => {
            dispatch(xeroAuthInfo(siteId || ""))
                .then(() => {
                    navigate(LoginHelper.getXeroRedirectUrl() || "");
                });
        }).catch(() => setIsLoading(false));

    }, []);

    return {
        isLoading
    }
}