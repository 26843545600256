import { Grid } from '@mui/material'
import { FiEdit } from 'react-icons/fi'
import { PastCustmerData } from '../../../../constants/DummyData'
import { PropertyCustomerList } from '../../../../store/sites/Type'
import { TextButton } from '../../../commoncomponents/designs/Button'
import { Paragraph1 } from '../../../commoncomponents/designs/Text'
import { PaperContainer } from '../../Style'
import { FC, useState } from "react";
import EditCustomerModal from '../currentcustomer/EditCustomerModal'

type PastCustomersProps = {
    customerList: PropertyCustomerList[];
}

const PastCustomers: FC<PastCustomersProps> = ({
    customerList
}) => {
    const [openEditModal, setOpenEditModal] = useState<PropertyCustomerList | undefined>(undefined);

    return (
        <PaperContainer elevation={0} padding={30}>
            <Paragraph1 style={{ fontSize: 16 }}>Past Customers</Paragraph1>
            <Grid container>
                <Grid item xs={12} sm={7}>
                    {
                        customerList.map((item) => (
                            <Grid key={item.id} container mt={"10px"} alignItems={"center"} justifyContent={"space-between"}>
                                <Grid item>
                                    <Paragraph1 style={{ fontSize: 16 }} fontWeight={700}>{item.name}</Paragraph1>
                                </Grid>
                                <Grid item>
                                    <TextButton
                                        onClick={() => setOpenEditModal(item)}
                                        label={"EDIT"} icon={<FiEdit />} />
                                </Grid>
                            </Grid>
                        ))
                    }
                </Grid>
            </Grid>
            {
                openEditModal && <EditCustomerModal
                    customerInfo={openEditModal}
                    type={"past"}
                    handleClose={() => setOpenEditModal(undefined)} />
            }
        </PaperContainer>
    )
}

export default PastCustomers