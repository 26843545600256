import { Grid } from "@mui/material";
import { CardContainer } from "../commoncomponents/CommonStyle";
import { Heading2 } from "../commoncomponents/designs/Text";
import { usePressEnterHook } from "../../common/Hooks";
import { CustomTextfieldWrapper } from "../commoncomponents/CustomTextfieldWrapper";
import { Formik } from "formik";
import {
  forgotPassword,
  forgotPasswordInitialValue,
  forgotPasswordValidationSchema,
} from "./Util";
import { toast } from "react-toastify";
import { ToastSuccessConfig } from "../../constants/SiteConstants";
import { PrimaryButton } from "../commoncomponents/designs/Button";
import { colors } from "../../constants/Colors";

const ForgotPassword = () => {
  const handleForgotpassword = (
    email: string,
    setSubmitting: (isSubmitting: boolean) => void
  ) => {
    forgotPassword(encodeURI(email))
      .then(() => {
        toast.success(
          "Please check your email. It contains secret code to reset password",
          ToastSuccessConfig
        );
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Grid container alignItems={"center"} justifyContent={"center"}>
      <Grid mt={"100px"} item xs={11} sm={6} lg={4}>
        <CardContainer elevation={0}>
          <Heading2 mb={"30px"} textAlign={"center"}>
            Forgot Password
          </Heading2>
          <Formik
            initialValues={forgotPasswordInitialValue}
            validationSchema={forgotPasswordValidationSchema}
            onSubmit={(values, { setSubmitting }) => {
              handleForgotpassword(values.email, setSubmitting);
            }}
          >
            {({ handleSubmit, isSubmitting }) => {
              usePressEnterHook(handleSubmit);
              return (
                <>
                  <Grid container gap={"30px"} direction={"column"}>
                    <Grid item xs={12}>
                      <CustomTextfieldWrapper
                        name="email"
                        id="email"
                        fullWidth
                        label={"Email"}
                      />
                    </Grid>
                    <Grid container justifyContent={"center"}>
                      <PrimaryButton
                        isLoading={isSubmitting}
                        textcolor={colors.secondary.light}
                        backgroundcolor={colors.primary.dark}
                        fullWidth
                        onClick={() => handleSubmit()}
                        label="Reset password"
                      />
                    </Grid>
                  </Grid>
                </>
              );
            }}
          </Formik>
        </CardContainer>
      </Grid>
    </Grid>
  );
};

export default ForgotPassword;
