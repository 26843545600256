import { useState } from "react";
import { useDispatch } from "react-redux";
import { updateProfileImageAction } from "../../../store/profile/ActionCreator";
import { addNewUser, uploadUserImageAction } from "../../../store/user/ActionCreator";
import { AddUserType } from "../../../store/user/Type";

export const useAddUserHook = () => {

    const dispatch = useDispatch();

    const [uploadPercent, setUploadPercent] = useState(0);
    const [isImageUploading, setIsImageUploading] = useState(false);

    const addUserDetail = (
        value: AddUserType,
        setLoading: (isLoading: boolean) => void,
        handleClose: () => void
    ) => {
        dispatch(addNewUser(value))
            .then((res: number) => {
                if (!value.image) {
                    handleClose();
                    return;
                }
                setIsImageUploading(true);
                value.image && dispatch(uploadUserImageAction({
                    image: value.image,
                    id: res,
                    firstName: value.firstName,
                    lastName: value.lastName,
                    email: value.email,
                    password: value.password,
                    imageUrl: value.imageUrl,
                    role: value.role,
                }, setUploadPercent)).then(() => {
                    setIsImageUploading(false);
                    handleClose();
                })
            })
            .finally(() => setLoading(false))
    }

    return {
        addUserDetail,
        uploadPercent,
        isImageUploading
    }
}