import { BiGroup } from "react-icons/bi";
import { BsGear } from "react-icons/bs";
import { TiKeyOutline } from "react-icons/ti";
import { object, string } from "yup";
import { OrgDetailType } from "../../../store/organisation/Type";
import { doesLoggedinUserHavePermission } from "../../../util/SiteUtil";
import { PERMISSIONS, USER_ROLES } from "../../../util/Type";
import { tabOptionType } from "../../commoncomponents/designs/Type";

export const OrganistaionStepper = [
    {
        label: 'Site Manager',
        url: '/portfolio',
    },
    {
        label: 'Organisation',
        url: `/organisation`,
    }
];

export const addOrganisationStepper = [
    ...OrganistaionStepper,
    {
        label: 'New organisation',
    },
];

export const editOrganisationStepper = (orgName: string) => [
    ...OrganistaionStepper,
    {
        label: orgName,
    },
];

export const addUsertabOptions = (role: string): tabOptionType[] => [
    {
        label: 'ORG. SETTINGS',
        value: 'settings',
        icon: <BsGear />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.ORGANISATION_MANAGEMENT, PERMISSIONS.VIEW)
    }
];

export const updateUserTabOptions = (role: string): tabOptionType[] => [
    ...addUsertabOptions(role),
    {
        label: 'USERS',
        value: 'users',
        icon: <BiGroup />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.ADMIN_USER, PERMISSIONS.VIEW)
    },
    {
        label: 'Sites',
        value: 'sites',
        icon: <TiKeyOutline />,
        isAvailaible: doesLoggedinUserHavePermission(role, USER_ROLES.MANAGE_SITE_PERMISSION, PERMISSIONS.VIEW)
    }
];

export const SettingFormInitialValue = (orgInfo?: OrgDetailType): OrgDetailType => ({
    id: orgInfo?.id || "",
    name: orgInfo?.name || "",
    imageUrl: orgInfo?.imageUrl || "",
    image: null,
    createdAt: orgInfo?.createdAt || "",
    modifiedAt: orgInfo?.modifiedAt || "",
    siteList: {
        allList: [],
        selectedList: []
    },
    userList: {
        allList: [],
        selectedList: []
    }
});

export const SettingValidationSchema = object().shape({
    name: string().required("Organisation name is required"),
});
