import { ActionPayload } from "../../common/Base";
import { IS_ERROR, IS_LOADING } from "../CommonType";
import { SiteListType, UPDATE_CUSTOMER_PROPERTY_LIST, UPDATE_PROPERTY_IMAGE, UPDATE_PROPERTY_LIST, UPDATE_SITE_DETAIL, UPDATE_SITE_IMAGE, UPDATE_SITE_LIST, UPDATE_SITE_PROPERTY_DETAIL } from "./Type";
import _cloneDeep from "lodash/cloneDeep";

const initialState: SiteListType = {
    siteList: [],
    isLoading: false,
    selectedSiteDetail: undefined,
    error: "",
    manageProperty: [],
    selectedPropertyDetail: undefined,
    propertyCustomers: []
};

export const SiteListReducer = (state = initialState, action: ActionPayload<any>): any => {
    const stateClone = _cloneDeep(state);
    switch (action.type) {
        case UPDATE_SITE_LIST: {
            stateClone.siteList = action.payload;
            return stateClone;
        }
        case UPDATE_SITE_DETAIL: {
            stateClone.selectedSiteDetail = action.payload;
            return stateClone;
        }
        case UPDATE_PROPERTY_LIST: {
            stateClone.manageProperty = action.payload;
            return stateClone;
        }
        case UPDATE_CUSTOMER_PROPERTY_LIST: {
            stateClone.propertyCustomers = action.payload
            return stateClone;
        }
        case UPDATE_SITE_IMAGE: {
            if (stateClone.selectedSiteDetail) {
                stateClone.selectedSiteDetail.invoiceImageUrl = action.payload;
                stateClone.selectedSiteDetail.invoiceImage = null;
            }
            return stateClone;
        }
        case UPDATE_PROPERTY_IMAGE: {
            if (stateClone.selectedPropertyDetail) {
                stateClone.selectedPropertyDetail.imageUrl = action.payload;
                stateClone.selectedPropertyDetail.image = null;
            }
            return stateClone;
        }
        case UPDATE_SITE_PROPERTY_DETAIL: {
            stateClone.selectedPropertyDetail = action.payload;
            return stateClone;
        }
        case IS_LOADING: {
            stateClone.isLoading = action.payload;
            return stateClone;
        }
        case IS_ERROR: {
            stateClone.error = action.payload;
            return stateClone;
        }
        default: {
            return state;
        }
    };
}